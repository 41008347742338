import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Loader,
  TableComponent,
  Pagination,
  AlertBox,
  DeleteModal,
} from 'components';
import { confirmDelete } from 'constants/messages';
import { getReports, deleteReports } from 'store/actions/adminElements.actions';
import { DOWNLOAD, DATE_TIME_FORMAT } from 'constants/forms';
import { getReportDownload } from 'store/actions/adminElements.actions';

const Reports = () => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const dispatch = useDispatch();
  const { reports, loaded, error } = useSelector(
    ({ adminElements }) => adminElements
  );
  const isAlertActive = useSelector(({ alert }) => alert);

  const toggleModal = () => {
    if (isOpenDeleteModal) {
      setReportToDelete(null);
    }
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const onDeleteHandler = (id) => {
    toggleModal();
    setReportToDelete(id);
  };

  const confirmDeleteNews = () => {
    toggleModal();
    dispatch(deleteReports.request(reportToDelete));
    setReportToDelete(null);
  };

  useEffect(() => {
    dispatch(getReports.request());
  }, [dispatch]);

  const tableHead = [
    {
      name: 'File Name',
      key: 'client_filename',
    },
    {
      name: 'Exported At',
      key: 'created_at',
    },
    {
      name: 'Download',
      key: 'action-btn',
      isHidden: (body) => {
        return body.status !== 'ready';
      },
      class: 'mdi mdi-download',
      type: DOWNLOAD,
      onClick: (elem) => {
        const payload = {
          fileUrl: elem.file_name,
          fileName: elem.client_filename,
        };

        dispatch(getReportDownload.request(payload));
      },
    },
    {
      name: 'Delete',
      key: 'action-btn',
      class: 'mdi mdi-trash-can-outline',
      onClick: ({ id }) => onDeleteHandler(id),
    },
  ];

  const tableBody = reports?.data?.map((el) => ({
    id: el?.id,
    client_filename: el?.client_filename,
    file_name: el?.file_name,
    created_at: moment(el?.created_at).format(DATE_TIME_FORMAT) || '',
    status: el?.status || '',
  }));

  return (
    <Loader loaded={loaded}>
      {isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger" />
      )}
      <div className="card">
        <div className="card-body">
          <TableComponent tableHead={tableHead} tableBody={tableBody} />
          <Pagination
            action={getReports}
            currentPage={reports?.current_page}
            lastPage={reports?.last_page}
            goToPage
          />
        </div>
      </div>
      <DeleteModal
        isOpened={isOpenDeleteModal}
        toggle={toggleModal}
        data={confirmDelete}
        confirm={confirmDeleteNews}
        fromAdmin={true}
      />
    </Loader>
  );
};

export default Reports;
