import React from "react";
import { Loader, TableComponent } from "components";
import moment from "moment";

const TIME_FORMAT = "LT";
const DATE_FORMAT = "l";

const tableHead = [
  {
    name: "#",
    key: "#"
  },
  {
    name: "Action",
    key: "action"
  },
  {
    name: "Model",
    key: "model"
  },
  {
    name: "Date",
    key: "date",
    field: "date",
    format: data => moment.utc(data).local().format(DATE_FORMAT)
  },
  {
    name: "Time",
    key: "date",
    field: "date",
    format: data => moment.utc(data).local().format(TIME_FORMAT)
  }
];

const ActionHistory = ({userActions}) => {
  const tBody = userActions?.map(uAction => ({ action: uAction.action, date: uAction.created_at, model: uAction.model }))

  return (
    <Loader loaded={true}>
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">Action History</h4>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <TableComponent tableHead={tableHead} tableBody={tBody} />
        </div>
      </div>
    </Loader>
  );
};

export default React.memo(ActionHistory);
