import React, { useState, useEffect,useContext,  useMemo } from "react";
import { PageTitle, Loader, Checkbox, AlertBox } from "components";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Chevron } from "assets/icons/grey-chevron-right.svg";
import {
  getSaudiSeasonEventElement,
  element,
} from "store/actions/saudiSeasonsEvents.actions";
import {
  applyUserRequest,
  getUserRequestsPending,
} from "store/actions/requests.actions";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import { useFormik, getIn, FormikProvider, Field } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  TYPES,
  EVENT,
  fileTypes,
  updatedFileTypes,
  getElementName,
} from "constants/seasonsEvents";
import { getFormattedDateTime } from "../../utils/helpers";
import { serialize } from "object-to-formdata";
import { sizeToMB } from "utils/validation";
import { MAX_FILE_SIZE, TEXT } from "constants/forms";
import cn from "classnames";
import styles from "./styles.module.scss";
import { Context } from "context";

const SeasonEventApply = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { type, id, elementId, elementTypeId } = useParams();
  const [data, setData] = useState({});
  const [participatingElement, setParticipatingElement] = useState({});
  const { saudiSeasonEventElement, loaded } = useSelector(
    ({ saudiSeasonsEvents }) => saudiSeasonsEvents
  );
  const { error, applyError, userRequestPending } = useSelector(
    ({ requests }) => requests
  );
  const isAlertActive = useSelector(({ alert }) => alert);
  const { rtl } = useContext(Context);

  useEffect(() => {
    if (Object.keys(saudiSeasonEventElement).length) {
      const element = saudiSeasonEventElement.main_elements.find(
        (el) => el.id === elementId
      );
      setData(element);
    }
  }, [saudiSeasonEventElement, elementId]);

  useEffect(() => {
    dispatch(
      getSaudiSeasonEventElement.request({ type: TYPES[type], id, elementId })
    );
    return () => {
      dispatch(element.remove());
    };
  }, [dispatch, id, type, elementId]);

  useEffect(() => {
    dispatch(
      getUserRequestsPending.request({
        [type]: id,
        element_type_id: elementTypeId,
        element_format_id: data?.format?.id || null,
      })
    );
  }, [dispatch, type, id, elementTypeId, data]);


  const applicationRequirementsList = useMemo(() => {
    const applicationRequirements = data?.application_requirement;
    if (applicationRequirements?.length) {
      const sortedData = applicationRequirements?.filter(
        (item) => item.type !== TEXT
      );
      const textFieldData = applicationRequirements.find(
        (item) => item.type === TEXT
      );
      if (textFieldData) return [...sortedData, textFieldData];
      return sortedData;
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      participating_element_id: "",
      type,
      application_requirements: applicationRequirementsList?.map((item) => {
        return item.type === TEXT
          ? {
              id: item.id,
              text: item.text,
              isText: true,
            }
          : {
              id: item.id,
              file: item.file,
              isFile: true,
            };
      }),
      disclaimer: ["0"],
    },
    format: Yup.string().when("haveFormat", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    enableReinitialize: true,
    validationSchema: Yup.object({
      participating_element_id: Yup.string().required(t("messages.required")),
      application_requirements: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          file: Yup.mixed().when("isFile", {
            is: true,
            then: Yup.mixed().required(t("messages.required")),
            otherwise: Yup.mixed(),
          }),
          text: Yup.mixed().when("isText", {
            is: true,
            then: Yup.string().required(t("messages.required")).nullable(),
            otherwise: Yup.mixed(),
          }),
        })
      ),
      disclaimer: Yup.array().required(t("messages.required")),
    }),
    onSubmit: (values) => {
      const { disclaimer, ...restValues } = values;
      if (type === EVENT) {
        restValues["event_id"] = id;
      } else {
        restValues["season_id"] = id;
      }
      dispatch(
        applyUserRequest.request(
          serialize(
            {
              ...restValues,
              application_requirements:
                restValues?.application_requirements?.map((item) => ({
                  id: item.id,
                  file: item.file,
                  text: item.text,
                })),
            },
            { indices: true }
          )
        )
      );
    },
  });

  const handleSelectElement = ({ id, ...rest }) => {
    setParticipatingElement(rest);
    formik.setFieldValue("participating_element_id", id);
  };

  const getErrorMessage = (name) => {
    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);
    return touch && <p className="text-danger form-field-error">{error}</p>;
  };

  const getApplicationError = () => {
    let message = "";
    if (!userRequestPending?.length) {
      message = "requests.incomplete";
    } else if (applyError) {
      message = "requests.applied";
    }
    return !!message ? (
      <div className={styles.dropdownEmpty}>
        <span>{t(message)}</span>
      </div>
    ) : null;
  };

  const customRequirementInput = ({ form, field, ...props }) => {
    const { req, index } = props.req;
    return (
      <input
        id={`file${index}`}
        type="file"
        accept={fileTypes[req.type.toLowerCase()]}
        onChange={(e) =>
          formik.setFieldValue(
            `application_requirements[${index}].file`,
            e.target.files[0]
          )
        }
      />
    );
  };

  const validateRequirement = (val, req) => {
    let errorMessage = null;
    if (!fileTypes[req.type.toLowerCase()].includes(val?.type)) {
      errorMessage = t("messages.invalidFile");
    } else if (val.size && sizeToMB(val.size) > MAX_FILE_SIZE) {
      errorMessage = t("messages.fileSize");
    }
    return errorMessage;
  };

  const getRequestName = (name) => (
    <>
      <p className={styles.subtitle}>
        {t(type === EVENT ? "requests.event_name" : "requests.season_name")}
      </p>
      <p className={styles.subtitleContent}>{name}</p>
    </>
  );
  const checkWrongFormat = (index) => {
    const message = formik.errors.application_requirements?.[index]?.file;
    return (
      message === t("messages.invalidFile") ||
      message === t("messages.fileSize")
    );
  };
  const showMessage = (index, req) => {
    const lang = localStorage.getItem("i18nextLng");
    // eslint-disable-next-line no-mixed-operators
    const errorMessage =
      (checkWrongFormat(index) &&
        formik.errors?.application_requirements?.[index].file + " | ") ||
      " ";
    const fileType = updatedFileTypes[req.type.toLowerCase()];
    let message = null;
    if (lang === "en") {
      message = `${errorMessage} File (${fileType}) - Size (${MAX_FILE_SIZE}MB)`;
    } else {
      message = `${errorMessage} ملف (${fileType}) - الحجم (${MAX_FILE_SIZE}MB)`;
    }
    return message;
  };

  return (
    <Loader loaded={loaded}>
      <div>
        <button onClick={() => history.goBack()} className={styles.btnBack}>
          <Chevron />
          {type === EVENT
            ? t("seasons_events.event_details")
            : t("seasons_events.season_details")}
        </button>
      </div>
      {!applyError && isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger" />
      )}
      <div className={styles.title}>
        <PageTitle title={t("requests.apply_request")} />
      </div>
      <section className={styles.information}>
        <div className={styles.section}>
          {getRequestName(saudiSeasonEventElement?.name)}
        </div>
        <div className={styles.section}>
          <p className={styles.subtitle}>{t("requests.element")}</p>
          <p className={styles.subtitleContent}>
            {data?.element_type?.name ?? ""}
          </p>
        </div>
        {data?.format && (
          <div className={styles.section}>
            <p className={styles.subtitle}>{t("requests.category")}</p>
            <p className={styles.subtitleContent}>{data?.format?.name ?? ""}</p>
          </div>
        )}
        {data?.description && (
          <div className={styles.section}>
            <p className={styles.subtitle}>{t("requests.description")}</p>
            <p className={styles.subtitleContent}>{data?.description ?? ""}</p>
          </div>
        )}
        <div className={styles.section}>
          <p className={styles.subtitle}>{t("requests.choose_element")}</p>
          <div className={`${styles.subtitleDropdown}`}>
            <DropdownButton
              variant="light"
              id="dropdown-basic-button"
              title={
                formik.values.participating_element_id
                  ? getElementName(participatingElement, getFormattedDateTime, rtl)
                  : `${t("requests.select_element")}`
              }
              disabled={!userRequestPending?.length}
            >
              {userRequestPending?.map(
                ({ id, element_type, element_format, created_at }) => (
                  <Dropdown.Item
                    key={id}
                    className="d-flex justify-content-between align-items-center"
                    onClick={() =>
                      handleSelectElement({
                        id,
                        element_type,
                        element_format,
                        created_at,
                      })
                    }
                  >
                    <span>{element_format?.name || element_type.name}</span>
                    <span
                      className={styles.elementDateTime}
                    >{`${getFormattedDateTime(
                      created_at,
                      "l"
                    )} - ${getFormattedDateTime(created_at, "LT", rtl)}`}</span>
                  </Dropdown.Item>
                )
              )}
            </DropdownButton>
            {getErrorMessage("participating_element_id")}
            {getApplicationError()}
          </div>
        </div>
        <p className={styles.note}>{t("requests.note")}</p>
      </section>
      {applicationRequirementsList?.length && (
        <section className={styles.requirements}>
          <p className={styles.subtitle}>
            {t("requests.application_requirements")}
          </p>
          <div className={styles.requirementsList}>
            <FormikProvider value={formik}>
              {applicationRequirementsList?.map((req, index) => (
                <div
                  key={index}
                  className={`${req.type === TEXT ? "d-flex flex-column" : ""}`}
                >
                  <div className={styles.requirementsDescription}>
                    <p className={styles.subtitle}>
                      {`${index + 1}`} - {req.name}:
                    </p>
                    <p className={styles.subtitleContent}>{req.description}</p>
                  </div>
                  {req.type === TEXT ? (
                    <div className={styles.requirementsTextarea}>
                      <div className={styles.textareaContainer}>
                        <textarea
                          type="text"
                          value={
                            formik.values?.application_requirements?.[index]
                              ?.text || ""
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `application_requirements[${index}].text`,
                              e.target.value
                            )
                          }
                          className="form-control"
                        />
                        {getErrorMessage(
                          `application_requirements[${index}].text`
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.requirementsDocs}>
                      <label
                        htmlFor={`file${index}`}
                        className={cn(
                          styles.requirementsFile,
                          checkWrongFormat(index) &&
                            styles.requirementsErrorField
                        )}
                      >
                        {formik.values?.application_requirements?.[index]?.file
                          ?.name || req.type}
                      </label>
                      <span
                        className={cn(styles.message, {
                          [styles.error]:
                            formik.errors?.application_requirements?.[index]?.file,
                        })}
                      >
                        {showMessage(index, req)}
                      </span>
                      <Field
                        id={`file${index}`}
                        key={index}
                        name={`application_requirements[${index}].file`}
                        req={{ req, index }}
                        component={customRequirementInput}
                        validate={(val) => validateRequirement(val, req)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </FormikProvider>
          </div>
        </section>
      )}
      <section className={styles.disclaimer}>
        <div className={styles.disclaimerBtn}>
          <span className="px-1 requiredField"></span>
          <Checkbox name="disclaimer" formik={formik} value="0" />
          <label htmlFor="disclaimer">
            {saudiSeasonEventElement.disclaimer}
          </label>
        </div>
        {getErrorMessage("disclaimer")}
      </section>
      <section className={styles.btnSubmit}>
        <Button
          type="button"
          onClick={formik.handleSubmit}
          disabled={!userRequestPending?.length}
        >
          {t("buttons.submit")}
        </Button>
      </section>
    </Loader>
  );
};

export default SeasonEventApply;
