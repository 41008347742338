import React, { useEffect, useContext } from "react"
import {
  Loader,
  TableComponent,
  Pagination,
  AdminTopPanel,
  AlertBox
} from "components";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsHistory } from "store/actions/adminElements.actions";
import moment from "moment";
import { ADMIN_CREATE_NOTIFICATION } from "constants/paths";
import { compile } from "path-to-regexp";
import { Context } from "context";

const DATE_FORMAT = "l";

const NotificationsHistory = () => {
  const { notificationsHistory, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch()
  const history = useHistory();
  const { user } = useContext(Context);

  useEffect(() => {
    dispatch(getNotificationsHistory.request())
  },[ dispatch ])

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Type",
      key: "recipient"
    },
    {
      name: "Message | Ar",
      key: "message_ar",
    },
    {
      name: "Message | En",
      key: "message_en",
    },
    {
      name: "Season",
      key: "season"
    },
    {
      name: "Event",
      key: "event"
    },
    {
      name: "Element Type",
      key: "element_type"
    },
    {
      name: "Way to Send",
      key: "transport",
    },
    {
      name: "Sent Date",
      key: "sent_at",
    },
    {
      name: "Sender’s Name",
      key: "name",
    },
  ];

  const tableBody = notificationsHistory?.data && notificationsHistory.data.map(el => ({
    id: el?.id,
    recipient: el?.recipient || "",
    message: el?.message || "",
    season: el?.season?.name || "",
    event: el?.event?.name || "",
    transport: el?.transport || "",
    sent_at: moment(el?.sent_at).format(DATE_FORMAT) || "",
    name: el?.adminuser?.name || "",
    element_type: el?.element_type?.slug || "",
    message_ar: el?.message_ar || '',
    message_en: el?.message_en || '',
  }));

  const buttonTools = {
    label: 'New Notification',
    onClick: () => history.push(compile(ADMIN_CREATE_NOTIFICATION)())
  }

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }

      <AdminTopPanel
        withButton
        buttonTools={buttonTools}
      />

      <div className="card">
        <div className="card-body">
          <TableComponent
            tableHead={tableHead.filter((item) => user?.user_type === 1 ? item : item.key !== "name")}
            tableBody={tableBody}
          />
          <Pagination
            action = {getNotificationsHistory}
            currentPage = {notificationsHistory?.current_page}
            lastPage = {notificationsHistory?.last_page}
            goToPage
          />
        </div>
      </div>
    </Loader>
  )
}

export default NotificationsHistory