const FOOD_CATEGORY_DATA = {
  value: "food_category_id",
  name: "Food Category",
  field: "dropdown",
  key: "foodCategories",
};

const COMPLETE_STATUS_DATA = {
  value: "status",
  name: "Status",
  field: "dropdown",
  data: [
    { name: "Complete", value: "1" },
    { name: "Incomplete", value: "0" },
  ],
};

const SAUDI_REGION_DATA = {
  value: "saudi_region_id",
  name: "Region",
  field: "dropdown",
  key: "regions",
};
const REGION_DATA = {
  value: "region_id",
  name: "Region",
  field: "dropdown",
  key: "regions",
};

const HAVE_OTHER_BRANCHES_DATA = {
  value: "is_other_branches",
  name: "Do you have other branches",
  field: "dropdown",
  data: [
    { name: "Yes", value: "1" },
    { name: "No", value: "0" },
  ],
};

const HOW_MANY_BRANCHES_DATA = {
  value: "num_branches_in_saudi",
  name: "How many branches in Saudi Arabia",
  field: "number",
};

const BRANCH_REGION_DATA = {
  value: "branch_region_id",
  name: "What are the branch Region",
  field: "dropdown",
  key: "regions",
};

const AVERAGE_NUMBER_WORKERS_DATA = {
  value: "average_workers_per_branch",
  name: "Average number of workers in one branch",
  field: "number",
};

const PERSON_BILL_DATA = {
  value: "average_bill_per_person",
  name: "Average per person bill",
  field: "number",
};

const ESTABLISHMENT_DATE = {
  value: "establishment_date",
  name: "Date of Establishment",
  field: "date",
};

const DATE_BEFORE = {
  value: "element_created_before",
  name: "Date - Before",
  field: "date",
};

const DATE_AFTER = {
  value: "element_created_after",
  name: "Date - After",
  field: "date",
};

const USER_NAME = {
  value: "name",
  field: "text",
  name: "User Name",
};

const EMAIL = {
  value: "email",
  field: "text",
  name: "Email",
};

const MOBILE_NUMBER = {
  value: "mobile_number",
  field: "text",
  name: "Mobile Number",
};

const CATEGORIES = {
  value: "favorite",
  name: "Favorite's Category",
  field: "dropdown",
  data: [
    { name: "Not Categorized", value: "0" },
    { name: "C", value: "1" },
    { name: "B", value: "2" },
    { name: "C", value: "3" },
  ],
};
const PARTICIPATE_IN_PREVIOUS_EVENTS_DATA = {
  value: "is_participated_in_prev_events",
  name: "Did you participate in previous activities?",
  field: "dropdown",
  data: [
    { name: "Yes", value: "1" },
    { name: "No", value: "0" },
  ],
};

const NUMBER_OF_WORKERS_DATA = {
  value: "num_of_worker_per_branch",
  name: "Number of workers in one branch",
  field: "number",
};

const PRODUCT_CATEGORY_DATA = {
  value: "product_category_id",
  name: "Product Category",
  field: "dropdown",
  key: "productCategories",
};

const MINIMUM_PRODUCT_PRICES_DATA = {
  value: "min_price",
  name: "Minimum prices of the products",
  field: "number",
};

const MAXIMUM_PRODUCT_PRICES_DATA = {
  value: "max_price",
  name: "Maximum prices of the products",
  field: "number",
};

const TALENT_CATEGORY_DATA = {
  value: "category_id",
  name: "Talents Category",
  field: "dropdown",
  key: "talentCategories",
};

const SUBMISION_TYPE_DATA = {
  value: "submission_type",
  name: "Submission type",
  field: "dropdown",
  data: [
    { name: "Individual", value: "1" },
    { name: "Group", value: "2" },
  ],
};

const BIRTH_DATE_DATA = {
  value: "birthdate",
  name: "Birth Date",
  field: "date",
};

const GENDER_DATA = {
  value: "gender",
  name: "Gender",
  field: "dropdown",
  data: [
    { name: "Male", value: "1" },
    { name: "Female", value: "2" },
  ],
};
const TEMPORARY_WORKS_CATEGORIES = {
  value: "category_id",
  name: "Categories",
  field: "dropdown",
  key: "temporaryWorksCategories",
};

const NATIONALITY_DATA = {
  value: "nationality_id",
  name: "Nationality",
  field: "dropdown",
  key: "countries",
};

const SOCIAL_STATUS_DATA = {
  value: "social_status",
  name: "Social Status",
  field: "dropdown",
  data: [
    { name: "Single", value: "1" },
    { name: "Married", value: "2" },
    { name: "Other", value: "3" },
  ],
};

const SPECIAL_NEEDS = {
  value: "special_needs",
  name: "Special Needs",
  field: "dropdown",
  data: [
    { name: "yes", value: "1" },
    { name: "no", value: "0" },
  ],
};

const COUNTRY_OF_RESIDENCE_DATA = {
  value: "country_id",
  name: "Country of residence",
  field: "dropdown",
  key: "countries",
};

const IDENTITY_TYPE_DATA = {
  value: "identity_type",
  name: "Identity Type",
  field: "dropdown",
  data: [
    { name: "National Id", value: "1" },
    { name: "Iqamah", value: "2" },
  ],
};

const WORKED_BEFORE_DATA = {
  value: "worked_before",
  name: "Have you Worked Before?",
  field: "dropdown",
  data: [
    { name: "Yes", value: "1" },
    { name: "No", value: "0" },
  ],
};

const LANGUAGES_DATA = {
  value: "language",
  name: "Languages ",
  field: "dropdown",
  key: "languages",
};

const LANGUAGE_LEVEL_DATA = {
  value: "level",
  name: "Language Level",
  field: "dropdown",
  data: [
    { name: "Good", value: "good" },
    { name: "Very Good", value: "very_good" },
    { name: "Excellent", value: "excellent" },
  ],
};

export const filteredCategoriesType = {
  "restaurants/restaurant-or-cafe": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    AVERAGE_NUMBER_WORKERS_DATA,
    PERSON_BILL_DATA,
    ESTABLISHMENT_DATE,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  "restaurants/food-truck": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    NUMBER_OF_WORKERS_DATA,
    PERSON_BILL_DATA,
    ESTABLISHMENT_DATE,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  "restaurants/food-and-drinks-provider": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    PERSON_BILL_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  "stores/store": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    ESTABLISHMENT_DATE,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  "stores/pop-up-stores": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    ESTABLISHMENT_DATE,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  "stores/personal-stores": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  talents: [
    TALENT_CATEGORY_DATA,
    SUBMISION_TYPE_DATA,
    BIRTH_DATE_DATA,
    GENDER_DATA,
    NATIONALITY_DATA,
    SOCIAL_STATUS_DATA,
    COUNTRY_OF_RESIDENCE_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
    SPECIAL_NEEDS,
  ],
  "temporary-work": [
    TEMPORARY_WORKS_CATEGORIES,
    BIRTH_DATE_DATA,
    GENDER_DATA,
    NATIONALITY_DATA,
    IDENTITY_TYPE_DATA,
    SOCIAL_STATUS_DATA,
    SAUDI_REGION_DATA,
    WORKED_BEFORE_DATA,
    LANGUAGES_DATA,
    LANGUAGE_LEVEL_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
    SPECIAL_NEEDS,
  ],
};

export const initialValues = {
  food_category_id: "",
  saudi_region_id: "",
  is_other_branches: "",
  num_branches_in_saudi: "",
  branch_region_id: "",
  average_workers_per_branch: "",
  average_bill_per_person: "",
  establishment_date: "",
  is_participated_in_prev_events: "",
  num_of_worker_per_branch: "",
  product_category_id: "",
  min_price: "",
  max_price: "",
  category_id: "",
  submission_type: "",
  birthdate: "",
  gender: "",
  nationality_id: "",
  social_status: "",
  special_needs: "",
  country_id: "",
  identity_type: "",
  worked_before: "",
  language: "",
  level: "",
  status: "",
  temporaryWorksCategories: "",
  mobile_number: "",
  name: "",
  email: "",
  element_created_after: "",
  element_created_before: "",
  favorite: "",
};
