import React, { useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Context } from "context";
import styles from "./styles.module.scss";

const LanguageSelect = ({ formik, label, name }) => {
  const { t } = useTranslation();
  const { languages } = useSelector((state) => state.elements);
  const { rtl } = useContext(Context);

  const addLanguage = () => {
    if (!formik.values[name].some((el) => el.language === null)) {
      return formik.setFieldValue(name, [
        ...(formik.values[name] || []),
        { language: null, level: null },
      ]);
    }
  };

  const checkIsLengSelected = (id) =>
    formik.values[name].filter((el) => el.language === id).length;
  const deleteLanguage = (index) => () =>
    formik.setFieldValue(
      name,
      formik.values[name].filter((_, idx) => idx !== index)
    );

  useEffect(() => {
    if (!formik.values[name]?.length) {
      formik.setFieldValue(name, [{ language: null, level: null }]);
    }
  }, [formik.values, name, formik]);
  return (
    <>
      {formik.values?.[name]?.map((_, index) => (
        <div
          className={classnames("d-flex my-2", styles.languageBox)}
          key={index}
        >
          <Form.Control
            as="select"
            className={classnames(styles.input, rtl ? "ml-20" : "mr-20")}
            custom
            name={`${name}.[${index}].language`}
            onChange={formik.handleChange}
            value={formik.values[name]?.[index]?.language || ""}
          >
            <option value="" disabled selected>
              {t(`${label}.languages`)}
            </option>
            {languages.map(({ name, id }) => (
              <option key={id} value={id} disabled={checkIsLengSelected(id)}>
                {name}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            as="select"
            className={classnames(styles.input)}
            custom
            name={`${name}[${index}].level`}
            onChange={formik.handleChange}
            value={formik.values[name]?.[index]?.level || ""}
          >
            <option value="" disabled selected>
              {t(`${label}.level`)}
            </option>
            {Object.entries(
              t(`${label}.levels`, {
                returnObjects: true,
              })
            ).map((item, index) => (
              <option key={index} value={item[0]}>
                {item[1]}
              </option>
            ))}
          </Form.Control>
          <div className="d-flex">
            {formik.values[name]?.length > 1 && (
              <button
                className={classnames("btn-list", styles.languageBoxDelete)}
                onClick={deleteLanguage(index)}
                type="button"
              >
                x
              </button>
            )}
            <button
              className={classnames("btn-list", styles.languageBoxAdd)}
              onClick={addLanguage}
              type="button"
            >
              +
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

LanguageSelect.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(LanguageSelect);
