import React, { useState, useContext, useMemo } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import _ from "lodash";
import {
  RadioButton,
  PhoneInput,
  FileInput,
  LanguageSelect,
  TextArea,
  DatePicker,
  Select,
  PersonalInfo,
  CountOfMembers,
  SocialMedia,
  Checkbox,
  ContactList,
  NumberInput,
  TableComponent,
  ApplicationRequirenmentsTable,
} from "components";
import AccordionHeader from "./AccordionHeader";
import {
  SINGLE,
  TEXT,
  PROFILE,
  DROPDOWN,
  PHONE_INPUT,
  LANGUAGES,
  DATE,
  FILE,
  TEXT_AREA,
  RADIO_LIST,
  PERSONAL_INFO,
  COUNT_OF_MEMBERS,
  SOCIAL_MEDIA,
  CONTACT_LIST,
  data,
  CHECKBOX,
  NUMBER,
  social_statuses,
  gender,
  special_needs,
  STATUS,
  COMMENT_TABLE,
  REDIRECT_BUTTON,
  TABLE,
  ELEMENTS_STATUSES,
  APPLICATION_REQUIREMENTS_TABLE,
  LINK,
  MISSING_DATA,
  ELEMENT_TYPES,
  INVERETED_TYPES,
  PRODUCT_TYPES,
  languageLevel,
  status,
  disability_period,
  personalInfoDependencies,
} from "./contants";
import { PDF_TYPE } from "constants/forms";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useFieldTouched } from "utils/forms";
import { isFieldWithError } from "utils/helpers";
import { Context } from "context";
import { getFormattedDateTime, filterFields } from "../../utils/helpers";
import ElementComments from "../ElementComments";
import { compile } from "path-to-regexp";
import { ADMIN_VIEW_ELEMENTS } from "constants/paths";
import { ADMIN_VERIFY_URL } from "constants/fileLink";
import { setCurrentTab } from "store/actions/accordion.actions";

const findInParentOrChild = (parent, toFind) => {
  const isEqualId = (item) => {
    return item?.id === toFind;
  };
  const inParent = parent?.find(isEqualId);
  if (inParent) {
    return inParent.name;
  }
  return (
    parent
      ?.map((item) => item.children)
      .flat()
      .find(isEqualId)?.name || MISSING_DATA
  );
};

const Accordion = ({
  formik = null,
  viewOnly = false,
  viewOnlyData,
  restState,
}) => {
  const { t } = useTranslation();
  const { type, id } = useParams();
  const { user } = useContext(Context);
  const dispatch = useDispatch();
  const history = useHistory();
  const { languages, interest, ...state } = useSelector(
    (state) => state.elements
  );
  const { detailsSelected } = useSelector(({ accordion }) => accordion);

  const handleDetailToogle = (index) => {
    let newData = { ...detailsSelected };
    Object.keys(newData).forEach((data) =>
      +data === index
        ? (newData[data] = !newData[data])
        : (newData[data] = false)
    );
    dispatch(setCurrentTab(newData));
  };
  const [personalInfoSelected, setPersonalInfoSelected] = useState(null);
  const setFieldTouched = useFieldTouched(formik);
  const errorMessage = (name) => {
    return (
      typeof formik?.errors[name] === "string" &&
      formik?.touched[name] && (
        <p className="mt-2 text-danger form-field-error">
          {formik?.errors[name] && t(`messages.${formik?.errors[name]}`)}
        </p>
      )
    );
  };

  const getCountry = (id) => {
    return restState?.countries?.find((country) => country.id === id)?.name;
  };

  const getDisabilityTypes = (typesId) => {
    return restState?.disabilityTypes
      ?.filter((type) => typesId?.indexOf(type.id) !== -1)
      ?.map((type) => type.name_en)
      ?.join(", ");
  };

  const getLanguage = (id) => {
    return restState?.languages?.find((lang) => lang.id === id)?.name;
  };

  const redirectTo = (type, id) => {
    history.push(
      compile(ADMIN_VIEW_ELEMENTS)({
        element_type: ELEMENT_TYPES[type],
        type,
        id,
      })
    );
  };

  const slugCompare = (data, slug) => {
    const element_format = data["participating_element.element_format.slug"];
    const element_type = data[slug];
    return INVERETED_TYPES[element_format] || INVERETED_TYPES[element_type];
  };

  const getPersonalInfo = (data) => {
    data &&
      data.forEach((item) => {
        personalInfoDependencies.forEach(
          ({ key, dependency, dependenceValue }) => {
            if (
              (Array.isArray(item?.[dependency]) &&
                !item?.[dependency].includes(dependenceValue)) ||
              item?.[dependency] !== dependenceValue
            ) {
              delete item[key];
            }
          }
        );
      });

    return data?.map((item) => ({
      ...item,
      gender: gender.find((g) => g.value === item.gender)?.name,
      country_of_residence_id: getCountry(item.country_of_residence_id),
      nationality_id: getCountry(item.nationality_id),
      disability_type: getDisabilityTypes(item.disability_type),
      social_status: social_statuses.find((s) => s.value === item.social_status)
        ?.name,
      special_needs: special_needs.find((s) => s.value === item.special_needs)
        ?.name,
      additional_support: special_needs.find(
        (s) => s.value === item.special_needs
      )?.name,
      disability_period: disability_period.find(
        (p) => p.value === item.disability_period
      )?.name,
    }));
  };

  let elementType = type;

  if (viewOnly) {
    elementType = PRODUCT_TYPES[type];
  }
  const createInfoTable = (info, label) => {
    return Object.entries(info)?.map(([k, v]) => (
      <span key={k} className="mr-3">
        <b>{t(`${label}.${k}`)}:</b>{" "}
        {(t(`${label}.${v}`).startsWith(`${label}.`)
          ? v
          : t(`${label}.${v}`)) || MISSING_DATA}
      </span>
    ));
  };

  const createMultiplyElements = (optionValues, label) => {
    const elementList = optionValues
      .filter(({ value }) => viewOnlyData?.[label]?.includes(value))
      .map((item) => item.name);
    return (
      <span>{elementList.length ? elementList.join(" , ") : MISSING_DATA}</span>
    );
  };

  const createViewElement = ({
    option: {
      data,
      items,
      type,
      label,
      optionValues,
      text,
      slug,
      elementId,
      tableHead,
      stateField,
    },
    detail: { title, forViewOnly = false },
  }) => {
    if (data) {
      if (viewOnlyData[label] instanceof Array) {
        return (
          <span>
            {viewOnlyData[label]
              ?.map(
                (item) =>
                  restState[data].find((val) =>
                    item.id ? val.id === item.id : item === val.id
                  )?.name_en
              )
              .join(", ") || MISSING_DATA}
          </span>
        );
      }
      return (
        <span>{findInParentOrChild(restState[data], viewOnlyData[label])}</span>
      );
    } else if (items) {
      const itemValue = items.find(
        (item) => item.value === viewOnlyData?.[label]
      )?.name;
      return (
        <span>
          {_.capitalize(
            t(`${title}.${itemValue}`).startsWith(`${title}.`)
              ? itemValue
              : t(`${title}.${itemValue}`)
          ) || MISSING_DATA}
        </span>
      );
    } else if (optionValues) {
      return <>{createMultiplyElements(optionValues, label)}</>;
    } else if (type === FILE) {
      return viewOnlyData[label] ? (
        <a
          href={`${ADMIN_VERIFY_URL}${viewOnlyData[label]}`}
          className={styles.fileContent}
        >
          Get file
        </a>
      ) : (
        <button className={styles.fileEmpty} disabled={true}>
          Get file
        </button>
      );
    } else if (type === DATE) {
      return (
        <span>
          {forViewOnly
            ? `${getFormattedDateTime(viewOnlyData?.[label], "l")}
                ${getFormattedDateTime(
                  viewOnlyData?.[label],
                  "LT"
                ).toLowerCase()}`
            : getFormattedDateTime(viewOnlyData?.[label], "LLLL") ||
              MISSING_DATA}
        </span>
      );
    } else if (type === CONTACT_LIST) {
      return (
        <ul className="list-group">
          {viewOnlyData?.[label]?.map((item, idx) => (
            <li key={idx} className="list-group-item">
              {createInfoTable(item, type)}
            </li>
          )) || MISSING_DATA}
        </ul>
      );
    } else if (type === LANGUAGES) {
      return (
        <ul className="list-group">
          {viewOnlyData?.[label]?.map((item, idx) => (
            <li key={idx} className="list-group-item d-flex flex-column">
              {createInfoTable(
                {
                  level: languageLevel[item.level],
                  language: getLanguage(item.language),
                },
                title
              )}
            </li>
          )) || MISSING_DATA}
        </ul>
      );
    } else if (type === PERSONAL_INFO) {
      return (
        <ul className="list-group">
          {getPersonalInfo(viewOnlyData?.[label])?.map(({ id, ...item }) => (
            <li key={id} className="list-group-item d-flex flex-column">
              {createInfoTable(item, title)}
            </li>
          )) || MISSING_DATA}
        </ul>
      );
    } else if (type === STATUS) {
      return (
        <p
          className={`p-1 mb-0 ${
            viewOnlyData?.[label]
              ? "badge bg-soft-success text-success"
              : "badge bg-soft-danger text-danger"
          }`}
        >
          {`${status[viewOnlyData?.[label]] || MISSING_DATA}`}
        </p>
      );
    } else if (type === COMMENT_TABLE) {
      return <ElementComments elementId={id} isAccordion={true} />;
    } else if (type === REDIRECT_BUTTON) {
      return (
        <button
          className={classnames("btn", styles.loadBtn)}
          onClick={() => {
            viewOnlyData &&
              redirectTo(
                slugCompare(viewOnlyData, slug),
                viewOnlyData[elementId]
              );
          }}
          disabled={!viewOnlyData?.[slug] && !viewOnlyData?.[elementId]}
        >
          {text}
        </button>
      );
    } else if (type === TABLE) {
      const tableBody = stateField
        ? restState[stateField]?.data?.map((item) => ({
            status_updated_by: item?.status_updated_by || "",
            old_value: ELEMENTS_STATUSES[item?.old_value] || "",
            new_value: ELEMENTS_STATUSES[item?.new_value] || "",
            created_at: getFormattedDateTime(
              item?.created_at,
              "MM/D/YYYY, h:mm a"
            ),
          }))
        : viewOnlyData?.[label];
      return <TableComponent tableHead={tableHead} tableBody={tableBody} />;
    } else if (type === APPLICATION_REQUIREMENTS_TABLE) {
      return <ApplicationRequirenmentsTable data={viewOnlyData?.[label]} />;
    } else if (type === LINK) {
      return (
        <a href={`${String(viewOnlyData?.[label]) || "#"}`}>{`${
          String(viewOnlyData?.[label]) || MISSING_DATA
        }`}</a>
      );
    } else {
      if (label === "count_of_members") {
        return <span>{viewOnlyData?.["personal_info"]?.length || 0}</span>;
      }
      return <span>{`${String(viewOnlyData?.[label]) || MISSING_DATA}`}</span>;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkAccessAndMode = (item) => {
    let show = true;
    if (item.accessType) {
      show = item.accessType?.includes(user?.user_type);
    }
    if (item.adminAccesType) {
      return item.adminAccesType?.includes(user?.user_type);
    }
    return show;
  };

  const createViewOnlyList = (index, option, detail) => (
    <div className={styles.optionsList} key={index}>
      <p className={styles.optionsTitle}>
        {option.titleText ? (
          <span>{option.titleText}</span>
        ) : (
          <span>{t(`${detail.title}.${option.label}`)}</span>
        )}
        {option.subTitle && (
          <span>{t(`${detail.title}.${option.subTitle}`) || MISSING_DATA}</span>
        )}
      </p>
      {createViewElement({ option, detail })}
    </div>
  );
  const filteredDataList = useMemo(() => {
    return data[elementType]?.filter(checkAccessAndMode).reduce(
      (acum, el) => ({
        ...acum,
        [el.name]: filterFields(formik?.values, el.options),
      }),
      {}
    );
  }, [checkAccessAndMode, elementType, formik]);
  return (
    <>
      {data[elementType]?.filter(checkAccessAndMode).map((detail, index) => (
        <div
          key={index}
          className={[
            classnames(styles.detail, {
              [styles.detailSelected]: detailsSelected[index],
            }),
          ]}
        >
          <AccordionHeader
            userType={user.user_type}
            formik={formik}
            handleToggle={handleDetailToogle}
            index={index}
            detail={detail}
            dependencyValue={state?.[detail.dependencyData]}
            isAdmin={viewOnly}
            filteredOptions={filteredDataList[detail.name]}
          />
          {viewOnly ? (
            <div className={styles.detailOptions}>
              <div key={index}>
                {detail.adminOptions?.map((option, index) => {
                  return option.notNullable
                    ? viewOnlyData[option.label] &&
                        createViewOnlyList(index, option, detail)
                    : option.dependency
                    ? (option.dependenceValue.includes(
                        viewOnlyData[option.dependency]
                      ) &&
                        createViewOnlyList(index, option, detail)) ||
                      null
                    : createViewOnlyList(index, option, detail);
                }) ||
                  detail.options.map((option, index) => {
                    return createViewOnlyList(index, option, detail);
                  })}
              </div>
            </div>
          ) : (
            <div className={styles.detailOptions}>
              <div key={index}>
                {filteredDataList[detail.name].map((option, index) => {
                  return (
                    <div
                      className={classnames(
                        styles[option.label],
                        styles.optionsList
                      )}
                      key={index}
                    >
                      <div>
                        {option.label && (
                          <p className={styles.optionsTitle}>
                            <span
                              className={classnames({
                                requiredField: option.required ?? true,
                              })}
                            >
                              {t(`${detail.title}.${option.label}`)}
                            </span>
                            {option.fileMessage && (
                              <span className={`mx-1 ${styles.optionSubTitle}`}>
                                - {t(`${detail.title}.${option.fileMessage}`)}
                              </span>
                            )}
                          </p>
                        )}
                        {(() => {
                          switch (option.type) {
                            case PROFILE: {
                              return (
                                <div className={`${styles.profile_block}`}>
                                  <FileInput
                                    formik={formik}
                                    name={option.label}
                                    accept={option.dataType}
                                    multiple={option.multiple}
                                  />
                                  {!option.mightbeReplaced && (
                                    <div className="d-flex align-items-start flex-wrap flex-column">
                                      <p className="mb-0 pt-2">
                                        {t(
                                          `${detail.title}.${option.link_label}`
                                        )}
                                      </p>
                                      <input
                                        className={classnames(
                                          `form-control`,
                                          styles.optionInpt,
                                          styles[option.class],
                                          {
                                            invalidField: isFieldWithError(
                                              formik,
                                              option.link_label
                                            ),
                                          }
                                        )}
                                        name={option.link_label}
                                        placeholder={
                                          option.placeholder === null
                                            ? ""
                                            : t(
                                                `${detail.title}.${
                                                  option.placeholder ||
                                                  option.link_label
                                                }`
                                              )
                                        }
                                        onChange={formik.handleChange}
                                        value={
                                          formik.values[option.link_label] || ""
                                        }
                                        onFocus={setFieldTouched}
                                      />
                                      {option.tag && (
                                        <span className="mx-2">
                                          {t(`${detail.title}.${option.tag}`)}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                            case TEXT: {
                              return (
                                <div className="d-flex align-items-center flex-wrap">
                                  <input
                                    className={classnames(
                                      `form-control`,
                                      styles.optionInpt,
                                      styles[option.class],

                                      {
                                        invalidField: isFieldWithError(
                                          formik,
                                          option.label
                                        ),
                                      }
                                    )}
                                    name={option.label}
                                    placeholder={
                                      option.placeholder === null
                                        ? ""
                                        : t(
                                            `${detail.title}.${
                                              option.placeholder || option.label
                                            }`
                                          )
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values[option.label] || ""}
                                    onFocus={setFieldTouched}
                                  />
                                  {option.tag && (
                                    <span className="mx-2">
                                      {t(`${detail.title}.${option.tag}`)}
                                    </span>
                                  )}
                                </div>
                              );
                            }

                            case DROPDOWN: {
                              return (
                                <Select
                                  {...option}
                                  styles={styles}
                                  formik={formik}
                                  name={option.label}
                                  data={state[option.data]}
                                  multiple={option.multiple}
                                  placeholder={t(
                                    `${detail.title}.${option.label}`
                                  )}
                                  className={styles.optionDropdown}
                                  dependency={option.dependency}
                                  condition={
                                    formik.values[option.label] !==
                                    interest?.[detail.name]?.[option.label]
                                  }
                                  value={formik.values[option.label]}
                                />
                              );
                            }

                            case SINGLE: {
                              return (
                                <>
                                  {option?.items?.map((item) => (
                                    <label
                                      className={styles.optionsListLabel}
                                      htmlFor={item.name}
                                      key={`${item.id}${item.name}`}
                                    >
                                      <div>
                                        <RadioButton
                                          name={option.label}
                                          value={item.value ?? item.name}
                                          onChange={formik.handleChange}
                                          formik={formik}
                                        />
                                        {t(`${detail.title}.${item.name}`)}
                                      </div>
                                    </label>
                                  ))}
                                </>
                              );
                            }

                            case CHECKBOX: {
                              return (
                                <>
                                  {option?.items?.map((item) => (
                                    <label
                                      className={styles.optionsListLabel}
                                      htmlFor={item.name}
                                      key={`${item.id}${item.name}`}
                                    >
                                      <Checkbox
                                        name={option.label}
                                        value={item.value ?? item.name}
                                        onChange={formik.handleChange}
                                        formik={formik}
                                      />
                                      {t(`${detail.title}.${item.name}`)}
                                    </label>
                                  ))}
                                </>
                              );
                            }

                            case RADIO_LIST: {
                              const selected = state[option.data]?.find(
                                (item) =>
                                  item.id === formik.values[option.label]
                              );
                              return (
                                <>
                                  {state[option.data]?.map(({ id, name }) => (
                                    <label
                                      className={styles.optionsListLabel}
                                      htmlFor={name}
                                      key={id}
                                    >
                                      <div>
                                        <RadioButton
                                          name={option.label}
                                          value={id}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              option.subCategory,
                                              null
                                            );
                                            formik.handleChange(e);
                                          }}
                                          formik={formik}
                                        />
                                        {name}
                                      </div>
                                    </label>
                                  ))}

                                  {selected?.children?.length > 0 && (
                                    <div
                                      className={styles.optionsList}
                                      key={index}
                                    >
                                      <div>
                                        <p className={styles.optionsTitle}>
                                          {selected?.name}
                                        </p>
                                        {selected.children?.map(
                                          ({ id, name }) => (
                                            <label
                                              className={
                                                styles.optionsListLabel
                                              }
                                              htmlFor={name}
                                              key={id}
                                            >
                                              <div>
                                                <RadioButton
                                                  name={option.subCategory}
                                                  value={id}
                                                  onChange={formik.handleChange}
                                                  formik={formik}
                                                />
                                                {name}
                                              </div>
                                            </label>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            }

                            case DATE: {
                              return (
                                <>
                                  <DatePicker
                                    formik={formik}
                                    styles={styles}
                                    detail={detail}
                                    name={option.label}
                                  />
                                </>
                              );
                            }

                            case PHONE_INPUT: {
                              return (
                                <PhoneInput
                                  className={classnames(
                                    "form-control",
                                    styles.optionInpt
                                  )}
                                  formik={formik}
                                  P
                                  name={option.label}
                                  onFocus={setFieldTouched}
                                />
                              );
                            }

                            case LANGUAGES: {
                              return (
                                <LanguageSelect
                                  formik={formik}
                                  name={option.label}
                                  label={detail.title}
                                />
                              );
                            }

                            case FILE: {
                              return (
                                <FileInput
                                  formik={formik}
                                  name={option.label}
                                  accept={option.dataType}
                                  multiple={option.multiple}
                                />
                              );
                            }

                            case TEXT_AREA: {
                              return (
                                <TextArea
                                  formik={formik}
                                  styles={styles}
                                  setFieldTouched={setFieldTouched}
                                  placeholder={t(
                                    `${detail.title}.${option.label}`
                                  )}
                                  maxLength={option.length}
                                  name={option.label}
                                />
                              );
                            }

                            case PERSONAL_INFO:
                              return (
                                <>
                                  {formik.values[option.label]?.map(
                                    (_, index) => (
                                      <PersonalInfo
                                        key={index}
                                        index={index}
                                        formik={formik}
                                        name={option.label}
                                        title={detail.title}
                                        styles={styles}
                                        setPersonalInfoSelected={
                                          setPersonalInfoSelected
                                        }
                                        openedPersonalInfo={
                                          personalInfoSelected
                                        }
                                      />
                                    )
                                  )}
                                </>
                              );

                            case SOCIAL_MEDIA:
                              return (
                                <SocialMedia
                                  formik={formik}
                                  title={detail.title}
                                  type={type}
                                />
                              );

                            case COUNT_OF_MEMBERS:
                              return <CountOfMembers formik={formik} />;

                            case CONTACT_LIST: {
                              return (
                                <ContactList
                                  amount={option.amount}
                                  formik={formik}
                                  styles={styles}
                                  name={option.label}
                                />
                              );
                            }

                            case NUMBER:
                              return (
                                <NumberInput
                                  formik={formik}
                                  name={option.label}
                                  title={detail.title}
                                  placeholder={option.placeholder}
                                  styles={styles}
                                  className={option.class}
                                  onFocus={setFieldTouched}
                                  tag={option?.tag}
                                />
                              );

                            default: {
                              return "";
                            }
                          }
                        })()}
                      </div>
                      {(option.type !== FILE || option.dataType !== PDF_TYPE) &&
                        errorMessage(option.label)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )) || MISSING_DATA}
    </>
  );
};

export default React.memo(Accordion);
