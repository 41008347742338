import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Loader,
  TableComponent,
  AdminTopPanel,
  Pagination,
  DeleteModal,
  AlertBox,
} from "components";
import history from "../../history";
import { compile } from "path-to-regexp";
import { getCPUsers, deleteCPUser } from "store/actions/adminElements.actions";
import { EDIT_ADMIN } from "constants/paths";
import { userType } from "constants/forms";
import { confirmDelete } from "constants/messages";

const AdminUsersList = () => {
  const dispatch = useDispatch();
  const { cpUsers, error, loaded } = useSelector(
    ({ adminElements }) => adminElements
  );
  const isAlertActive = useSelector(({ alert }) => alert);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [typeSelected, setTypeSelected] = useState({});

  const toggleModal = () => {
    if (isOpenDeleteModal) {
      setUserToDelete(null);
    }
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const onDeleteHandler = (id) => {
    toggleModal();
    setUserToDelete(id);
  };

  const confirmDeleteCPUser = () => {
    toggleModal();
    dispatch(deleteCPUser.request(userToDelete));
    setUserToDelete(null);
  };

  useEffect(() => {
    dispatch(getCPUsers.request());
  }, [dispatch]);

  const tableHead = [
    {
      name: "#",
      key: "#",
    },
    {
      name: "Full Name",
      key: "full_name",
    },
    {
      name: "Mobile Number",
      key: "mobile_number",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "User Type",
      key: "user_type",
    },
    {
      name: "Employer",
      key: "employer",
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ id }) => {
        window.open(compile(EDIT_ADMIN)({ id }), "_blank");
      },
    },
    {
      name: "Delete",
      key: "action-btn",
      class: "mdi mdi-trash-can-outline",
      onClick: ({ id }) => onDeleteHandler(id),
    },
  ];

  const handleNCPRoute = () => {
    history.push("new-control-panel-user");
  };

  const dropdownTools = {
    label: "Type",
    dropdownElements: Object.entries(userType).map(([key, value]) => ({
      name: value,
      type: key,
    })),
    optionSelected: typeSelected,
    setOptionSelected: (element) => {
      dispatch(getCPUsers.request({ type: element.type }));
      setTypeSelected(element);
    },
    order: 0,
  };

  const buttonTools = {
    label: "New CP User",
    onClick: handleNCPRoute,
  };

  const tableBody =
    cpUsers?.data &&
    cpUsers.data.map((el) => ({
      id: el?.id,
      full_name: el?.name || "",
      mobile_number: el?.mobile_number || "",
      email: el?.email || "",
      employer: el?.employer || "",
      user_type: userType[el?.user_type] || "",
    }));

  return (
    <Loader loaded={loaded}>
      {isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger" />
      )}
      <AdminTopPanel
        withButton
        buttonTools={buttonTools}
        withDropdown
        dropdownTools={dropdownTools}
      />

      <div className="card">
        <div className="card-body">
          <TableComponent tableHead={tableHead} tableBody={tableBody} />
          <Pagination
            action={getCPUsers}
            currentPage={cpUsers?.current_page}
            lastPage={cpUsers?.last_page}
            type={typeSelected?.type || null}
            goToPage
          />
        </div>
      </div>
      <DeleteModal
        isOpened={isOpenDeleteModal}
        toggle={toggleModal}
        data={confirmDelete}
        confirm={confirmDeleteCPUser}
        fromAdmin={true}
      />
    </Loader>
  );
};

export default React.memo(AdminUsersList);
