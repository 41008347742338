import React from "react";
import Layout from "layouts/Admin";
import { AdminMatloobUsersList } from "components";

const AdminMatloobUsers = () => (
  <Layout title="Matloob Users">
      <AdminMatloobUsersList/>
  </Layout>
);

export default AdminMatloobUsers;