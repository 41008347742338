import React from "react"
import Layout from "layouts/Admin";
import { MediaCenterAddEditNews } from "components";
import { useParams } from 'react-router-dom';

const AdminMediaCenterAddEditNews = () => {
  const { id } = useParams();
  return (
    <Layout  title={`${id ? "Edit" : "Create New"} News`}>
      <MediaCenterAddEditNews id={id}/>
    </Layout>
  )
}

export default AdminMediaCenterAddEditNews;