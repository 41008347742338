import React from "react"
import { TableComponent } from "components"
// import { useDispatch } from "react-redux";
// import { getApplicationRequirementRequestsTarget } from "store/actions/adminElements.actions";
import { VIEW, DOWNLOAD } from "constants/forms";

const ApplicationRequirenmentsTable = (data) => {
  
  // const dispatch = useDispatch()
  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Application Name | En",
      key: "name_en"
    },
    {
      name: "Application Name | Ar",
      key: "name_ar"
    },
    {
      name: "Application Name | Type",
      key: "type"
    },
    {
      name: "Application Name | Text",
      key: "text"
    },
    {
      name: "View",
      key: "action-btn-redirect",
      class: "mdi mdi-eye",
      type: VIEW
    },
    {
      name: "Download",
      key: "action-btn-redirect",
      class: "mdi mdi-download",
      type: DOWNLOAD
      // onClick: ((elem) => {
      //   dispatch(getApplicationRequirementRequestsTarget.request(elem.file))
      // })
    }
  ]

  return(
    <div>
      <TableComponent tableHead={tableHead} tableBody={data?.data}/>
    </div>
  )
}

export default ApplicationRequirenmentsTable;