import React,{ useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import {
    Loader,
    TableComponent,
    Pagination,
    AdminTopPanel,
    AlertBox,
    DeleteModal,
} from "components";
import { confirmDelete } from "constants/messages";
import { compile } from "path-to-regexp";
import { getMediaCenterCarouselImagesHistory,deleteMediaCenterCarouselImage } from "store/actions/adminElements.actions";
import { ADMIN_MEDIA_CENTER_NEW_CAROUSEL_IMAGES,ADMIN_MEDIA_CAROUSEL_IMAGE_DETAILS } from "constants/paths";


const MediaCenterImagesCarouselImagesHistory = () => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { mediaCenterCarouselImagesHistory, loaded, error } = useSelector(({ adminElements }) => adminElements);
    const isAlertActive =  useSelector(({ alert }) => alert);
    const DATE_FORMAT = "l";

    useEffect(() => {
        dispatch(getMediaCenterCarouselImagesHistory.request()) 
    },[ dispatch ])
    
    const toggleModal = () => {
        if (isOpenDeleteModal) {
            setItemToDelete(null);
        }
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };
    const onDeleteHandler = (id) => {
        toggleModal();
        setItemToDelete(id);
    };

    const confirmDeleteItem = () => {
        toggleModal();
        dispatch(deleteMediaCenterCarouselImage.request(itemToDelete));
        setItemToDelete(null);
    };
    const buttonTools = {
        label: 'New Carousel Image',
        onClick: () => history.push(compile(ADMIN_MEDIA_CENTER_NEW_CAROUSEL_IMAGES)())
    }
    const tableHead = [
        {
            name: "#",
            key: "#"
        },
        {
            name: "Image",
            key: "images_view"
        },

        {
            name: "Creation Date",
            key: "created_at"
        },
        {
            name: "Details",
            key: "action-btn",
            class: "mdi mdi-eye",
            onClick: ({id}) => {
                history.push(compile(ADMIN_MEDIA_CAROUSEL_IMAGE_DETAILS)({ id }))
            },
        },
        {
            name: "Delete",
            key: "action-btn",
            class: "mdi mdi-trash-can-outline",
            onClick: ({ id }) => onDeleteHandler(id),
        },
    ];

    const tableBody = mediaCenterCarouselImagesHistory?.data && mediaCenterCarouselImagesHistory.data.map(el => ({
        id: el?.id || "",
        images_view: el?.image || "",
        created_at: moment(el?.created_at).format(DATE_FORMAT) || "",
    }));
    return (
        <Loader loaded={loaded}>
            {
                isAlertActive &&
                <AlertBox
                error={error}
                additionalStyles='alert-danger'
                />
            }
            <AdminTopPanel
            withButton
            buttonTools={buttonTools}
            />
            <div className="card">
                <div className="card-body">
                <TableComponent
                    tableHead={tableHead}
                    tableBody={tableBody}
                />
                <Pagination
                    action ={getMediaCenterCarouselImagesHistory}
                    currentPage = {mediaCenterCarouselImagesHistory?.current_page}
                    lastPage = {mediaCenterCarouselImagesHistory?.last_page}
                    goToPage
                />
                </div>
            </div>
            <DeleteModal
                isOpened={isOpenDeleteModal}
                toggle={toggleModal}
                data={confirmDelete}
                confirm={confirmDeleteItem}
                fromAdmin={true}
            />
        </Loader>
    )
}

export default MediaCenterImagesCarouselImagesHistory;