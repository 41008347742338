import * as ElementsActions from "store/actions/elements.actions";

const initialState = {
  loaded: true,
  error: "",
  userElementTypes: [],
  interests: [],
  countries: [],
  nationalities: [],
  talentCategories: [],
  newInterestId: "",
  interest: {},
  regions: [],
  languages: [],
  eventCategories: [],
  serviceCategories: [],
  storeFormats: [],
  productCategories: [],
  foodCategories: [],
  restaurantFormats: [],
  venueSiteCategories: [],
  temporaryWorksCategories:[],
  venueSiteTopographies: [],
  venueSiteLandTypes: [],
  disabilityTypes: [],
  cities: [],
  dashboardElements: {},
  current_page: 1,
  last_page: 1,
  successfullyField: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ElementsActions.GET_INTERESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.GET_INTERESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload
      };

    case ElementsActions.GET_INTERESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.CREATE_INTEREST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.CREATE_INTEREST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case ElementsActions.CREATE_INTEREST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.EDIT_INTEREST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.EDIT_INTEREST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        successfullyField: action.payload
      };

    case ElementsActions.EDIT_INTEREST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
      case ElementsActions.CLEAR_SUCCESSFULLY_FIELD:
        return {
          ...state,
          successfullyField: false
        }
    case ElementsActions.GET_REGIONS.SUCCESS:
      return {
        ...state,
        regions: action.payload,
      };

    case ElementsActions.GET_INTEREST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.GET_INTEREST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case ElementsActions.GET_INTEREST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.GET_DISABILITY_TYPES.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.GET_DISABILITY_TYPES.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        disabilityTypes: action.payload,
      };

    case ElementsActions.GET_DISABILITY_TYPES.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.GET_CITIES.SUCCESS:
      return {
        ...state,
        cities: action.payload,
      };

    case ElementsActions.DELETE_INTEREST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.DELETE_INTEREST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        interests: action.payload,
      };

    case ElementsActions.DELETE_INTEREST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.GET_ADMIN_MAIN_ELEMENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.GET_ADMIN_MAIN_ELEMENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case ElementsActions.GET_ADMIN_MAIN_ELEMENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.GET_ADMIN_SUPPORTING_ELEMENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case ElementsActions.GET_ADMIN_SUPPORTING_ELEMENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case ElementsActions.GET_ADMIN_SUPPORTING_ELEMENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case ElementsActions.GET_DASHBOARD_ELEMENTS_REQUEST.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case ElementsActions.GET_DASHBOARD_ELEMENTS_REQUEST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        dashboardElements: action.payload,
      };
    case ElementsActions.GET_DASHBOARD_ELEMENTS_REQUEST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    default:
      return state;
  }
}
