import { createRequestTypes, createActionTypes } from "utils/redux";

export const GET_ELEMENTS_USERS = createRequestTypes("GET_ELEMENTS_USERS");
export const GET_ELEMENT_USER_DETAILS = createRequestTypes(
  "GET_ELEMENT_USER_DETAILS"
);
export const GET_ELEMENT_COMMENTS = createRequestTypes("GET_ELEMENT_COMMENTS");
export const GET_ELEMENT_COMMENT = createRequestTypes("GET_ELEMENT_COMMENT");
export const CREATE_ELEMENT_COMMENT = createRequestTypes(
  "CREATE_ELEMENT_COMMENT"
);
export const EDIT_ELEMENT_COMMENT = createRequestTypes("EDIT_ELEMENT_COMMENT");
export const DELETE_ELEMENT_COMMENT = createRequestTypes(
  "DELETE_ELEMENT_COMMENT"
);
export const ADD_REMOVE_FAVORITE_USER = createRequestTypes(
  "ADD_REMOVE_FAVORITE_USER"
);
export const GET_APPLICATION_REQUIREMENTS = createRequestTypes(
  "GET_APPLICATION_REQUIREMENTS"
);
export const ADD_APPLICATION_REQUIREMENT = createRequestTypes(
  "ADD_APPLICATION_REQUIREMENT"
);
export const GET_APPLICATION_REQUIREMENT = createRequestTypes(
  "GET_APPLICATION_REQUIREMENT"
);
export const EDIT_APPLICATION_REQUIREMENT = createRequestTypes(
  "EDIT_APPLICATION_REQUIREMENT"
);
export const GET_ADMIN_REGIONS = createRequestTypes("GET_ADMIN_REGIONS");
export const GET_MATLOOB_USERS = createRequestTypes("GET_MATLOOB_USERS");
export const GET_MATLOOB_USER_ELEMENTS = createRequestTypes(
  "GET_MATLOOB_USER_ELEMENTS"
);
export const CHANGE_BLOCKED_STATUS = createRequestTypes(
  "CHANGE_BLOCKED_STATUS"
);
export const GET_EVENTS = createRequestTypes("GET_EVENTS");
export const GET_SEASONS = createRequestTypes("GET_SEASONS");
export const GET_CP_USERS = createRequestTypes("GET_CP_USERS");
export const ADD_NEW_CP_USER = createRequestTypes("ADD_NEW_CP_USER");
export const DELETE_CP_USER = createRequestTypes("DELETE_CP_USER");
export const DELETE_MATLOOB_USER = createRequestTypes("DELETE_MATLOOB_USER");
export const GET_DASHBOARD_STATISTICS = createRequestTypes(
  "GET_DASHBOARD_STATISTICS"
);
export const GET_SUBMITTED_FORMS_STATISTICS = createRequestTypes(
  "GET_SUBMITTED_FORMS_STATISTICS"
);
export const GET_ELEMENT_STATISTICS = createRequestTypes(
  "GET_ELEMENT_STATISTICS"
);
export const REORDER_SEASONS = createRequestTypes("REORDER_SEASONS");
export const REORDER_EVENTS = createRequestTypes("REORDER_EVENTS");
export const GET_USER_ACTIONS = createRequestTypes("GET_USER_ACTIONS");
export const GET_CP_USER_DETAILS = createRequestTypes("GET_CP_USER_DETAILS");
export const EDIT_CP_USER_DETAILS = createRequestTypes("EDIT_CP_USER_DETAILS");
export const DOWNLOAD_FILE = createRequestTypes("DOWNLOAD_FILE");
export const GET_USER_DETAILS = createRequestTypes("GET_USER_DETAILS");
export const CLEAN_DATA = "CLEAN_DATA";
export const EXPORT_ADMIN_STATISTICS = createRequestTypes(
  "EXPORT_ADMIN_STATISTICS"
);
export const GET_ADMIN_MAIN_ELEMENTS_TYPES = createRequestTypes(
  "GET_ADMIN_MAIN_ELEMENTS_TYPES"
);
export const CREATE_ADMIN_EVENT_SEASON = createRequestTypes(
  "CREATE_ADMIN_EVENT_SEASON"
);
export const GET_NOTIFICATIONS_HISTORY = createRequestTypes(
  "GET_NOTIFICATIONS_HISTORY"
);
export const GET_NOTIFICATION_DATA = createRequestTypes(
  "GET_NOTIFICATION_DATA"
);
export const CREATE_NOTIFICATION = createRequestTypes("CREATE_NOTIFICATION");
export const GET_EVENT_SEASON = createRequestTypes("GET_EVENT_SEASON");
export const EDIT_EVENT_SEASON = createRequestTypes("EDIT_EVENT_SEASON");
export const CLEAN_SUCCESS = "CLEAN_SUCCESS";
export const GET_ELEMENTS_USERS_TARGET = createRequestTypes(
  "GET_ELEMENTS_USERS_TARGET"
);
export const GET_EVENTS_SEASONS_REQUESTS = createRequestTypes(
  "GET_EVENTS_SEASONS_REQUESTS"
);
export const GET_EVENTS_SEASONS_STATUS_REQUESTS = createRequestTypes(
  "GET_EVENTS_SEASONS_STATUS_REQUESTS"
);
export const GET_EVENT_SEASON_REQUEST = createRequestTypes(
  "GET_EVENT_SEASON_REQUEST"
);
export const CHANGE_REQUEST_STATUS = createRequestTypes(
  "CHANGE_REQUEST_STATUS"
);
export const UPDATE_BULK_EVENT_SEASON_STATUS = createRequestTypes(
  "UPDATE_BULK_EVENT_SEASON_STATUS"
);
export const GET_SUPPORTING_ELEMENTS_REQUESTS = createRequestTypes(
  "GET_SUPPORTING_ELEMENTS_REQUESTS"
);
export const GET_SEASONS_ARRANGEMENT = createRequestTypes(
  "GET_SEASONS_ARRANGEMENT"
);
export const GET_EVENTS_ARRANGEMENT = createRequestTypes(
  "GET_EVENTS_ARRANGEMENT"
);
export const GET_EVENT_SEASON_REQUESTS_INFO = createRequestTypes(
  "GET_EVENT_SEASON_REQUESTS_INFO"
);
export const GET_USERS_REQUESTS_TARGET = createRequestTypes(
  "GET_USERS_REQUESTS_TARGET"
);
export const GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET = createRequestTypes(
  "GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET"
);
export const GET_EVENT_SEASON_APPLICATION_REQUIREMENTS = createRequestTypes(
  "GET_EVENT_SEASON_APPLICATION_REQUIREMENTS"
);
export const GET_REPORTS = createRequestTypes(
  "GET_REPORTS"
);
export const DELETE_REPORTS = createRequestTypes(
  "DELETE_REPORTS"
);
export const GET_REPORTS_DOWNLOAD = createRequestTypes(
  "GET_REPORTS_DOWNLOAD"
);
export const GET_MEDIA_CENTER_NEWS_HISTORY = createRequestTypes(
  "GET_MEDIA_CENTER_NEWS_HISTORY"
);
export const ADD_MEDIA_CENTER_NEW_NEWS = createRequestTypes(
  "ADD_MEDIA_CENTER_NEW_NEWS"
);
export const ADD_MEDIA_CENTER_NEW_ITEM = createRequestTypes(
  "ADD_MEDIA_CENTER_NEW_ITEM"
);
export const EDIT_MEDIA_CENTER_ITEM = createRequestTypes(
  "EDIT_MEDIA_CENTER_ITEM"
);
export const GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS = createRequestTypes(
  "GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS"
);
export const GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS = createRequestTypes(
  "GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS"
);
export const EDIT_MEDIA_CENTER_NEWS = createRequestTypes(
  "EDIT_MEDIA_CENTER_NEWS"
);
export const DELETE_MEDIA_CENTER_NEWS = createRequestTypes(
  "DELETE_MEDIA_CENTER_NEWS"
);
export const DELETE_MEDIA_CENTER_ITEM = createRequestTypes(
  "DELETE_MEDIA_CENTER_ITEM"
);
export const GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY = createRequestTypes(
  "GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY"
);
export const GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY = createRequestTypes(
  "GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY"
);
export const ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE = createRequestTypes(
  "ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE"
);
export const GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS = createRequestTypes(
  "GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS"
);
export const EDIT_MEDIA_CENTER_CAROUSEL_IMAGE = createRequestTypes(
  "EDIT_MEDIA_CENTER_CAROUSEL_IMAGE"
);
export const DELETE_MEDIA_CENTER_CAROUSEL_IMAGE = createRequestTypes(
  "DELETE_MEDIA_CENTER_CAROUSEL_IMAGE"
);
export const POST_ASSIGN_DIRECTOR_TO_ELEMENTS = createRequestTypes(
  "POST_ASSIGN_DIRECTOR_TO_ELEMENTS"
);

export const GET_ASSIGN_DIRECTOR_TO_ELEMENTS = createRequestTypes(
  "GET_ASSIGN_DIRECTOR_TO_ELEMENTS"
);

export const UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS = createRequestTypes(
  "UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS"
);

export const GET_DISABILITY_TYPES = createRequestTypes("GET_DISABILITY_TYPES");

export const CLEAR_USERS_REQUESTS_TARGET = "CLEAR_USERS_REQUESTS_TARGET";
export const GET_USERS_REQUESTS = createRequestTypes("GET_USERS_REQUESTS");
export const CHANGE_CATEGORY = createRequestTypes("CHANGE_CATEGORY");
export const GET_ALL_CATEGORIES = createRequestTypes("GET_ALL_CATEGORIES");
export const GET_ADMIN_ELEMENTS_DROP_DOWN = createRequestTypes("GET_ADMIN_ELEMENTS_DROP_DOWN");

export const getDisabilityTypes = createActionTypes(GET_DISABILITY_TYPES);
export const getAllCategories = createActionTypes(GET_ALL_CATEGORIES);
export const getElementsUsers = createActionTypes(GET_ELEMENTS_USERS);
export const getUsersRequests = createActionTypes(GET_USERS_REQUESTS);
export const getElementUserDetails = createActionTypes(
  GET_ELEMENT_USER_DETAILS
);
export const getElementComments = createActionTypes(GET_ELEMENT_COMMENTS);
export const createElementComment = createActionTypes(CREATE_ELEMENT_COMMENT);
export const editElementComment = createActionTypes(EDIT_ELEMENT_COMMENT);
export const deleteElementComment = createActionTypes(DELETE_ELEMENT_COMMENT);
export const getElementComment = createActionTypes(GET_ELEMENT_COMMENT);
export const addRemoveFavoriteUser = createActionTypes(
  ADD_REMOVE_FAVORITE_USER
);
export const getApplicationRequirements = createActionTypes(
  GET_APPLICATION_REQUIREMENTS
);
export const addApplicationRequirement = createActionTypes(
  ADD_APPLICATION_REQUIREMENT
);
export const getApplicationRequirement = createActionTypes(
  GET_APPLICATION_REQUIREMENT
);
export const editApplicationRequirement = createActionTypes(
  EDIT_APPLICATION_REQUIREMENT
);
export const getAdminRegions = createActionTypes(GET_ADMIN_REGIONS);
export const getMatloobUsers = createActionTypes(GET_MATLOOB_USERS);
export const getMatloobUserElements = createActionTypes(
  GET_MATLOOB_USER_ELEMENTS
);
export const changeBlockedStatus = createActionTypes(CHANGE_BLOCKED_STATUS);
export const getEvents = createActionTypes(GET_EVENTS);
export const getSeasons = createActionTypes(GET_SEASONS);
export const getCPUsers = createActionTypes(GET_CP_USERS);
export const addNewCPUser = createActionTypes(ADD_NEW_CP_USER);
export const deleteCPUser = createActionTypes(DELETE_CP_USER);
export const deleteMatloobUser = createActionTypes(DELETE_MATLOOB_USER);
export const getDashboardStatistics = createActionTypes(
  GET_DASHBOARD_STATISTICS
);
export const getSubmittedFormsStatistics = createActionTypes(
  GET_SUBMITTED_FORMS_STATISTICS
);
export const getElementStatistics = createActionTypes(GET_ELEMENT_STATISTICS);
export const reorderSeasons = createActionTypes(REORDER_SEASONS);
export const reorderEvents = createActionTypes(REORDER_EVENTS);
export const getUserActions = createActionTypes(GET_USER_ACTIONS);
export const getCPUserDetails = createActionTypes(GET_CP_USER_DETAILS);
export const editCPUserDetails = createActionTypes(EDIT_CP_USER_DETAILS);
export const downloadFile = createActionTypes(DOWNLOAD_FILE);
export const getMatloobUserDetails = createActionTypes(GET_USER_DETAILS);
export const cleanData = (member) => ({ type: CLEAN_DATA, payload: member });
export const exportAdminStatistics = createActionTypes(EXPORT_ADMIN_STATISTICS);
export const getAdminMainElementsTypes = createActionTypes(
  GET_ADMIN_MAIN_ELEMENTS_TYPES
);
export const createAdminEventSeason = createActionTypes(
  CREATE_ADMIN_EVENT_SEASON
);
export const getNotificationsHistory = createActionTypes(
  GET_NOTIFICATIONS_HISTORY
);
export const getNotificationData = createActionTypes(GET_NOTIFICATION_DATA);
export const createNotification = createActionTypes(CREATE_NOTIFICATION);
export const getEventSeason = createActionTypes(GET_EVENT_SEASON);
export const editEventSeason = createActionTypes(EDIT_EVENT_SEASON);
export const getElementsUsersTarget = createActionTypes(
  GET_ELEMENTS_USERS_TARGET
);
export const getEventsSeasonsRequests = createActionTypes(
  GET_EVENTS_SEASONS_REQUESTS
);
export const getEventsSeasonsStatusRequests = createActionTypes(
  GET_EVENTS_SEASONS_STATUS_REQUESTS
);
export const getEventSeasonRequest = createActionTypes(
  GET_EVENT_SEASON_REQUEST
);
export const changeRequestStatus = createActionTypes(CHANGE_REQUEST_STATUS);
export const updateBulkEventSeasonStatusRequest = createActionTypes(UPDATE_BULK_EVENT_SEASON_STATUS);
export const getSupportingElementsRequests = createActionTypes(
  GET_SUPPORTING_ELEMENTS_REQUESTS
);
export const getSeasonsArrangement = createActionTypes(GET_SEASONS_ARRANGEMENT);
export const getEventsArrangement = createActionTypes(GET_EVENTS_ARRANGEMENT);
export const getEventSeasonRequestsInfo = createActionTypes(
  GET_EVENT_SEASON_REQUESTS_INFO
);
export const getUsersRequestTarget = createActionTypes(
  GET_USERS_REQUESTS_TARGET
);
export const getApplicationRequirementRequestsTarget = createActionTypes(
  GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET
);
export const getEventSeasonApplicationRequirements = createActionTypes(
  GET_EVENT_SEASON_APPLICATION_REQUIREMENTS
);
export const changeCategoryAction = createActionTypes(CHANGE_CATEGORY);
export const clearUserRequestTarget = () => ({
  type: CLEAR_USERS_REQUESTS_TARGET,
});
export const getReports = createActionTypes(
  GET_REPORTS
);
export const deleteReports = createActionTypes(
  DELETE_REPORTS
);
export const getReportDownload = createActionTypes(
  GET_REPORTS_DOWNLOAD
);
export const getMediaCenterNewsHistory = createActionTypes(
  GET_MEDIA_CENTER_NEWS_HISTORY
);
export const addMediaCenterNewNews = createActionTypes(
  ADD_MEDIA_CENTER_NEW_NEWS
);
export const addMediaCenterNewItem = createActionTypes(
  ADD_MEDIA_CENTER_NEW_ITEM
);
export const editMediaCenterItem = createActionTypes(
  EDIT_MEDIA_CENTER_ITEM
);
export const getAdminMediaCenterNewsDetails = createActionTypes(
  GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS
);
export const getAdminMediaCenterImagesVideosDetails = createActionTypes(
  GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS
);
export const editMediaCenterNews = createActionTypes(
  EDIT_MEDIA_CENTER_NEWS
);
export const deleteMediaCenterNews = createActionTypes(
  DELETE_MEDIA_CENTER_NEWS
);
export const deleteMediaCenterItem = createActionTypes(
  DELETE_MEDIA_CENTER_ITEM
);
export const getMediaCenterImagesVideosHistory = createActionTypes(
  GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY
);
export const getMediaCenterCarouselImagesHistory = createActionTypes(
  GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY
);
export const addMediaCenterNewCarouselImage = createActionTypes(
  ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE
);
export const getAdminMediaCenterCarouselImagesDetails = createActionTypes(
  GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS 
);
export const editMediaCenterCarouselImage = createActionTypes(
  EDIT_MEDIA_CENTER_CAROUSEL_IMAGE
);
export const deleteMediaCenterCarouselImage = createActionTypes(
  DELETE_MEDIA_CENTER_CAROUSEL_IMAGE
);

export const postAssignDirectorToElements = createActionTypes(
  POST_ASSIGN_DIRECTOR_TO_ELEMENTS
);

export const getAssignDirectorToElements = createActionTypes(
  GET_ASSIGN_DIRECTOR_TO_ELEMENTS
);
export const updateAssignDirectorToElements = createActionTypes(
  UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS
);
export const getAdminElementsDropDown = createActionTypes(
  GET_ADMIN_ELEMENTS_DROP_DOWN
);
