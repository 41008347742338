import axiosInstance from "./interceptor";

const OTPApis = {
  admin: "/admin/auth/generate-mobile-otp",
  portal: "/auth/generate-mobile-otp",
}

export const AuthApi = {
  signUp: data => axiosInstance.post("/auth/signup", data),
  confirmSignUp: data => axiosInstance.post("/auth/confirm-signup", data),
  login: data => axiosInstance.post("/auth/login", data),
  generateMobileOTP: ({values, type="portal"}) => axiosInstance.post(OTPApis[type], values),
  // resendMobileOTP: data => axiosInstance.post("/auth/signup", data), //fixes error mobile nuber is not registered, due to change in the sign up flow
  logout: () => axiosInstance.get("/auth/logout"),
  getUser: () => axiosInstance.get("/auth/user"),
  getGuestToken: () => axiosInstance.post("/auth/generate-guest-token"),
  loginAdmin: data => axiosInstance.post("/admin/auth/login", data),
  logoutAdmin: () => axiosInstance.get("/admin/auth/logout"),
  forgotPassword: data =>
    axiosInstance.post("/admin/auth/forgot-password", data),
  resetPassword: data => axiosInstance.post("/admin/auth/reset-password", data),
  getAdmin: () => axiosInstance.get("/admin/auth/user"),
  editAdmin: data => axiosInstance.put("/admin/auth/user", data),
  changePassword: (data) => axiosInstance.post("/admin/auth/change-password", data),
  editUserInfo: data => axiosInstance.put("/auth/user", data),
};
