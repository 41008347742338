import React from 'react'
import {ReactComponent as Calendar} from 'assets/icons/calendar.svg'
import { useHistory } from "react-router-dom";
import { compile } from "path-to-regexp";
import styles from './styles.module.scss'
import {NEWS_DETAILS} from "constants/paths";
import classnames from "classnames";


const NewsCard = ({item, latestNewsCard}) => {
    const history = useHistory();
    const openNewsPage = (id) => {
        history.push(compile(NEWS_DETAILS)({id}));
    };

    return (
        <div 
            className={classnames(styles.newsCard, {
            [styles.newsCardLatest]: latestNewsCard,
            })} 
            onClick={()=>openNewsPage(item.id)}>
            <img src={item.images[0].image} alt='test'/>
            <div className={styles.cardSection}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.data}><Calendar/>
                            {new Date(item.created_date).toLocaleString(
                                `${
                                    localStorage.getItem(
                                        "i18nextLng"
                                    ) === "en"
                                        ? "en-GB"
                                        : "ar-SA"
                                }`,
                                {
                                    month: "long",
                                    day: "numeric",
                                }
                            )}</div>
                <div className={styles.text}>{item.description}</div>
            </div>
        </div>
    )
}

export default NewsCard;