import React, { useState } from "react";
import { logoutAdmin } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { ADMIN_PROFILE, CHANGE_PASSWORD } from "constants/paths";

const ProfileDropdown = ({ username }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const logout = () => {
    dispatch(logoutAdmin.request())
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="notification-list"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        <span className="pro-user-name mr-1">{username}</span>
      </DropdownToggle>
      <DropdownMenu right className="topbar-dropdown-menu profile-dropdown">
        <div onClick={toggleDropdown}>
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">Welcome</h6>
          </div>
          <React.Fragment>
            <DropdownItem divider />
            <Link to={ADMIN_PROFILE}>
              <p className="dropdown-item notify-item">
                <i className={`fe-user mr-1`}></i>
                <span className="notify-item  p-1">Profile</span>
              </p>
            </Link>
            <Link to={CHANGE_PASSWORD}>
              <p className="dropdown-item notify-item ">
                <i className={`fe-lock mr-1`}></i>
                <span className="notify-item  p-1">Change Password</span>
              </p>
            </Link>
            <p
              className="dropdown-item notify-item"
              role="button"
              onClick={logout}
            >
              <i className={`fe-log-out mr-1`}></i>
              <span className="notify-item  p-1">Logout</span>
            </p>
          </React.Fragment>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(ProfileDropdown);
