import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import PublicLayout from "layouts/Public";
import { useTranslation } from "react-i18next";
import styles from './styles.module.scss'
import { NewsCard } from 'components'
import { getMediaCenterNews } from "store/actions/mediaCenter.actions";

const News = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mediaCenterNews, loaded } = useSelector(
        ({ mediaCenter }) => mediaCenter
    );
    useEffect(() => {
        dispatch(getMediaCenterNews.request());
    }, [dispatch]);
    return (
        <PublicLayout title={t("mediaCenter.news")}>
            <div className={styles.newsWrapper}>
                    {loaded && mediaCenterNews?.data?.map((news) => (
                        <NewsCard
                        key={news.id}
                        item={news}
                        latestNewsCard
                        />
                    ))}
            </div>
        </PublicLayout>
    )
}

export default News;