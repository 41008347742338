import React from "react";
import Layout from "layouts/Admin";
import { AdminSeasonsList } from "components";

const AdminSeasons = () => {
  return (
    <Layout title="Seasons">
      <AdminSeasonsList />
    </Layout>
  );
};

export default AdminSeasons;
