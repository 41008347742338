import React from "react";
import Layout from "layouts/Admin";
import { AdminUsersList } from "components";

const AdminUsers = () => (
  <Layout title="Control Panel Users">
    <AdminUsersList />
  </Layout>
);

export default AdminUsers;
