import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/actions/auth.actions";
import { Loader } from "components";

const Logout = () => {
  const { loaded } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout.request());
  }, [dispatch]);

  return <Loader loaded={loaded} />;
};

export default Logout;
