export const chartData = [
  { name: "Restaurant or Cafe", request: "food-categories" },
  { name: "Food & Beverages Provider", request: "food-categories" },
  { name: "Food Truck", request: "food-categories" },
  { name: "Store", request: "store-product-categories" },
  { name: "Pop Up Stores", request: "store-product-categories " },
  { name: "Personal Store", request: "store-product-categories" },
  { name: "Talents", request: "talent-categories" },
  { name: "Temporary Work", request: "temporary-work-categories" },
  { name: "Events", request: "event-categories" },
  { name: "Venues", request: "venue-categories" },
  { name: "Logistics", request: "service-categories" },
];

export const mainElements = (dataChart, allCategories) => [
  {
    title: "Restaurant or Cafe",
    complete: dataChart.data.completed?.restaurants["restaurant-or-cafe"],
    draft: dataChart.data.draft?.restaurants["restaurant-or-cafe"],
    categories: allCategories.allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Food & Beverages Provider",
    complete: dataChart.data.completed?.restaurants["food-and-drinks-provider"],
    draft: dataChart.data.draft?.restaurants["food-and-drinks-provider"],
    categories: allCategories.allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Food Truck",
    complete: dataChart.data.completed?.restaurants["food-truck"],
    draft: dataChart.data.draft?.restaurants["food-truck"],
    categories: allCategories.allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Store",
    complete: dataChart.data.completed?.stores.store,
    draft: dataChart.data.draft?.stores.store,
    categories: allCategories.allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Pop Up Stores",
    complete: dataChart.data.completed?.stores["pop-up-stores"],
    draft: dataChart.data.draft?.stores["pop-up-stores"],
    categories: allCategories.allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Personal Store",
    complete: dataChart.data.completed?.stores["personal-stores"],
    draft: dataChart.data.draft?.stores["personal-stores"],
    categories: allCategories.allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Talents",
    complete: dataChart.data.completed.talents,
    draft: dataChart.data.draft.talents,
    categories: allCategories.allCategories.find(
      (item) => item.talentCategories
    )?.talentCategories,
  },
  {
    title: "Temporary Work",
    complete: dataChart.data.completed["temporary-work"],
    draft: dataChart.data.draft["temporary-work"],
    categories: allCategories.allCategories.find(
      (item) => item.temporaryWorksCategories
    )?.temporaryWorksCategories,
  },
];

export const supportingElements = (dataChart, allCategories) => [
  {
    title: "Events",
    complete: dataChart.data.completed.events,
    draft: dataChart.data.draft.events,
    categories: allCategories.allCategories.find((item) => item.eventCategories)
      ?.eventCategories,
  },
  {
    title: "Venues",
    complete: dataChart.data.completed.venues,
    draft: dataChart.data.draft.venues,
    categories: allCategories.allCategories.find((item) => item.venueCategories)
      ?.venueCategories,
  },
  {
    title: "Logistics",
    complete: dataChart.data.completed.logistics,
    draft: dataChart.data.draft.logistics,
    categories: allCategories.allCategories.find(
      (item) => item.serviceCategories
    )?.serviceCategories,
  },
];

export const mainElementsCategory = ( allCategories) => [
  {
    title: "Restaurant or Cafe",
    categories: allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Food & Beverages Provider",
    categories: allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Food Truck",
    categories: allCategories.find((item) => item.foodCategories)
      ?.foodCategories,
  },
  {
    title: "Store",
    categories: allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Pop Up Stores",
    categories: allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Personal Store",
    categories: allCategories.find(
      (item) => item.storeProductCategories
    )?.storeProductCategories,
  },
  {
    title: "Talents",
    categories: allCategories.find(
      (item) => item.talentCategories
    )?.talentCategories,
  },
  {
    title: "Temporary Work",
    categories: allCategories.find(
      (item) => item.temporaryWorksCategories
    )?.temporaryWorksCategories,
  },
  {
    title: "Events",
    categories: allCategories.find((item) => item.eventCategories)
      ?.eventCategories,
  },
  {
    title: "Venues",
    categories: allCategories.find((item) => item.venueCategories)
      ?.venueCategories,
  },
  {
    title: "Logistics",
    categories: allCategories.find(
      (item) => item.serviceCategories
    )?.serviceCategories,
  },
];



