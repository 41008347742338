import React from "react";
import styles from "./styles.module.scss";

const AdminLogin = ({ children }) => (
  <div className={styles.adminLoginPage}>
    <div></div>
    {children}
    <div className={styles.copyright}>Copyright - Events Center © {new Date().getFullYear()}</div>
  </div>
);

export default AdminLogin;
