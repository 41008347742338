import React from "react";
import Layout from "layouts/Admin";
import { NewEvent } from "components";
import { useParams } from 'react-router-dom';

const AdminNewEvent = () => {
  const { id } = useParams()
  return (
    <Layout title={`${id ? "Edit" : "New"} Event`}>
      <NewEvent id={id}/>
    </Layout>
  );
};

export default AdminNewEvent;
