import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { dateFormatter } from "utils/helpers";
import { compile } from "path-to-regexp";
import { SAUDI_SEASON_EVENT_REVIEW } from "constants/paths";
import { statuses, REVIEW, SEASON } from "constants/seasonsEvents";
import BlueChevron from "assets/icons/Blue_Arrow.svg";
import styles from "./styles.module.scss";

const RequestAccordion = ({ request, userElementTypes, rtl }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen);
    const getElementTypeName = (id) => {
        return userElementTypes.find((element) => element.id === id)?.name;
    };
    const storeRequest = (reqId) => {
        localStorage.setItem(REVIEW, reqId);
    };
    return (
        <div className={styles.mobileRequestTableHeaderWrapper}>
            <div
                className={styles.mobileRequestTableHeader}
                onClick={() => toggleModal()}
            >
                <div className={styles.mobileRequestTableItem}>
                    <div className={styles.chevronIcon}>
                        <img
                            src={BlueChevron}
                            alt=""
                            className={classnames(styles.blueChevron, {
                                [styles.blueChevronOpen]: isOpen,
                            })}
                        />
                    </div>
                    <div className={styles.request_number}>
                        #{request.request_number}
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={styles.mobileRequestTableOpen}>
                    <div className={styles.section}>
                        <div className={styles.leftSide}>
                            {t(`interests.elementType`)} :
                        </div>
                        <div className={styles.rightSide}>
                            {request.event
                                ? request.event?.name
                                : request.season?.name}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.leftSide}>
                            {t(`interests.requestStatus`)} :
                        </div>
                        <div className={styles[statuses[request.status]]}>
                            {t(`requests.statuses.${request.status}`)}
                            {request.other_status && request.status === 4
                                ? ` - ${request.other_status}`
                                : ""}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.leftSide}>
                            {t(`interests.requestElement`)} :
                        </div>
                        <div className={styles.rightSide}>
                            {getElementTypeName(request.element_type_id)}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.leftSide}>
                            {t(`interests.requestDate`)} :
                        </div>
                        <div className={styles.rightSide}>
                            {dateFormatter(rtl, request.created_at)}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.leftSide}>
                            {t(`interests.requestDetails`)} :
                        </div>
                        <div className={styles.rightSide}>
                            <Link
                                to={compile(SAUDI_SEASON_EVENT_REVIEW)({
                                    type: SEASON,
                                    id: request.id,
                                    action: REVIEW,
                                })}
                            >
                                <span
                                    className={styles.requestDetails}
                                    onClick={() => storeRequest(request.id)}
                                >
                                    <i className="mdi mdi-eye"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default RequestAccordion;
