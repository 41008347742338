import React from "react"
import Layout from "layouts/Admin";
import { SupportingElementsRequests } from "components"

const AdminSupportingElementsRequests = () => {
  return (
    <Layout title="Supporting Elements">
      <SupportingElementsRequests />
    </Layout>
  )
}

export default AdminSupportingElementsRequests