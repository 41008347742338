import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, AlertBox } from "components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { passwordExp } from "utils/validation";
import { incorrectPassword, passwordRecovered } from "constants/messages";
import { Button, ModalFooter } from "reactstrap";
import { useError, useFieldTouched } from "utils/forms";
import { changePassword, resetAuthState } from "store/actions/auth.actions";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const { loaded, loginError: error, success } = useSelector(
    state => state.auth
  );
  const isAlertActive =  useSelector(({ alert }) => alert);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: ""
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      old_password: Yup.string()
        // .matches(passwordExp, incorrectPassword)
        .required("Required"),
      password: Yup.string()
        .matches(passwordExp, incorrectPassword)
        .required("Required"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required")
    }),
    onSubmit: values => dispatch(changePassword.request({ ...values }))
  });

  useEffect(() => {
    return () => dispatch(resetAuthState());
  }, [dispatch]);

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  return (
    <Loader loaded={loaded}>
      <div className="col-12">
        {
          isAlertActive &&
        <AlertBox
            error={error}
            additionalStyles='alert-danger'
          />
        }

        {
          success &&
          <AlertBox
            message={passwordRecovered}
            additionalStyles='alert-success'
          />
        }
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="old_password" className="control-label">
                      Old Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="old_password"
                      name="old_password"
                      placeholder="Old Password"
                      value={formik.values.old_password}
                      onFocus={setFieldTouched}
                      onChange={formik.handleChange}
                    />
                    {getError("old_password")}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="password" className="control-label">
                      New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="New Password"
                      value={formik.values.password}
                      onFocus={setFieldTouched}
                      onChange={formik.handleChange}
                    />
                    {getError("password")}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label
                      htmlFor="password_confirmation"
                      className="control-label"
                    >
                      Repeat Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password_confirmation"
                      name="password_confirmation"
                      placeholder="Repeat Password"
                      value={formik.values.password_confirmation}
                      onFocus={setFieldTouched}
                      onChange={formik.handleChange}
                    />
                    {getError("password_confirmation")}
                  </div>
                </div>
              </div>

              <ModalFooter>
                <Button type="submit" className="admin-submit-btn">
                  Edit
                </Button>
              </ModalFooter>
            </form>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default ChangePassword;
