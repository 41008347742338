import React, { useState, useEffect } from "react";
import ProfileLayout from "layouts/Profile";
import styles from "./styles.module.scss";
import { paths } from "./constants";
import {
    InterestItem,
    Loader,
    DeleteModal,
    PageTitle,
    AlertBox,
} from "components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChevronRight from "assets/icons/chevron-right.svg";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getUser, resetAuthState } from "store/actions/auth.actions";
import {
    getInterests,
    createInterest,
    deleteInterest,
    clearSuccessfullyField,
} from "store/actions/elements.actions";
import { TYPES } from "components/Accordion/contants";
import { ProfilePagination, SuccessInterestModal } from "components";
import { isTemporaryWork } from "./heplers";
import * as Paths from "constants/paths";
import turquoiseStar from "../../assets/icons/turquoiseStar.svg";
import purpleStar from "../../assets/icons/purpleStar.svg";

const ITEM_PER_PAGE = 15;

const Interests = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        loaded,
        error,
        userElementTypes,
        interests,
        current_page,
        last_page,
        total,
        successfullyField,
    } = useSelector((state) => state.elements);
    const isAlertActive = useSelector(({ alert }) => alert);

    const identityType = useSelector((state) => state.auth.user.identity_type);

    useEffect(() => {
        dispatch(getUser.request());
        return () => {
            dispatch(resetAuthState());
        };
    }, [dispatch]);

    const [showDropDown, toggle] = useState(false);
    const [currentPage, setCurrentPage] = useState(current_page);

    const [interestToDelete, setInterestToDelete] = useState({});

    const [openModal, setOpenModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(successfullyField);

    const toggleModal = () => setOpenModal(!openModal);

    const handleClick = () => toggle(!showDropDown);

    const handleInterest = (type) => () =>
        dispatch(createInterest.request(type));

    const confirmDelete = () => {
        toggleModal();
        dispatch(
            deleteInterest.request({
                id: interestToDelete.id,
                type: TYPES[interestToDelete.element_type?.slug],
            })
        );
    };

    const closeModalHandler = () => {
        setOpenSuccessModal(!openSuccessModal);
        history.push(Paths.SAUDI_SEASONS_EVENTS);
    };
    const modalCloseBtn = () => {
        setOpenSuccessModal(!openSuccessModal);
    };

    const handleDelete = (data) => {
        setInterestToDelete(data);
        toggleModal();
    };
    const emptyList = () => (
        <>
            {loaded && (
                <div className={styles.emptyList}>
                    <img
                        src={turquoiseStar}
                        alt=""
                        className={styles.turquoiseStar}
                    />
                    <img
                        src={purpleStar}
                        alt=""
                        className={styles.purpleStar}
                    />
                </div>
            )}
        </>
    );

    const interestsList = () => (
        <div className={styles.interestsList}>
            {interests
                .filter((item) =>
                    isTemporaryWork(identityType, item.element_type.id)
                )
                .map((item, index) => (
                    <InterestItem
                        key={index}
                        {...item}
                        onDelete={handleDelete}
                    />
                ))}
        </div>
    );

    const interestsDropDown = () => (
        <div
            className={classnames(styles.interestsDropDown, {
                [styles.interestsDropDownShow]: showDropDown,
            })}
        >
            {userElementTypes
                .filter((item) => isTemporaryWork(identityType, item.id))
                .map(({ name, id }) => {
                    return (
                        <div key={id} onClick={handleInterest(paths[id].type)}>
                            <div>{name}</div>
                            <img alt="" src={ChevronRight} />
                        </div>
                    );
                })}
        </div>
    );

    useEffect(() => {
        dispatch(
            getInterests.request({ perPage: ITEM_PER_PAGE, page: currentPage })
        );
        return () => {
            dispatch(clearSuccessfullyField());
        };
    }, [dispatch, currentPage]);
    return (
        <ProfileLayout>
            <Loader loaded={loaded}>
                {isAlertActive && (
                    <AlertBox error={error} additionalStyles="alert-danger" />
                )}
                <div className={styles.interests}>
                    <div className={styles.interestsHeader}>
                        <div>
                            <PageTitle title={t("interests.title")} />
                            <p className={styles.interestsText}>
                                {t("interests.apply")}
                            </p>
                        </div>
                        <div className={styles.section}>
                            {interestsDropDown()}

                            <button
                                className={styles.interestsAdd}
                                onClick={handleClick}
                            >
                                {t("interests.addBtn")}
                            </button>
                        </div>
                    </div>

                    {interests.length ? interestsList() : emptyList()}
                </div>
                {total > ITEM_PER_PAGE ? (
                    <ProfilePagination
                        currentPage={currentPage}
                        total={last_page}
                        handleClick={setCurrentPage}
                    />
                ) : null}
            </Loader>
            <DeleteModal
                isOpened={openModal}
                toggle={toggleModal}
                data={interestToDelete}
                confirm={confirmDelete}
            />
            <SuccessInterestModal
                modalOpen={openSuccessModal}
                modalClose={closeModalHandler}
                modalCloseBtn={modalCloseBtn}
            />
        </ProfileLayout>
    );
};

export default Interests;
