import React, {useContext} from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { changeMenuStatus } from "store/actions/auth.actions";
import { Links} from "./constants";
import { Context } from "context";
import SideBarDropDown from "./sideBarDropDown";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";

const Sidebar = ({ match }) => {
  const { user } = useContext(Context);
  const dispatch = useDispatch();

  return (
    <div className="left-side-menu">
      <div id="sidebar-menu">
        <ul className="metismenu" id="side-menu">
          {Links.map(link => {
            if(link.user_types.includes(user?.user_type)){
              if (link.children) {
                return (
                  <SideBarDropDown key={link.name} link={link} match={match} />
                );
              } else {
                return (
                  <li key={link.name} id="menu-item">
                    <NavLink
                      to={link.url}
                      onClick={() => dispatch(changeMenuStatus.failure())}
                      className="has-dropdown"
                      activeClassName="activeLink"
                    >
                      <i className={link.icon}></i>
                      <span>{link.name} </span>
                    </NavLink>
                  </li>
                );
              }
            }
            else return null
          })}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
