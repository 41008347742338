import React, { useState, useEffect } from "react";
import { Loader, PhoneInput, ActionHistory, AlertBox, AssignDirectorToElement } from "components";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, ModalFooter } from "reactstrap";
import { useError, useFieldTouched } from "utils/forms";
import { resetAuthState } from "store/actions/auth.actions";
import {
  getCPUserDetails,
  editCPUserDetails,
} from "store/actions/adminElements.actions";
import { phoneNumberWithoutCountryCode } from "utils/validation";
import { invalidPhone, incorrectEmail, updated } from "constants/messages";
import { userType } from "constants/forms";

const EditAdminForm = ({ id }) => {
  const dispatch = useDispatch();
  const { error, loaded, success, cpUserDetails } = useSelector(
    ({ adminElements }) => adminElements
  );
  const isAlertActive = useSelector(({ alert }) => alert);
  const [userInfo, setUserInfo] = useState({});
  const [historyInfo, setHistoryInfo] = useState([]);
  const formik = useFormik({
    initialValues: userInfo,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email(incorrectEmail).required("Required"),
      name: Yup.string().required("Required"),
      mobile_number: Yup.string()
        .matches(phoneNumberWithoutCountryCode, invalidPhone)
        .required(),
      employer: Yup.string().required("Required").nullable(),
      user_type: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      const { id, ...data } = values;
      const modifiedOnly = Object.fromEntries(
        Object.entries(data).filter(
          ([dKey, dValue]) => formik.initialValues[dKey] !== dValue
        )
      );
      dispatch(editCPUserDetails.request({ id, data: modifiedOnly }));
    },
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  useEffect(() => {
    return () => dispatch(resetAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getCPUserDetails.request(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.values(cpUserDetails).length) {
      const { user_actions, ...data } = cpUserDetails;
      setUserInfo(data);
      setHistoryInfo(user_actions);
    }
  }, [cpUserDetails]);

  return (
    <Loader loaded={loaded}>
      {isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger" />
      )}
      {success && (
        <AlertBox message={updated} additionalStyles="alert-success" />
      )}
          <div className="card">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="name" className="control-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                        value={formik?.values?.name || ""}
                        onFocus={setFieldTouched}
                        onChange={formik.handleChange}
                      />
                      {getError("name")}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="mobile_number" className="control-label">
                        Mobile Number
                      </label>
                      <div>
                        <PhoneInput
                          boxClassName="w-100"
                          className="form-control w-100"
                          formik={formik}
                          name="mobile_number"
                          onFocus={setFieldTouched}
                        />
                      </div>
                      {getError("mobile_number")}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="email" className="control-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formik?.values?.email || ""}
                        onFocus={setFieldTouched}
                        onChange={formik.handleChange}
                      />
                      {getError("email")}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="user_type" className="control-label">
                        User Type
                      </label>

                      <select
                        className="form-control"
                        name="user_type"
                        value={formik?.values?.user_type}
                        onChange={formik.handleChange}
                        disabled={formik?.values?.user_type === 1}
                      >
                        {Object.entries(userType)
                          .filter((el) =>
                            formik?.values?.user_type !== 1 ? el[0] !== "1" : el
                          )
                          .map((item, index) => (
                            <option key={index} value={item[0]}>
                              {item[1]}
                            </option>
                          ))}
                      </select>

                      {getError("user_type")}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="employer" className="control-label">
                        Employer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="employer"
                        name="employer"
                        placeholder="Employer"
                        value={formik?.values?.employer || ""}
                        onFocus={setFieldTouched}
                        onChange={formik.handleChange}
                      />
                      {getError("employer")}
                    </div>
                  </div>
                </div>

                <ModalFooter>
                  <Button type="submit" className="btn-paua">
                    Save
                  </Button>
                </ModalFooter>
              </form>
            </div>
          </div>
          <AssignDirectorToElement id={id}/>
          <ActionHistory userActions={historyInfo} />
    </Loader>
  );
};

export default React.memo(EditAdminForm);
