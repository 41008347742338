import * as MediaCenterApi from "store/actions/mediaCenter.actions";
import { getErrorsList } from "utils/redux";

const initialState = {
    loaded: true,
    error: "",
    mediaCenterNews: {},
    newsDetails:{},
    mediaCenterPhotos:{},
    mediaCenterVideos:{},
    mediaCenterPhotosDetails:{},
    mediaCenterVideosDetails:{},
    mediaCenterCarouselImages:{},
};

export default function (state = initialState, action) {
    switch (action.type) {
            case MediaCenterApi.GET_MEDIA_CENTER_NEWS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_NEWS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterNews: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_NEWS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };
            
        case MediaCenterApi.GET_MEDIA_CENTER_NEWS_DETAILS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_NEWS_DETAILS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                newsDetails: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_NEWS_DETAILS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };
        
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterPhotos: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };
    
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS_DETAILS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS_DETAILS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterPhotosDetails: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_PHOTOS_DETAILS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };
    
        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterVideos: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };

        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS_DETAILS.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS_DETAILS.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterVideosDetails: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_VIDEOS_DETAILS.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };

        case MediaCenterApi.GET_MEDIA_CENTER_CAROUSEL_IMAGES.REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_CAROUSEL_IMAGES.SUCCESS:
            return {
                ...state,
                loaded: true,
                error: "",
                mediaCenterCarouselImages: action.payload,
            };
        case MediaCenterApi.GET_MEDIA_CENTER_CAROUSEL_IMAGES.FAILURE:
            return {
                ...state,
                loaded: true,
                error: getErrorsList(action.payload),
            };

        default:
            return state;
    }
}
