import React,{useContext} from 'react'
import { Context } from "context";
import { ENGLISH_TITLE, ARABIC_TITLE } from "constants/forms";
import classnames from "classnames";

import styles from './styles.module.scss'

const LanguageButton = () => {
    const { changeLang, rtl } = useContext(Context);

    return(
        <div className={styles.lng_wrapper}>
            <button
                className={classnames(
                    styles.lng_btn,
                    styles.publicButton
                )}
                onClick={changeLang}
            >
                {rtl ? ENGLISH_TITLE : ARABIC_TITLE}
            </button>
        </div>
    )
}

export default React.memo(LanguageButton);