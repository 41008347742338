import React, { useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    PhoneInput,
    // Checkbox,
    Loader,
    Button,
    AlertBox,
} from "components";
import { useError, useFieldTouched } from "utils/forms";
import DropDown from "react-dropdown-select";
// import { Link } from "react-router-dom";
// import { TERMS, POLICY } from "constants/paths";
import { SUBMIT } from "constants/forms";
import { contactUs } from "store/actions/contact.actions";
import {
    phoneNumberWithoutCountryCode,
    englishOrArabic,
    emailChars,
} from "utils/validation";
import classnames from "classnames";
import Check from "assets/icons/check-circle.svg";
import { RECAPTCHA_KEY } from "constants/forms";
import styles from "./styles.module.scss";
import { useRef } from "react";

const OTHERS = "other";
const MATLOOB = 1;

const ContactUsForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { error, loaded, success } = useSelector((state) => state.contact);
    const isAlertActive = useSelector(({ alert }) => alert);
    const reCaptcha = useRef();
    const subjects = [
        { name: t("contactUs.inquiry"), id: "inquiry" },
        { name: t("contactUs.suggestion"), id: "suggestion" },
        { name: t("contactUs.complaint"), id: "complaint" },
        { name: t("contactUs.others"), id: OTHERS },
    ];

    const formik = useFormik({
        initialValues: {
            mobile_number: "",
            subject: "",
            name: "",
            email: "",
            message: "",
            privacy_policy: true,
            captureValue: null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            mobile_number: Yup.string()
                .matches(
                    phoneNumberWithoutCountryCode,
                    t("messages.invalidPhone")
                )
                .required("."),
            subject: Yup.string().required("."),
            name: Yup.string()
                .matches(englishOrArabic, t("messages.arabicOrEnglish"))
                .required(".")
                .test(
                    "len",
                    t("messages.enterFullName"),
                    (value) =>
                        value?.split(" ").filter((el) => el.length).length > 1
                ),
            email: Yup.string()
                .email(t("messages.invalidEmail"))
                .required(".")
                .matches(emailChars, t("messages.name_en")),
            message: Yup.string()
                .matches(englishOrArabic, t("messages.arabicOrEnglish"))
                .required("."),
            other: Yup.string().when("subject", (subject) =>
                subject === OTHERS
                    ? Yup.string()
                          .matches(
                              englishOrArabic,
                              t("messages.arabicOrEnglish")
                          )
                          .required(".")
                    : Yup.string()
            ),
            captureValue: Yup.string()
                .required(t("messages.required"))
                .nullable(),
        }),
        onSubmit: (values) => {
            dispatch(contactUs.request({ ...values, recipient: MATLOOB }));
            formik.resetForm();
            reCaptcha.current.reset();
        },
    });

    const getError = useError(formik);
    const setFieldTouched = useFieldTouched(formik);

    const handleSubjectChange = (value) => {
        formik.setFieldValue("subject", value[0]?.id);
        formik.setFieldTouched("subject");
    };
    const handleReCapture = (value) => {
        formik.setFieldValue("captureValue", value);
    };
    // useEffect(() => {
    //   return () => dispatch(resetReducer());
    // }, [dispatch]);
    // useEffect(() => {
    //   if (success) formik.resetForm();
    // }, [success, formik]);

    const selectedSubject = useMemo(
        () => subjects.filter(({ id }) => id === formik.values.subject),
        [formik.values.subject, subjects]
    );
    const checkError = (name) => formik.errors[name] && formik.submitCount;
    const checkSuccess = (name) => formik.touched[name] && !formik.errors[name];
    return (
        <Loader loaded={loaded}>
            <div className={styles.contactUs}>
                {isAlertActive && (
                    <AlertBox error={error} additionalStyles="alert-danger" />
                )}
                {success && (
                    <div className={styles.successMessage}>
                        <img src={Check} alt="" />
                        {t("messages.successfully")}
                    </div>
                )}
                {/* <div className={styles.contactUsHeader}>
          <h2>{t("contactUs.title")}</h2>
        </div> */}
                <div className={styles.contactUsForm}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.formGroup}>
                            <div>
                                <label htmlFor="name" className="requiredField">
                                    {t("contactUs.name")}
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onFocus={setFieldTouched}
                                    className={classnames("form-control", {
                                        invalidField: checkError("name"),
                                        successField: checkSuccess("name"),
                                    })}
                                    value={formik.values.name}
                                />
                                {getError("name")}
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <div>
                                <label
                                    htmlFor="subject"
                                    className="requiredField"
                                >
                                    {t("contactUs.subject")}
                                </label>
                                <DropDown
                                    id="subject"
                                    options={subjects}
                                    valueField="id"
                                    labelField="name"
                                    placeholder=""
                                    onChange={handleSubjectChange}
                                    onClick={setFieldTouched}
                                    values={selectedSubject}
                                    className={classnames("form-control", {
                                        invalidField: checkError("subject"),
                                        successField: checkSuccess("subject"),
                                    })}
                                />
                                {getError("subject")}
                            </div>
                        </div>

                        {formik.values.subject === OTHERS && (
                            <div className={styles.formGroup}>
                                <div>
                                    <label
                                        htmlFor="other"
                                        className="requiredField"
                                    >
                                        {t("contactUs.others")}
                                    </label>
                                    <input
                                        id="other"
                                        type="text"
                                        name="other"
                                        onChange={formik.handleChange}
                                        onFocus={setFieldTouched}
                                        className={classnames("form-control", {
                                            invalidField: checkError("other"),
                                            successField: checkSuccess("other"),
                                        })}
                                        value={formik.values.other || ""}
                                    />
                                    {getError("other")}
                                </div>
                            </div>
                        )}

                        <div className={styles.formGroup}>
                            <div>
                                <label
                                    htmlFor="mobile_number"
                                    className="requiredField"
                                >
                                    {t("contactUs.mobile")}
                                </label>
                                <PhoneInput
                                    name="mobile_number"
                                    formik={formik}
                                    onFocus={setFieldTouched}
                                    className={classnames(
                                        "form-control",
                                        styles.phoneNumber,
                                        {
                                            invalidField:
                                                checkError("mobile_number"),
                                            successField:
                                                checkSuccess("mobile_number"),
                                        }
                                    )}
                                    bgColor
                                />
                                {getError("mobile_number")}
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="requiredField"
                                >
                                    {t("contactUs.email")}
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onFocus={setFieldTouched}
                                    className={classnames("form-control", {
                                        invalidField: checkError("email"),
                                        successField: checkSuccess("email"),
                                    })}
                                    value={formik.values.email}
                                />
                                {getError("email")}
                            </div>
                        </div>
                        <div className={styles.messageBox}>
                            <label htmlFor="message" className="requiredField">
                                {t("contactUs.message")}
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                rows="10"
                                onFocus={setFieldTouched}
                                onChange={formik.handleChange}
                                className={classnames({
                                    invalidField: checkError("message"),
                                    successField: checkSuccess("message"),
                                })}
                                value={formik.values.message}
                            ></textarea>
                            {getError("message")}
                        </div>
                        {
                            <div className={styles.captureWrapper}>
                                <ReCAPTCHA
                                    ref={reCaptcha}
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={handleReCapture}
                                />
                                {getError("captureValue")}
                            </div>
                        }
                        <Button
                            type="submit"
                            title={t("buttons.submit")}
                            color={SUBMIT}
                            className={classnames("mt-3", styles.submitBtn)}
                        />
                    </form>
                </div>
            </div>
        </Loader>
    );
};

export default ContactUsForm;
