import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getElementStatistics } from "store/actions/adminElements.actions";
import _ from "lodash";
import AdminGraphsContainer from "./componets/AdminGraphsContainer";
import { adminCategoriesStatisticsElements } from "pages/AdminStatistics/constants";

const AdminCategoriesStatistics = (allCategories) => {
  const { elementStatistics } = useSelector(
    ({ adminElements }) => adminElements
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getElementStatistics.request());
  }, [dispatch]);

  return (
    <section>
      {!_.isEmpty(elementStatistics) &&
        adminCategoriesStatisticsElements.map(
          ({ title, children, link }, index) => (
            <AdminGraphsContainer
              allCategories={allCategories}
              data={elementStatistics}
              title={title}
              children={children}
              key={index}
              withChart
              withCards={false}
              link={link}
            />
          )
        )}
    </section>
  );
};

export default AdminCategoriesStatistics;
