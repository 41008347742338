import React,{ useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import {
    Loader,
    TableComponent,
    Pagination,
    AdminTopPanel,
    AlertBox,
    DeleteModal,
} from "components";
import { confirmDelete } from "constants/messages";
import { compile } from "path-to-regexp";
import { getMediaCenterImagesVideosHistory, deleteMediaCenterItem } from "store/actions/adminElements.actions";
import { ADMIN_MEDIA_CENTER_NEW_PHOTOS_VIDEOS,ADMIN_MEDIA_PHOTOS_VIDEOS_DETAILS } from "constants/paths";


const MediaCenterImagesVideosHistory = () => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { mediaCenterImagesVideosHistory, loaded, error } = useSelector(({ adminElements }) => adminElements);
    const isAlertActive =  useSelector(({ alert }) => alert);
    const DATE_FORMAT = "l";

    useEffect(() => {
        dispatch(getMediaCenterImagesVideosHistory.request())
    },[ dispatch ])

    const toggleModal = () => {
        if (isOpenDeleteModal) {
            setItemToDelete(null);
        }
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };
    const onDeleteHandler = (id) => {
        toggleModal();
        setItemToDelete(id);
    };

    const confirmDeleteItem = () => {
        toggleModal();
        dispatch(deleteMediaCenterItem.request(itemToDelete));
        setItemToDelete(null);
    };
    const buttonTools = {
        label: 'New Media',
        onClick: () => history.push(compile(ADMIN_MEDIA_CENTER_NEW_PHOTOS_VIDEOS)())
    }
    const tableHead = [
        {
            name: 'Title | En',
            key: "title_en"
        },
        {
            name: "Title | Ar",
            key: "title_ar"
        },

        {
            name: "Creation Date",
            key: "created_at"
        },
        {
            name: "Type",
            key: "type"
        },
        {
            name: "Status",
            key: "switch",
            onChange: () => {
                console.log('will added later')
            }
        },
        {
            name: "Details",
            key: "action-btn",
            class: "mdi mdi-eye",
            onClick: ({id}) => {
                history.push(compile(ADMIN_MEDIA_PHOTOS_VIDEOS_DETAILS)({ id }))
            },
        },
        {
            name: "Delete",
            key: "action-btn",
            class: "mdi mdi-trash-can-outline",
            onClick: ({ id }) => onDeleteHandler(id),
        },
    ];
    const tableBody = mediaCenterImagesVideosHistory?.data && mediaCenterImagesVideosHistory.data.map(el => ({
        id: el?.id,
        title_en: el?.title_en,
        title_ar: el?.title_ar || "",
        type:el?.type || "",
        created_at: moment(el?.created_at).format(DATE_FORMAT) || "",
        status: el?.status,
    }));

    return (
        <Loader loaded={loaded}>
            {
                isAlertActive &&
                <AlertBox
                error={error}
                additionalStyles='alert-danger'
                />
            }
            <AdminTopPanel
            withButton
            buttonTools={buttonTools}
            />
            <div className="card">
                <div className="card-body">
                <TableComponent
                    tableHead={tableHead}
                    tableBody={tableBody}
                />
                <Pagination
                    action ={getMediaCenterImagesVideosHistory}
                    currentPage = {mediaCenterImagesVideosHistory?.current_page}
                    lastPage = {mediaCenterImagesVideosHistory?.last_page}
                    goToPage
                />
                </div>
            </div>
            <DeleteModal
                isOpened={isOpenDeleteModal}
                toggle={toggleModal}
                data={confirmDelete}
                confirm={confirmDeleteItem}
                fromAdmin={true}
            />
        </Loader>
    )
}

export default MediaCenterImagesVideosHistory;