import React, { useState } from "react";
import classnames from "classnames";
import Switch from "react-switch";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { VIEW } from "constants/forms";
import { ADMIN_VERIFY_URL } from "constants/fileLink";
import styles from "../ElementsFilter/styles.module.scss";
import classNames from "classnames";

const categories = {
  3: "A",
  2: "B",
  1: "C",
  0: "Not Categorized",
};

const isSelectAll = (name) => name === "Select All";

const tableComponent = ({
  tableBody = [],
  tableHead,
  isAccordion = false,
  selectAllToRequestList,
  modalBody,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selected, setSelected] = useState(false);
  return (
    <div className="table-responsive">
      {!tableBody.length ? (
        <div className="notFound">Not Found</div>
      ) : (
        <div className={`${isAccordion && "border border-dark"}`}>
          <table className="table table-centered table-borderless table-hover mb-0">
            <thead className="thead-light">
              <tr>
                {tableHead.map(
                  (header) =>
                    header && (
                      <th key={header.name} className="text-center">
                        <div
                          className={classNames(
                            isSelectAll(header.name) && styles.selectAll
                          )}
                        >
                          {isSelectAll(header.name) && (
                            <input
                              type="checkbox"
                              checked={selected}
                              onClick={() => {
                                setSelected(!selected);
                                selectAllToRequestList(!selected);
                              }}
                            />
                          )}
                          <div> {header.name}</div>
                        </div>
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {tableBody.map((body, i) => (
                <tr key={i}>
                  {tableHead.map((head, index) => {
                    switch (head.key) {
                      case "#":
                        return (
                          <td key={index} className="text-center">
                            {i + 1}
                          </td>
                        );

                      case "action-btn":
                        if (head.isHidden?.(body)) {
                          return <td key={index} className="text-center"></td>;
                        }

                        return (
                          <td key={index} className="text-center">
                            {head.field && <span>{body[head.field]}</span>}
                            <span className="action-icon">
                              <i
                                className={classnames(head.class)}
                                onClick={() => {
                                  head.onClick(body);
                                  setSelected(false);
                                }}
                              ></i>
                            </span>
                          </td>
                        );

                      case "dropdown":
                        return (
                          <td key={index} className="text-center">
                            <DropdownButton
                              title={categories[+body[head.field]]}
                              className={styles.dropdown}
                              variant="light"
                            >
                              {Object.keys(categories)
                                .reverse()
                                .map((categoryKey) => (
                                  <Dropdown.Item
                                    onClick={(e) =>
                                      head.onClick(body, +categoryKey)
                                    }
                                    key={categoryKey}
                                    value={+categoryKey}
                                  >
                                    {categories[categoryKey]}
                                  </Dropdown.Item>
                                ))}
                            </DropdownButton>
                          </td>
                        );

                      case "boolean":
                        return (
                          <td key={index} className="text-center">
                            {!body[head.field] ? (
                              <span className="badge bg-soft-danger text-danger p-1">
                                {head.values ? head.values[0] : "false"}
                              </span>
                            ) : (
                              <span className="badge bg-soft-success text-success p-1">
                                {head.values ? head.values[1] : "true"}
                              </span>
                            )}
                          </td>
                        );

                      case "editWithRedirect":
                        return (
                          <td key={index} className="text-center">
                            <i
                              className="mdi mdi-pencil action-icon"
                              onClick={() => head.onClick(body)}
                            ></i>
                          </td>
                        );

                      case "indicator":
                        return (
                          <td key={index} className="text-center">
                            {body[head.field]} <i className={head.class} />
                          </td>
                        );

                      case "boolean-icon":
                        return (
                          <td key={index} className="text-center">
                            <i
                              className={classnames(
                                "mdi",
                                body[head.field] ? head.class[1] : head.class[0]
                              )}
                              onClick={() => head.onClick(i, body.id)}
                            />
                          </td>
                        );
                      case "checkbox":
                        return (
                          <td key={index} className="text-center">
                            <Form.Check
                              type="checkbox"
                              onClick={(e) => {
                                setSelected(false);
                                head.onClick(body, e.target.checked, i);
                              }}
                              checked={modalBody?.requests?.find(
                                (e) => e.id === body.id
                              )}
                            />
                          </td>
                        );

                      case "switch":
                        return (
                          <td key={index} className="text-center">
                            <div className="d-flex justify-content-center">
                              <Switch
                                checked={body.blocked || body.status}
                                onChange={() => head.onChange(i, body.id)}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={40}
                                height={20}
                                activeBoxShadow=""
                              />
                            </div>
                          </td>
                        );

                      case "action-btn-redirect":
                        return (
                          <td key={index} className="text-center">
                            {head.field && <span>{body[head.field]}</span>}
                            <a
                              className="action-icon"
                              href={
                                body.type !== "Text"
                                  ? `${ADMIN_VERIFY_URL}${body.file}${
                                      head.type === VIEW ? "?view=1" : ""
                                    }`
                                  : null
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className={classnames(head.class)}></i>
                            </a>
                          </td>
                        );
                        case "images_view":
                          return (
                          <td key={index} className="text-center">
                            <img src={body.images_view} width="30" height="30" alt={body.images_view}/>
                          </td>
                          );
                          
                      default:
                        return (
                          <td key={index} className="text-center">
                            {head.format
                              ? head.format(body[head.field])
                              : String(body[head.key])}
                          </td>
                        );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default tableComponent;
