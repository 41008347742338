import { EVENTS, LOGISTICS, VENUES, IDEAS } from "constants/forms";

const VENUE_CATEGORY_DATA = {
  value: "venue_category_id",
  name: "Venue Category",
  field: "dropdown",
  key: "venueCategories",
};

const COMPLETE_STATUS_DATA = {
  value: "status",
  name: "Status",
  field: "dropdown",
  data: [
    { name: "Complete", value: "1" },
    { name: "Incomplete", value: "0" },
  ],
};

const TOPOGRAPHY_DATA = {
  value: "topography_id",
  name: "Topography ",
  field: "dropdown",
  key: "venueTopographies",
};

const VENUE_SIZE_DATA = {
  value: "site_area",
  name: "Venue Size",
  field: "number",
};

const PARKING_SPACE_DATA = {
  value: "parking_spaces",
  name: "Is there parking spaces",
  field: "dropdown",
  data: [
    { name: "Yes", value: "0" },
    { name: "No", value: "1" },
  ],
};

const HOW_MANY_PARKING_DATA = {
  value: "count_parking_spaces",
  name: "How many parking",
  field: "number",
};

const ROOF_LOAD_DATA = {
  value: "roof_load_capacity",
  name: "The load-bearing capacity of the roof",
  field: "number",
};

const MAX_GROUND_DATA = {
  value: "max_ground_carrying_weight",
  name: "Maximum Ground Carrying Weight  ",
  field: "number",
};

const LAND_TYPE_DATA = {
  value: "land_type_id",
  name: "Land Type",
  field: "dropdown",
  key: "venueLandTypes",
};

const DATE_BEFORE = {
  value: "element_created_before",
  name: "Date - Before",
  field: "date",
};

const DATE_AFTER = {
  value: "element_created_after",
  name: "Date - After",
  field: "date",
};

const USER_NAME = {
  value: "name",
  field: "text",
  name: "User Name",
};

const EMAIL = {
  value: "email",
  field: "text",
  name: "Email",
};

const MOBILE_NUMBER = {
  value: "mobile_number",
  field: "text",
  name: "Mobile Number",
};

const CATEGORIES = {
  value: "favorite",
  name: "Favorite's Category",
  field: "dropdown",
  data: [
    { name: "Not Categorized", value: "0" },
    { name: "C", value: "1" },
    { name: "B", value: "2" },
    { name: "C", value: "3" },
  ],
};

const REGION_DATA = {
  value: "saudi_region_id",
  name: "Region",
  field: "dropdown",
  key: "regions",
};

const CITY_DATA = {
  value: "city_id",
  name: "City",
  field: "dropdown",
  key: "cities",
};

const EVENT_CATEGORY_DATA = {
  value: "event_category_id",
  name: "Event Category",
  field: "dropdown",
  key: "eventCategories",
};

const TARGET_AUDIENCE_DATA = {
  value: "target_audience",
  name: "Target Audience",
  field: "dropdown",
  data: [
    { name: "Men", value: "1" },
    { name: "Women", value: "2" },
    { name: "Kids", value: "3" },
  ],
};

const PROPOSED_REGION_DATA = {
  value: "recommended_region_id",
  name: "The proposed Region for event",
  field: "dropdown",
  key: "regions",
};

const EVENT_DURATION_DATA = {
  value: "duration",
  name: "Event Duration",
  field: "number",
};

const LOCATED_COMPANY_DATA = {
  value: "located_saudi_arabia",
  name: "Company located in Saudi Arabia",
  field: "dropdown",
  data: [
    { name: "Yes", value: "1" },
    { name: "No", value: "0" },
  ],
};

const COUNTRY_OF_REDIDENCE_DATA = {
  value: "country_id",
  name: "Country",
  field: "dropdown",
  key: "countries",
};

const SAUDI_REGION_DATA = {
  value: "saudi_region_id",
  name: "Saudi Region",
  field: "dropdown",
  key: "regions",
};

const LOGISTICS_CATEGORY_DATA = {
  value: "service_category_id",
  name: "Logistics Category",
  field: "dropdown",
  key: "serviceCategories",
};

export const filteredCategoriesType = {
  venues: [
    VENUE_CATEGORY_DATA,
    TOPOGRAPHY_DATA,
    VENUE_SIZE_DATA,
    PARKING_SPACE_DATA,
    HOW_MANY_PARKING_DATA,
    ROOF_LOAD_DATA,
    MAX_GROUND_DATA,
    LAND_TYPE_DATA,
    REGION_DATA,
    CITY_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  ideas: [
    REGION_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  events: [
    EVENT_CATEGORY_DATA,
    TARGET_AUDIENCE_DATA,
    PROPOSED_REGION_DATA,
    EVENT_DURATION_DATA,
    LOCATED_COMPANY_DATA,
    COUNTRY_OF_REDIDENCE_DATA,
    SAUDI_REGION_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
  logistics: [
    LOGISTICS_CATEGORY_DATA,
    LOCATED_COMPANY_DATA,
    COUNTRY_OF_REDIDENCE_DATA,
    SAUDI_REGION_DATA,
    COMPLETE_STATUS_DATA,
    DATE_BEFORE,
    DATE_AFTER,
    USER_NAME,
    EMAIL,
    MOBILE_NUMBER,
    CATEGORIES,
  ],
};

export const initialValues = {
  venue_category_id: "",
  topography_id: "",
  site_area: "",
  parking_spaces: "",
  count_parking_spaces: "",
  roof_load_capacity: "",
  max_ground_carrying_weight: "",
  land_type_id: "",
  saudi_region_id: "",
  city_id: "",
  status: "",
  mobile_number: "",
  name: "",
  email: "",
  element_created_after: "",
  element_created_before: "",
  favorite: "",
};

export const allElements = [
  { name: "Events", type: EVENTS, key: EVENTS },
  { name: "Ideas & Suggestions", type: IDEAS, key: IDEAS },
  { name: "Venues", type: VENUES, key: VENUES },
  { name: "Logistics", type: LOGISTICS, key: LOGISTICS },
];

export const logisticsElements = [
  { name: "Logistics", type: LOGISTICS, key: LOGISTICS },
];
