import {ADMIN_MATLOOB_USERS, ADMIN_SEASONS, ADMIN_EVENTS_LIST, MAIN_ELEMENTS, SUPPORTING_ELEMENTS} from "constants/paths"
import { 
  EVENTS,
  IDEAS,
  LOGISTICS,
  VENUES,
  TALENTS, 
  TEMPORARY_WORK, 
  RESTAURANT_OF_CAFE_KEY, 
  FOOD_TRUCK_KEY, 
  FOOD_AND_DRINKS_PROVIDER_KEY, 
  STORE_KEY,
  POP_UP_STORES_KEY, 
  PERSONAL_STORES_KEY, 
} from "constants/forms"

const statusList = {
  eventsAndSeasons: [
    {
      name: "Comming Soon", 
      key: "coming_soon"
    },
    {
      name: "Open", 
      key: "open"
    },
    {
      name: "Closed", 
      key: "closed"
    },
    {
      name: "Cancelled", 
      key: "cancelled"
    },
  ],
  ideas: [
    {
      name: "Count",
      key:"count"
    },
    {
      name: "Comments", 
      key:"comments"
    },
    {
      name: "Favorites", 
      key: "favorites"
    },
  ],
  elements: [
    {
      name: "Complete", 
      key:"complete"
    },
    {
      name: "Incomplete", 
      key: "incomplete"
    },
    {
      name: "Comments", 
      key: "comments"
    },
    {
      name: "Favorites", 
      key: "favorites"
    },
  ]
}
  
export const adminDashboardStatisticsElements = [
    {
      children: [
        {
          title: 'Users',
          label: "Total Registered Users",
          value: "users",
          subLink:  ADMIN_MATLOOB_USERS
        },
        {
          title: 'Seasons',
          label: "Seasons",
          subLink: ADMIN_SEASONS,
          elements: statusList.eventsAndSeasons,
          value: "saudi_seasons"  
        },
        {
          title: 'Events',
          label: "Events",
          subLink: ADMIN_EVENTS_LIST,
          elements: statusList.eventsAndSeasons,
          value: "saudi_events"  
        }
      ],
    }
]
  export const adminCategoriesStatisticsElements = [
    {
      title: "Main Elements",
      link: MAIN_ELEMENTS,
      children:[
        {
          label: "Restaurant or Cafe",
          elements: statusList.elements,
          value: "restaurants",
          param: RESTAURANT_OF_CAFE_KEY,
          type: RESTAURANT_OF_CAFE_KEY,
          chartLabel: ["Restaurant", "or Cafe"]
        },
        {
          label: "Food Truck",
          elements: statusList.elements,
          value: "restaurants",
          param: FOOD_TRUCK_KEY,
          type: FOOD_TRUCK_KEY,
          chartLabel: ["Food", "Truck"]
        },
        {
          label: "Food & Beverages Provider",
          elements: statusList.elements,
          value: "restaurants",
          param: FOOD_AND_DRINKS_PROVIDER_KEY,
          type: FOOD_AND_DRINKS_PROVIDER_KEY,
          chartLabel: ["Food &", "Beverages", "Provider"]
        },
        {
          label: "Store",
          elements: statusList.elements,
          value: "stores",
          param: STORE_KEY,
          type: STORE_KEY,
          chartLabel: ["Store"]
        },
        {
          label: "Pop Up Store",
          elements: statusList.elements,
          value: "stores",
          param: POP_UP_STORES_KEY,
          type: POP_UP_STORES_KEY,
          chartLabel: ["Pop Up", "Store"]
        },
        {
          label: "Personal Store",
          elements: statusList.elements,
          value: "stores",
          param: PERSONAL_STORES_KEY,
          type: PERSONAL_STORES_KEY,
          chartLabel: ["Personal", "store"]
        },
        {
          label: "Talents",
          elements: statusList.elements,
          value: TALENTS,
          type: TALENTS,
          chartLabel: ["Talents"]
        },
        {
          label: "Temporary Work",
          elements: statusList.elements,
          value: TEMPORARY_WORK,
          type: TEMPORARY_WORK,
          chartLabel: ["Temporary", "Work"]
        }
      ],
    },
  ]
  export const adminSubmittedFormStatisticsElements = [
    {
    title: "Main Elements",
    link: MAIN_ELEMENTS,
    children:[
      {
        label: "Restaurant or Cafe",
        elements: statusList.elements,
        value: "restaurants",
        param: RESTAURANT_OF_CAFE_KEY,
        type: RESTAURANT_OF_CAFE_KEY,
        chartLabel: ["Restaurant", "or Cafe"]
      },
      {
        label: "Food Truck",
        elements: statusList.elements,
        value: "restaurants",
        param: FOOD_TRUCK_KEY,
        type: FOOD_TRUCK_KEY,
        chartLabel: ["Food", "Truck"]
      },
      {
        label: "Food & Beverages Provider",
        elements: statusList.elements,
        value: "restaurants",
        param: FOOD_AND_DRINKS_PROVIDER_KEY,
        type: FOOD_AND_DRINKS_PROVIDER_KEY,
        chartLabel: ["Food &", "Beverages", "Provider"]
      },
      {
        label: "Store",
        elements: statusList.elements,
        value: "stores",
        param: STORE_KEY,
        type: STORE_KEY,
        chartLabel: ["Store"]
      },
      {
        label: "Pop Up Store",
        elements: statusList.elements,
        value: "stores",
        param: POP_UP_STORES_KEY,
        type: POP_UP_STORES_KEY,
        chartLabel: ["Pop Up", "Store"]
      },
      {
        label: "Personal Store",
        elements: statusList.elements,
        value: "stores",
        param: PERSONAL_STORES_KEY,
        type: PERSONAL_STORES_KEY,
        chartLabel: ["Personal", "store"]
      },
      {
        label: "Talents",
        elements: statusList.elements,
        value: TALENTS,
        type: TALENTS,
        chartLabel: ["Talents"]
      },
      {
        label: "Temporary Work",
        elements: statusList.elements,
        value: TEMPORARY_WORK,
        type: TEMPORARY_WORK,
        chartLabel: ["Temporary", "Work"]
      }
    ],
  },
  {
    title: "Supporting Elements",
    link: SUPPORTING_ELEMENTS,
    children: [
      {
        label: "Events",
        elements: statusList.elements,
        value: EVENTS,
        type: EVENTS,
        chartLabel: ["Events"]
      },
      {
        label: "Ideas & Suggestions",
        elements: statusList.ideas,
        value: IDEAS,
        type: IDEAS,
        chartLabel: ["Ideas &", "Suggestions"]
      },
      {
        label: "Venues",
        elements: statusList.elements,
        value: VENUES,
        type: VENUES,
        chartLabel: ["Venues"]
      },
      {
        label: "Logistics",
        elements: statusList.elements,
        value: LOGISTICS,
        type: LOGISTICS,
        chartLabel: ["Logistics"]
      },
    ],
  }
]