import React from "react";
import PropTypes from "prop-types";
import { COUNTRY_CODE } from "constants/forms";
import styles from "./styles.module.scss";
import { arabicToEnglishNums, ArEnDigits } from "utils/validation";
import { isFieldWithError } from "utils/helpers";
import classnames from "classnames";

const CODE_LENGTH = String(COUNTRY_CODE).length;

const PhoneInput = ({
    name,
    onFocus,
    formik,
    className,
    boxClassName,
    bgColor,
}) => {
    const handleChange = ({ target: { value } }) => {
        if (ArEnDigits.test(value) || value === "") {
            formik.setFieldValue(
                name,
                `${COUNTRY_CODE}${arabicToEnglishNums(value)}`
            );
        }
    };
    return (
        <div
            className={classnames(
                styles.phoneInput,
                boxClassName,
                className?.inputWrapper,
                {
                    [styles.withBg]: bgColor,
                }
            )}
        >
            <input
                id={name}
                name={name}
                type="tel"
                inputMode="decimal"
                className={classnames("form-control", className?.input, {
                    invalidField: isFieldWithError(formik, name),
                })}
                placeholder="5xxxxxxxx"
                value={formik.values[name]?.slice(CODE_LENGTH) || ""}
                onChange={handleChange}
                onFocus={onFocus}
                maxLength="9"
            />
        </div>
    );
};

PhoneInput.propTypes = {
    name: PropTypes.string,
    onFocus: PropTypes.func.isRequired,
    boxClassName: PropTypes.string,
    formik: PropTypes.object.isRequired,
    bgColor: PropTypes.bool,
};

export default React.memo(PhoneInput);
