import * as AuthActions from "store/actions/auth.actions";

const initialState = {
  loaded: true,
  error: "",
  user: {},
  openOtpModal: false,
  openSignupModal: false,
  loginError: "",
  signupError: "",
  otpError: "",
  openMenu: false,
  menuStatus: false,
  success: false,
  tmpUserData: {},
  authenticated: false,
  adminToken: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AuthActions.SIGN_UP.REQUEST:
      return {
        ...state,
        loaded: false,
        tmpUserData: action.payload,
      };

    case AuthActions.SIGN_UP.SUCCESS:
      return {
        ...state,
        loaded: true,
        signupError: "",
        openSignupModal: false,
        openOtpModal: true
      };

    case AuthActions.SIGN_UP.FAILURE:
      return {
        ...state,
        loaded: true,
        signupError: action.payload
      };

    case AuthActions.CONFIRM_SIGNUP.REQUEST:
      return { ...state, loaded: false };

    case AuthActions.CONFIRM_SIGNUP.SUCCESS:
      return {
        ...state,
        loaded: true,
        otpError: "",
        tmpUserData: {}
      };

    case AuthActions.CONFIRM_SIGNUP.FAILURE:
      return {
        ...state,
        loaded: true,
        otpError: action.payload
      };

    case AuthActions.LOGIN.REQUEST:
      return { ...state, loaded: false };

    case AuthActions.LOGIN.SUCCESS:
      return {
        ...state,
        loaded: true,
        otpError: "",
        tmpUserData: {},
        authenticated: true
      };

    case AuthActions.LOGIN.FAILURE:
      return {
        ...state,
        loaded: true,
        otpError: action.payload
      };

    case AuthActions.GET_MOBILE_OTP.REQUEST:
      return { ...state, otpError:false, loaded: false };

    case AuthActions.GET_MOBILE_OTP.SUCCESS:
      return { ...state, loaded: true, loginError: "", openOtpModal: true };

    case AuthActions.GET_MOBILE_OTP.FAILURE:
      return {
        ...state,
        loaded: true,
        // loginError: getErrorsList(error),
        loginError: action.payload.error
        // ...rest
      };

    case AuthActions.LOGOUT.REQUEST:
      return { ...state, loaded: false };

    case AuthActions.LOGOUT.SUCCESS:
      return {
        ...initialState,
        authenticated: false
      }

    case AuthActions.LOGOUT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case AuthActions.GET_USER.REQUEST:
      return { ...state, loaded: false };

    case AuthActions.GET_USER.SUCCESS:
      return { ...state, loaded: true, error: "", user: action.payload };

    case AuthActions.GET_USER.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case AuthActions.TOGGLE_OTP_MODAL:
      return {
        ...state,
        openOtpModal: !state.openOtpModal,
        otpError: ""
      };

    case AuthActions.TOGGLE_SIGNUP_MODAL:
      return {
        ...state,
        openSignupModal: !state.openSignupModal
      };

    case AuthActions.TOGGLE_MENU:
      return {
        ...state,
        openMenu: action.payload ? action.payload.open : !state.openMenu
      };

    case AuthActions.LOGIN_ADMIN.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.LOGIN_ADMIN.SUCCESS:
      return {
        ...state,
        loaded: true,
        loginError: "",
        user: action.payload.user,
        adminToken: action.payload.adminToken,
        openOtpModal: true,
        authenticated: true
      };

    case AuthActions.LOGIN_ADMIN.FAILURE:
      return {
        ...state,
        loaded: true,
        // loginError: getErrorsList(action.payload),
        otpError: action.payload,
      };

    case AuthActions.CHANGE_MENU_STATUS.REQUEST:
      return {
        ...state,
        menuStatus: !state.menuStatus
      };

    case AuthActions.CHANGE_MENU_STATUS.FAILURE:
      return {
        ...state,
        menuStatus: false
      };

    case AuthActions.LOGOUT_ADMIN.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.LOGOUT_ADMIN.SUCCESS:
      return {
        ...initialState,
        authenticated: false
      }

    case AuthActions.LOGOUT_ADMIN.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case AuthActions.FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loaded: true,
        loginError: "",
        success: true
      };

    case AuthActions.FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        loaded: true,
        loginError: action.payload,
        success: false
      };

    case AuthActions.RESET_AUTH_STATE:
      return {
        ...state,
        error: "",
        otpError: "",
        signupError: "",
        success: false
      };

    case AuthActions.RESET_PASSWORD.REQUEST:
    case AuthActions.CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.RESET_PASSWORD.SUCCESS:
    case AuthActions.CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loaded: true,
        loginError: "",
        success: true
      };

    case AuthActions.RESET_PASSWORD.FAILURE:
    case AuthActions.CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        loaded: true,
        loginError: action.payload,
        success: false
      };

    case AuthActions.GET_ADMIN.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.GET_ADMIN.SUCCESS:
      return {
        ...state,
        loaded: true,
        user: action.payload
      };

    case AuthActions.GET_ADMIN.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case AuthActions.EDIT_ADMIN.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.EDIT_ADMIN.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        user: action.payload,
        success: true
      };

    case AuthActions.EDIT_ADMIN.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        success: false
      };

    case AuthActions.REMOVE_USER:
      return {
        ...state,
        user: {},
        tmpUserData: {}
      };

    case AuthActions.LOGIN_ADMIN_OTP.REQUEST:
      return { ...state, loaded: false };

    case AuthActions.LOGIN_ADMIN_OTP.SUCCESS:
      return { ...state, loaded: true, loginError: "", openOtpModal: true };

    case AuthActions.LOGIN_ADMIN_OTP.FAILURE:
      return {
        ...state,
        loaded: true,
        loginError: action.payload,
        // loginError: action.payload.error.message
        // ...rest
      };

    case AuthActions.EDIT_USER_INFO.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case AuthActions.EDIT_USER_INFO.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        user: action.payload,
        success: true
      };

    case AuthActions.EDIT_USER_INFO.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        success: false
      };

    case AuthActions.INVALID_TOKEN_REDIRECT.REQUEST:
      return {
        ...state,
      }

    case AuthActions.INVALID_TOKEN_REDIRECT.SUCCESS:
      return {
        ...state,
        loginError: action.payload
      }
    case AuthActions.CLEAR_ERROR:
      return {
        ...state,
        loginError: ''
      }
    default:
      return state;
  }
}
