import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  TableComponent,
  Loader,
  AdminTopPanel,
  ElementsFilter,
  Pagination,
  AlertBox
} from "components";
import { 
  getElementsUsers, 
  // addRemoveFavoriteUser, 
  getElementsUsersTarget, 
  clearUserRequestTarget, 
  changeCategoryAction
} from "store/actions/adminElements.actions";
import { ELEMENT_COMMENTS, ADMIN_VIEW_ELEMENTS } from "constants/paths";
import { useFormik } from "formik";
import { compile } from "path-to-regexp";
import { getAdminSupportingElements } from "store/actions/elements.actions";
import { filteredCategoriesType, initialValues } from "./constants";
import { SUPPORTING_ELEMENTS } from "constants/paths"
import * as Yup from "yup";
import { phoneNumberRegExp } from "utils/validation";
import { EVENTS, LOGISTICS, IDEAS, SUPPORTING_ELEMENTS_TYPE } from "constants/forms"
import { Context } from "context";
import { allElements, logisticsElements } from "./constants";
// const TMP_ID = "458e2c2d-6c2c-4555-9d6d-eec0c87607f7";



const STATUS = "status"

const SupportingElements = () => {
  const [ typeSelected, setTypeSelected ] = useState({});
  const { elementsUsers, loaded, error, supportMessage } = useSelector(({ adminElements }) => adminElements)
  const isAlertActive =  useSelector(({ alert }) => alert);
  const { loaded: elementsLoaded, error: elementsError, ...data } = useSelector(({ elements }) => elements)
  const dispatch = useDispatch();
  const history = useHistory();
  const { type }  = useParams();
  const { user } = useContext(Context);

  const supportingElements = useMemo(()=>{
    switch(user.user_type){
      case 5:
        return logisticsElements
      default:
        return allElements;
    }
  },[user])
  
  

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      dispatch(getElementsUsers.request(setParams()));
    },
    validationSchema: Yup.object().shape({
      mobile_number: Yup.string().matches(phoneNumberRegExp, 'Mobile format is not correct, must be 9665XXXXXXXX'),
    }),
  });

  useEffect(() => {
    const elementType = supportingElements.find(item => item.key === type)
    if(!elementType){
      history.push(compile(SUPPORTING_ELEMENTS)({type: user.user_type === 5 ? LOGISTICS : EVENTS}))
      return
    }
    setTypeSelected(elementType)
    dispatch(getElementsUsers.request({type: elementType.type}));
  }, [dispatch, history, type, user, supportingElements]);

  useEffect(() => {
    dispatch(getAdminSupportingElements.request())
  },[dispatch])

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "User Name",
      key: "user_name"
    },
    {
      name: "Mobile Number",
      key: "mobile_number"
    },
    {
      name: "Status",
      key: "boolean",
      values: ['Incomplete', 'Complete'],
      field: "status"
    },
    {
      name: "Comments",
      key: "action-btn",
      class: "fas fa-comments",
      field: 'comments',
      onClick: ({ id }) => {
        history.push(compile(ELEMENT_COMMENTS)({element_type: SUPPORTING_ELEMENTS_TYPE}), {id})
      }
    },
    {
      name: "Category",
      key: "dropdown",
      field: 'favorite',
      onClick: ({id}, val) => {
        dispatch(changeCategoryAction.request({id, val}))
        dispatch(getElementsUsers.request(setParams()));
      }
    },
    // {
    //   name: "Favorite",
    //   key: "boolean-icon",
    //   field: "favorites",
    //   class: ['mdi-star-outline','mdi-star'],
    //   onClick: ( index, userId ) => {
    //     dispatch(addRemoveFavoriteUser.request(
    //       {
    //         form_id: userId,
    //         index,
    //       },
    //       )
    //     )
    //   },
    // },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: (({ id }) => {
        window.open(compile(ADMIN_VIEW_ELEMENTS)({element_type: SUPPORTING_ELEMENTS_TYPE, type: typeSelected.type, id}), '_blank')
      })
    }
  ];

  const setParams = () => {
    const { type } = typeSelected;
    const params = {}
    for(let key in formik.values){
      if(formik.values[key])
        params[key] = formik.values[key]?.value || formik.values[key]?.id || formik.values[key]
    }
    return {type, params}
  }

  const tableBody = elementsUsers?.data?.map(el => ({
    id: el?.id,
    user_name: el?.user?.full_name || el?.user?.company_name || el?.user?.name || "",
    mobile_number: el?.user?.mobile_number || el?.user?.mobile || "",
    status: el?.status || "",
    comments: el?.comments || "",
    favorite: el?.favorite || ""
  }));

  const onDropdownChange = (element) => {
    history.push(compile(SUPPORTING_ELEMENTS)({type: element.key}))
    formik.resetForm()
    dispatch(clearUserRequestTarget())
  }
  
  const dropdownTools = {
    label: "Type",
    dropdownElements: supportingElements,
    optionSelected: typeSelected,
    setOptionSelected: onDropdownChange
  }

  const buttonTools = {
    label: 'Export',
    onClick: () => {
      dispatch(getElementsUsersTarget.request({ ...setParams(), typeSelected }))
    }
  };

  const resetData = () => {
    formik.resetForm()
    const { type } = typeSelected;
    dispatch(getElementsUsers.request({type}));
  }

  return (
    <Loader loaded={loaded && elementsLoaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error || elementsError}
          additionalStyles='alert-danger'
        />
      }
      {
        supportMessage &&
          <div className='successAlert'>
            {supportMessage}
          </div>
      }
        <AdminTopPanel
          withButton
          buttonTools={buttonTools}
          withDropdown
          dropdownTools={dropdownTools}
        />
        <ElementsFilter
          typeSelected={typeSelected}
          data={data}
          filteredCategoriesType={filteredCategoriesType}
          formik={formik}
          resetData={resetData}
        />
        <section>
          <div className="card">
            <div className="card-body">
              <TableComponent
                tableHead={type === IDEAS ? tableHead.filter(item => item.field !== STATUS) : tableHead }
                tableBody={tableBody}
              />
              {elementsUsers?.data?.length ?
              <Pagination
                type={typeSelected.type}
                action = {getElementsUsers}
                currentPage = {elementsUsers?.current_page}
                lastPage = {elementsUsers?.last_page}
                extraParams = {setParams()}
                goToPage
              />
              :
              null
            }
            </div>
          </div>
        </section>
    </Loader>
  );
};

export default React.memo(SupportingElements);
