import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Switch from "react-switch";
import { serialize } from "object-to-formdata";
import { Button,DropdownButton,Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { Context } from "context";
import { DragDropFile, Loader, AlertBox, DragDropVideoFile } from "components";
import { getAdminMediaCenterImagesVideosDetails, addMediaCenterNewItem, editMediaCenterItem } from "store/actions/adminElements.actions";
import styles from './styles.module.scss'

const MediaCenterAddEditImagesVideos = ({ id }) => {
    const [type, setType] = useState(1)
    const { mediaCenterAdminImagesVideosDetails, loaded, error } = useSelector(
        ({ adminElements }) => adminElements
    );
    const isAlertActive = useSelector(({ alert }) => alert);
    const { user } = useContext(Context);
    const viewOnly = user?.user_type === 3;

    const dispatch = useDispatch();
    const DATE_FORMAT = "l";
    const typeList = [
        {id:1, value:'Images'},
        {id:2, value:'Videos'}
    ]


    useEffect(() => {
        if (id) {
            dispatch(getAdminMediaCenterImagesVideosDetails.request({ id }));
        }
    }, [dispatch, id]);

    const showErrorMessage = (name) => (
        <ErrorMessage
            name={name}
            render={(msg) => (
                <span className="text-danger font-weight-normal">{msg}</span>
            )}
        />
    );

    const TextArea = ({ field }) => (
        <>
            <textarea {...field} disabled={viewOnly} className="form-control" />
            {showErrorMessage(field.name)}
        </>
    );
    const ImageInput = ({ field, form }) => {
        return (
            <>
                <DragDropFile field={field} form={form} value={form.values.main_image} viewOnly={viewOnly}/>
                {showErrorMessage(field.name)}
            </>
            )
    }
    const submitForm = (values) => {
        const dataImage = {
            type:values.type,
            title_ar: values.title_ar,
            title_en: values.title_en,
            description_en: values.description_en,
            description_ar: values.description_ar,
            status: values.status,
            media_center_images: values.media_center_images?.map(({id, image, description_ar, description_en}) => {
                if(typeof(image) === "string"){
                    return {
                        id,
                        description_ar,
                        description_en
                    }
                }else if(id){
                    return{
                        id,
                        image,
                        description_ar,
                        description_en
                    }
                } else{
                    return{
                        image,
                        description_ar,
                        description_en
                    }
                }
            }),
        }
        const dataVideo = {
            type:values.type,
            title_ar: values.title_ar,
            title_en: values.title_en,
            description_en: values.description_en,
            description_ar: values.description_ar,
            status: values.status,
            media_center_videos: values.media_center_videos?.map(({id, video, description_ar, description_en}) => {
                if(typeof(video) === "string"){
                    return {
                        id,
                        description_ar,
                        description_en
                    }
                    }  else if(id){
                        return{
                            id,
                            video,
                            description_ar,
                            description_en
                        }
                    } else{
                        return{
                            video,
                            description_ar,
                            description_en
                        }
                    }
                }),
        }
        const imageWithMainImage = typeof(values.main_image) === "string" ? {...dataImage} : {...dataImage, main_image: values.main_image};
        const videoDataWithMainImage = typeof(values.main_image) === "string" ? {...dataVideo} : {...dataVideo, main_image: values.main_image};
        if(!id){
            if(type === 1){
                dispatch(addMediaCenterNewItem.request(serialize({...imageWithMainImage}, { indices: true })));
            }
            if(type === 2){
                dispatch(addMediaCenterNewItem.request(serialize({...videoDataWithMainImage}, { indices: true })));
            }
        }
        if(id){
            if(type === 1){
                dispatch(editMediaCenterItem.request({id, data:serialize({...imageWithMainImage, _method: 'PUT'}, { indices: true })}));
            }
            if(type === 2){
                dispatch(editMediaCenterItem.request({id, data:serialize({...videoDataWithMainImage, _method: 'PUT'}, { indices: true })}));
            }
        }
    };

    return (
        <Loader loaded={loaded}>
            {isAlertActive && (
                <AlertBox error={error} additionalStyles="alert-danger" />
            )}
            <div className="pb-4">
                <Formik
                    initialValues={{
                            type: id ? mediaCenterAdminImagesVideosDetails.type : 1,
                            created_at: id ? moment(mediaCenterAdminImagesVideosDetails.created_at).format(DATE_FORMAT) : '',
                            title_ar: id ? mediaCenterAdminImagesVideosDetails.title_ar : "",
                            title_en: id ? mediaCenterAdminImagesVideosDetails.title_en : "",
                            description_ar: id ? mediaCenterAdminImagesVideosDetails.description_ar : "",
                            description_en:  id ? mediaCenterAdminImagesVideosDetails.description_en : "",
                            status:  id ? mediaCenterAdminImagesVideosDetails.status : 0,
                            main_image: id ? mediaCenterAdminImagesVideosDetails.main_image : "" ,
                            media_center_images: id && mediaCenterAdminImagesVideosDetails?.images ?
                            mediaCenterAdminImagesVideosDetails.images?.map(item => ({
                                id: item.id,
                                description_ar: item.description_ar,
                                description_en: item.description_en,
                                image: `${item?.image}`
                            }))
                            :
                            [
                                {
                                    image: "",
                                    description_ar: "",
                                    description_en: ""
                                }
                            ],
                            media_center_videos: id && mediaCenterAdminImagesVideosDetails?.videos ?
                                mediaCenterAdminImagesVideosDetails.videos?.map(item => ({
                                    id: item.id,
                                    description_ar: item.description_ar,
                                    description_en: item.description_en,
                                    video: `${item?.video}`
                                }))
                                :
                                [
                                    {
                                        video: "",
                                        description_ar: "",
                                        description_en: ""
                                    }
                                ],
                    }}
                    enableReinitialize={true}
                    validationSchema={type === 1 ? 
                        Yup.object().shape({
                            title_ar: Yup.string().required('Required'),
                            title_en: Yup.string().required('Required'),
                            description_en: Yup.string().required('Required'),
                            description_ar: Yup.string().required('Required'),
                            status: Yup.number().required('Required'),
                            main_image:Yup.string().required('Required'),
                            media_center_images: Yup.array().of(
                                Yup.object().shape({
                                    image: Yup.mixed().required('Required'),
                                    description_ar: Yup.string().required('Required'),
                                    description_en: Yup.string().required('Required')
                                })
                            ),
                        }) : 
                        Yup.object().shape({
                            title_ar: Yup.string().required('Required'),
                            title_en: Yup.string().required('Required'),
                            description_en: Yup.string().required('Required'),
                            description_ar: Yup.string().required('Required'),
                            status: Yup.number().required('Required'),
                            main_image:Yup.string().required('Required'),
                            media_center_videos: Yup.array().of(
                                Yup.object().shape({
                                    video: Yup.mixed().required('Required'),
                                    description_ar: Yup.string().required('Required'),
                                    description_en: Yup.string().required('Required')
                                })
                            ),
                        })
                    }
                    onSubmit={(values) => submitForm(values, true)}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <section>
                                <div className="card">
                                <div className="card-body">
                                    <h4>{`${values.type === 1 ? 'Images' : 'Videos'} Main Image`}</h4>
                                    <code>Image Type: JPG or PNG</code>
                                    <div className="d-flex flex-column px-3 pb-3">
                                    <Field name="main_image" component={ImageInput}/>
                                    </div>
                                </div>
                                </div>
                            </section>
                            <section>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label className="control-label">Type</label>
                                            <DropdownButton
                                            variant="light"
                                            id="dropdown-basic-button"
                                            title={values.type === 1 ? 'Images' : 'Videos' }
                                            disabled={id ? true : false}
                                            >
                                            {typeList.map((type, i) => (
                                                <Dropdown.Item
                                                key={i}
                                                onSelect={setType(values.type)}
                                                onClick={() => setFieldValue("type", type.id)}
                                                >
                                                {type.value}
                                                </Dropdown.Item>
                                            ))}
                                            </DropdownButton>
                                            {showErrorMessage('type')}
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">
                                                Title Ar
                                            </label>
                                            <Field
                                                name="title_ar"
                                                placeholder="Title Ar"
                                                className="form-control"
                                            />
                                            {showErrorMessage("title_ar")}
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">
                                                Title En
                                            </label>
                                            <Field
                                                name="title_en"
                                                placeholder="Title En"
                                                className="form-control"
                                            />
                                            {showErrorMessage("title_en")}
                                        </div>
                                        {id && (
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Created Date
                                                </label>
                                                <Field
                                                    name="created_at"
                                                    placeholder="Created Date"
                                                    className="form-control"
                                                    disabled
                                                />
                                                {showErrorMessage("created_at")}
                                            </div>
                                        )}
                                        <div className="d-flex flex-column form-group">
                                            <label className="control-label">
                                                Status
                                            </label>
                                            <Switch
                                                checked={Boolean(values.status)}
                                                onChange={(item) =>
                                                    setFieldValue(
                                                        "status",
                                                        +item
                                                    )
                                                }
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                width={40}
                                                height={20}
                                                activeBoxShadow=""
                                                disabled={viewOnly}
                                            />
                                            {showErrorMessage("status")}
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">
                                                Description Ar
                                            </label>
                                            <Field
                                                name="description_ar"
                                                className="form-control"
                                                component={TextArea}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">
                                                Description En
                                            </label>
                                            <Field
                                                name="description_en"
                                                className="form-control"
                                                component={TextArea}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                {values.type === 1 ?
                                <FieldArray name="media_center_images">
                                {({remove, push, form}) => (
                                <div className="card">
                                    <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4>Images</h4>
                                        {!viewOnly && <Button className="btn-paua" onClick={() => type === 1 ? push({media_center_images: "", description_ar: "", description_en: ""}): push({media_center_images: null, description_ar: "", description_en: ""})}>
                                            New Image
                                        </Button>}
                                    </div>
                                    {values?.media_center_images?.map((image, index) => {
                                        return (
                                            <div key={index} className={`border border-light rounded my-2 p-2 ${styles.image__container}`}>
                                                {values.media_center_images.length > 1 && !viewOnly &&
                                                <span
                                                    className={classNames(`font-weight-bold ${styles.image__close}`)}
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                >
                                                    ×
                                                </span>
                                                }
                                            <div className="p-1">
                                            <h4>Image</h4>
                                            <code>Image Type: JPG or PNG</code>
                                            <Field name={`media_center_images[${index}].image`}>
                                                {(props) => {
                                                const { field, form } = props;
                                                    return(
                                                        <DragDropFile idx={index} form={form} field={field} value={values.media_center_images[index].image} viewOnly={viewOnly} onRemove={() => remove(index)}/>
                                                    )
                                                }}
                                            </Field>
                                            {showErrorMessage(`media_center_images[${index}].image`)}
                                            </div>
                                            <div className="d-flex p-1">
                                                <label className="flex-grow-1 mr-1">
                                                Description Ar
                                                <Field
                                                    name={`media_center_images[${index}].description_ar`}
                                                    component={TextArea}
                                                    className="form-control"
                                                />
                                                </label>
                                                <label className="flex-grow-1 ml-1">
                                                    Description En
                                                    <Field
                                                        name={`media_center_images[${index}].description_en`}
                                                        component={TextArea}
                                                        className="form-control"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        )
                                        })
                                    }
                                    </div>
                                </div>
                            )}
                            </FieldArray>
                            : null}
                            {values.type === 2 ? <FieldArray name="media_center_videos">
                            {({remove, push, form}) => (
                            <div className="card">
                                <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h4>Videos</h4>
                                    {!viewOnly && <Button className="btn-paua" onClick={() => type === 2 ? push({media_center_videos: "", description_ar: "", description_en: ""}): push({media_center_videos: null, description_ar: "", description_en: ""})}>
                                        New Video
                                    </Button>}
                                </div>
                                {
                                values?.media_center_videos?.map((image, index) => {
                                    return (
                                        <div key={index} className={`border border-light rounded my-2 p-2 ${styles.image__container}`}>
                                            {values.media_center_videos.length > 1 && !viewOnly &&
                                            <span
                                                className={classNames(`font-weight-bold ${styles.image__close}`)}
                                                onClick={() => {
                                                    remove(index)
                                                }}
                                            >
                                                ×
                                            </span>
                                            }
                                        <div className="p-1">
                                        <h4>Video</h4>
                                        <code>Video Type: MP4</code>
                                        <Field name={`media_center_videos[${index}].video`}>
                                            {(props) => {
                                            const { field, form } = props;
                                                return(
                                                    <DragDropVideoFile idx={index} form={form} field={field} value={values.media_center_videos[index].video} viewOnly={viewOnly} onRemove={() => remove(index)}/>
                                                )
                                            }}
                                        </Field>
                                        {showErrorMessage(`media_center_videos[${index}].video`)}
                                        </div>
                                        <div className="d-flex p-1">
                                            <label className="flex-grow-1 mr-1">
                                            Description Ar
                                            <Field
                                                name={`media_center_videos[${index}].description_ar`}
                                                component={TextArea}
                                                className="form-control"
                                            />
                                            </label>
                                            <label className="flex-grow-1 ml-1">
                                                Description En
                                                <Field
                                                    name={`media_center_videos[${index}].description_en`}
                                                    component={TextArea}
                                                    className="form-control"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    )
                                    })
                                }
                                </div>
                            </div>
                        )}
                        </FieldArray> : null}
                            </section>
                            {!viewOnly && (
                                <section className="d-flex">
                                    <Button
                                        className="btn-paua ml-auto"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </section>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </Loader>
    );
};

export default MediaCenterAddEditImagesVideos;
