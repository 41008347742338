import React, { useEffect } from "react";
import ProfileLayout from "layouts/Profile";
import { useTranslation } from "react-i18next";
import Check from "assets/icons/check-circle.svg";
import {
  IdeasForm,
  Loader,
  PageTitle,
  Button,
  AlertBox
} from "components";
import classnames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError, useFieldTouched } from "utils/forms";
import { getRegions } from "store/actions/elements.actions";
import { createIdea, resetState } from "store/actions/ideas.actions";
import { useDispatch, useSelector } from "react-redux";
import { serialize } from "object-to-formdata";
import styles from "./styles.module.scss";
import { englishOrArabic, sizeToMB } from "utils/validation";
import { PDF, MAX_FILE_SIZE, SUBMIT } from "constants/forms";

const Ideas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loaded, error, success } = useSelector(state => state.ideas);
  const isAlertActive = useSelector(({ alert }) => alert);

  const handleOnSubmit = (values, { resetForm }) => {
    dispatch(createIdea.request({ data: serialize(values, { indices: true }), resetFn: resetForm }));
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      file: null,
      saudi_regions: []
      // agree: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .matches(englishOrArabic, t("messages.arabicOrEnglish"))
        .required(".").max(50, t("messages.maxLength50")),
      description: Yup.string()
        .matches(englishOrArabic, t("messages.arabicOrEnglish"))
        .required("."),
      saudi_regions: Yup.array().required("."),
      file: Yup.mixed()
        .test("size", t("messages.fileSize"), val =>
          val?.size ? sizeToMB(val.size) <= MAX_FILE_SIZE : true
        )
        .test("extension", t("messages.pdfFile"), val =>
          val?.name ? val?.name?.split(".")?.reverse()?.[0] === PDF : true
        )
      // agree: Yup.boolean().required(".")
    }),
    onSubmit: handleOnSubmit
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  const successMessage = () => (
    <div className={classnames(styles.messageBox, styles.ideasSuccess)}>
      <img src={Check} alt="" />
      <p>{t("ideas.success")}</p>
    </div>
  );

  useEffect(() => {
    dispatch(getRegions.request());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  return (
    <ProfileLayout>
      <Loader loaded={loaded}>
        {
          isAlertActive &&
          <AlertBox
            error={error.message}
            additionalStyles="alert-danger"
          />
        }
        <div className={styles.ideas}>
          <div className={styles.ideasHeader}>
            <PageTitle title={t("ideas.title")} />
            {success && successMessage()}
          </div>
          {!success && (
            <div className={styles.ideasForm}>
              <form onSubmit={formik.handleSubmit}>
                <IdeasForm
                  formik={formik}
                  getError={getError}
                  setFieldTouched={setFieldTouched}
                />
                <div className={styles.buttons}>
                  <Button
                    title={t("buttons.submit")}
                    color={SUBMIT}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </Loader>
    </ProfileLayout>
  );
};

export default Ideas;
