import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Checkbox = ({ name, value, formik, className }) => {
  const handleClick = () => formik.setFieldTouched(name, true);
  const handleChange = e => formik.handleChange(e)
  return (
    <input
      id={name}
      type="checkbox"
      name={name}
      value={value}
      className={classnames(className, styles.checkbox)}
      onChange={handleChange}
      onClick={handleClick}
      checked={
        Array.isArray(formik.values[name])
          ? formik?.values[name]?.includes(value)
          : formik.values[name]?.toString() === value.toString()
      }
    />
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  formik: PropTypes.object,
  className: PropTypes.string
};

export default Checkbox;
