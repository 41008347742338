export const requestHeadings = [
    {
        key: "request_number",
        value: "requestNumber"
    },
    {
        key: "season",
        value: "requestElementSeasons"
    },
    {
        key: "status",
        value: "requestStatus"
    },
    {
        key: "element",
        value: "requestElement"
    },
    {
        key: "created_at",
        value: "requestDate"
    },
    {
        key: "request_details",
        value: "requestDetails",
    },
];