import axiosInstance from "./interceptor";

const SE_AMOUNT = 1000;

export const saudiSeasonsEventsApi = {
  getSaudiSeasons: () =>
    axiosInstance.get("/saudi-seasons?archive=false&cancelled=false", { params: { perPage: SE_AMOUNT } }),
  getSaudiEvents: () =>
    axiosInstance.get("/saudi-events?archive=false&cancelled=false", { params: { perPage: SE_AMOUNT } }),
  getSaudiSeasonEvent: ({ type, id }) => axiosInstance.get(`/${type}/${id}`),
  getStoreFormats: () => axiosInstance.get("/store-formats"),
  getRestaurantFormats: () => axiosInstance.get("/restaurant-formats"),
  getRegions: () => axiosInstance.get("/saudi-regions"),
  getSaudiSeasonEventElement: ({ type, id, main_element }) =>
    axiosInstance.get(`/${type}/${id}`, {
      params: { main_element },
    }),
  getRequestInterests: ({ perPage = 20, page = 1, status = 1, parameters }) =>
    axiosInstance.get("/interests", {
      params: { perPage, status, page, ...parameters },
    }),
  getFile: (path) => axiosInstance.get(`/${path}`),
};
