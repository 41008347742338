import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col } from "react-bootstrap";
import styles from "./styles.module.scss";
import { calculateSum } from "utils/helpers";
import { Chart } from "components";
import { Link } from "react-router-dom";
import { compile } from "path-to-regexp";
import { useHistory } from "react-router-dom";

const AdminStatisticsItem = ({
  withChart,
  withCards,
  data,
  title,
  children,
  link,
}) => {
  const history = useHistory();

  const getLinkTitle = (title, link) => (
    <div className="col-12">
      <div className="page-title-box">
        <Link to={link} className={styles.title}>
          {title}
        </Link>
      </div>
    </div>
  );

  const dataChart = useMemo(() => {
    const result = !data.draft
      ? children.reduce(
          (acc, prev) => {
            acc.categories.push(prev.chartLabel);
            acc.data.push(
              prev.param
                ? calculateSum(data[prev.value][prev.param])
                : calculateSum(data[prev.value])
            );
            return acc;
          },
          { categories: [], data: [] }
        )
      : {
          data: {
            categories: children.map((elem) => elem.chartLabel),
            completed: data["completed"],
            draft: data["draft"],
          },
        };

    return result;
  }, [children, data]);

  const redirectTo = (link, type) => {
    history.push(compile(link)({ type }));
  };

  const getCardItems = (label, elements, value, title, param, subLink) => (
    <>
      {title && getLinkTitle(title, subLink)}
      <div className="card-box">
        <h4 className={classNames("mt-0 font-16", styles.label)}>{label}</h4>
        <h2
          className={classNames(
            "my-3 text-center",
            styles.adminStatisticsTotal
          )}
        >
          {elements
            ? param
              ? calculateSum(data[value][param])
              : calculateSum(data[value])
            : data[value]}
        </h2>
        <div className="d-flex justify-content-between">
          {elements?.map(({ name, key }, index) => (
            <div className="d-flex flex-column" key={index}>
              <p className={styles.adminStatisticsName}>{name}</p>
              <h4 className={styles.adminStatisticsData}>
                {param ? data[value]?.[param]?.[key] : data[value]?.[key]}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      {title && getLinkTitle(title, link)}
      {withChart && (
        <div className="card">
          <div className="card-body">
            <Chart dataChart={dataChart} fileName={title} />
          </div>
        </div>
      )}

      {withCards && (
        <Row>
          {children?.map(
            (
              { label, elements, value, title, param, subLink, type },
              index
            ) => (
              <Col key={index} sm={12} md={6} lg={6} xl={4}>
                {type ? (
                  <div
                    onClick={() => redirectTo(link, type)}
                    className={styles.linkBox}
                  >
                    {getCardItems(
                      label,
                      elements,
                      value,
                      title,
                      param,
                      subLink,
                      type
                    )}
                  </div>
                ) : (
                  getCardItems(
                    label,
                    elements,
                    value,
                    title,
                    param,
                    subLink,
                    type
                  )
                )}
              </Col>
            )
          )}
        </Row>
      )}
    </>
  );
};

AdminStatisticsItem.defaultProps = {
  withChart: false,
  withCards: true,
};

AdminStatisticsItem.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.array.isRequired,
  withChart: PropTypes.bool,
  withCards: PropTypes.bool,
};

export default AdminStatisticsItem;
