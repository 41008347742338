import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";

const SaveModal = ({ save, cancel }) => {
  const { t } = useTranslation();

  return (
    <Modal className="modal-dialog-centered modal-md" isOpen={true}>
      <div className={styles.deleteModalBox}>
        <div className={styles.deleteModalBoxHeader}>
          <p>{t("deleteModal.title")}</p>
        </div>
        <div className={styles.deleteModalBoxMain}>
          <p>{t(`messages.saveOrCancel`)}</p>
        </div>
        <div className={styles.deleteModalBoxButtons}>
          <button className={styles.Confirm} onClick={save}>
            {t("buttons.save&close")}
          </button>
          <button className={styles.Cancel} onClick={cancel}>
            {t("buttons.cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

SaveModal.propTypes = {
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  interest: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
};

export default SaveModal;
