import axiosInstance from "./interceptor";

export const MediaCenterApi = {
    getMediaCenterNews: ( page = 1, perPage = 40) => axiosInstance.get("/news", {
        params: { page, perPage },
    }),
    getMediaCenterNewsDetails: (id) => axiosInstance.get(`/news/${id}`),
    getMediaCenterPhotos: ( type, page = 1, perPage = 40) => axiosInstance.get(`/media-center/`, {
        params: { page, perPage,type:type },
    }),
    getMediaCenterVideos: ( type, page = 1, perPage = 40) => axiosInstance.get(`/media-center/`, {
        params: { page, perPage,type:type },
    }),
    getMediaCenterPhotosDetails: (id, type, page = 1, perPage = 40) => axiosInstance.get(`/media-center/${id}`, {
        params: { page, perPage,type:type },
    }),
    getMediaCenterVideosDetails: (id, type, page = 1, perPage = 40) => axiosInstance.get(`/media-center/${id}`, {
        params: { page, perPage,type:type },
    }),
    getMediaCenterCarouselImages: ( page = 1, perPage = 40) => axiosInstance.get("/media-center-carousel-images/", {
        params: { page, perPage },
    }),
};