export const DASHBOARD = "/portal";
export const INTERESTS = "/interests";
export const LOGOUT = "/logout";
export const SAUDI_SEASONS_EVENTS = "/saudi-seasons-events";
export const SAUDI_SEASON_EVENT = "/saudi/:type/:id";
export const SAUDI_SEASON_EVENT_APPLY = "/saudi/:type/:id/:elementId/:elementTypeId/:action";
export const SAUDI_SEASON_EVENT_REVIEW = "/saudi/:type/:id/:action";
export const REQUESTS = "/requests";
export const ARCHIVE = "/archive";
export const LOGIN = "/";
export const PROFILE = "/profile";
export const EDIT_INTEREST = "/interests/edit/:type/:id";
export const IDEAS = "/ideas";
export const POLICY = "/policy";
export const TERMS = "/terms&conditions";
export const QUESTIONS = "/questions";
export const CONTACT_US = "/contact-us";
export const MEDIA_CENTER = "/media-center";
export const NEWS = "/media-center/news";
export const NEWS_DETAILS = '/media-center/news/:id';
export const PHOTOS = "/media-center/photos";
export const PHOTOS_DETAILS = '/media-center/photo/:id';
export const VIDEOS = "/media-center/videos";
export const VIDEOS_DETAILS = '/media-center/video/:id';
export const NOTIFICATIONS = "/notifications";
export const ADMIN_LOGIN = "/admin";
export const ADMIN_HOME = "/admin/home";
export const FORGOT_PASSWORD = "/admin/forgot-password";
export const RESET_PASSWORD = "/admin/reset-password";
export const ADMIN_PROFILE = "/admin/profile";
export const CHANGE_PASSWORD = "/admin/change-password";
export const ADMIN_USERS = "/admin/users";
export const MAIN_ELEMENTS = "/admin/main-elements/:type";
export const ADMIN_VIEW_ELEMENTS = "/admin/view/:element_type/:type/:id";
export const SUPPORTING_ELEMENTS = "/admin/supporting-elements/:type";
export const ELEMENT_COMMENTS = "/admin/:element_type/comments";
export const ADD_EDIT_COMMENT_ID = "/admin/:element_type/comment/:comment_id";
export const ADD_EDIT_COMMENT = "/admin/:element_type/comment";
export const ADMIN_MATLOOB_USERS = "/admin/matloob-users";
export const ADMIN_MATLOOB_USER_ELEMENTS = "/admin/matloob-user-elements/:id";
export const ADMIN_MATLOOB_USER_REQUESTS = "/admin/user-requests/:id";
export const EDIT_ADMIN = "/admin/edit-admin/:id";
export const ADMIN_USERS_DETAILS = "/admin/view-users/:id";
export const NEW_CONTROL_PANEL = "/admin/new-control-panel-user";
export const ADMIN_SEASONS = "/admin/seasons";
export const ADMIN_APPLICATION_REQUIREMENTS = "/admin/application-requirements"
export const ADD_APPLICATION_REQUIREMENT = "/admin/application-requirement-form"
export const EDIT_APPLICATION_REQUIREMENT = "/admin/application-requirement/:id"
export const SEASONS_ARRANGEMENT = "/admin/seasons-arrangement";
export const ADMIN_EVENTS_LIST = "/admin/events-list";
export const ADMIN_EVENTS_ARRANGEMENT = "/admin/events-arrangement";
export const ADMIN_DASHBOARD_STATISTICS = "/admin/dashboard-statistics";
export const NEW_SEASON = "/admin/new-season";
export const NEW_EVENT = "/admin/new-event";
export const ADMIN_NOTIFICATIONS_HISTORY = "/admin/notifcations-history";
export const ADMIN_CREATE_NOTIFICATION = "/admin/create-notification"
export const EDIT_SEASON = "/admin/season/:id";
export const EDIT_EVENT = "/admin/event/:id";
export const SEASONS_REQUESTS = "/admin/seasons-request";
export const EVENTS_REQUESTS = "/admin/events-request";
export const SEASON_EVENT_REQUEST_INFO = "/admin/season-event-request/:id";
export const ADMIN_VIEW_REQUEST = "/admin/:type/:typeId/:id";
export const SUPPORTING_ELEMENTS_REQUESTS = "/admin/:type/supporting-elements/info/:id";
export const ERROR_PAGE = "/404";
export const VERIFY = "/verify";
export const ADMIN_VERIFY = "/admin/verify";
export const ADMIN_CATEGORIES_STATISTICS = "/admin/categories-statistics";
export const ADMIN_REPORTS = "/admin/reports";
export const ADMIN_MEDIA_CENTER_NEWS = "/admin/media-center/news";
export const ADMIN_MEDIA_CENTER_IMAGES_VIDEOS = "/admin/media-center/images&videos";
export const ADMIN_MEDIA_CENTER_CAROUSEL_IMAGES = "/admin/media-center/carousel-images"
export const ADMIN_MEDIA_CENTER_NEW_NEWS = "/admin/media-center/new-news"
export const ADMIN_MEDIA_NEWS_DETAILS = "/admin/media-center/news/details/:id"
export const ADMIN_MEDIA_CENTER_NEW_PHOTOS_VIDEOS = "/admin/media-center/new-photos&videos"
export const ADMIN_MEDIA_PHOTOS_VIDEOS_DETAILS = "/admin/media-center/photos&videos/details/:id"
export const ADMIN_MEDIA_CENTER_NEW_CAROUSEL_IMAGES = "/admin/media-center/new-carousel-images"
export const ADMIN_MEDIA_CAROUSEL_IMAGE_DETAILS = "/admin/media-center/carousel-images/details/:id"
