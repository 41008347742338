import React from "react";
import PublicLayout from "layouts/Public";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const Policy = () => {
    const { t } = useTranslation();
    const data = t("policy.data", { returnObjects: true });

    return (
        <PublicLayout title={t("policy.title")}>
            <div className={styles.wrapper}>
                <div className={styles.policyContent}>
                    {data.map(({ title, text, list }, index) => (
                        <div key={index}>
                            <h3 className={styles.Title}>{title}</h3>
                            <p className={styles.Text}>{text}</p>
                            {list && (
                                <ul className={styles.List}>
                                    {list.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </PublicLayout>
    );
};

export default Policy;
