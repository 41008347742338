import React from "react";
import Layout from "layouts/Admin";
import { AdminInfo } from "components";

const AdminProfile = () => (
  <Layout>
    <AdminInfo />
  </Layout>
);

export default AdminProfile;
