import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { SAUDI_SEASON_EVENT } from "constants/paths";
import { compile } from "path-to-regexp";
import { useTranslation } from "react-i18next";
import { seasonEventStatuses } from "constants/seasonsEvents";
import { getDaysFromDates } from "utils/helpers";
import styles from "./styles.module.scss";
import { ProgressBar } from "./components";

const SeasonEventItem = ({
  id,
  logo,
  name,
  description,
  end_date,
  start_date,
  created_at,
  type,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link
        to={compile(SAUDI_SEASON_EVENT)({ type, id })}
        className={styles.itemDetails}
      >
        <div
          className={classnames(styles.item, {
            [styles.itemActive]: true,
          })}
        >
          <div className={styles.itemLogo}>
            <img src={logo} alt={name} />
          </div>
          <div className={styles.itemInformation}>
            <h3 className={styles.itemName}>{name}</h3>
            <p
              className={classnames(
                styles.itemStatus,
                styles[seasonEventStatuses[status]]
              )}
            >
              {t(`seasons_events.statuses.${status}`)}
            </p>
            <div className={styles.itemInfoWrapper}>
              <p className={styles.itemDescription}>{description}</p>
              {!start_date || !end_date ? null : (
                <div className={styles.dateContainer}>
                  {
                    <ProgressBar
                      endDate={end_date}
                      createdAt={created_at}
                      startDate={start_date}
                      dayTo={getDaysFromDates(
                        status === 0 ? start_date : end_date
                      )}
                      status={status}
                    />
                  }
                  <div
                    className={classnames(
                      styles.dateText,
                      styles[seasonEventStatuses[status]]
                    )}
                  >
                    {!start_date || !end_date
                      ? null
                      : status === 0
                      ? `${getDaysFromDates(start_date)}  ${t(
                          getDaysFromDates(start_date) === 1
                            ? "seasons_events.oneDayToOpen"
                            : "seasons_events.toOpen"
                        )}`
                      : status === 1
                      ? `${getDaysFromDates(end_date)} ${t(
                          getDaysFromDates(end_date) === 1
                            ? "seasons_events.oneDayToClose"
                            : "seasons_events.toClose"
                        )}`
                      : status === 2
                      ? t("interests.closedStatus")
                      : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SeasonEventItem;
