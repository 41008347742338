import React, { useState, useEffect, useContext, useRef } from "react";
import {
  getUserNotifications,
  offNotification,
  setOpneNotificationBox
} from "store/actions/notifications.actions";
import { useHistory } from "react-router-dom";
import { useOnClickOutside } from "utils/hooks";
import * as Paths from "constants/paths";
import { Context } from "context";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FileIcon } from "assets/icons/file-text.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as GridIcon } from "assets/icons/grid.svg";
import { ReactComponent as SettingIcon } from "assets/icons/settings.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as ArLangIcon } from "assets/icons/languageAr.svg";
import { ReactComponent as EnLangIcon } from "assets/icons/languageEnNew.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/power.svg";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { getFormattedDateTime } from "utils/helpers";
import styles from "./styles.module.scss";

const ICONS = {
  request: <FileIcon />,
  season: <GridIcon />,
  event: <GridIcon />,
  all: <BellIcon />
};

const NotificationsBox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const { changeLang, rtl } = useContext(Context);

  const settingItems = [
    { icon: <ProfileIcon />, action: () => profileAction() },
    { icon: <NotificationIcon />, action: (e) => handleBellClick(e) },
    {
      icon: !rtl ? <ArLangIcon /> : <EnLangIcon />,
      action: () => changeLang()
    },
    {
      icon: <LogoutIcon />, action: () => history.push(Paths.LOGOUT)
    }
  ];

  const profileAction = () => {
    history.push(Paths.PROFILE);
    dispatch(setOpneNotificationBox(false));
  };

  const { userNotifications, newNotificationReceived, openNotificationBox } =
    useSelector(({ notifications }) => notifications);
  const [isNotificationIn, setIsNotificationIn] = useState(false);
  const [dropdownState, setDropdown] = useState(false);

  useEffect(() => {
    dispatch(getUserNotifications.request());
  }, [dispatch]);

  useEffect(() => {
    setIsNotificationIn(newNotificationReceived);
  }, [newNotificationReceived]);

  useOnClickOutside(ref, openNotificationBox, () =>
    dispatch(setOpneNotificationBox(false))
  );

  const notificationsList = () =>
    userNotifications?.slice(0,5)?.map((notification, idx) => {
      const notificationDate =
        notification?.created_at ||
        notification?.createdAt ||
        notification?.updatedAt;
      return (
        <div
          className={classnames(styles.notificationItem, {
            [styles.notificationItemChecked]: notification?.checked
          })}
          key={`${notification.id}-${idx}`}
        >
          <div className={styles.notificationItemIcon}>
            {ICONS[notification?.recipient || "all"]}
          </div>
          <div className={styles.notificationItemContent}>
            <p>{notification?.message}</p>
            <div className={styles.notificationItemDate}>
              <span>{getFormattedDateTime(notificationDate, "l")}</span>
              <span>{getFormattedDateTime(notificationDate, "LT")}</span>
            </div>
          </div>
        </div>
      );
    });

  const emptyNotificationsList = () => (
    <div className={styles.emptyList}>
      <p>{t("Notifications.empty")}</p>
    </div>
  );

  const handleBellClick = (e) => {
    e.stopPropagation();
    dispatch(offNotification());
    dispatch(setOpneNotificationBox(!openNotificationBox));
  };

  const goToNotification = (e) => {
      history.push(Paths.NOTIFICATIONS);
      closeSetting();
  };

  const closeSetting = () => {
      setDropdown(!dropdownState);
      dispatch(setOpneNotificationBox(false));
  };

  return (
      <div
          className={classnames(styles.bell, {
              [styles.bellOpen]: openNotificationBox,
              [styles.bellPointed]: isNotificationIn,
          })}
          ref={ref}
      >
          <div className={classnames(styles.boxContainer, {})}>
              <SettingIcon
                  className={classnames(styles.mainIcon, {
                      [styles.bellOpen]: dropdownState,
                  })}
                  onClick={() => closeSetting()}
              />
              <div
                  className={classnames(styles.infoNotificationBox, {
                      [styles.infoNotificationBoxOpen]: openNotificationBox,
                      [styles.infoNotificationAr]: rtl,
                  })}
              >
                  {userNotifications?.length
                      ? notificationsList()
                      : emptyNotificationsList()}
                  {userNotifications?.length && (
                      <div
                          className={styles.showMoreBtn}
                          onClick={() => goToNotification()}
                      >
                          {t("Notifications.show_more")}
                      </div>
                  )}
              </div>
              <div
                  className={classnames(styles.mainClass, {
                      [styles.activeItemsContainer]: dropdownState,
                  })}
              >
                  {settingItems.map(({ icon, action }, index) => {
                      return (
                          <div
                              className={styles.item}
                              onClick={action}
                              key={index}
                          >
                              {icon}
                          </div>
                      );
                  })}
              </div>
          </div>
      </div>
  );
};

export default NotificationsBox;
