import axiosInstance from "./interceptor";

export const requestsApi = {
  getUserRequests: (params) => axiosInstance.get("/user-requests", { params }),
  getUserRequest: (id) => axiosInstance.get(`/user-requests/${id}`),
  cancelRequest: (id) => axiosInstance.post(`/user-requests/cancel/${id}`),
  getUserRequestsPending: (params) =>
    axiosInstance.get("/user-requests/pending-elements", { params }),
  applyUserRequest: (data) => axiosInstance.post("/user-requests", data),
  portalDownloadFile: (data) =>
    axiosInstance.get(`/download/${data}`, {
      Accept: "application/json",
      responseType: "blob",
    }),
  getRequestInterest: (id) => axiosInstance.get(`/interests/${id}`),
};
