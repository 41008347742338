import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getElementComments, deleteElementComment } from "store/actions/adminElements.actions";
import { TableComponent, AlertBox } from 'components';
import { ADD_EDIT_COMMENT_ID, ADD_EDIT_COMMENT } from "constants/paths";
import { DeleteModal } from "components";
import { confirmDelete } from "constants/messages";
import { Button } from 'react-bootstrap';
import { compile } from "path-to-regexp";
import moment from "moment";
import classnames from "classnames";

const DATE_FORMAT = "l";

const ElementComments = ({elementId, isAccordion}) => {
  const [ isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [ commentToDelete, setCommentToDelete ] = useState(null);
  const { elementComments, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);

  const { element_type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectTo = (action, comment_id = null) => {
    if(comment_id){
      history.push(compile(ADD_EDIT_COMMENT_ID)({element_type, comment_id}), {action});
      return;
    }
    history.push(compile(ADD_EDIT_COMMENT)({element_type}), {action, elementId});
  }

  useEffect(() => {
    if(elementId){
      dispatch(getElementComments.request(elementId))
    }
  }, [dispatch, elementId]);

  const toggleModal = () => {
    if(isOpenDeleteModal) {
      setCommentToDelete(null);
    }
    setIsOpenDeleteModal(!isOpenDeleteModal);
  }

  const onDeleteHandler = (id) => {
    toggleModal();
    setCommentToDelete(id);
  }

  const confirmDeleteComment = () => {
    toggleModal();
    dispatch(deleteElementComment.request(commentToDelete));
    setCommentToDelete(null);
  }

  const tableHead = [
    {
      name: 'Admin Name',
      key: 'user_name'
    },
    {
      name: 'Comment',
      key: 'comment'
    },
    {
      name: 'Created At',
      key: 'date',
      fiel: 'created_at',
      format: data => moment.utc(data).local().format(DATE_FORMAT)
    },
    {
      name: "Edit",
      key: "editWithRedirect",
      path: 'comment',
      param: "id",
      onClick: ({id}) => {
        return redirectTo('edit', id)
      }
    },
    {
      name: "Delete",
      key: "action-btn",
      class: "mdi mdi-trash-can-outline",
      onClick: ({id}) => onDeleteHandler(id)
    }
  ];

const tableBody = elementComments.map(comment => ({
  id: comment.id,
  form_id: comment.form_id,
  user_name: comment.user_name,
  comment: comment.comment,
  created_at: comment.created_at,
  updated_at: comment.updated_at
}));

  return (
    <>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      {loaded &&
        <>
          <Button
            className={classnames("ml-auto my-2", "admin-submit-btn-black")}
            onClick={() => redirectTo('create')}
          >
            + New Comment
          </Button>
          {elementComments && <TableComponent tableHead={tableHead} tableBody={tableBody} isAccordion={isAccordion} />}
        </>
      }
      
      <DeleteModal
        isOpened={isOpenDeleteModal}
        toggle={toggleModal}
        data={confirmDelete}
        confirm={confirmDeleteComment}
        fromAdmin={true}
      />
    </>
  )
}



export default ElementComments;