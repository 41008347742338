import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { Loader, FooterSection, HeaderMenu } from "components";
import { useTranslation } from "react-i18next";
// import { ReactComponent as PublicPentagon } from "assets/images/loginImg/pentagon.svg";
// import { ReactComponent as PublicStar } from "assets/images/loginImg/greenStar.svg";
// import PublicSmallCircle  from '../../assets/images/loginImg/smallCircle.svg'
import PublicBackground from "./PublicBackground";
const LoginLayout = ({ children, title }) => {
    const { loaded } = useSelector((state) => state.auth);
    const { t } = useTranslation();

    return (
        <Loader loaded={loaded}>
            <PublicBackground>
                <div
                    className={`${styles.HomePage} beta-v`}
                    data-before-content={t("beta.title")}
                >
                    <HeaderMenu publicTitle={title} />
                    <div className={styles.Content}>{children}</div>
                    <div className={styles.wrapper_down__sections}>
                        <FooterSection withPadding={true} />
                    </div>
                </div>
            </PublicBackground>
        </Loader>
    );
};

export default LoginLayout;
