import React from "react";
import { useTranslation } from "react-i18next";
import { ModalBody } from "react-bootstrap";
import { Modal, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import check from "assets/images/check.svg";
import styles from "./styles.module.scss";

const SuccessInterestModal = ({ modalOpen, modalClose, modalCloseBtn }) => {
    const { t } = useTranslation();
    return (
        <Modal
            className={`modal-dialog-centered modal-md ${styles.modalWrapper}`}
            isOpen={modalOpen}
            toggle={modalCloseBtn}
        >
            <div onClick={modalCloseBtn} className={styles.modalCloseBtn}>
                x
            </div>
            <ModalHeader className={styles.headerWrapper}>
                <img
                    src={check}
                    alt="checked"
                    className={styles.imageWrapper}
                />
            </ModalHeader>
            <ModalBody className={styles.bodyWrapper}>
                <p className={styles.title}>{t("successModal.title")}</p>
                    <p className={styles.text}>{t("successModal.message")}</p>
            </ModalBody>
        </Modal>
    );
};
SuccessInterestModal.propTypes = {
    modalOpen: PropTypes.bool,
    modalClose: PropTypes.func,
    modalCloseBtn: PropTypes.func,
};

export default SuccessInterestModal;
