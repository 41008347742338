import React from "react";
import Layout from "layouts/Admin";
import { AddEditApplicationRequirement } from "components";
import { useParams } from 'react-router-dom';


const AdminAddEditApplicationRequirement = () => {
  const { id } = useParams();

  return(
    <Layout title={`${id ? "Edit" : "Add"} Application Requirement`}>
      <AddEditApplicationRequirement id={id}/>
    </Layout>
  )
};

export default AdminAddEditApplicationRequirement;