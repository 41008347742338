import React from "react";
import { FooterSection,
    LanguageButton,
    MediaCenterBackground
} from 'components'
import { Link } from "react-router-dom";
import { LOGIN } from "constants/paths";
import styles from './styles.module.scss'
import NewLogo from "assets/images/LogoWithOrangeStar.svg";
import MediaCenterCarousel from './MediaCenterCarousel/MediaCenterCarousel'
import PhotoCarousel from "./PhotoCarousel/PhotoCarousel";
import VideoCarousel from "./VideoCarousel/VideoCarousel";
import LatestNews from "./LatestNews/LatestNews";

const MediaCenter = () => {
    return (
        <MediaCenterBackground>
            <div className={styles.mediaCenter}>
            <div className={styles.lngWrapper}>
                <LanguageButton/>
            </div>
                <Link to={LOGIN} className={styles.publicLogo}>
                    <img src={NewLogo} alt="" />
                </Link>
                <MediaCenterCarousel/>
                <div className={styles.latestNews}>
                    <LatestNews/>
                </div>
                <div className={styles.photoVideoSection}>
                    <PhotoCarousel/>
                    <VideoCarousel/>
                </div>
            </div>
            <div className={styles.footer}>
                <FooterSection />
            </div>
        </MediaCenterBackground>
    );
};

export default React.memo(MediaCenter);
