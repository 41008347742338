import React from "react";
import Layout from "layouts/Admin";
import { MatloobUserElements } from "components";

const AdminMatloobUserElements = () => {
  return (
    <Layout title="Matloob User Elements">
      <MatloobUserElements />
    </Layout>
  );
};

export default AdminMatloobUserElements;
