import { mainElementsCategory } from "../CategoriesChart/constants";

export const checkCategories=(values,index,allCategories)=>{
  if(values?.elements[index]?.element[0]?.id=== "42fb5884-5a36-48e0-b7b8-5a96acc4f15a"){
    return mainElementsCategory(allCategories)[0].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "a0558093-1d16-4bc6-a4ad-ad7aa9994198"){
    return mainElementsCategory(allCategories)[3].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "0472cebe-9923-497b-9a64-09d3c2bbb943"){
    return mainElementsCategory(allCategories)[6].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "66b1946e-e4c4-4254-8ce3-fdcb0b0726f3"){
    return mainElementsCategory(allCategories)[7].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "97ad819d-8361-445d-b535-298c4616a0d0"){
    return mainElementsCategory(allCategories)[8].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "11c772a5-b90b-4db2-a55c-8075c5ad232a"){
    return mainElementsCategory(allCategories)[9].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "d77bfbcc-434f-4c57-ae10-76f362322429"){
    return mainElementsCategory(allCategories)[9].categories;
  }
  if(values?.elements[index]?.element[0]?.id=== "1a31c17b-d11a-43de-a1e3-c1938e983eaa"){
    return mainElementsCategory(allCategories)[10].categories;
  }
}

export const checkSubCategories=(values,index,allCategories)=>{
  //foodCategories
  if(values?.elements[index]?.categories[0]?.id === "fc0f1659-618f-4ee4-a19a-b04605b58d6b"){
    return Object.values(allCategories)[0]?.foodCategories[0]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "be975622-1e72-4513-b43c-1e70e68a75e6"){
    return Object.values(allCategories)[0]?.foodCategories[1]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "ab377685-de4b-41cc-bdf7-738e6ff22ff0"){
    return Object.values(allCategories)[0]?.foodCategories[2]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "2c317155-096b-4928-a76c-00eddcd848a1"){
    return Object.values(allCategories)[0]?.foodCategories[3]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "0a18494c-b6c9-43de-9027-7be83dd9dbb8"){
    return Object.values(allCategories)[0]?.foodCategories[4]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "9087fe25-2f18-4403-8b2a-ca5583027c10"){
    return Object.values(allCategories)[0]?.foodCategories[5]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "a198d1fb-91c3-4dd5-8acf-46137715e2ec"){
    return Object.values(allCategories)[0]?.foodCategories[6]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "72294f9d-64dc-4d88-92e9-b3877aa507eb"){
    return Object.values(allCategories)[0]?.foodCategories[7]?.children
  }
  //storeCategories
  if(values?.elements[index]?.categories[0]?.id ===  "4b5f4768-1650-4129-cccc-ccccccccc444"){
    return Object.values(allCategories)[3]?.storeProductCategories[0]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "4b5f4768-1650-4129-cccc-ccccccccc222"){
    return Object.values(allCategories)[3]?.storeProductCategories[1]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "c0f43afb-e427-43d1-816b-1a872b779c43"){
    return Object.values(allCategories)[3]?.storeProductCategories[2]?.children
  }
  if(values?.elements[index]?.categories[0]?.id ===  "4b5f4768-1650-4129-cccc-ccccccccc666"){
    return Object.values(allCategories)[3]?.storeProductCategories[3]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "0240e80e-b992-4314-ad2b-5f44a6fe1e29"){
    return Object.values(allCategories)[3]?.storeProductCategories[4]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "b825f69d-718a-4c2b-9815-71eec51a315c"){
    return Object.values(allCategories)[3]?.storeProductCategories[5]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "0e79676f-9a56-4c65-a6de-e83c24f62ff0"){
    return Object.values(allCategories)[3]?.storeProductCategories[6]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "4b5f4768-1650-4129-cccc-ccccccccc555"){
    return Object.values(allCategories)[3]?.storeProductCategories[7]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "71cbec03-520d-40d4-9e4f-27f1b05c1637" ){
    return Object.values(allCategories)[3]?.storeProductCategories[8]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "4b5f4768-1650-4129-cccc-ccccccccc333"){
    return Object.values(allCategories)[3]?.storeProductCategories[9]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "e4d2e72d-dbb4-422d-8021-a0c309540312" ){
    return Object.values(allCategories)[3]?.storeProductCategories[10]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "4b5f4768-1650-4129-cccc-ccccccccc777"){
    return Object.values(allCategories)[3]?.storeProductCategories[11]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "4b5f4768-1650-4129-cccc-ccccccccc888"){
    return Object.values(allCategories)[3]?.storeProductCategories[12]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "4278ca47-70f1-4307-a965-b2fb1e89cf1b"){
    return Object.values(allCategories)[3]?.storeProductCategories[13]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === "923e41c3-b9df-4319-97a8-e401acb6e379"){
    return Object.values(allCategories)[3]?.storeProductCategories[14]?.children
  }
  //talentCategories
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-20181ba6d55b'){
    return Object.values(allCategories)[6]?.talentCategories[0]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '5a3b0485-b5a6-4ab3-8603-16fad53acaa4'){
    return Object.values(allCategories)[6]?.talentCategories[1]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '9a25326e-4696-4ffc-8320-bde549f59f09'){
    return Object.values(allCategories)[6]?.talentCategories[2]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-00000000000a'){
    return Object.values(allCategories)[6]?.talentCategories[3]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-00000000000b'){
    return Object.values(allCategories)[6]?.talentCategories[4]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-00000000000c'){
    return Object.values(allCategories)[6]?.talentCategories[5]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-00000000000d'){
    return Object.values(allCategories)[6]?.talentCategories[6]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '4886ec53-3ca7-4ef5-aeb5-5a6a91941f1d'){
    return Object.values(allCategories)[6]?.talentCategories[7]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '57106713-d105-437c-992a-0c8f24eb2eb9'){
    return Object.values(allCategories)[6]?.talentCategories[8]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '57106713-d105-437c-992a-0c8f24eb2fff'){
    return Object.values(allCategories)[6]?.talentCategories[9]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'd53a0446-bb54-4d80-854a-f308559d61c8'){
    return Object.values(allCategories)[6]?.talentCategories[10]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'd04710dc-e94a-4572-84e2-c1ccbcee63d8'){
    return Object.values(allCategories)[6]?.talentCategories[11]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '7f9c9f99-363b-4255-8f15-4cc267275d8a'){
    return Object.values(allCategories)[6]?.talentCategories[12]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === '2b6e89c3-2f4d-4b62-9c44-45134e87e005'){
    return Object.values(allCategories)[6]?.talentCategories[13]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'ad97f061-66df-4264-b1db-00000000000e'){
    return Object.values(allCategories)[6]?.talentCategories[14]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'edd6eb5e-4c7b-42ff-be68-c7158af1ceeb'){
    return Object.values(allCategories)[6]?.talentCategories[15]?.children
  }
  if(values?.elements[index]?.categories[0]?.id === 'fdd6eb5e-4c7b-42ff-be68-c7158af1ceeb'){
    return Object.values(allCategories)[6]?.talentCategories[16]?.children
  }
}