import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError } from "utils/forms";
import { useDispatch, useSelector } from "react-redux";
import { getMobileOTP, toggleSignUpModal } from "store/actions/auth.actions";
import { phoneNumberRegExp } from "utils/validation";
import styles from "./styles.module.scss";
import { OTPmodal, SignUpModal, PhoneInput } from "components";
import yellowStarBG from "../../assets/icons/yellowStarBG.svg";

const LoginForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loginError: error } = useSelector((state) => state.auth);
    const [forLogin, setForLogin] = useState(false);

    const formik = useFormik({
        initialValues: {
            mobile_number: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            mobile_number: Yup.string()
                .matches(phoneNumberRegExp, t("messages.invalidPhone"))
                .required("."),
        }),
        onSubmit: (values) => {
            setForLogin(true);
            const searchParams = new URLSearchParams(window.location.search);
            const param = searchParams.get("redirect");
            if (param) {
                localStorage.setItem("dv", param);
            }

            localStorage.setItem("action", "login");
            localStorage.setItem("target", JSON.stringify(values));
            dispatch(getMobileOTP.request({ values }));
        },
    });

    const toggleModal = () => {
        setForLogin(false);
        dispatch(toggleSignUpModal());
    };

    const getError = useError(formik);

    const handleFocus = () => formik.setFieldTouched("mobile_number", true);

    const handleChange = (value) =>
        formik.setFieldValue("mobile_number", value);

    return (
        <div>
            <div className={styles.loginWrapper}>
                <div className={styles.loginForm}>
                    <div className={styles.loginFormHeader}>
                        <p>{t("homepage.Be_part")} </p>
                        <p className={styles.loginSection}>
                            <span className={styles.loginSectionText}>
                                {t("login.login")}
                            </span>
                            <span>
                                <img
                                    src={yellowStarBG}
                                    alt="circle"
                                    className={styles.yellowStarBG}
                                />
                            </span>
                        </p>
                    </div>

                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className={styles.loginFormPhoneNumber}>
                            <PhoneInput
                                name="mobile_number"
                                formik={formik}
                                className={{
                                    inputWrapper: styles.inputWrapper,
                                    input: styles.input,
                                }}
                                onFocus={handleFocus}
                                onChange={handleChange}
                            />
                            {getError("mobile_number")}
                            {!getError("mobile_number") && (
                                <div className={styles.errorMessage}>
                                    {error.message}
                                </div>
                            )}
                        </div>

                        <button type="submit" className={styles.submitBtn}>
                            {t("login.title")}
                        </button>
                    </form>
                    <p className={styles.toSignUp}>
                        <span>{t("login.account")}</span>
                        <span
                            className={styles.link}
                            role="button"
                            onClick={toggleModal}
                        >
                            {t("signup.title")}
                        </span>
                    </p>
                </div>
            </div>
            <OTPmodal formik={formik} forLogin={forLogin} />
            <SignUpModal formik={formik} />
        </div>
    );
};

export default LoginForm;
