import React from "react";
import ProfileLayout from "layouts/Profile";
import { SeasonEventApplyReview } from "components"; 

const SaudiSeasonEventApplyReview = () => {
  return (
    <ProfileLayout >
      <SeasonEventApplyReview />
    </ProfileLayout>
  )
};

export default SaudiSeasonEventApplyReview;
