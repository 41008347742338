import React from "react";
import classnames from "classnames";
import { LanguageButton } from 'components'
import { Link } from "react-router-dom";
import { LOGIN } from "constants/paths";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import NewLogo from "assets/images/LogoWithOrangeStar.svg";

const HeaderMenu = ({ publicTitle }) => {
    // const { changeLang, rtl } = useContext(Context);

    return (
        <div className={classnames(publicTitle && styles.publicHeader)}>
            <div
                className={classnames(
                    styles.headerMenu,
                    styles.publicHeaderMenu
                )}
            >
                <LanguageButton/>
                <Link to={LOGIN} className={styles.publicLogo}>
                    <img src={NewLogo} alt="" />
                </Link>
            </div>
            {publicTitle && (
                <div className={styles.publicTitleContainer}>
                    <h2 className={styles.publicTitle}>{publicTitle}</h2>
                </div>
            )}
        </div>
    );
};

HeaderMenu.propTypes = {
    publicTitle: PropTypes.string,
};

export default HeaderMenu;
