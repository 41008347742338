import { createRequestTypes, createActionTypes } from "utils/redux";

export const GET_USER_REQUESTS = createRequestTypes("GET_USER_REQUESTS");
export const GET_USER_REQUEST = createRequestTypes("GET_USER_REQUEST");
export const GET_USER_REQUESTS_PENDING = createRequestTypes(
  "GET_USER_REQUESTS_PENDING"
);
export const APPLY_USER_REQUEST = createRequestTypes("APPLY_USER_REQUEST");
export const PORTAL_DOWNLOAD_FILE = createRequestTypes("PORTAL_DOWNLOAD_FILE");
export const GET_REQUEST_INTEREST = createRequestTypes("GET_REQUEST_INTEREST");
export const CANCEL_REQUEST = createRequestTypes("CANCEL_REQUEST");
export const IS_APPLIED = "IS_APPLIED";
export const CLEAR_REQ_DATA = "CLEAR_REQ_DATA";

export const getUserRequests = createActionTypes(GET_USER_REQUESTS);
export const getUserRequest = createActionTypes(GET_USER_REQUEST);
export const applyUserRequest = createActionTypes(APPLY_USER_REQUEST);
export const portalDownloadFile = createActionTypes(PORTAL_DOWNLOAD_FILE);
export const getRequestInterest = createActionTypes(GET_REQUEST_INTEREST);
export const getUserRequestsPending = createActionTypes(
  GET_USER_REQUESTS_PENDING
);

export const isApplied = () => ({ type: IS_APPLIED });
export const clearReqData = () => ({ type: CLEAR_REQ_DATA });

export const cancelRequest = createActionTypes(CANCEL_REQUEST);
