import React, { useEffect } from "react";
import { Loader, AlertBox } from "components";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { resetAuthState } from "store/actions/auth.actions";
import { getMatloobUserDetails } from "store/actions/adminElements.actions";
import { COMPANY } from "constants/forms";

const userType = {
  1: "Individual",
  2: "Company",
}

const IdentityType = {
  1: "National ID",
  2: "1qamah"
}

const AdminViewUsersDetails = () => {
  const dispatch = useDispatch();
  // const { error, loaded } = useSelector(state => state.auth);
  const { userDetails, loaded, error } = useSelector(({ adminElements }) => adminElements )
  const isAlertActive =  useSelector(({ alert }) => alert);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getMatloobUserDetails.request(id))
    return () => dispatch(resetAuthState());
  }, [dispatch, id]);

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      <div className="card">
        <div className="card-body">

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="fullName"
                  placeholder="Fullname"
                  disabled={true}
                  value={userDetails?.full_name || userDetails?.company_name || ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="mobileNumber"
                  disabled={true}
                  value={userDetails?.mobile_number || ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="email"
                  disabled={true}
                  value={userDetails?.email || ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="user_type">User Type</label>
                <input
                  type="text"
                  className="form-control"
                  id="user_type"
                  name="user_type"
                  placeholder="User type"
                  disabled={true}
                  value={userType?.[userDetails.user_type] || ""}
                />
              </div>
            </div>
            {
            userDetails?.user_type !== COMPANY &&
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="identity_type">Identity Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="identity_type"
                    name="identity_type"
                    placeholder="Identity type"
                    disabled={true}
                    value={IdentityType?.[userDetails.identity_type] || ""}
                  />
                </div>
              </div>
            }
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="id_number">ID/Commercial Record</label>
                <input
                  type="text"
                  className="form-control"
                  id="id_number"
                  name="id_number"
                  placeholder="Id number"
                  disabled={true}
                  value={userDetails?.id_number || userDetails?.commercial_record || ""}
                />
              </div>
            </div>

        </div>
      </div>
    </Loader>
  );
};

export default React.memo(AdminViewUsersDetails);
