import React from "react";
import Layout from "layouts/Admin";
import { AdminApplicationRequirementsList } from "components";



const AdminApplicationRequirements = () => {

  return(
    <Layout title="Application Requirements">
        <AdminApplicationRequirementsList/>
    </Layout>
  )
};

export default AdminApplicationRequirements;