import * as NotificationsApi from "store/actions/notifications.actions";
import { getErrorsList } from "utils/redux";

const initialState = {
  loaded: true,
  error: "",
  userNotifications: {},
  newNotificationReceived: false,
  openNotificationBox: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NotificationsApi.GET_USER_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case NotificationsApi.GET_USER_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        userNotifications: action.payload,
      };
    case NotificationsApi.GET_USER_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: getErrorsList(action.payload),
      };

    case NotificationsApi.NOTIFICATION_RECEIVED:
      return {
        ...state,
        userNotifications: [
          action.payload, ...state.userNotifications,
        ],
        newNotificationReceived: true,
      };

    case NotificationsApi.OFF_NOTIFICATION:
      return {
        ...state,
        newNotificationReceived: false
      }
    case NotificationsApi.OPEN_NOTIFICATION_BOX:
      return {
        ...state,
        openNotificationBox: action.payload
      }
    default:
      return state;
  }
}
