import React from "react";

import styles from "./styles.module.scss";
import orangeCircle from "../../../assets/icons/orangeSemiCircle.svg";
import pinkCircle from "../../../assets/icons/pinkSemiCircle.svg";
import yellowStarBG from "../../../assets/icons/yellowStarBG.svg";
import yellowCircle from "../../../assets/icons/yellowSemiCircle.svg";
import turquoiseStar from "../../../assets/icons/turquoiseStar.svg";

const PublicBackground = ({ children, turquoiseStarMain, yellowStar }) => {
    return (
        <div className={styles.bgWrapper}>
            <img
                src={orangeCircle}
                alt="circle"
                className={styles.orangeCircle}
            />
            <img
                src={turquoiseStar}
                alt="circle"
                className={styles.turquoiseStarSmall}
            />
            <img src={pinkCircle} alt="circle" className={styles.pinkCircle} />
            <img
                src={yellowCircle}
                alt="circle"
                className={styles.yellowCircleFirst}
            />
            {!turquoiseStarMain && (
                <img
                    src={turquoiseStar}
                    alt="circle"
                    className={styles.turquoiseStarMain}
                />
            )}
            {!yellowStar && (
                <img
                    src={yellowStarBG}
                    alt="circle"
                    className={styles.yellowStarBG}
                />
            )}
            <img
                src={yellowCircle}
                alt="circle"
                className={styles.yellowCircleSecond}
            />
            {children}
        </div>
    );
};
export default PublicBackground;
