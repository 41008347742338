import React, { useState } from "react";
import AnimatedCard from "../AnimatedCard/AnimatedCard";
import styles from "./styles.module.scss";
import { CardBackgroundColor } from "../../constants/animatedCardBackground";
import Map from "../../assets/images/newCards/Map.png";
import Man from "../../assets/images/newCards/Man.png";
import Food from "../../assets/images/newCards/Food.png";
import Bus from "../../assets/images/newCards/bus.png";
import Packages from "../../assets/images/newCards/Packages.png";
import Clown from "../../assets/images/newCards/clown.png";
import Musician from "../../assets/images/newCards/musician.png";
import NewLogo from "assets/images/LogoWithOrangeStar.svg";
import { useTranslation } from "react-i18next";
import { InterestModal } from "components";

import Img1 from "assets/images/newCardsOpen/Logistics.png";
import Img2 from "assets/images/newCardsOpen/Temporary_Works1.png";
import Img3 from "assets/images/cards/cardImg3.png";
import Img4 from "assets/images/newCardsOpen/Talents_1.png";
import Img5 from "assets/images/cards/cardImg5.png";
import Img6 from "assets/images/newCardsOpen/Events.png";
import Img7 from "assets/images/newCardsOpen/Venues.png";

import Img15 from "assets/images/newCardsOpen/Food_Beverage.png";
import Img16 from "assets/images/newCardsOpen/Foodtruck.png";
import Img17 from "assets/images/newCardsOpen/Food_Provider.png";
import Img18 from "assets/images/newCardsOpen/Personal_Stores.png";
import Img19 from "assets/images/newCardsOpen/Stores.png";
import Img20 from "assets/images/newCardsOpen/Pop-up.png";

const restaurants = [Img15, Img16, Img17];
const stores = [Img18, Img19, Img20];
const images = [
    Img2,
    {
        main: Img3,
        subImages: restaurants,
    },
    Img1,
    Img4,
    {
        main: Img5,
        subImages: stores,
    },
    Img6,
    Img7,
];
const AnimatedCardSection = () => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [active, setActive] = useState("");

    const toggleModal = () => setOpened(!opened);

    const handleClick = (index) => {
        toggleModal();
        setActive(index);
    };

    const data = t("cardsSection.items", { returnObjects: true });

    const animatedCard = [
        {
            backgroundColor: CardBackgroundColor.pink,
            type: "workAnimation",
            image: Man,
            styles: styles.manImage,
        },
        {
            backgroundColor: CardBackgroundColor.purple,
            type: "foodAnimation",
            image: Food,
            styles: styles.foodImage,
        },
        {
            backgroundColor: CardBackgroundColor.pink,
            type: "logisticAnimation",
            image: Bus,
            styles: styles.busImage,
        },
        {
            backgroundColor: CardBackgroundColor.orange,
            type: "talentsAnimation",
            image: Musician,
            styles: styles.musicianImage,
        },
        {
            backgroundColor: CardBackgroundColor.pink,
            type: "productsAnimation",
            image: Packages,
            styles: styles.packagesImage,
        },
        {
            backgroundColor: CardBackgroundColor.purple,
            type: "eventAnimation",
            image: Clown,
            styles: styles.clownImage,
        },
        {
            backgroundColor: CardBackgroundColor.orange,
            type: "venuesAnimation",
            image: Map,
            styles: styles.mapImage,
        },
    ];

    return (
        <div className={styles.cardsSection}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <img src={NewLogo} className={styles.logo} alt="logo" />
                    <div className={styles.title}>
                        {t("homepage.Enjoyable_moments_title")}
                    </div>
                </header>
                <div className={styles.introductionContent}>
                    {t("homepage.Enjoyable_moments_subtitle", {
                        returnObjects: true,
                    }).join(" ")}
                </div>
                <div className={styles.animatedCardSection}>
                    {animatedCard.map((item, index) => (
                        <AnimatedCard
                            key={index}
                            backgroundColor={item.backgroundColor}
                            type={item.type}
                            onClick={() => handleClick(index)}
                            index={index}
                        >
                            <img
                                src={item.image}
                                alt=""
                                className={item.styles}
                            />
                        </AnimatedCard>
                    ))}
                </div>
            </div>
            <InterestModal
                isOpened={opened}
                toggle={toggleModal}
                data={data[active]}
                img={images[active]}
            />
        </div>
    );
};

export default AnimatedCardSection;
