import React from 'react';
import { Pagination } from 'react-bootstrap';
import styles from './styles.module.scss';

const ProfilePagination = ({currentPage, total, handleClick}) => {
  const paginationField = [
    {
      component: <Pagination.Prev disabled={currentPage === 1} onClick={() => {handleClick(currentPage-1)}}/>,
      condition: true
    },
    {
      component: <Pagination.Item onClick={() => {handleClick(1)}}>1</Pagination.Item>,
      condition: currentPage !== 1
    },
    {
      component: <Pagination.Ellipsis />,
      condition: currentPage - 2 > 1
    },
    {
      component: <Pagination.Item onClick={() => {handleClick(currentPage - 1)}} >{currentPage - 1}</Pagination.Item>,
      condition: currentPage - 1 > 1
    },
    {
      component: <Pagination.Item active activeLabel={currentPage}>{currentPage}</Pagination.Item>,
      condition: true
    },
    {
      component: <Pagination.Item onClick={() => {handleClick(currentPage + 1)}}>{currentPage + 1}</Pagination.Item>,
      condition:  currentPage + 1 < total
    },
    {
      component: <Pagination.Ellipsis />,
      condition: currentPage + 2 < total
    },
    {
      component: <Pagination.Item onClick={() => {handleClick(total)}}>{total}</Pagination.Item>,
      condition: currentPage !== total
    },
    {
      component: <Pagination.Next disabled={currentPage === total} onClick={() => {handleClick(currentPage + 1)}}/>,
      condition: true
    },
  ]
  return <div>
    <Pagination className={styles.paginationWrapper}>
      {
        paginationField.map((item, index) =>
            <React.Fragment key={index}>{item.condition ? item.component : null}</React.Fragment>
        )
      }
    </Pagination>
  </div>
};
export default ProfilePagination;