import React from "react";
import moment from "moment";
import classnames from "classnames";
import PropTypes from "prop-types";
import Picker from "react-datepicker";
import { DATE_FORMAT } from "constants/forms";

const DatePicker = ({
  formik,
  styles,
  name,
  onChange,
  selected,
  className
}) => {
  const handleChange = e =>
    onChange
      ? onChange(e)
      : formik.setFieldValue([name], moment.utc(e).local().format(DATE_FORMAT));

  const handleFocus = () => formik.setFieldTouched(name, true);

  const handleChangeRaw = e => e.preventDefault();

  return (
    <Picker
      onChangeRaw={handleChangeRaw}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      selected={
        selected ||
        (formik.values[name] &&
          moment(formik.values[name], DATE_FORMAT).toDate())
      }
      onFocus={handleFocus}
      onChange={handleChange}
      className={classnames("datepicker", className, styles.optionInpt)}
      maxDate={new Date()-(24 * 60 * 60 * 1000)}
    />
  );
};

DatePicker.propTypes = {
  formik: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.instanceOf(Date),
  className: PropTypes.string
};

export default React.memo(DatePicker);
