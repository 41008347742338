import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { ReactComponent as Edit } from "assets/icons/editNew.svg";
import { ReactComponent as Delete } from "assets/icons/trashNew.svg";
import { ReactComponent as FileText } from "assets/icons/file-text.svg";
import Check from "assets/icons/checkNew.svg";
import Minus from "assets/icons/minusNew.svg";
import { Link } from "react-router-dom";
import { EDIT_INTEREST } from "constants/paths";
import { compile } from "path-to-regexp";
import { BASE_URL } from "constants/api";
import { useSelector } from "react-redux";
import { TYPES } from "components/Accordion/contants";
import { Context } from "context";
import { vsPath, dateFormatter } from "utils/helpers";
import { useHistory } from "react-router-dom";
import {
    TALENTS,
    WORK,
    EVENTS,
    SERVICES,
    STORES,
    RESTAURANTS,
    SITES,
    VENUES,
} from "./constants";

const InterestItem = ({
    element_type,
    created_at,
    updated_at,
    status,
    id,
    image_ar,
    image_en,
    image,
    has_request,
    onDelete,
    ...props
}) => {
    const { t } = useTranslation();

    const [name, setName] = useState("");

    const history = useHistory();

    const { lang, rtl } = useContext(Context);

    const { venueSiteCategories, serviceCategories } = useSelector(
        (state) => state.elements
    );

    const handleDelete = () => onDelete({ id, element_type });
    useEffect(() => {
        switch (element_type?.slug) {
            case TALENTS:
                return setName(props.contact_social_media?.name);
            case WORK:
                return setName(props.personal_information?.[`name_${lang}`]);
            case EVENTS:
                return setName(props.event_detail?.name);
            case SERVICES:
                const data = serviceCategories?.find(
                    ({ id }) => id === props.service_type?.service_category_id
                );
                return setName(data?.name);
            case STORES:
                return setName(
                    props.project_details?.[`name_${lang}`] ||
                        props.store_detail?.[`name_${lang}`]
                );
            case RESTAURANTS:
                return setName(
                    props.booth_detail?.[`name_${lang}`] ||
                        props.restaurant_detail?.[`name_${lang}`]
                );
            case SITES:
            case VENUES:
                const site = venueSiteCategories?.find(
                    ({ id }) =>
                        id ===
                        props[`${vsPath}_address`]?.[`${vsPath}_category_id`]
                );
                return setName(site?.name);

            default:
                return setName("");
        }
    }, [element_type, props, venueSiteCategories, serviceCategories, lang]);
    return (
        <div className={styles.interestItem}>
            {has_request ? (
                <div
                    className={styles.hasRequest}
                    onClick={() =>
                        history.push(
                            compile(EDIT_INTEREST)({
                                id,
                                type: TYPES[element_type.slug],
                            })
                        )
                    }
                >
                    <FileText />
                </div>
            ) : null}
            <div className={styles.interestItemImage}>
                <img src={`${BASE_URL}${image}`} alt={name} />
            </div>
            <div className={styles.interestItemInfo}>
                <p className={styles.interestItemType}>
                    {element_type?.name} {name && ` - ${name}`}
                </p>

                <p className={styles.interestItemUpdated}>
                    {t("interests.created")}
                    {": "}
                    {dateFormatter(rtl, created_at)}
                </p>
                <p className={styles.interestItemUpdated}>
                    {t("interests.updated")}
                    {": "}
                    {dateFormatter(rtl, updated_at)}
                </p>
            </div>
            <div className={styles.interestItemFooter}>
                <p
                    className={
                        status
                            ? styles.interestItemCompleted
                            : styles.interestItemIncompleted
                    }
                >
                    <img src={status ? Check : Minus} alt="" />
                    {t(`interests.${status ? "completed" : "incompleted"}`)}
                </p>
                <div className={styles.imageSection}>
                    <Link
                        to={compile(EDIT_INTEREST)({
                            id,
                            type: TYPES[element_type.slug],
                        })}
                        className={styles.editTool}
                    >
                        <Edit />
                    </Link>
                    <div onClick={handleDelete} className={styles.deleteTool}>
                        <Delete />
                    </div>
                </div>
            </div>
        </div>
    );
};

InterestItem.propTypes = {
    element_type: PropTypes.object,
    status: PropTypes.number,
    updated_at: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
};

export default React.memo(InterestItem);
