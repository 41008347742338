import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminSubmittedFormStatisticsElements } from "pages/AdminStatistics/constants";
import { AdminStatisticsItem } from "components";
import { getSubmittedFormsStatistics } from "store/actions/adminElements.actions";
import _ from "lodash";

const AdminSubmittedFormStatistics = () => {
  const { submittedFormsStatistics } = useSelector(
    ({ adminElements }) => adminElements
  );
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    dispatch(getSubmittedFormsStatistics.request());
  }, [dispatch]);

  const handleGetData = () => {
    dispatch(getSubmittedFormsStatistics.request({ from, to }));
  };

  const resetDate = () => {
    setFrom(null);
    setTo(null);
    dispatch(getSubmittedFormsStatistics.request());
  };
  return (
    <>
      <section className="mt-5">
        <div className="card mt-3">
          <div className="card-body d-flex align-items-end">
            <div className="flex-grow-0 mr-4">
              <label>From:</label>
              <input
                className="form-control"
                placeholder="Date From"
                type="date"
                value={from || ""}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
            <div className="flex-grow-0">
              <label>To:</label>
              <input
                className="form-control"
                placeholder="Date To"
                type="date"
                value={to || ""}
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
            <button className="btn-paua btn ml-4" onClick={handleGetData}>
              Get Data
            </button>
            <button className="btn-paua btn ml-4" onClick={resetDate}>
              Clear
            </button>
          </div>
        </div>
      </section>
      <section>
        {!_.isEmpty(submittedFormsStatistics) &&
          adminSubmittedFormStatisticsElements.map(
            ({ title, children, link }, index) => (
              <AdminStatisticsItem
                data={submittedFormsStatistics}
                title={title}
                children={children}
                key={index}
                withChart
                link={link}
              />
            )
          )}
      </section>
    </>
  );
};

export default AdminSubmittedFormStatistics;
