import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compile } from "path-to-regexp";
import { confirmDelete } from "constants/messages";
import {
  Loader,
  TableComponent,
  AdminTopPanel,
  Pagination,
  AlertBox,
  DeleteModal
} from "components";
import { ADMIN_USERS_DETAILS, ADMIN_MATLOOB_USER_ELEMENTS, ADMIN_MATLOOB_USER_REQUESTS } from "constants/paths";
import { getMatloobUsers, changeBlockedStatus, deleteMatloobUser } from "store/actions/adminElements.actions";
import { INDIVIDUAL_USER, COMPANY_USER } from "constants/forms";
import { useState } from "react";
import _ from 'lodash';

const userTypes = [
  { name: COMPANY_USER, type: 2 },
  { name: INDIVIDUAL_USER, type: 1 }
];

const objWithoutFalsy = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value));
}

const AdminMatloobUsersList = () => {
  const dispatch = useDispatch();
  const { matloobUsers, error, loaded } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const [ typeSelected, setTypeSelected ] = useState({});
  const [ isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [ userToDelete, setUserToDelete ] = useState(null);

  const [ data, setData ] = useState({
    id_number: "",
    commercial_record: "",
    name: "",
    mobile: "",
  })

  const handleSearchOnChange = useCallback(
    _.debounce((parameters) => {
      dispatch(getMatloobUsers.request({parameters}))
    }, 1000),[]
  )

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const truthyOnly = objWithoutFalsy(data);
  //   dispatch(getMatloobUsers.request({parameters: truthyOnly}))
  // }

  useEffect(()=>{
    const type = typeSelected?.type || null
    dispatch(getMatloobUsers.request({ type }))
  },[ dispatch, typeSelected ])

  const handleChangeSearchInput = (e) => {
    const {target: {name, value}} = e;
    setData({...data, [name]: value})
    handleSearchOnChange(objWithoutFalsy({...data, [name]: value}))
  }

  const toggleModal = () => {
    if(isOpenDeleteModal) {
      setUserToDelete(null);
    }
    setIsOpenDeleteModal(!isOpenDeleteModal);
  }

  const onDeleteHandler = (id) => {
    toggleModal();
    setUserToDelete(id);
  }

  const confirmDeleteUser = () => {
    toggleModal();
    dispatch(deleteMatloobUser.request(userToDelete));
    setUserToDelete(null);
  }


  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Name",
      key: "name"
    },
    {
      name: "Email",
      key: "email"
    },
    {
      name: "Mobile Number",
      key: "mobile"
    },
    {
      name: "User Type",
      key: "user_type"
    },
    {
      name: "ID/Commercial Record",
      key: "id_number",
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ id }) => {
        window.open(compile(ADMIN_USERS_DETAILS)({ id }), '_blank')
      }
    },
    {
      name: "User Elements",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ id }) => {
        window.open(compile(ADMIN_MATLOOB_USER_ELEMENTS)({id}), '_blank')
      }
    },
    {
      name: "User Requests",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ id }) => {
        window.open(compile(ADMIN_MATLOOB_USER_REQUESTS)({id}), '_blank')
      }
    },
    {
      name: "Block",
      key: "switch",
      onChange: (index, id) => {
        dispatch(changeBlockedStatus.request(
          {
            id,
            index,
          },
          )
        )
      }
    },
    {
      name: "Delete",
      key: "action-btn",
      class: "mdi mdi-trash-can-outline",
      onClick: ({id}) => {
        onDeleteHandler(id)
      }
    }
  ];
  // const handleNCPRoute = () => {
  //   history.push("new-control-panel-user");
  // };


  const searchTools = {
    placeholder: "Search user",
    // onSubmit: handleSearch,
    order: 1,
    items: [
      {
        placeholder: 'User id',
        key: "id_number",
        value: data.id_number,
        setValue: handleChangeSearchInput
      },
      {
        placeholder: 'Commercial record',
        key: "commercial_record",
        value: data.commercial_record,
        setValue: handleChangeSearchInput
      },
      {
        placeholder: 'User name',
        key: "name",
        value: data.name,
        setValue: handleChangeSearchInput
      },
      {
        placeholder: 'Mobile number',
        key: "mobile",
        value: data.mobile,
        setValue: handleChangeSearchInput
      }
    ]
  }

  // const buttonTools = {
  //   label: 'New CP User',
  //   onClick: handleNCPRoute
  // }

  const tableBody = matloobUsers?.data && matloobUsers.data.map(el => ({
    id: el?.id,
    id_number: el?.id_number || el?.commercial_record || "",
    name: el?.name || el?.company_name || "",
    mobile: el?.mobile || "",
    email: el?.email || "",
    blocked: el?.blocked,
    // user_type: userType.get(el?.user_type) || ""
    user_type: userTypes.find(uType => uType.type === el?.user_type)?.name || ""
  }));

  const dropdownTools = {
    label: "Type",
    dropdownElements: userTypes,
    optionSelected: typeSelected,
    setOptionSelected: setTypeSelected,
    order: 0
  };

  return (
    <>
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      <AdminTopPanel
        withDropdown
        dropdownTools={dropdownTools}
        withSearch
        searchTools={searchTools}
      />

      <div className="card">
        <div className="card-body">
          <TableComponent
            tableHead={tableHead}
            tableBody={tableBody}
          />
          <Pagination
            action = {getMatloobUsers}
            currentPage = {matloobUsers?.current_page}
            lastPage = {matloobUsers?.last_page}
            type={typeSelected?.type || null}
            goToPage
          />
        </div>
      </div>
    </Loader>
    <DeleteModal
        isOpened={isOpenDeleteModal}
        toggle={toggleModal}
        data={confirmDelete}
        confirm={confirmDeleteUser}
        fromAdmin={true}
      />
    </>
  );
};

export default React.memo(AdminMatloobUsersList);
