import React from "react";
import Layout from "layouts/Admin";
import { EditAdminForm } from "components";
import { useParams } from "react-router-dom";  

const EditAdmin = () => {
  const params = useParams();
  return (
    <Layout title="Control Panel User Details">
      <EditAdminForm id={params.id}/>
    </Layout>
  )
};

export default EditAdmin;
