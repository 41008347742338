import React from "react"
import Layout from "layouts/Admin";
import { MediaCenterNewsHistory } from "components"

const AdminMediaCenterNews = () => {
  return (
    <Layout title="News">
      <MediaCenterNewsHistory/>
    </Layout>
  )
}

export default AdminMediaCenterNews