import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import { InterestModal } from "components";
// import { Context } from "context";
import styles from "./styles.module.scss";
import Img1 from "assets/images/cards/cardImg1.png";
import Img2 from "assets/images/cards/cardImg2.png";
import Img3 from "assets/images/cards/cardImg3.png";
import Img4 from "assets/images/cards/cardImg4.png";
import Img5 from "assets/images/cards/cardImg5.png";
import Img6 from "assets/images/cards/cardImg6.png";
import Img7 from "assets/images/cards/cardImg7.png";
import Img15 from "assets/images/cards/RestaurantOrCafe.png";
import Img16 from "assets/images/cards/Foodtruck.png";
import Img17 from "assets/images/cards/FoodProviders.png";
import Img18 from "assets/images/cards/PersonalStore.png";
import Img19 from "assets/images/cards/Stores.png";
import Img20 from "assets/images/cards/PopUpStore.png";
import NewLogo from "assets/images/LogoWithOrangeStar.svg";

const restaurants = [Img15, Img16, Img17];
const stores = [Img18, Img19, Img20];
const images = [
    Img2,
    {
        main: Img3,
        subImages: restaurants,
    },
    Img1,
    Img4,
    {
        main: Img5,
        subImages: stores,
    },
    Img6,
    Img7,
];

const CardsSection = () => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [active, setActive] = useState("");

    const toggleModal = () => setOpened(!opened);

    const handleClick = (index) => {
        toggleModal();
        setActive(index);
    };

    const data = t("cardsSection.items", { returnObjects: true });
    return (
        <div className={styles.cardsSection}>
            <div className="container">
                <header className={styles.header}>
                    <img src={NewLogo} className={styles.logo} alt="logo" />
                    <div className={styles.title}>
                        {t("homepage.Enjoyable_moments_title")}
                    </div>
                </header>
                <div className={styles.introductionContent}>
                    {t("homepage.Enjoyable_moments_subtitle", {
                        returnObjects: true,
                    }).join(" ")}
                </div>
                <div className={styles.cardsSectionItems}>
                    {images.map((item, index) => (
                        <Card
                            img={item.main || item}
                            index={index}
                            key={index}
                            onClick={handleClick}
                        />
                    ))}
                </div>
            </div>
            <InterestModal
                isOpened={opened}
                toggle={toggleModal}
                data={data[active]}
                img={images[active]}
            />
        </div>
    );
};

export default CardsSection;
