import { all } from "redux-saga/effects";
import authSaga from "./auth.saga";
import elements from "./elements.saga";
import ideas from "./ideas.saga";
import contact from "./contact.saga";
import adminElements from "./adminElements.saga"
import saudiSeasonEvents from "./saudiSeasonsEvents.saga";
import requests from "./requests.saga";
import notificationsSaga from "./notifications.saga";
import mediaCenterSaga from "./mediaCenter.saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    elements(),
    ideas(),
    contact(),
    adminElements(),
    saudiSeasonEvents(),
    requests(),
    notificationsSaga(),
    mediaCenterSaga()
  ]);
}
