import React, { useContext, useEffect } from "react";
import { Loader, PhoneInput, TableComponent, AlertBox } from "components";
import { useSelector, useDispatch } from "react-redux";
import { Context } from "context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, ModalFooter } from "reactstrap";
import { useError, useFieldTouched } from "utils/forms";
import { editAdmin, resetAuthState } from "store/actions/auth.actions";
import { getUserActions } from "store/actions/adminElements.actions";
import { phoneNumberWithoutCountryCode } from "utils/validation";
import { invalidPhone, incorrectEmail, updated } from "constants/messages";
import moment from "moment";
import "../../App.scss";

const user_types = {
  1: "Super Admin",
  2: "Admin",
  3: "Director",
  4: "Customer Services",
  5: "Logistics Manager",
};

const tableHead = [
  {
    name: "#",
    key: "#",
  },
  {
    name: "Action",
    key: "action",
  },
  {
    name: "Model",
    key: "model",
  },
  {
    name: "Date",
    key: "date",
  },
  {
    name: "Time",
    key: "time",
  },
];

const DATE_FORMAT = "l";
const HOUR_FORMAT = "LT";

const AdminInfo = () => {
  const dispatch = useDispatch();
  const { error, loaded, success } = useSelector((state) => state.auth);
  const { userActions, loaded: userActionsLoaded } = useSelector(
    ({ adminElements }) => adminElements
  );
  const isAlertActive = useSelector(({ alert }) => alert);

  const { user } = useContext(Context);

  const formik = useFormik({
    initialValues: {
      email: user.email,
      name: user.name,
      mobile_number: user.mobile_number,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email(incorrectEmail).required("Required"),
      name: Yup.string().required("Required"),
      mobile_number: Yup.string()
        .matches(phoneNumberWithoutCountryCode, invalidPhone)
        .required(),
    }),
    onSubmit: (values) => dispatch(editAdmin.request(values)),
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  useEffect(() => {
    dispatch(getUserActions.request());
    return () => dispatch(resetAuthState());
  }, [dispatch]);

  const tableBody = userActions.map(({ action, created_at, model }) => ({
    action,
    date: moment(created_at).format(DATE_FORMAT),
    time: moment(created_at).format(HOUR_FORMAT),
    model,
  }));

  return (
    <Loader loaded={loaded && userActionsLoaded}>
      {isAlertActive && (
        <AlertBox error={error.message} additionalStyles="alert-danger" />
      )}
      {success && (
        <AlertBox message={updated} additionalStyles="alert-success mt-2" />
      )}
      <section>
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Profile</h4>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email" className="control-label">
                      User Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_type"
                      name="user_type"
                      disabled={true}
                      placeholder="user_type"
                      value={user_types[user.user_type]}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formik.values.name}
                      onFocus={setFieldTouched}
                      onChange={formik.handleChange}
                    />
                    {getError("name")}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email" className="control-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onFocus={setFieldTouched}
                      onChange={formik.handleChange}
                    />
                    {getError("email")}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="mobile_number" className="control-label">
                      Mobile Number
                    </label>
                    <div>
                      <PhoneInput
                        boxClassName="w-100"
                        className="form-control w-100"
                        formik={formik}
                        name="mobile_number"
                        onFocus={setFieldTouched}
                      />
                    </div>
                    {getError("mobile_number")}
                  </div>
                </div>
              </div>

              <ModalFooter>
                <Button type="submit" className="admin-submit-btn">
                  Edit
                </Button>
              </ModalFooter>
            </form>
          </div>
        </div>
      </section>

      <section>
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Action History</h4>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <TableComponent tableHead={tableHead} tableBody={tableBody} />
          </div>
        </div>
      </section>
    </Loader>
  );
};

export default React.memo(AdminInfo);
