import {
  UNDER_PROGRESS, 
  ACCEPTED, REQUIRES_UPDATES, 
  REJECTED, 
  OTHER,  
  RESTAURANT_OF_CAFE, 
  FOOD_TRUCK, 
  FOOD_AND_DRINKS_PROVIDER, 
  STORE,
  POP_UP_STORES, 
  PERSONAL_STORES, 
  CANCELLED,
  TALENTS, 
  TEMPORARY_WORK, 
} from "constants/forms"

const RESTAURANT_FORMAT_ID = "fe5069f2-0fd6-41ab-8c50-599671f98df9";
const FOOD_TRUCK_FORMAT_ID = "7aaf1529-b122-4c1c-9e94-633ae66d79ff";
const FOOD_BEVERAGES_FORMAT_ID = "8262d47a-d01c-4e6a-9475-ef6e2fb5b176"
const STORE_FORMAT_ID = "f2300501-dafc-4aa5-8925-1496f0a2fecd";
const POP_UP_STORE_FORMAT_ID = "27612fae-aded-4b83-ae1e-249c681351cb";
const PERSONAL_STORE_FORMAT_ID = "b41ad0aa-9332-439f-8eb2-a29b012d7b71";
const TALENTS_FORMAT_ID = "0472cebe-9923-497b-9a64-09d3c2bbb943";
const TEMPORARY_WORK_FORMAT_ID = "66b1946e-e4c4-4254-8ce3-fdcb0b0726f3";
const STORE_FORMAT = "store";
const RESTAURANTS_FORMAT = "restaurant";

const elements_type_id = {
  restaurant: '42fb5884-5a36-48e0-b7b8-5a96acc4f15a',
  store: 'a0558093-1d16-4bc6-a4ad-ad7aa9994198',
}

const REQUEST_NUMBER_DATA = {
  value: "request_number",
  name: "Request Number",
  field: "number"
}

const USER_TYPE_DATA = {
  value: "user_type",
  name: "User Type", 
  field: "dropdown",
  data: [
    {name: "Individual", value: "1"},
    {name: "Company", value: "2"},
  ]
}

const REQUEST_STATUS_DATA = {
  value: "request_status",
  name: "Request Status", 
  field: "dropdown",
  data: [
    {value: "0", name: UNDER_PROGRESS},
    {value: "1", name: ACCEPTED},
    {value: "2", name: REQUIRES_UPDATES},
    {value: "3", name: REJECTED},
    {value: "4", name: OTHER},
  ]
}

const FROM_DATE_DATA = {
  value: "start_date",
  name: "From - Date",
  field: "date"
}

const TO_DATE_DATA = {
  value: "end_date",
  name: "To - Date",
  field: "date"
}

export const otherFilteredCategoriesTypes = [
  REQUEST_NUMBER_DATA,
  USER_TYPE_DATA,
  REQUEST_STATUS_DATA,
  FROM_DATE_DATA,
  TO_DATE_DATA
]

const SAUDI_REGION_DATA = {
  value: "saudi_region_id", 
  name: "Region", 
  field: "dropdown", 
  key: "regions"
}
const REGION_DATA = {
  value: "region_id", 
  name: "Region", 
  field: "dropdown", 
  key: "regions"
}

const HAVE_OTHER_BRANCHES_DATA = {
  value: "is_other_branches",
  name: "Do you have other branches", 
  field: "dropdown",
  data: [
    {name: "Yes", value: "1"},
    {name: "No", value: "0"},
  ]
}

const HOW_MANY_BRANCHES_DATA = {
  value: "num_branches_in_saudi",
  name: "How many branches in Saudi Arabia", 
  field: "number"
}

const BRANCH_REGION_DATA = {
  value: "branch_region_id", 
  name: "What are the branch Region", 
  field: "dropdown", 
  key: "regions"
}

const AVERAGE_NUMBER_WORKERS_DATA = {
  value: "average_workers_per_branch",
  name: "Average number of workers in one branch", 
  field: "number"
}

const PERSON_BILL_DATA = {
  value: "average_bill_per_person",
  name: "Average per person bill", 
  field: "number"
}

const ESTABLISHMENT_DATE = {
  value: "establishment_date",
  name: "Date of Establishment", 
  field: "date"
}

const USER_NAME = {
  value: "name",
  field: "text",
  name: 'User Name'
}

const MOBILE_NUMBER = {
  value: "mobile_number",
  field: "text",
  name: 'Mobile Number'
}

const PARTICIPATE_IN_PREVIOUS_EVENTS_DATA = {
  value: "is_participated_in_prev_events",
  name: "Did you participate in previous activities?",
  field: "dropdown",
  data: [
    {name: "Yes", value: "1"},
    {name: "No", value: "0"},
  ]
}

const NUMBER_OF_WORKERS_DATA = {
  value: "num_of_worker_per_branch",
  name: "Number of workers in one branch", 
  field: "number"
}

const PRODUCT_CATEGORY_DATA = {
  value: "product_category_id",
  name: "Product Category", 
  field: "dropdown",
  key: "productCategories"
}

const MINIMUM_PRODUCT_PRICES_DATA = {
  value: "min_price",
  name: "Minimum prices of the products", 
  field: "number"
}

const MAXIMUM_PRODUCT_PRICES_DATA = {
  value: "max_price",
  name: "Maximum prices of the products", 
  field: "number"
}

const TALENT_CATEGORY_DATA = {
  value: "category_id",
  name: "Talents Category", 
  field: "dropdown",
  key: "talentCategories"
}

const SUBMISION_TYPE_DATA = {
  value: "submission_type",
  name: "Submission type", 
  field: "dropdown",
  data: [
    {name: "Individual", value: "1"},
    {name: "Group", value: "2"},
  ]
}

const BIRTH_DATE_DATA = {
  value: "birthdate",
  name: "Birth Date", 
  field: "date"
}

const GENDER_DATA = {
  value: "gender",
  name: "Gender", 
  field: "dropdown",
  data: [
    {name: "Male", value: "1"},
    {name: "Female", value: "2"},
  ]
}
const TEMPORARY_WORKS_CATEGORIES = {
  value: "category_id",
  name: "Categories", 
  field: "dropdown",
  key: 'temporaryWorksCategories'
}

const NATIONALITY_DATA = {
  value: "nationality_id",
  name: "Nationality", 
  field: "dropdown",
  key: "countries"
}

const SOCIAL_STATUS_DATA = {
  value: "social_status",
  name: "Social Status", 
  field: "dropdown",
  data: [
    {name: "Single", value: "1"},
    {name: "Married", value: "2"},
    {name: "Other", value: "3"},
  ]
}

const SPECIAL_NEEDS = {
  value: "special_needs",
  name: "Special Needs", 
  field: "dropdown",
  data: [
    {name: "yes", value: "1"},
    {name: "no", value: "0"},
  ]
}

const COUNTRY_OF_RESIDENCE_DATA = {
  value: "country_id",
  name: "Country of residence", 
  field: "dropdown",
  key: "countries"
}

const IDENTITY_TYPE_DATA = {
  value: "identity_type",
  name: "Identity Type", 
  field: "dropdown",
  data: [
    {name: "National Id", value: "1"},
    {name: "Iqamah", value: "2"},
  ]
}

const WORKED_BEFORE_DATA = {
  value: "worked_before",
  name: "Have you Worked Before?", 
  field: "dropdown",
  data: [
    {name: "Yes", value: "1"},
    {name: "No", value: "0"},
  ]
}

const LANGUAGES_DATA = {
  value: "language",
  name: "Languages ", 
  field: "dropdown",
  key: "languages"
}

const FOOD_CATEGORY_DATA = {
  value: "food_category_id" ,
  name: "Food Category", 
  field: "dropdown", 
  key: "foodCategories" 
}

const LANGUAGE_LEVEL_DATA = {
  value: "level",
  name: "Language Level", 
  field: "dropdown",
  data: [
    {name: "Good", value: "good"},
    {name: "Very Good", value: "very_good"},
    {name: "Excellent", value: "excellent"},

  ]
}

export const filteredCategoriesType = {
  "restaurants/restaurant-or-cafe": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    AVERAGE_NUMBER_WORKERS_DATA,
    PERSON_BILL_DATA,
    ESTABLISHMENT_DATE,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,    
  ],
  "restaurants/food-truck": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    NUMBER_OF_WORKERS_DATA,
    PERSON_BILL_DATA,
    ESTABLISHMENT_DATE,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,
  ],
  "restaurants/food-and-drinks-provider": [
    FOOD_CATEGORY_DATA,
    REGION_DATA,
    PERSON_BILL_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,
  ],
  "stores/store": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    ESTABLISHMENT_DATE,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,
  ],
  "stores/pop-up-stores": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    HAVE_OTHER_BRANCHES_DATA,
    HOW_MANY_BRANCHES_DATA,
    BRANCH_REGION_DATA,
    ESTABLISHMENT_DATE,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,
  ],
  "stores/personal-stores": [
    PRODUCT_CATEGORY_DATA,
    SAUDI_REGION_DATA,
    MINIMUM_PRODUCT_PRICES_DATA,
    MAXIMUM_PRODUCT_PRICES_DATA,
    PARTICIPATE_IN_PREVIOUS_EVENTS_DATA,
    USER_NAME,
    MOBILE_NUMBER,
  ],
  "talents": [
    TALENT_CATEGORY_DATA,
    SUBMISION_TYPE_DATA,
    BIRTH_DATE_DATA,
    GENDER_DATA,
    NATIONALITY_DATA,
    SOCIAL_STATUS_DATA,
    COUNTRY_OF_RESIDENCE_DATA,
    USER_NAME,
    MOBILE_NUMBER,
    SPECIAL_NEEDS
  ],
  "temporary-work": [
    TEMPORARY_WORKS_CATEGORIES,
    BIRTH_DATE_DATA,
    GENDER_DATA,
    NATIONALITY_DATA,
    IDENTITY_TYPE_DATA,
    SOCIAL_STATUS_DATA,
    SAUDI_REGION_DATA,
    WORKED_BEFORE_DATA,
    LANGUAGES_DATA,
    LANGUAGE_LEVEL_DATA,
    USER_NAME,
    MOBILE_NUMBER,
    SPECIAL_NEEDS
  ],
}

export const initialValues = {
  "name": "",
  "request_status": "",
  "start_date": "",
  "request_number": "",
  "end_date": "",
  "food_category_id" : "",
  "region_id": "",
  "is_other_branches": "",
  "num_branches_in_saudi": "",
  "branch_region_id" : "",
  "average_workers_per_branch" : "",
  "average_bill_per_person" : "",
  "establishment_date" : "",
  "is_participated_in_prev_events" : "",
  "num_of_worker_per_branch" : "",
  "product_category_id" : "",
  "min_price" : "",
  "max_price" : "",
  "category_id" : "",
  "submission_type" : "",
  "birthdate" : "",
  "gender" : "",
  "nationality_id" : "",
  "social_status" : "",
  "special_needs":"",
  "country_id" : "",
  "identity_type" : "",
  "worked_before" : "",
  "language" : "",
  "level" : "",
}

export const statuses = {
  0: UNDER_PROGRESS,
  1: ACCEPTED,
  2: REQUIRES_UPDATES,
  3: REJECTED,
  4: OTHER,
  5: CANCELLED,
}

export const REQUEST_DETAILS = "request-details"

export const elementsType = [
  {
    name: "All", 
    type: '', 
    type_id: ''
  },
  {
    name: "Restaurant or Cafe", 
    type: RESTAURANT_OF_CAFE, 
    format_id: RESTAURANT_FORMAT_ID,
    format: RESTAURANTS_FORMAT,
    type_id: elements_type_id.restaurant
  },
  {
    name: "Food Truck", 
    type: FOOD_TRUCK, 
    format_id: FOOD_TRUCK_FORMAT_ID,
    format: RESTAURANTS_FORMAT,
    type_id: elements_type_id.restaurant
  },
  {
    name: "Food & Beverages Provider", 
    type: FOOD_AND_DRINKS_PROVIDER, 
    format_id: FOOD_BEVERAGES_FORMAT_ID,
    format: RESTAURANTS_FORMAT,
    type_id: elements_type_id.restaurant
  },
  {
    name: "Store", 
    type: STORE, 
    format_id: STORE_FORMAT_ID,
    format: STORE_FORMAT,
    type_id: elements_type_id.store
  },
  {
    name: "Pop Up Stores", 
    type: POP_UP_STORES, 
    format_id: POP_UP_STORE_FORMAT_ID,
    format: STORE_FORMAT,
    type_id: elements_type_id.store
  },
  {
    name: "Personal Store", 
    type: PERSONAL_STORES, 
    format_id: PERSONAL_STORE_FORMAT_ID,
    format: STORE_FORMAT,
    type_id: elements_type_id.store
  },
  {
    name: "Talents", 
    type: TALENTS,
    type_id: TALENTS_FORMAT_ID
  },
  {
    name: "Temporary Work", 
    type: TEMPORARY_WORK, 
    type_id: TEMPORARY_WORK_FORMAT_ID
  },
];