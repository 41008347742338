import { createRequestTypes, createActionTypes } from "utils/redux";

export const GET_USER_NOTIFICATIONS = createRequestTypes(
  "GET_USER_NOTIFICATIONS"
);
export const NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED";
export const OFF_NOTIFICATION = "OFF_NOTIFICATION";
export const OPEN_NOTIFICATION_BOX = 'OPEN_NOTIFICATION_BOX';

export const getUserNotifications = createActionTypes(GET_USER_NOTIFICATIONS);
export const notificationReceived = (notification) => ({
  type: NOTIFICATION_RECEIVED,
  payload: notification,
});
export const offNotification = () => ({ type: OFF_NOTIFICATION });
export const setOpneNotificationBox = state => ({type: OPEN_NOTIFICATION_BOX, payload: state});
