import React from "react";
import { useParams } from "react-router-dom"
import SeasonEventReview from "./SeasonEventReview";
import SeasonEventApply from "./SeasonEventApply";
import { APPLY } from "constants/seasonsEvents";


const SeasonEventApplyReview = () => {
  const { action } = useParams();

  return action === APPLY ? <SeasonEventApply/> : <SeasonEventReview/>

}

export default SeasonEventApplyReview;