import React,{useEffect,useState} from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import {
  Loader,
  TableComponent,
  Pagination,
  AdminTopPanel,
  AlertBox,
  DeleteModal,
} from "components";
import { confirmDelete } from "constants/messages";
import { compile } from "path-to-regexp";
import { ADMIN_MEDIA_CENTER_NEW_NEWS,ADMIN_MEDIA_NEWS_DETAILS } from "constants/paths";
import { getMediaCenterNewsHistory,deleteMediaCenterNews } from "store/actions/adminElements.actions";


const MediaCenterNewsHistory = () => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory();
  const { mediaCenterNewsHistory, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const DATE_FORMAT = "l";

  const toggleModal = () => {
    if (isOpenDeleteModal) {
      setNewsToDelete(null);
    }
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const onDeleteHandler = (id) => {
    toggleModal();
    setNewsToDelete(id);
  };

  const confirmDeleteNews = () => {
    toggleModal();
    dispatch(deleteMediaCenterNews.request(newsToDelete));
    setNewsToDelete(null);
  };

  useEffect(() => {
    dispatch(getMediaCenterNewsHistory.request())
  },[ dispatch ])

  const buttonTools = {
    label: 'New News',
    onClick: () => history.push(compile(ADMIN_MEDIA_CENTER_NEW_NEWS)())
  }
  const tableHead = [
    {
      name: 'Title | En',
      key: "title_en"
    },
    {
      name: "Title | Ar",
      key: "title_ar"
    },
    {
      name: "Creation Date",
      key: "created_at"
    },
    {
      name: "Status",
      key: "switch",
      onChange: () => {
        console.log('will added later')
      }
      
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({id}) => {
        window.open(compile(ADMIN_MEDIA_NEWS_DETAILS)({ id }), '_blank')
      },
    },
    {
      name: "Delete",
      key: "action-btn",
      class: "mdi mdi-trash-can-outline",
      onClick: ({ id }) => onDeleteHandler(id),
    },
  ];
  const tableBody = mediaCenterNewsHistory?.data && mediaCenterNewsHistory.data.map(el => ({
    id: el?.id,
    title_en: el?.title_en,
    title_ar: el?.title_ar || "",
    created_at: moment(el?.created_at).format(DATE_FORMAT) || "",
    status: el?.status || "",
  }));

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      <AdminTopPanel
        withButton
        buttonTools={buttonTools}
      />
      <div className="card">
        <div className="card-body">
        <TableComponent
            tableHead={tableHead}
            tableBody={tableBody}
          />
        <Pagination
            action ={getMediaCenterNewsHistory}
            currentPage = {mediaCenterNewsHistory?.current_page}
            lastPage = {mediaCenterNewsHistory?.last_page}
            goToPage
          />
        </div>
      </div>
      <DeleteModal
        isOpened={isOpenDeleteModal}
        toggle={toggleModal}
        data={confirmDelete}
        confirm={confirmDeleteNews}
        fromAdmin={true}
      />
    </Loader>
  )
}

export default MediaCenterNewsHistory;