import { call, put, takeLatest, select, all,delay } from "redux-saga/effects";
import { AdminElementsApi } from "services/adminElements.service";
import { ElementsApi } from "services/elements.service";
import history from "../../history";
import {
  GET_ELEMENTS_USERS,
  GET_ELEMENT_USER_DETAILS,
  GET_ELEMENT_COMMENTS,
  CREATE_ELEMENT_COMMENT,
  EDIT_ELEMENT_COMMENT,
  DELETE_ELEMENT_COMMENT,
  GET_ELEMENT_COMMENT,
  // ADD_REMOVE_FAVORITE_USER,
  CHANGE_CATEGORY,
  GET_APPLICATION_REQUIREMENTS,
  ADD_APPLICATION_REQUIREMENT,
  GET_APPLICATION_REQUIREMENT,
  EDIT_APPLICATION_REQUIREMENT,
  GET_ADMIN_REGIONS,
  GET_MATLOOB_USERS,
  GET_MATLOOB_USER_ELEMENTS,
  CHANGE_BLOCKED_STATUS,
  GET_EVENTS,
  GET_SEASONS,
  GET_CP_USERS,
  ADD_NEW_CP_USER,
  DELETE_CP_USER,
  DELETE_MATLOOB_USER,
  GET_DASHBOARD_STATISTICS,
  GET_SUBMITTED_FORMS_STATISTICS,
  GET_ELEMENT_STATISTICS,
  REORDER_SEASONS,
  REORDER_EVENTS,
  GET_USER_ACTIONS,
  GET_CP_USER_DETAILS,
  EDIT_CP_USER_DETAILS,
  DOWNLOAD_FILE,
  GET_USER_DETAILS,
  EXPORT_ADMIN_STATISTICS,
  GET_ADMIN_MAIN_ELEMENTS_TYPES,
  CREATE_ADMIN_EVENT_SEASON,
  GET_NOTIFICATIONS_HISTORY,
  GET_NOTIFICATION_DATA,
  CREATE_NOTIFICATION,
  GET_EVENT_SEASON,
  EDIT_EVENT_SEASON,
  CLEAN_SUCCESS,
  GET_ELEMENTS_USERS_TARGET,
  GET_EVENTS_SEASONS_REQUESTS,
  GET_EVENTS_SEASONS_STATUS_REQUESTS,
  GET_EVENT_SEASON_REQUEST,
  CHANGE_REQUEST_STATUS,
  GET_SUPPORTING_ELEMENTS_REQUESTS,
  GET_EVENTS_ARRANGEMENT,
  GET_SEASONS_ARRANGEMENT,
  GET_EVENT_SEASON_REQUESTS_INFO,
  GET_USERS_REQUESTS_TARGET,
  GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET,
  UPDATE_BULK_EVENT_SEASON_STATUS,
  GET_USERS_REQUESTS,
  GET_ADMIN_ELEMENTS_DROP_DOWN,
  getElementsUsers,
  getElementUserDetails,
  getElementComments,
  createElementComment,
  editElementComment,
  deleteElementComment,
  getElementComment,
  // addRemoveFavoriteUser,
  getApplicationRequirements,
  addApplicationRequirement,
  getApplicationRequirement,
  editApplicationRequirement,
  getAdminRegions,
  getMatloobUsers,
  getMatloobUserElements,
  changeBlockedStatus,
  getEvents,
  getSeasons,
  getCPUsers,
  addNewCPUser,
  deleteCPUser,
  deleteMatloobUser,
  getDashboardStatistics,
  getSubmittedFormsStatistics,
  getElementStatistics,
  reorderSeasons,
  reorderEvents,
  getUserActions,
  getCPUserDetails,
  editCPUserDetails,
  downloadFile,
  getMatloobUserDetails,
  exportAdminStatistics,
  getAdminMainElementsTypes,
  createAdminEventSeason,
  getNotificationsHistory,
  getNotificationData,
  createNotification,
  getEventSeason,
  editEventSeason,
  changeCategoryAction,
  getElementsUsersTarget,
  getEventsSeasonsRequests,
  getEventsSeasonsStatusRequests,
  getEventSeasonRequest,
  changeRequestStatus,
  getSupportingElementsRequests,
  getSeasonsArrangement,
  getEventsArrangement,
  getEventSeasonRequestsInfo,
  getUsersRequestTarget,
  getApplicationRequirementRequestsTarget,
  updateBulkEventSeasonStatusRequest,
  getUsersRequests,
  GET_ALL_CATEGORIES,
  getAllCategories,
  getMediaCenterNewsHistory,
  GET_MEDIA_CENTER_NEWS_HISTORY,
  getMediaCenterImagesVideosHistory,
  GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY,
  getMediaCenterCarouselImagesHistory,
  GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY,
  addMediaCenterNewNews,
  ADD_MEDIA_CENTER_NEW_NEWS,
  DELETE_MEDIA_CENTER_NEWS,
  deleteMediaCenterNews,
  EDIT_MEDIA_CENTER_NEWS,
  editMediaCenterNews,
  getAdminMediaCenterNewsDetails,
  GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS,
  DELETE_MEDIA_CENTER_ITEM,
  deleteMediaCenterItem,
  GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS,
  getAdminMediaCenterImagesVideosDetails,
  ADD_MEDIA_CENTER_NEW_ITEM,
  addMediaCenterNewItem,
  EDIT_MEDIA_CENTER_ITEM,
  editMediaCenterItem,
  deleteMediaCenterCarouselImage,
  DELETE_MEDIA_CENTER_CAROUSEL_IMAGE,
  addMediaCenterNewCarouselImage,
  ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE,
  editMediaCenterCarouselImage,
  EDIT_MEDIA_CENTER_CAROUSEL_IMAGE,
  getAdminMediaCenterCarouselImagesDetails,
  GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS,
  POST_ASSIGN_DIRECTOR_TO_ELEMENTS,
  postAssignDirectorToElements,
  getAssignDirectorToElements,
  GET_ASSIGN_DIRECTOR_TO_ELEMENTS,
  UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS,
  updateAssignDirectorToElements,
  getAdminElementsDropDown,
  GET_REPORTS,
  getReports,
  DELETE_REPORTS,
  deleteReports,
  GET_REPORTS_DOWNLOAD,
  getReportDownload
} from "store/actions/adminElements.actions";
import { saveAs } from "file-saver";
import store from "../store";
import { chartData } from "../../components/CategoriesChart/constants";

function* getElementsUsersWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getElementsUsers,
      payload.type,
      payload.params,
      payload.page
    );
    yield put(getElementsUsers.success(data));
  } catch (e) {
    yield put(getElementsUsers.failure(e.data));
  }
}

function* getElementUserDetailsWorker({ payload }) {
  try {
    const [
      elementDetails,
      countries,
      categories,
      temporaryWorksCategories,
      regions,
      languages,
      eventCategories,
      serviceCategories,
      storeFormats,
      productCategories,
      foodCategories,
      restaurantFormats,
      venueCategories,
      venueTopographies,
      venueLandTypes,
      cities,
      disabilityTypes,
    ] = yield all([
      call(AdminElementsApi.getElementUserDetails, payload),
      call(ElementsApi.getCountries),
      call(ElementsApi.getTalentCategories),
      call(ElementsApi.getTemporaryWorkCategories),
      call(ElementsApi.getRegions),
      call(ElementsApi.getLanguages),
      call(ElementsApi.getEventCategories),
      call(ElementsApi.getServiceCategories),
      call(ElementsApi.getStoreFormats),
      call(ElementsApi.getProductCategories),
      call(ElementsApi.getFoodCategories),
      call(ElementsApi.getRestaurantFormats),
      call(AdminElementsApi.getVenueCategories),
      call(AdminElementsApi.getVenueTopographies),
      call(AdminElementsApi.getVenueLandTypes),
      call(ElementsApi.getCities),
      call(ElementsApi.getDisabiltyTypes),
    ]);
    yield put(
      getElementUserDetails.success({
        disabilityTypes: disabilityTypes.data.disabilityTypes,
        elementDetails: elementDetails.data,
        countries: countries.data.countriesOfResidence,
        talentCategories: categories.data.talentCategories,
        temporaryWorksCategories:
          temporaryWorksCategories.data.temporaryWorksCategories,
        regions: regions.data.saudiRegions,
        languages: languages.data.languages,
        eventCategories: eventCategories.data.eventCategories,
        serviceCategories: serviceCategories.data.serviceCategories,
        storeFormats: storeFormats.data.storeFormats,
        productCategories: productCategories.data.storeProductCategories,
        foodCategories: foodCategories.data.foodCategories,
        restaurantFormats: restaurantFormats.data.restaurantFormats,
        venueCategories: venueCategories.data.venueCategories,
        venueTopographies: venueTopographies.data.venueTopographies,
        venueLandTypes: venueLandTypes.data.venueLandTypes,
        cities: cities.data.cities,
      })
    );
  } catch (e) {
    yield put(getElementUserDetails.failure(e.data));
  }
}

function* getElementCommentsWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getElementComments, payload);
    yield put(getElementComments.success(data));
  } catch (e) {
    yield put(getElementComments.failure(e.data));
  }
}

function* getElementCommentWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getElementComment, payload);
    yield put(getElementComment.success(data));
  } catch (e) {
    yield put(getElementComment.failure(e.data));
  }
}

function* createElementCommentWorker({ payload }) {
  try {
    const { elementComments } = yield select(
      ({ adminElements }) => adminElements
    );
    const { data } = yield call(AdminElementsApi.createElementComment, payload);
    history.goBack();
    yield put(
      createElementComment.success({
        elementComments: [...elementComments, data],
      })
    );
  } catch (e) {
    yield put(createElementComment.failure(e.data));
  }
}

function* editElementCommentWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.editElementComment, payload);
    history.goBack();
    yield put(editElementComment.success(data));
  } catch (e) {
    yield put(editElementComment.failure(e.data));
  }
}

function* deletElementCommentWorker({ payload }) {
  try {
    const { elementComments } = yield select(
      ({ adminElements }) => adminElements
    );
    yield call(AdminElementsApi.deleteElementComment, payload);
    yield put(
      deleteElementComment.success({
        elementComments: elementComments.filter(
          (comment) => comment.id !== payload
        ),
      })
    );
  } catch (e) {
    yield put(deleteElementComment.failure(e.data));
  }
}

// function* addRemoveFavoriteUserWorker({ payload }) {
//   try {
//     const { elementsUsers } = yield select(
//       ({ adminElements }) => adminElements
//     );
//     const currentUserData = elementsUsers?.data[payload.index];
//     yield call(
//       currentUserData.favorites
//         ? AdminElementsApi.removeFavoriteUser
//         : AdminElementsApi.addFavoriteUser,
//       payload.form_id
//     );
//     currentUserData.favorites = !currentUserData.favorites;
//     yield put(addRemoveFavoriteUser.success(currentUserData));
//   } catch (e) {
//     yield put(addRemoveFavoriteUser.failure(e.data));
//   }
// }

function* changeCategoryWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.changeCategoryService,
      payload.id,
      payload.val
    );

    yield put(changeCategoryAction.success(data));
  } catch (e) {
    yield put(changeCategoryAction.failure(e.data));
  }
}

function* getApplicationRequirementsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getApplicationRequirements,
      payload
    );
    yield put(getApplicationRequirements.success(data));
  } catch (e) {
    yield put(getApplicationRequirements.failure(e.data));
  }
}

function* addApplicationRequirementWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.addApplicationRequirement,
      payload
    );
    history.goBack();
    yield put(addApplicationRequirement.success(data));
  } catch (e) {
    yield put(addApplicationRequirement.failure(e.data));
  }
}

function* getApplicationRequirementWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getApplicationRequirement,
      payload
    );
    yield put(getApplicationRequirement.success(data));
  } catch (e) {
    yield put(getApplicationRequirement.failure(e.data));
  }
}

function* editApplicationRequirementWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.editApplicationRequirement,
      payload
    );
    history.goBack();
    yield put(editApplicationRequirement.success(data));
  } catch (e) {
    yield put(editApplicationRequirement.failure(e.data));
  }
}

function* getAdminRegionsWorker() {
  try {
    const { data } = yield call(AdminElementsApi.getAdminRegions);
    yield put(getAdminRegions.success(data.saudiRegions));
  } catch (e) {
    yield put(getAdminRegions.failure(e.data));
  }
}

function* getMatloobUsersWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getMatloobUsers, payload);
    yield put(getMatloobUsers.success(data));
  } catch (e) {
    yield put(getMatloobUsers.failure(e.data));
  }
}

function* getMatloobUserElementsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getMatloobUserElements,
      payload.id,
      payload.page
    );
    yield put(getMatloobUserElements.success(data));
  } catch (e) {
    yield put(getMatloobUserElements.failure(e.data));
  }
}

function* changeBlockedStatusWorker({ payload }) {
  try {
    const { matloobUsers } = yield select(({ adminElements }) => adminElements);
    const currentUserData = matloobUsers?.data[payload.index];
    const isBlocked = !currentUserData.blocked;
    payload.isBlocked = isBlocked;
    yield call(AdminElementsApi.changeBlockedStatus, payload);
    currentUserData.blocked = isBlocked;
    yield put(changeBlockedStatus.success(currentUserData));
  } catch (e) {
    yield put(changeBlockedStatus.failure(e.data));
  }
}

function* getEventsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getEvents,
      !!payload.type,
      payload.page
    );
    yield put(getEvents.success(data?.events));
  } catch (e) {
    yield put(getEvents.failure(e.data));
  }
}

function* getSeasonsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getSeasons,
      !!payload.type,
      payload.page
    );
    yield put(getSeasons.success(data?.seasons));
  } catch (e) {
    yield put(getSeasons.failure(e.data));
  }
}

function* getCPUsersWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getCPUsers, payload);
    yield put(getCPUsers.success(data));
  } catch (e) {
    yield put(getCPUsers.failure(e.data));
  }
}

function* addNewCPUserWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.addNewCPUser, payload);
    history.goBack();
    yield put(addNewCPUser.success(data));
  } catch (e) {
    yield put(addNewCPUser.failure(e.data));
  }
}

function* deleteCPUserWorker({ payload }) {
  try {
    const { cpUsers } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteCPUser, payload);
    const filteredUsers = cpUsers?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteCPUser.success({
        cpUsers: { ...cpUsers, data: filteredUsers },
      })
    );
  } catch (e) {
    yield put(deleteElementComment.failure(e.data));
  }
}
function* deleteMatloobUserWorker({ payload }) {
  try {
    const { matloobUsers } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteMatloobUser, payload);
    const filteredUsers = matloobUsers?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteMatloobUser.success({
        matloobUsers: { ...matloobUsers, data: filteredUsers },
      })
    );
  } catch (e) {
    yield put(deleteElementComment.failure(e.data));
  }
}

function* getDashboardStatisticsWorker() {
  try {
    const { data } = yield call(AdminElementsApi.getDashboardStatistics);
    yield put(getDashboardStatistics.success(data));
  } catch (e) {
    yield put(getDashboardStatistics.failure(e.data));
  }
}

function* getSubmittedFormsStatisticsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getSubmittedFormsStatistics,
      payload
    );
    yield put(getSubmittedFormsStatistics.success(data));
  } catch (e) {
    yield put(getSubmittedFormsStatistics.failure(e.data));
  }
}

function* getElementStatisticsWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getElementStatistics, payload);
    yield put(getElementStatistics.success(data));
  } catch (e) {
    yield put(getElementStatistics.failure(e.data));
  }
}

function* reorderSeasonsWorker({ payload }) {
  const seasonsUpdated = payload.data.map(({ id, order }) => ({
    seasonId: id,
    order,
  }));
  try {
    yield call(AdminElementsApi.reorderSeasons, seasonsUpdated);
    yield put(reorderSeasons.success());
  } catch (e) {
    yield put(reorderSeasons.failure(e.data));
  }
}

function* reorderEventsWorker({ payload }) {
  const eventsUpdated = payload.data.map(({ id, order }) => ({
    eventId: id,
    order,
  }));
  try {
    yield call(AdminElementsApi.reorderEvents, eventsUpdated);
    yield put(reorderEvents.success());
  } catch (e) {
    yield put(reorderEvents.failure(e.data));
  }
}

function* getUserActionsWorker() {
  try {
    const { data } = yield call(AdminElementsApi.getUserActions);
    yield put(getUserActions.success(data));
  } catch (e) {
    yield put(getUserActions.failure(e.data));
  }
}

function* getCPUserDetailsWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getCPUserDetails, payload);
    yield put(getCPUserDetails.success(data));
  } catch (e) {
    yield put(getCPUserDetails.failure(e.data));
  }
}

function* editCPUserDetailsWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.editCPUserDetails, payload);
    yield put(editCPUserDetails.success(data));
  } catch (e) {
    yield put(editCPUserDetails.failure(e.data));
  }
}

function* downloadFileWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.downloadFile, payload);
    saveAs(data, "downloaded.pdf");
    yield put(downloadFile.success());
  } catch (e) {
    yield put(downloadFile.failure(e.data));
  }
}

function* getMatloobUserDetailsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getMatloobUserDetails,
      payload
    );
    yield put(getMatloobUserDetails.success(data));
  } catch (e) {
    yield put(getMatloobUserDetails.failure(e.data));
  }
}

function* exportAdminStatisticsWorker() {
  try {
    const { data } = yield call(AdminElementsApi.exportAdminStatistics);
    saveAs(data, "statistics.csv");
    yield put(exportAdminStatistics.success());
  } catch (e) {
    yield put(exportAdminStatistics.failure(e.data));
  }
}

function* getAdminMainElementsTypesWorker({ payload }) {
  const type = payload.type === null ? null : 3;
  try {
    const [
      adminRegions,
      adminMainElements,
      restaurantFormats,
      storeFormats,
      eventSeasonApplicationRequirements,
      cpUsers,
    ] = yield all([
      call(AdminElementsApi.getAdminRegions),
      call(AdminElementsApi.getAdminMainElementsTypes),
      call(AdminElementsApi.getAdminMainElementFormats, "restaurant-formats"),
      call(AdminElementsApi.getAdminMainElementFormats, "store-formats"),
      call(AdminElementsApi.getEventSeasonApplicationRequirements),
      !payload.viewOnly && call(AdminElementsApi.getCPUsers, { type }),
    ]);

    yield put(
      getAdminMainElementsTypes.success({
        adminRegions: adminRegions.data.saudiRegions,
        adminMainElements: adminMainElements.data.userElementTypes,
        restaurantFormats: restaurantFormats.data.restaurantFormats,
        storeFormats: storeFormats.data.storeFormats,
        eventSeasonApplicationRequirements:
          eventSeasonApplicationRequirements.data,
        cpUsers: cpUsers?.data || [],
        editEventSeason,
      })
    );
  } catch (e) {
    yield put(getAdminMainElementsTypes.failure(e.data));
  }
}

function* createAdminEventSeasonWorker({ payload }) {
  try {
    yield call(AdminElementsApi.createAdminEventSeason, payload);
    history.goBack();
    yield put(createAdminEventSeason.success());
  } catch (e) {
    yield put(createAdminEventSeason.failure(e.data));
  }
}

function* getNotificationsHistoryWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getNotificationsHistory,
      payload
    );
    yield put(getNotificationsHistory.success(data));
  } catch (e) {
    yield put(getNotificationsHistory.failure(e.data));
  }
}

function* getNotificationDataWorker() {
  try {
    const [seasonsArrangement, eventsArrangement, adminMainElements] =
      yield all([
        call(AdminElementsApi.getSeasonsArrangement),
        call(AdminElementsApi.getEventsArrangement),
        call(AdminElementsApi.getAdminMainElementsTypes),
      ]);

    yield put(
      getNotificationData.success({
        seasonsArrangement: seasonsArrangement.data,
        eventsArrangement: eventsArrangement.data,
        adminMainElements: adminMainElements.data.userElementTypes,
      })
    );
  } catch (e) {
    yield put(getNotificationData.failure(e.data));
  }
}

function* createNotificationWorker({ payload }) {
  try {
    yield call(AdminElementsApi.createNotification, payload);
    yield put(createNotification.success());
    setTimeout(() => {
      history.push("/admin/notifcations-history");
      store.dispatch({ type: CLEAN_SUCCESS });
    }, 1000);
  } catch (e) {
    yield put(createNotification.failure(e.data));
  }
}

function* getEventSeasonWorker({ payload }) {
  try {
    const { data } = yield call(
      payload.type === "Season"
        ? AdminElementsApi.getSeason
        : AdminElementsApi.getEvent,
      payload.id
    );
    yield put(getEventSeason.success(data));
  } catch (e) {
    yield put(getEventSeason.failure(e.data));
  }
}

function* editEventSeasonWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.editEventSeason,
      payload.type,
      payload.id,
      payload.data
    );
    yield put(editEventSeason.success());
    history.goBack();
  } catch (e) {
    yield put(editEventSeason.failure(e.data));
  }
}

function* getElementsUsersTargetWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getElementsUsersTarget,
      payload.type,
      payload.params
    );
    // saveAs(data, `${payload.typeSelected.name}.csv`);
    yield put(getElementsUsersTarget.success(data.message));
  } catch (e) {
    yield put(getElementsUsersTarget.failure(e.data));
  }
}

function* getEventsSeasonsRequestsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getEventsSeasonsRequests,
      payload.type,
      payload.page
    );
    yield put(getEventsSeasonsRequests.success(data));
  } catch (e) {
    yield put(getEventsSeasonsRequests.failure(e.data));
  }
}
function* getEventSeasonRequestWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getEventSeasonRequest,
      payload.id || payload.typeId,
      payload.params,
      payload.page
    );
    yield put(getEventSeasonRequest.success(data));
  } catch (e) {
    yield put(getEventSeasonRequest.failure(e.data));
  }
}

function* getEventsSeasonsStatusRequestsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getEventsSeasonsStatusRequests,
      payload.type,
      payload.seasonType,
      payload.page,
    );
    yield put (getEventsSeasonsStatusRequests.success(data));
  } catch (e) {
    yield put(getEventsSeasonsStatusRequests.failure(e.data));
  }
}

function* changeRequestStatusWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.changeRequestStatus,
      payload.data,
      payload.id,
      payload.params
    );
    window.location.pathname.startsWith('/admin/user-requests/') 
    ? yield call(getUsersRequestWorker, { payload: payload.params }) 
    : yield call(getEventSeasonRequestWorker, { payload: payload.params });
  } catch (e) {
    yield put(changeRequestStatus.failure(e.data));
  }
}

function* updateBulkEventSeasonStatusRequestsWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.updateBulkEventSeasonStatusRequest,
      payload.type,
      payload.data,
      payload.params
    );
    yield delay(2500);
    yield call(getEventSeasonRequestWorker, { payload: payload.params });
  } catch (e) {
    yield put(updateBulkEventSeasonStatusRequest.failure(e.data));
  }
}

function* getSupportingElementsRequestsWorker({ payload }) {
  try {
    const [supportingElementsRequests, adminMainElements] = yield all([
      call(AdminElementsApi.getSupportingElementsRequests, payload),
      call(AdminElementsApi.getAdminMainElementsTypes),
    ]);
    yield put(
      getSupportingElementsRequests.success({
        supportingElementsRequests: supportingElementsRequests.data,
        adminMainElements: adminMainElements.data.userElementTypes,
      })
    );
  } catch (e) {
    yield put(getSupportingElementsRequests.failure(e.data));
  }
}

function* getEventsArrangementWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.getEventsArrangement, payload);
    yield put(getEventsArrangement.success(data));
  } catch (e) {
    yield put(getEventsArrangement.failure(e.data));
  }
}

function* getSeasonsArrangementWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getSeasonsArrangement,
      payload
    );
    yield put(getSeasonsArrangement.success(data));
  } catch (e) {
    yield put(getSeasonsArrangement.failure(e.data));
  }
}

function* getEventSeasonRequestsInfoWorker({ payload }) {
  try {
    const [eventSeasonRequestsInfo, updatedHistory] = yield all([
      call(
        AdminElementsApi.getEventSeasonRequestsInfo,
        payload.typeId,
        payload.id
      ),
      payload.user_type !== 4 && payload.user_type !== 3 &&
        call(AdminElementsApi.getUpdatedHistory, payload.id),
    ]);

    yield put(
      getEventSeasonRequestsInfo.success(
        payload.user_type !== 4 && payload.user_type !== 3
          ? {
              eventSeasonRequestsInfo: eventSeasonRequestsInfo.data,
              updatedHistory: updatedHistory.data,
            }
          : {
              eventSeasonRequestsInfo: eventSeasonRequestsInfo.data,
            }
      )
    );
  } catch (e) {
    yield put(getEventSeasonRequestsInfo.failure(e.data));
  }
}

function* getUsersRequestTargetWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getUsersRequestTarget,
      payload
    );
    yield put(getUsersRequestTarget.success(data.message));
  } catch (e) {
    yield put(getUsersRequestTarget.failure(e.data));
  }
}

function* getUsersRequestWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getUsersRequests,
      payload.id || payload.typeId,
      payload.perPage
    );
    yield put(getUsersRequests.success(data));
  } catch (e) {
    yield put(getUsersRequests.failure(e.data));
  }
}

function* getApplicationRequirementRequestsTargetWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getApplicationRequirementRequestsTarget,
      payload
    );
    saveAs(data, "Application Requirements Requests");
    yield put(getApplicationRequirementRequestsTarget.success());
  } catch (e) {
    yield put(getApplicationRequirementRequestsTarget.failure(e.data));
  }
}
function* getAllCategoriesWorker() {
  try {
    const data = yield all(
      chartData.map((item) =>
        call(AdminElementsApi.adminCategories, item.request)
      )
    );
    const formattedData = Object.values(data.map((item) => item.data));
    yield put(getAllCategories.success(formattedData));
  } catch (e) {
    yield put(getAllCategories.failure(e.data));
  }
}

function* getReportsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getReports,
      payload
    );
    yield put(getReports.success(data));
  } catch (e) {
    yield put(getReports.failure(e.data));
  }
}

function* deleteReportsWorker ({ payload }) {
  try {
    const { reports } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteReports, payload);
    const filteredReports = reports?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteReports.success({
        reports: { ...reports, data: filteredReports },
      })
    );
  } catch (e) {
    yield put(deleteReports.failure(e.data));
  }
}

function* getReportDownloadWorker({ payload: { fileUrl, fileName } }) {
  try {
    const { data } = yield call(AdminElementsApi.getReportDownload, fileUrl);
    saveAs(data, fileName);
    yield put(getReportDownload.success());
  } catch (e) {
    yield put(getReportDownload.failure(e.data));
  }
}

function* getMediaCenterNewsHistoryWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getMediaCenterNewsHistory,
      payload
    );
    yield put(getMediaCenterNewsHistory.success(data));
  } catch (e) {
    yield put(getMediaCenterNewsHistory.failure(e.data));
  }
}
function* getAdminMediaCenterNewsDetailsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getAdminMediaCenterNewsDetails,
      payload
    );
    yield put(getAdminMediaCenterNewsDetails.success(data));
  } catch (e) {
    yield put(getAdminMediaCenterNewsDetails.failure(e.data));
  }
}

function* getAdminMediaCenterImagesVideosDetailsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getAdminMediaCenterImagesVideosDetails,
      payload
    );
    yield put(getAdminMediaCenterImagesVideosDetails.success(data));
  } catch (e) {
    yield put(getAdminMediaCenterImagesVideosDetails.failure(e.data));
  }
}

function* addMediaCenterNewNewsWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.addMediaCenterNewNews, payload);
    history.goBack();
    yield put(addMediaCenterNewNews.success(data));
  } catch (e) {
    yield put(addMediaCenterNewNews.failure(e.data));
  }
}

function* addMediaCenterNewItemWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.addMediaCenterNewItem, payload);
    history.goBack();
    yield put(addMediaCenterNewItem.success(data));
  } catch (e) {
    yield put(addMediaCenterNewItem.failure(e.data));
  }
}

function* deleteMediaCenterNewsWorker ({ payload }) {
  try {
    const { mediaCenterNewsHistory } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteMediaCenterNews, payload);
    const filteredNews = mediaCenterNewsHistory?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteMediaCenterNews.success({
        mediaCenterNewsHistory: { ...mediaCenterNewsHistory, data: filteredNews },
      })
    );
  } catch (e) {
    yield put(deleteMediaCenterNews.failure(e.data));
  }
}

function* deleteMediaCenterItemWorker ({ payload }) {
  try {
    const { mediaCenterImagesVideosHistory } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteMediaCenterItem, payload);
    const filteredNews = mediaCenterImagesVideosHistory?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteMediaCenterItem.success({
        mediaCenterImagesVideosHistory: { ...mediaCenterImagesVideosHistory, data: filteredNews },
      })
    );
  } catch (e) {
    yield put(deleteMediaCenterItem.failure(e.data));
  }
}

function* editMediaCenterNewsWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.editMediaCenterNews,
      payload.id,
      payload.data
    );
    yield put(editMediaCenterNews.success());
    history.push("/admin/media-center/news");
  } catch (e) {
    yield put(editMediaCenterNews.failure(e.data));
  }
}

function* editMediaCenterItemWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.editMediaCenterItem,
      payload.id,
      payload.data
    );
    yield put(editMediaCenterItem.success());
    history.push("/admin/media-center/images&videos");
  } catch (e) {
    yield put(editMediaCenterItem.failure(e.data));
  }
}

function* getMediaCenterImagesVideosHistoryWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getMediaCenterImagesVideosHistory,
      payload
    );
    yield put(getMediaCenterImagesVideosHistory.success(data));
  } catch (e) {
    yield put(getMediaCenterImagesVideosHistory.failure(e.data));
  }
}

function* getMediaCenterCarouselImagesHistoryWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getMediaCenterCarouselImagesHistory,
      payload
    );
    yield put(getMediaCenterCarouselImagesHistory.success(data));
  } catch (e) {
    yield put(getMediaCenterCarouselImagesHistory.failure(e.data));
  }
}

function* getAdminMediaCenterCarouselImagesDetailsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getAdminMediaCenterCarouselImagesDetails,
      payload
    );
    yield put(getAdminMediaCenterCarouselImagesDetails.success(data));
  } catch (e) {
    yield put(getAdminMediaCenterCarouselImagesDetails.failure(e.data));
  }
}

function* addMediaCenterNewCarouselImageWorker({ payload }) {
  try {
    const { data } = yield call(AdminElementsApi.addMediaCenterNewCarouselImage, payload);
    history.goBack();
    yield put(addMediaCenterNewCarouselImage.success(data));
  } catch (e) {
    yield put(addMediaCenterNewCarouselImage.failure(e.data));
  }
}

function* editMediaCenterCarouselImageWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.editMediaCenterCarouselImage,
      payload.id,
      payload.data
    );
    yield put(editMediaCenterCarouselImage.success());
    history.push("/admin/media-center/carousel-images");
  } catch (e) {
    yield put(editMediaCenterCarouselImage.failure(e.data));
  }
}

function* deleteMediaCenterCarouselImageWorker ({ payload }) {
  try {
    const { mediaCenterCarouselImagesHistory } = yield select(({ adminElements }) => adminElements);
    yield call(AdminElementsApi.deleteMediaCenterCarouselImage, payload);
    const filteredNews = mediaCenterCarouselImagesHistory?.data.filter(
      (element) => element.id !== payload
    );
    yield put(
      deleteMediaCenterCarouselImage.success({
        mediaCenterCarouselImagesHistory: { ...mediaCenterCarouselImagesHistory, data: filteredNews },
      })
    );
  } catch (e) {
    yield put(deleteMediaCenterCarouselImage.failure(e.data));
  }
}

function* postAssignDirectorToElementsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.postAssignDirectorToElements,
      payload
    );
    yield put(postAssignDirectorToElements.success(data));
    history.go(0)
  } catch (e) {
    yield put(postAssignDirectorToElements.failure(e.data));
  }
}
function* getAssignDirectorToElementsWorker({ payload }) {
  try {
    const { data } = yield call(
      AdminElementsApi.getAssignDirectorToElements,
      payload
    );
    yield put(getAssignDirectorToElements.success(data));
  } catch (e) {
    yield put(getAssignDirectorToElements.failure(e.data));
  }
}


function* updateAssignDirectorToElementsWorker({ payload }) {
  try {
    yield call(
      AdminElementsApi.updateAssignDirectorToElements,
      payload.id,
      payload.data
    );
    yield put(updateAssignDirectorToElements.success());
  } catch (e) {
    yield put(updateAssignDirectorToElements.failure(e.data));
  }
}

function* getAdminElementsDropDownWorker() {
  try {
    const { data } = yield call(AdminElementsApi.getAdminElementsDropDown);
    yield put(getAdminElementsDropDown.success(data));
  } catch (e) {
    yield put(getAdminElementsDropDown.failure(e.data));
  }
}



function* adminElementsSaga() {
  yield takeLatest(GET_ELEMENTS_USERS.REQUEST, getElementsUsersWorker);
  yield takeLatest(
    GET_ELEMENT_USER_DETAILS.REQUEST,
    getElementUserDetailsWorker
  );
  yield takeLatest(GET_ELEMENT_COMMENTS.REQUEST, getElementCommentsWorker);
  yield takeLatest(CREATE_ELEMENT_COMMENT.REQUEST, createElementCommentWorker);
  yield takeLatest(EDIT_ELEMENT_COMMENT.REQUEST, editElementCommentWorker);
  yield takeLatest(DELETE_ELEMENT_COMMENT.REQUEST, deletElementCommentWorker);
  yield takeLatest(GET_ELEMENT_COMMENT.REQUEST, getElementCommentWorker);
  yield takeLatest(CHANGE_CATEGORY.REQUEST, changeCategoryWorker);
  yield takeLatest(
    GET_APPLICATION_REQUIREMENTS.REQUEST,
    getApplicationRequirementsWorker
  );
  yield takeLatest(
    ADD_APPLICATION_REQUIREMENT.REQUEST,
    addApplicationRequirementWorker
  );
  yield takeLatest(
    GET_APPLICATION_REQUIREMENT.REQUEST,
    getApplicationRequirementWorker
  );
  yield takeLatest(
    EDIT_APPLICATION_REQUIREMENT.REQUEST,
    editApplicationRequirementWorker
  );
  yield takeLatest(GET_ADMIN_REGIONS.REQUEST, getAdminRegionsWorker);
  yield takeLatest(GET_MATLOOB_USERS.REQUEST, getMatloobUsersWorker);
  yield takeLatest(
    GET_MATLOOB_USER_ELEMENTS.REQUEST,
    getMatloobUserElementsWorker
  );
  yield takeLatest(CHANGE_BLOCKED_STATUS.REQUEST, changeBlockedStatusWorker);
  yield takeLatest(GET_EVENTS.REQUEST, getEventsWorker);
  yield takeLatest(GET_SEASONS.REQUEST, getSeasonsWorker);
  yield takeLatest(GET_CP_USERS.REQUEST, getCPUsersWorker);
  yield takeLatest(ADD_NEW_CP_USER.REQUEST, addNewCPUserWorker);
  yield takeLatest(DELETE_CP_USER.REQUEST, deleteCPUserWorker);
  yield takeLatest(DELETE_MATLOOB_USER.REQUEST, deleteMatloobUserWorker);
  yield takeLatest(
    GET_DASHBOARD_STATISTICS.REQUEST,
    getDashboardStatisticsWorker
  );
  yield takeLatest(
    GET_SUBMITTED_FORMS_STATISTICS.REQUEST,
    getSubmittedFormsStatisticsWorker
  );
  yield takeLatest(GET_ELEMENT_STATISTICS.REQUEST, getElementStatisticsWorker);
  yield takeLatest(REORDER_SEASONS.REQUEST, reorderSeasonsWorker);
  yield takeLatest(REORDER_EVENTS.REQUEST, reorderEventsWorker);
  yield takeLatest(GET_USER_ACTIONS.REQUEST, getUserActionsWorker);
  yield takeLatest(GET_CP_USER_DETAILS.REQUEST, getCPUserDetailsWorker);
  yield takeLatest(EDIT_CP_USER_DETAILS.REQUEST, editCPUserDetailsWorker);
  yield takeLatest(DOWNLOAD_FILE.REQUEST, downloadFileWorker);
  yield takeLatest(GET_USER_DETAILS.REQUEST, getMatloobUserDetailsWorker);
  yield takeLatest(
    EXPORT_ADMIN_STATISTICS.REQUEST,
    exportAdminStatisticsWorker
  );
  yield takeLatest(
    GET_ADMIN_MAIN_ELEMENTS_TYPES.REQUEST,
    getAdminMainElementsTypesWorker
  );
  yield takeLatest(
    CREATE_ADMIN_EVENT_SEASON.REQUEST,
    createAdminEventSeasonWorker
  );
  yield takeLatest(
    GET_NOTIFICATIONS_HISTORY.REQUEST,
    getNotificationsHistoryWorker
  );
  yield takeLatest(GET_NOTIFICATION_DATA.REQUEST, getNotificationDataWorker);
  yield takeLatest(CREATE_NOTIFICATION.REQUEST, createNotificationWorker);
  yield takeLatest(GET_EVENT_SEASON.REQUEST, getEventSeasonWorker);
  yield takeLatest(EDIT_EVENT_SEASON.REQUEST, editEventSeasonWorker);
  yield takeLatest(
    GET_ELEMENTS_USERS_TARGET.REQUEST,
    getElementsUsersTargetWorker
  );
  yield takeLatest(
    GET_EVENTS_SEASONS_REQUESTS.REQUEST,
    getEventsSeasonsRequestsWorker
  );
  yield takeLatest(
    GET_EVENTS_SEASONS_STATUS_REQUESTS.REQUEST,
    getEventsSeasonsStatusRequestsWorker
  );
  yield takeLatest(
    GET_EVENT_SEASON_REQUEST.REQUEST,
    getEventSeasonRequestWorker
  );
  yield takeLatest(CHANGE_REQUEST_STATUS.REQUEST, changeRequestStatusWorker); 
  yield takeLatest(UPDATE_BULK_EVENT_SEASON_STATUS.REQUEST, updateBulkEventSeasonStatusRequestsWorker);
  yield takeLatest(
    GET_SUPPORTING_ELEMENTS_REQUESTS.REQUEST,
    getSupportingElementsRequestsWorker
  );
  yield takeLatest(GET_EVENTS_ARRANGEMENT.REQUEST, getEventsArrangementWorker);
  yield takeLatest(
    GET_SEASONS_ARRANGEMENT.REQUEST,
    getSeasonsArrangementWorker
  );
  yield takeLatest(
    GET_EVENT_SEASON_REQUESTS_INFO.REQUEST,
    getEventSeasonRequestsInfoWorker
  );
  yield takeLatest(
    GET_USERS_REQUESTS_TARGET.REQUEST,
    getUsersRequestTargetWorker
  );
  yield takeLatest(GET_USERS_REQUESTS.REQUEST, getUsersRequestWorker);
  yield takeLatest(
    GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET.REQUEST,
    getApplicationRequirementRequestsTargetWorker
  );
  yield takeLatest(GET_ALL_CATEGORIES.REQUEST, getAllCategoriesWorker);
  yield takeLatest(
    GET_REPORTS.REQUEST,
    getReportsWorker
  );
  yield takeLatest(
    DELETE_REPORTS.REQUEST,
    deleteReportsWorker
  );
  yield takeLatest(
    GET_REPORTS_DOWNLOAD.REQUEST,
    getReportDownloadWorker
  );
  yield takeLatest(
    GET_MEDIA_CENTER_NEWS_HISTORY.REQUEST,
    getMediaCenterNewsHistoryWorker
  );
  yield takeLatest(ADD_MEDIA_CENTER_NEW_NEWS.REQUEST, addMediaCenterNewNewsWorker);
  yield takeLatest(GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS.REQUEST, getAdminMediaCenterNewsDetailsWorker);
  yield takeLatest(ADD_MEDIA_CENTER_NEW_ITEM.REQUEST, addMediaCenterNewItemWorker);
  yield takeLatest(EDIT_MEDIA_CENTER_ITEM.REQUEST, editMediaCenterItemWorker);
  yield takeLatest(GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS.REQUEST, getAdminMediaCenterImagesVideosDetailsWorker);
  yield takeLatest(DELETE_MEDIA_CENTER_NEWS.REQUEST, deleteMediaCenterNewsWorker);
  yield takeLatest(DELETE_MEDIA_CENTER_ITEM.REQUEST, deleteMediaCenterItemWorker);
  yield takeLatest(EDIT_MEDIA_CENTER_NEWS.REQUEST, editMediaCenterNewsWorker);
  yield takeLatest(GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY.REQUEST,getMediaCenterImagesVideosHistoryWorker);
  yield takeLatest(GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY.REQUEST, getMediaCenterCarouselImagesHistoryWorker);
  yield takeLatest(ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE.REQUEST, addMediaCenterNewCarouselImageWorker);
  yield takeLatest(GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS.REQUEST, getAdminMediaCenterCarouselImagesDetailsWorker);
  yield takeLatest(EDIT_MEDIA_CENTER_CAROUSEL_IMAGE.REQUEST, editMediaCenterCarouselImageWorker);
  yield takeLatest(DELETE_MEDIA_CENTER_CAROUSEL_IMAGE.REQUEST, deleteMediaCenterCarouselImageWorker);
  yield takeLatest(POST_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST, postAssignDirectorToElementsWorker);
  yield takeLatest(GET_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST, getAssignDirectorToElementsWorker);
  yield takeLatest(UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST, updateAssignDirectorToElementsWorker);
  yield takeLatest(GET_ADMIN_ELEMENTS_DROP_DOWN.REQUEST,getAdminElementsDropDownWorker);
}


export default adminElementsSaga;