import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useError, useFieldTouched } from "utils/forms";
import { digits, arabicToEnglishNums } from "utils/validation";
import styles from "./styles.module.scss";

const NUMBER_OF_INPUTS = 4;
const indexesArray = [0, 1, 2, 3];

const OTPinput = ({ formik }) => {
  const inputRef = useRef([]);

  const [value, setValue] = useState(new Array(NUMBER_OF_INPUTS).fill(null));

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  const handleChange = index => ({ target: { value: newValue } }) => {
    const lastIndex = indexesArray.length - 1;
    if (newValue) {
      if (digits.test(newValue)) {
        if (index < lastIndex) inputRef.current[index + 1].focus();
        const arr = value.map((item, num) => (num === index ? newValue : item));
        formik.setFieldValue("code", arabicToEnglishNums(arr.join("")));
        setValue(arr);
      } else {
        inputRef.current[index].value = "";
      }
    } else {
      const arr = value.map((item, num) => (num === index ? "" : item));
      setValue(arr);
      formik.setFieldValue("code", arr.join(""));
      inputRef.current[index].value = "";
      if (index > 0) inputRef.current[index - 1].focus();
    }
  };

  return (
    <>
      <div
        className={classnames("form-group", styles.otpInput)}
        onClick={setFieldTouched}
      >
        {indexesArray.map(idx => (
          <input
            ref={el => (inputRef.current[idx] = el)}
            name="code"
            onChange={handleChange(idx)}
            key={idx}
            type="tel"
            className="form-control otp-item"
            maxLength={1}
            minLength={1}
            autoComplete="one-time-code"
            inputMode="decimal"
          />
        ))}
      </div>
      {getError("code")}
    </>
  );
};

OTPinput.propTypes = {
  formik: PropTypes.object.isRequired
};

export default OTPinput;
