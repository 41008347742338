import moment from "moment";

export const notificationsList = (
    userNotifications,
    getFormattedDateTime
) => {
    const today = new Date();
    const sevenDaysBefore = moment().subtract(7, "days");
    const monthBefore = moment().subtract(1, "month");
    const lastWeek = moment(sevenDaysBefore);
    const lastMonths = moment(monthBefore);
    const year = new Date().getFullYear();
    const yearBefore = moment(`${year - 1}-12-31 23:59:59`);
    const weekTimeStamp = lastWeek.toDate().getTime();
    const monthTimeStamp = lastMonths.toDate().getTime();
    const yearTimeStamp = yearBefore.toDate().getTime();
    const initialValues = {
        today: [],
        thisWeek: [],
        lastWeek: [],
        lastMonth: [],
        lastYear: [],
    };
    return Array.isArray(userNotifications)
        ? userNotifications?.reduce((acc, item) => {
            const notificationDate =
                item?.created_at || item?.createdAt || item?.updatedAt;
            const notificationTime = new Date(notificationDate).getTime();
            if (notificationTime <= yearTimeStamp) {
                acc.lastYear.push(item);
            }
            if (getFormattedDateTime(notificationDate, "l") === getFormattedDateTime(today, "l")) {
                acc.today.push(item);
            }
            if ( notificationTime >= weekTimeStamp && getFormattedDateTime(notificationDate, "l") !== getFormattedDateTime(today, "l")
            ) {
                acc.thisWeek.push(item);
            }
            if ( notificationTime >= monthTimeStamp && notificationTime <= weekTimeStamp ) {
                acc.lastWeek.push(item);
            }
            if ( notificationTime <= monthTimeStamp &&  notificationTime >= yearTimeStamp ) {
                acc.lastMonth.push(item);
            }
            return acc;
        }, initialValues)
        : initialValues;
};
