import React,{ useEffect,useContext } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Context } from "context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Autoplay } from "swiper";

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

import styles from './styles.module.scss'

import { getMediaCenterCarouselImages } from "store/actions/mediaCenter.actions";


const MediaCenterCarousel = () => {
  const { rtl } = useContext(Context);
  const dispatch = useDispatch();
  const { mediaCenterCarouselImages, loaded } = useSelector(
      ({ mediaCenter }) => mediaCenter
  );
  useEffect(() => {
      dispatch(getMediaCenterCarouselImages.request());
  }, [dispatch]);

return (
        <div className={styles.mainCarouselWrapper}>
            <Swiper
                pagination={{
                    clickable: true,
                    renderBullet: function(index,className) {
                        return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
                    }
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                    1500:{
                        slidesPerView: 1,
                    }
                }}
                modules={[Pagination,Autoplay]}
                className={styles.mainCarouselPagination}
                navigation={false}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                dir={rtl ? 'rtl' : 'ltr'}
                >
                {loaded &&  mediaCenterCarouselImages?.data?.map((image,index) => (
                    <SwiperSlide key={index}>
                        <img src={image.image} alt='test'/>
                    </SwiperSlide>
                    )
                )}
            </Swiper>
        </div>
    )
}

export default MediaCenterCarousel
