import React from "react";
import { Loader, TableComponent } from "components";

const tableHead = [
  {
    name: "#",
    key: "#"
  },
  {
    name: "Element Name",
    key: "name"
  },
  {
    name: "Elements Status",
    key: "boolean",
    values: ['Incomplete', 'Complete'],
    field: "status"
  },
  {
    name: "Details",
    key: "action-btn",
    class: "mdi mdi-eye",
    onClick: () => {}
  },

];

const tableBody = [
  {
    name: "NAME",
    status: true
  }
];

const AdminUserElements = () => {
  return (
    <Loader loaded={true}>
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">User Elements</h4>
        </div>
      </div>
      {/* {error && <div className="alert alert-danger">{error}</div>} */}

      <div className="card">
        <div className="card-body">
          <TableComponent tableHead={tableHead} tableBody={tableBody} />
        </div>
      </div>
    </Loader>
  );
};

export default React.memo(AdminUserElements);
