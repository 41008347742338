import React from "react";
import LoginLayout from "layouts/Login";
import { LoginForm } from "components";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  return (
    <LoginLayout title={t("login.title")} subTitle={t("login.subTitle")}>
      <LoginForm />
    </LoginLayout>
  );
};

export default Login;
