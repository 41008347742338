import React from "react"
import Layout from "layouts/Admin";
import { MediaCenterImagesVideosHistory } from "components"

const AdminMediaCenterImagesVideos = () => {
  return (
    <Layout title="Media Center">
      <MediaCenterImagesVideosHistory/>
    </Layout>
  )
}

export default AdminMediaCenterImagesVideos;