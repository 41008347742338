import React,{ useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NewsCard } from 'components'
import styles from './styles.module.scss'
import { NEWS} from "constants/paths";
import { getMediaCenterNews } from "store/actions/mediaCenter.actions";

const LatestNews = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mediaCenterNews, loaded } = useSelector(
        ({ mediaCenter }) => mediaCenter
    );
    useEffect(() => {
        dispatch(getMediaCenterNews.request());
    }, [dispatch]);
    return (
        <div className={styles.lastNewsSection}>
            <div className={styles.headerSection}> 
                <div className={styles.header}>{t("mediaCenter.news")}</div>
                <Link to={NEWS} className={styles.moreNews}>{t("mediaCenter.moreNews")}</Link>
            </div>
            <div className={styles.cardsSection}>
                {loaded && mediaCenterNews?.data?.slice(0,3).map((news) => (
                    <NewsCard
                    key={news.id}
                    item={news}
                    />
                ))}
            </div>
        </div>
    )
}

export default LatestNews