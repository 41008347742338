import React, { useState } from "react";
import VideoButton from "assets/images/loginImg/video-play-button.png";
// import homepageVideoEn from "assets/videos/home-video-en.mp4";
// import homepageVideoAr from "assets/videos/home-video-ar.mp4";
import newHomeVideoAr from "assets/videos/new-home-vide-ar.mp4";
import ReactPlayer from "react-player";
import classnames from "classnames";
import styles from "./styles.module.scss";
import backgroundVideoImage from "assets/images/image00001.png";

const VideoSection = () => {
    const [isPause, setIsPause] = useState(true);
    const [isStarted, setIsStarted] = useState(false);
    // const homepageVideo =
    //     localStorage.getItem("i18nextLng") === "en"
    //         ? homepageVideoEn
    //         : homepageVideoAr;
    const handlePlay = () => {
        setIsPause(false);
        setIsStarted(true);
    };
    return (
        <section className={styles.videoSection}>
            <div
                className={classnames("container", styles.videoSectionContent)}
            >
                <div className={styles.videoBlock}>
                    <ReactPlayer
                        width="100%"
                        height="auto"
                        url={newHomeVideoAr}
                        className={styles.videoSection__video}
                        volume={0.1}
                        controls={isStarted}
                        onPlay={() => setIsPause(false)}
                        onPause={() => setIsPause(true)}
                        playing={!isPause}
                        config={{
                            file: {
                                attributes: {
                                    poster: backgroundVideoImage,
                                },
                            },
                        }}
                    />
                    {isPause ? (
                        <button
                            className={styles.videoSection__button}
                            onClick={handlePlay}
                        >
                            <img
                                src={VideoButton}
                                className={styles.videoSection__button_img}
                                alt="play-button"
                            />
                        </button>
                    ) : null}
                </div>
            </div>
            {/* <Container fluid="xl" className={styles.videoSection__container}>
        <Row className="justify-content-center">
          <Col
            md={12}
            className={`${styles.videoSection__item} justify-content-center mr-10`}
          ></Col>
        </Row>
      </Container> */}
        </section>
    );
};

export default React.memo(VideoSection);
