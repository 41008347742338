import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Loader, PhoneInput, AlertBox } from "components";
import { ModalFooter } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addNewCPUser } from "store/actions/adminElements.actions";
import { useError, useFieldTouched } from "utils/forms";
import { phoneNumberRegExp, passwordExp } from "utils/validation";
import { invalidPhone, incorrectPassword } from "constants/messages";
import styles from "./styles.module.scss";

const userTypeOptions = [
  { name: "Admin", value: 2 },
  { name: "Director", value: 3 },
  { name: "Customer Services", value: 4 },
  { name: "Logistics Manager", value: 5 },
];

const NewControlPanel = () => {
  const dispatch = useDispatch();
  const { loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive = useSelector(({ alert }) => alert);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      mobile_number: "",
      email: "",
      user_type: {
        name: "",
        value: null,
      },
      employer: "",
      password: "",
      repeat_password: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      full_name: Yup.string().required("Required"),
      mobile_number: Yup.string()
        .matches(phoneNumberRegExp, invalidPhone)
        .required("Required"),
      email: Yup.string().email().required("Required"),
      user_type: Yup.object().shape({
        name: Yup.string().required("Required"),
        value: Yup.number().required("Required"),
      }),
      employer: Yup.string().required("Required"),
      password: Yup.string()
        .matches(passwordExp, incorrectPassword)
        .required("Required"),
      repeat_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: () => {
      const { full_name, mobile_number, email, user_type, employer, password } =
        formik.values;
      const obj = {
        name: full_name,
        email,
        mobile_number,
        user_type: user_type.value,
        password,
        employer,
      };
      dispatch(addNewCPUser.request(obj));
    },
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  return (
    <Loader loaded={loaded}>
      {isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger" />
      )}
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="full_name"
                    name="full_name"
                    placeholder="Full Name"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                  />
                  {getError("full_name")}
                </div>
                <div className="form-group">
                  <label className="control-label">Mobile Number</label>
                  <div>
                    <PhoneInput
                      boxClassName="w-100"
                      className="form-control w-100"
                      formik={formik}
                      name="mobile_number"
                      onFocus={setFieldTouched}
                    />
                  </div>
                  {getError("mobile_number")}
                </div>
                <div className="form-group">
                  <label className="control-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                  />
                  {getError("email")}
                </div>
                <div className={`${styles.ddUsertype} form-group`}>
                  <label className="control-label ">User Type</label>
                  <DropdownButton
                    variant="light"
                    id="dropdown-basic-button"
                    title={formik.values?.user_type?.name || "User Type"}
                  >
                    {userTypeOptions.map((element) => (
                      <Dropdown.Item
                        key={element.value}
                        onClick={() =>
                          formik.setFieldValue("user_type", element)
                        }
                      >
                        {element.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {getError("user_type")}
                </div>
                <div className="form-group">
                  <label className="control-label">Employer</label>
                  <input
                    type="text"
                    className="form-control"
                    id="employer"
                    name="employer"
                    placeholder="Employer"
                    value={formik.values.employer}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                  />
                  {getError("employer")}
                </div>
                <div className="form-group">
                  <label className="control-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                  />
                  {getError("password")}
                </div>
                <div className="form-group">
                  <label className="control-label">Repeat Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="repeat_password"
                    name="repeat_password"
                    placeholder="Repeat Password"
                    value={formik.values.repeat_password}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                  />
                  {getError("repeat_password")}
                </div>
              </div>
            </div>
            <ModalFooter>
              <button className={styles.ncp_btn}>Save</button>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Loader>
  );
};

export default NewControlPanel;
