import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { sizeToMB } from "utils/validation";
import { updatedFileTypes } from "constants/seasonsEvents";
import { MAX_FILE_SIZE } from "constants/forms";

const FileInput = ({ name, formik, accept, multiple = false }) => {
  const { t } = useTranslation();
  const input = useRef(null);

  const handleChange = ({ target: { files } }) => {
    if (
      Object.values(files)
        .map((el) => validateRequirement(el))
        .every((el) => el)
    )
      formik.setFieldValue(name, multiple ? Object.values(files) : files[0]);
  };

  const handleRemoveFile = () => {
    formik.setFieldValue(name, null);
  };

  const handleClick = () => input.current.click();

  const setFieldTouched = () => formik.setFieldTouched(name, true);

  const sizeToString = (size) => (size ? sizeToMB(size).toFixed(2) : "");

  const size = Array.isArray(formik.values[name])
    ? formik.values[name]?.map((i) => i.size && sizeToString(i.size)).join(", ")
    : sizeToString(formik.values[name]?.size);

  const hasTruthyElements = (element) => {
    if (!element) {
      return;
    } else if (element instanceof Array) {
      return element.length > 0;
    } else {
      return element;
    }
  };
  const validateRequirement = (file) => {
    let errorMessage = null;
    const checkArrayTypes = () => {
      const types = accept.replaceAll(".", "").replaceAll(",", "").split(" ");
      for (let type of types) {
        if (file.type?.includes(type)) {
          return true;
        }
      }
    };

    if (!file.type?.includes(accept.replace(".", "")) && !checkArrayTypes()) {
      errorMessage = t("messages.invalidFile");
    } else if (file.size && sizeToMB(file.size) > MAX_FILE_SIZE) {
      errorMessage = t("messages.fileSize");
    }
    formik.setFieldError(name, errorMessage);
    return errorMessage === null;
  };
  const showMessage = () => {
    const lang = localStorage.getItem("i18nextLng");
    const errorMessage = formik.errors[name] ? `${formik.errors[name]} |` : "";
    const fileType =
      updatedFileTypes[accept?.replace(".", "")] || updatedFileTypes[accept];
    let message = null;

    if (lang === "en") {
      message = `${errorMessage}  File (${fileType}) - Size (${MAX_FILE_SIZE}MB)`;
    } else {
      message = `${errorMessage} ملف (${fileType}) - الحجم (${MAX_FILE_SIZE}MB)`;
    }
    return message;
  };
  return (
    <div className={styles.inputWrapper} onClick={setFieldTouched}>
      <span className="mb-1">{t("messages.uploadFile")}</span>
      <div>
        <button
          className={classnames("btn", styles.button)}
          type="button"
          onClick={handleClick}
        >
          {t("buttons.choose")}
        </button>
        {hasTruthyElements(formik.values[name]) && (
          <button
            className={classnames("btn btn-md mx-2", styles.closeBtn)}
            type="button"
            onClick={handleRemoveFile}
          >
            ×
          </button>
        )}
        <p
          className={classnames(
            styles.message,
            formik.errors[name] && styles.error
          )}
        >
          {showMessage()}
        </p>
      </div>
      <label
        htmlFor={name}
        className="pt-2 d-flex align-items-center flex-wrap"
      >
        <p>
          {Array.isArray(formik.values[name])
            ? formik.values[name]
                ?.map((i) => i.name || i?.split("/")[2])
                .join(", ")
            : formik.values[name]?.name ||
              formik.values[name]?.split("/")[2] ||
              t("messages.noFile")}
        </p>
        {size && <p className="mx-1">({size} MB)</p>}
      </label>
      <input
        multiple={multiple}
        ref={input}
        className={styles.input}
        id={name}
        type="file"
        onChange={handleChange}
        accept={accept}
      />
    </div>
  );
};

FileInput.propTypes = {
  name: PropTypes.string,
  formik: PropTypes.object.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
};
export default FileInput;
