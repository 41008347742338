import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { calculateSum } from "utils/helpers";
import CategoriesChart from "../../CategoriesChart";
import { Link } from "react-router-dom";
import {
  mainElements,
  supportingElements,
} from "./../../CategoriesChart/constants";
import { SUPPORTING_ELEMENTS } from "../../../constants/paths";
import { MAIN_ELEMENTS } from "constants/paths";

const AdminGraphsContainer = ({
  withChart,
  data,
  title,
  children,
  allCategories,
}) => {
  const getLinkTitle = (title, link) => (
    <div className="col-12">
      <div className="page-title-box">
        <Link to={link} className={styles.title}>
          {title}
        </Link>
      </div>
    </div>
  );

  const dataChart = useMemo(() => {
    const result = !data.draft
      ? children.reduce(
          (acc, prev) => {
            acc.categories.push(prev.chartLabel);
            acc.data.push(
              prev.param
                ? calculateSum(data[prev.value][prev.param])
                : calculateSum(data[prev.value])
            );
            return acc;
          },
          { categories: [], data: [] }
        )
      : {
          data: {
            categories: children.map((elem) => elem.chartLabel),
            completed: data["completed"],
            draft: data["draft"],
          },
        };

    return result;
  }, [children, data]);

  return (
    <>
      {getLinkTitle("Main Elements", MAIN_ELEMENTS)}
      {withChart && (
        <>
          <div className="card">
            <div className="card-body">
              {mainElements(dataChart, allCategories).map((item, index) => {
                return (
                  <CategoriesChart
                    dataChart={item}
                    fileName={title}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          {getLinkTitle("Suporting elements", SUPPORTING_ELEMENTS)}
          <div className="card">
            <div className="card-body">
              {supportingElements(dataChart, allCategories).map(
                (item, index) => {
                  return (
                    <CategoriesChart
                      dataChart={item}
                      fileName={title}
                      key={index}
                    />
                  );
                }
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

AdminGraphsContainer.defaultProps = {
  withChart: false,
  withCards: true,
};

AdminGraphsContainer.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  children: PropTypes.array.isRequired,
  withChart: PropTypes.bool,
  withCards: PropTypes.bool,
};

export default AdminGraphsContainer;
