import React, { useState } from "react";
import cn from 'classnames';
import PropTypes from "prop-types";
import { ProfileTopBar, ProfileSideBar } from "components";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { InstructionsModal } from "components";
import { ReactComponent as PinkCircle } from 'assets/icons/ParticipatingElements/PinkCircle.svg';
import { ReactComponent as PinkCircleSmall } from 'assets/icons/ParticipatingElements/PinkCircleSmall.svg';
import { ReactComponent as PinkSmallStar } from 'assets/icons/ParticipatingElements/PinkSmallStar.svg';
import { ReactComponent as PinkStar } from 'assets/icons/ParticipatingElements/PinkStar.svg';
import { ReactComponent as TurquoiseSmallStar } from 'assets/icons/ParticipatingElements/TurquoiseSmallStar.svg';
import { ReactComponent as TurquoiseStar } from 'assets/icons/ParticipatingElements/TurquoiseStar.svg';
import { ReactComponent as YellowStar } from 'assets/icons/ParticipatingElements/YellowStar.svg';
import { ReactComponent as YellowCircle } from 'assets/icons/ParticipatingElements/YellowCircle.svg';


import classNames from 'classnames';


const ProfileLayout = ({ children }) => {
  const { openMenu } = useSelector(({ auth }) => auth);
  const newUser = localStorage.getItem("newUser", true);
  const [openInstructionModal, setOpenInstructionModal] = useState(!!newUser || false);

  return (
    <>
      <div className={cn(styles.profileLayout, {
        [styles.activeSidebar]: openMenu
      })}
      >
        <ProfileSideBar modalOpen={() => setOpenInstructionModal(true)} />
        <div className={styles.layoutWrapper}>
            <div className={classNames(styles.layoutBg)}>
              <PinkCircle className={styles.pinkCircle}/>
              <PinkCircleSmall className={styles.pinkCircleSmall}/>
              <PinkSmallStar className={styles.pinkSmallStar}/>
              <PinkStar className={styles.pinkStar}/>
              <TurquoiseSmallStar className={styles.turquoiseSmallStar}/>
              <TurquoiseStar className={styles.turquoiseStar}/>
              <YellowStar className={styles.yellowStar}/>
              <YellowCircle className={styles.yellowCircle}/>
            </div>
          <div className={styles.layoutContent}>
            <ProfileTopBar />
            {children}
          </div>
        </div>
      </div>
      <InstructionsModal modalOpen={openInstructionModal} modalClose={() => setOpenInstructionModal(false)} />
    </>
  );
};

ProfileLayout.propTypes = {
  children: PropTypes.node,
};

export default ProfileLayout;
