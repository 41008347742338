import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import styles from "./styles.module.scss";
const CancelModal = ({ isOpened, toggle, close, confirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={`modal-dialog-centered modal-md rounded`}
      isOpen={isOpened}
      toggle={toggle}
    >
      <div className={styles.ModalBox}>
        <div className={styles.ModalBoxHeader}>
          <p>{t("cancelModal.title")}</p>
        </div>
        <div className={styles.ModalBoxMain}>
          <p>{t("cancelModal.question")}</p>
        </div>
        <div className={styles.ModalBoxButtons}>
          <button className={styles.Cancel} onClick={close}>
            {t("cancelModal.no")}
          </button>
          <button className={styles.Confirm} onClick={confirm}>
            {t("cancelModal.yes")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
