import React from "react";
import Layout from "layouts/Admin";
import { useSelector } from "react-redux";
import { AdminDashboardStatistics, AdminSubmittedFormStatistics } from "components";
import { Loader, AlertBox } from "components";

const AdminStatistics = () => {
  const { loaded, error } = useSelector(({ adminElements }) => adminElements)
  const isAlertActive =  useSelector(({ alert }) => alert);

  return (
    <Layout title="Dashboard Statistics">
      <Loader loaded={loaded}>
        {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
        }
        <AdminDashboardStatistics/>
        <AdminSubmittedFormStatistics/>
      </Loader>
    </Layout>
  )
}

export default AdminStatistics;