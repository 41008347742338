import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Loader = ({ loaded, children, color = "dark" }) => {
  return (
    <div className={classnames(styles.loaderBox, { [styles.loaderActive]: !loaded })}>
      {children}
      {!loaded &&
        <div className={styles.loader}>
          <div
            className={`spinner-border avatar-lg text-${color} ${styles.spinner}`}
            role="status"
          ></div>
        </div>
      }
    </div>
  );
};

Loader.propTypes = {
  loaded: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string
};

export default Loader;
