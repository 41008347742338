import axiosInstance from "./interceptor";
import { vsPath } from "utils/helpers";

export const ElementsApi = {
  getElements: () => axiosInstance.get("/user/user-element-types"),
  getInterests: params => axiosInstance.get("/interests", { params }),
  createInterest: type => axiosInstance.post(`/${type}`),
  editInterest: ({ id, type, data }) =>
    axiosInstance.post(`/${type}/${id}`, data),
  getCountries: () => axiosInstance.get("/countries-of-residence"),
  getTalentCategories: () => axiosInstance.get("/talent-categories"),
  getTemporaryWorkCategories: () => axiosInstance.get("/temporary-work-categories"),
  getInterest: ({ id, type }) => axiosInstance.get(`/${type}/${id}`),
  getRegions: () => axiosInstance.get("/saudi-regions"),
  getLanguages: () => axiosInstance.get("/languages"),
  getEventCategories: () => axiosInstance.get("/event-categories"),
  getServiceCategories: () => axiosInstance.get("/service-categories"),
  getProductCategories: () => axiosInstance.get("/store-product-categories"),
  getTemporaryWorkCaterogries: () => axiosInstance.get('/temporary-work-categories'),
  getFoodCategories: () => axiosInstance.get("/food-categories"),
  getVenueSiteCategories: () => axiosInstance.get(`/${vsPath}-categories`),
  getVenueSiteTopographies: () => axiosInstance.get(`/${vsPath}-topographies`),
  getVenueSiteLandTypes: () => axiosInstance.get(`/${vsPath}-land-types`),
  // getCities: region_id => axiosInstance.get("/cities", { params: region_id && { region_id } }),
  // getVenueLandTypes: () => axiosInstance.get("/venue-land-types"),
  // getCities: region_id => axiosInstance.get("/cities", { params: region_id && { region_id } }),
  getCities: (params = null) => axiosInstance.get("/cities", { params }),
  deleteInterest: ({ id, type }) => axiosInstance.delete(`/${type}/${id}`),
  createMember: id => axiosInstance.post(`talent-personal-info/${id}`),
  deleteMember: id => axiosInstance.delete(`talent-personal-info/${id}`),
  getStoreFormats: () => axiosInstance.get("/store-formats"),
  getRestaurantFormats: () => axiosInstance.get("/restaurant-formats"),
  getSaudiSeasons: () => axiosInstance.get("/saudi-seasons"),
  getSaudiEvents: () => axiosInstance.get("/saudi-events"),
  getUserRequests: () => axiosInstance.get("/user-requests"),
  getUserRequest: (id) => axiosInstance.get(`/user-requests/${id}`),
  getSaudiSeasonEvent: ({type, id}) => axiosInstance.get(`/${type}/${id}`),
  getDashboardElementsRequest: () => axiosInstance.get('/dashboard'),
  getDisabiltyTypes: () => axiosInstance.get('/disability-types'),
};
