import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { historyHeadings } from "./constants";
import { useTranslation } from "react-i18next";
import { statuses } from "constants/seasonsEvents";
import { Link } from "react-router-dom";
import { Context } from "context";
import { dateFormatter } from "utils/helpers";

const HistoryTable = ({ tableBody }) => {
  const { t } = useTranslation();
  const { rtl } = useContext(Context);
  const type = tableBody[0]?.season ? "season" : "event";
  return (
    <div className="table-responsive">
      <div className={styles.interestTable}>
        <table className="table table-centered table-borderless mb-0">
          <thead>
            <tr>
              {historyHeadings.map(({ value }, index) =>
                value !== "type" ? (
                  <th key={index}>{t(`interests.${value}`)}</th>
                ) : (
                  <th key={index}>{t(`interests.${type}_name`)}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((request, i) => (
              <tr key={i}>
                {historyHeadings.map(({ key }) => {
                  switch (key) {
                    case "request_number":
                      return (
                        <td
                          className={styles.interestTableUnderlined}
                          key={key}
                        >
                          <Link to={`/saudi/season/${request.id}/review`}>
                            #{request[key]}
                          </Link>
                        </td>
                      );
                    case "status":
                      return (
                        <td
                          className={styles[statuses[request.status]]}
                          key={key}
                        >
                          {t(`requests.statuses.${request.status}`)}
                          {request.other_status && request.status === 4
                            ? ` - ${request.other_status}`
                            : ""}
                        </td>
                      );
                    case "name":
                      return <td key={key}>{request[type][key]}</td>;
                    case "created_at":
                      return (
                        <td key={key}>{dateFormatter(rtl, request[key])}</td>
                      );
                    case "request_details":
                      return (
                        <td key={key}>
                          <Link to={`/saudi/season/${request.id}/review`}>
                            <span className="action-icon">
                              <i className="mdi mdi-eye"></i>
                            </span>
                          </Link>
                        </td>
                      );
                    default:
                      return <td key={key}>{request[key]}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoryTable;
