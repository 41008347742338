import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";

const LicenseModal = ({ isOpened, toggle, type }) => {
  const { t } = useTranslation();

  const links = {
    municipality: (
      <a
        href={t("license.links.municipality.href")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {` ${t("license.links.municipality.title")} `}
      </a>
    ),
    commercial: (
      <a
        href={t("license.links.commercial.href")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {` ${t("license.links.commercial.title")} `}
      </a>
    ),
    social: (
      <a
        href={t("license.links.social.href")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {` ${t("license.links.social.title")} `}
      </a>
    )
  };

  return (
    <Modal
      className="modal-dialog-centered modal-md"
      isOpen={isOpened}
      toggle={toggle}
    >
      <div className={styles.licenseModalBox}>
        <button onClick={toggle}>&times;</button>
        <div className={styles.licenseModalBoxHeader}>
          <p>{t("license.required")}</p>
        </div>
        <ol>
          {[
            ...t(`license.${type}.modal.items`, {
              returnObjects: true
            })
          ]?.map((item, index) => (
            <li key={index}>
              {[...item].map((i, idx) => (
                <span key={idx}>{i.text || links[i.link]}</span>
              ))}
            </li>
          ))}
        </ol>
      </div>
    </Modal>
  );
};

LicenseModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default LicenseModal;
