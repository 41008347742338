import React, { useEffect } from "react";
import {Link} from 'react-router-dom';
import Logo from "assets/images/new-logo.svg";
import { Loader, AlertBox } from "components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError, useFieldTouched } from "utils/forms";
import { useDispatch, useSelector } from "react-redux";
import { incorrectEmail, resetLink } from "constants/messages";
import { forgotPassword, resetAuthState } from "store/actions/auth.actions";
import { ADMIN_LOGIN } from 'constants/paths';
import styles from "./styles.module.scss";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { loaded, loginError: error, success } = useSelector(
    state => state.auth
  );
  const isAlertActive =  useSelector(({ alert }) => alert);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().email(incorrectEmail).required("Required")
    }),
    onSubmit: values => dispatch(forgotPassword.request(values))
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  useEffect(() => {
    return () => dispatch(resetAuthState());
  }, [dispatch]);

  return (
    <div className={styles.forgotPasswordForm}>
      <Loader loaded={loaded}>
        <Link to={ADMIN_LOGIN} className={styles.forgotPasswordFormHeader}>
          <img src={Logo} alt="" />
          <p>Enter your email to reset your password</p>
        </Link>

        {
          isAlertActive &&
          <AlertBox
            error={error}
            additionalStyles='alert-danger mt-4'
          />
        }
        {
          success &&
          <AlertBox
            message={resetLink}
            additionalStyles='alert-success mt-4'
            link={ADMIN_LOGIN}
          />
        }
        <div className={styles.forgotPasswordFormMain}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <label forHtml="email">Email</label>
              <input
                id="email"
                type="email"
                className="form-control"
                name="email"
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("email")}
            </div>

            <button type="submit" className={styles.submitBtn}>
              Send
            </button>
          </form>
        </div>
      </Loader>
    </div>
  );
};

export default ForgotPasswordForm;
