export const checkedValues = [
  "booth_detail",
  "profile_detail",
  "company_detail",
  "project_detail",
  "store_detail",
  "restaurant_detail",
];

export const EMAIL = "email";
export const INDIVIDUAL = 1;
export const TEMPORARY_WORKS = "temporary-works";
export const ADDITIONAL_NUMBER = "phone_number";
