import React from "react";
import Layout from "layouts/Admin";
import { ChangePassword } from "components";

const AdminChangePassword = () => (
  <Layout title="Change Password">
    <ChangePassword />
  </Layout>
);

export default AdminChangePassword;
