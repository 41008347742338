import { createRequestTypes, createActionTypes, createSyncAction } from "utils/redux";

export const GET_SAUDI_SEASONS_EVENTS = createRequestTypes("GET_SAUDI_SEASONS_EVENTS");
export const GET_SAUDI_SEASON_EVENT = createRequestTypes("GET_SAUDI_SEASON_EVENT");
export const GET_SAUDI_SEASON_EVENT_ELEMENT = createRequestTypes("GET_SAUDI_SEASON_EVENT_ELEMENT");
export const GET_REGIONS = createRequestTypes("GET_REGIONS");
export const GET_REQUEST_INTERESTS = createRequestTypes("GET_REQUEST_INTERESTS");
export const GET_FILE = createRequestTypes("GET_FILE");

export const getSaudiSeasonsEvents = createActionTypes(GET_SAUDI_SEASONS_EVENTS);
export const getSaudiSeasonEvent = createActionTypes(GET_SAUDI_SEASON_EVENT);
export const getRegions = createActionTypes(GET_REGIONS);
export const getSaudiSeasonEventElement = createActionTypes(GET_SAUDI_SEASON_EVENT_ELEMENT);
export const getRequestInterests = createActionTypes(GET_REQUEST_INTERESTS);
export const getFile = createActionTypes(GET_FILE);

export const MANAGE_ELEMENT = "MANAGE_ELEMENT";
export const MANAGE_ELEMENT_SET = "MANAGE_ELEMENT_SET";
export const MANAGE_ELEMENT_REMOVE = "MANAGE_ELEMENT_REMOVE";
export const ELEMENT = "ELEMENT";
export const ELEMENT_SET = "ELEMENT_SET";
export const ELEMENT_REMOVE = "ELEMENT_REMOVE";

export const manageElement = createSyncAction(MANAGE_ELEMENT);
export const element = createSyncAction(ELEMENT);
