import React, { useEffect } from "react";
import { Button, ModalFooter } from "reactstrap";
import { Dropdown, DropdownButton} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Loader, AlertBox } from "components";
import { useError, useFieldTouched } from "utils/forms";
import { arabicChars, englishChars } from "utils/validation";
import { addApplicationRequirement, editApplicationRequirement, getApplicationRequirement } from "store/actions/adminElements.actions";
import Switch from "react-switch";

const typeOptions = [
  { name: "PDF", value: 0 },
  { name: "Excel", value: 1 },
  { name: "Text", value: 2 },
  { name: "Image", value: 3 },
];

const AddEditApplicationRequirement = ({ id }) => {
  const { applicationRequirement, loaded, error } = useSelector(({ adminElements }) => adminElements )
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch();

  const initalValues = {
    name_ar: id ? applicationRequirement?.name_ar : "",
    name_en: id ? applicationRequirement?.name_en : "",
    description_ar: id ? applicationRequirement?.description_ar : "",
    description_en: id ? applicationRequirement?.description_en : "",
    type: id ? applicationRequirement?.type : "",
    hidden: id ? applicationRequirement.hidden : 0
  }

  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name_ar: Yup.string().matches(arabicChars, "Arabic only").required('Required') ,
      name_en: Yup.string().matches(englishChars, "English only").required('Required'),
      description_ar: Yup.string().matches(arabicChars, "Arabic only").required('Required'),
      description_en: Yup.string().matches(englishChars, "English only").required('Required'),
      type: Yup.string().required("Required"),
      hidden: Yup.number().required("Required")
    }),
    onSubmit: () => {
      id ?
        dispatch(editApplicationRequirement.request({id, requirement: formik.values}))
        :
        dispatch(addApplicationRequirement.request(formik.values));
    }
  });

  useEffect(() => {
    if(id){
      dispatch(getApplicationRequirement.request(id))
    }
  },[ dispatch, id ])

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="name_ar" className="control-label">
                    Name | AR
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name_ar"
                    name="name_ar"
                    placeholder="Name | AR"
                    onChange={formik.handleChange}
                    value={formik.values.name_ar || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("name_ar")}
                </div>

                <div className="form-group">
                  <label htmlFor="name_en" className="control-label">
                    Name | EN
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name_en"
                    name="name_en"
                    placeholder="Name | EN"
                    onChange={formik.handleChange}
                    value={formik.values.name_en || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("name_en")}
                </div>

                <div className="form-group">
                  <label htmlFor="description_ar" className="control-label">
                    Description | AR
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description_ar"
                    name="description_ar"
                    placeholder="Description | AR"
                    onChange={formik.handleChange}
                    value={formik.values.description_ar || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("description_ar")}
                </div>

                <div className="form-group">
                  <label htmlFor="description_en" className="control-label">
                    Description | EN
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description_en"
                    name="description_en"
                    placeholder="Description | EN"
                    onChange={formik.handleChange}
                    value={formik.values.description_en || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("description_en")}
                </div>

                <div className="form-group">
                  <label htmlFor="type" className="control-label">
                    Type
                  </label>
                  <DropdownButton
                    variant="light"
                    id="dropdown-basic-button"
                    title={formik.values?.type || "Type"}
                  >
                    {typeOptions.map(element => (
                      <Dropdown.Item
                        key={element.value}
                        onClick={() => formik.setFieldValue("type", element.name)}
                      >
                        {element.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {getError("type")}
                </div>
                <div className="d-flex flex-column form-group">
                  <label className="control-label">Hide</label>
                  <Switch
                    checked={Boolean(formik.values.hidden)}
                    onChange={item => formik.setFieldValue("hidden", +item)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={40}
                    height={20}
                    activeBoxShadow=""
                  />
                  {getError('hidden')}
                </div>
              </div>
            </div>
            <ModalFooter>
              <Button type="submit" className="btn-paua">
                Save
              </Button>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Loader>
  );
}

AddEditApplicationRequirement.propTypes = {
  id: PropTypes.string
}

export default React.memo(AddEditApplicationRequirement);
