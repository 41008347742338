import React from "react"
import Layout from "layouts/Admin";
import { SeasonEventRequests } from "components";
import { useHistory } from "react-router-dom";
import { SEASONS_REQUEST, EVENTS_REQUEST } from "constants/forms";

const AdminEventsSeasonsRequests = () => {
  const history = useHistory();

  const getType = history.location.pathname.includes(SEASONS_REQUEST) ? SEASONS_REQUEST : EVENTS_REQUEST

  return (
    <Layout title={`${getType === SEASONS_REQUEST ? "Seasons" : "Events"} Requests`}>
      <SeasonEventRequests type={getType}/>
    </Layout>
  )
}

export default AdminEventsSeasonsRequests