import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader, TableComponent, ChangeStatusModal } from "components";
import { useParams } from "react-router-dom";
import { getUsersRequests } from "store/actions/adminElements.actions";
import { initialValues, statuses } from "./constants";
import { ADMIN_VIEW_REQUEST } from "constants/paths";
import { REQUEST_DETAILS } from "./constants";
import { useFormik } from "formik";
import { compile } from "path-to-regexp";
import moment from "moment";

const DATE_FORMAT = "l";

const AdminViewUsersRequests = () => {
  const [modalBody, setModalBody] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [typeSelected] = useState({});
  const dispatch = useDispatch();
  const { id: typeId } = useParams();

  const { elementsUsersRequests, loaded } = useSelector(
    ({ adminElements }) => adminElements
  );

  const openModal = (item) => {
    setModalBody(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalBody(null);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      dispatch(getUsersRequests.request(setParams()));
    },
  });
  const currentType = elementsUsersRequests?.data?.[0]?.type;
  const setParams = () => {
    return {
      params: Object.keys(formik.values).reduce((params, currVal) => {
        if (formik.values[currVal]) {
          params[currVal] =
            formik.values[currVal]?.value ||
            formik.values[currVal]?.id ||
            formik.values[currVal];
        } else if (typeSelected.format_id && typeSelected.type_id) {
          params[`${typeSelected.format}_format_id`] = typeSelected.format_id;
          params["element_type_id"] = typeSelected.type_id;
        } else if (typeSelected.type_id) {
          params["element_type_id"] = typeSelected.type_id;
        } else if (typeSelected.format_id) {
          params[`${typeSelected.format}_format_id`] = typeSelected.format_id;
        }
        params[`${currentType}_id`] = typeId;
        return params;
      }, {}),
    };
  };

  const tableHead = [
    {
      name: "#",
      key: "#",
    },
    {
      name: "Request Number",
      key: "request_number",
    },
    {
      name: "Date & Time",
      key: "created_at",
    },
    {
      name: "Type",
      key: "type",
    },
    {
      name: "Season / Event Name",
      key: "season_name",
    },
    {
      name: "Element Name | En",
      key: "element_type_en",
    },
    {
      name: "Element Format | En",
      key: "element_format_en",
    },
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ season_event_id, id }) => {
        window.open(
          compile(ADMIN_VIEW_REQUEST)({
            type: REQUEST_DETAILS,
            typeId: season_event_id,
            id,
          }),
          "_blank"
        );
      },
    },
    {
      name: "Change Status",
      key: "action-btn",
      class: "mdi mdi-pencil",
      onClick: (item) => openModal(item),
    },
  ];

  const tableBody = elementsUsersRequests?.data?.map((el) => ({
    id: el?.id,
    request_number: el?.request_number || "",
    created_at: el?.created_at ? moment(el.created_at).format(DATE_FORMAT) : "",
    type: el?.type + "s" || "",
    season_name: el?.season?.name || el?.event?.name || "",
    element_type_en: el?.participating_element?.element_type?.name_en || "",
    element_format_en: el?.participating_element?.element_format?.name_en || "",
    status: statuses[el?.status.status] || "",
    status_info: {
      status: el?.status?.status,
      other_status: el?.status?.other_status,
    },
    season_event_id: el?.season_id || el?.event_id,
  }));

  useEffect(() => {
    dispatch(getUsersRequests.request({ typeId }));
  }, [dispatch, typeId]);

  return (
    <Loader loaded={loaded}>
      <div className="col-12"></div>
      <div className="card">
        <div className="card-body">
          <TableComponent tableHead={tableHead} tableBody={tableBody} />
          {modalOpen && (
            <ChangeStatusModal
              modalOpen={modalOpen}
              closeModal={closeModal}
              modalBody={modalBody}
              params={setParams()}
            />
          )}
        </div>
      </div>
    </Loader>
  );
};

export default React.memo(AdminViewUsersRequests);
