import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import YellowStar from "../../assets/icons/yellowStarBG.svg";
import orangeStar from "../../assets/icons/orangeStar.svg";
import turquoiseStar from "../../assets/icons/turquoiseStar.svg";
import turquoiseSemiCircle from "../../assets/icons/turquoiseSemiCircle.svg";
import yellowSemiCircle from "../../assets/icons/yellowSemiCircle.svg";
import classnames from "classnames";
import {
    useMediaQuery,
    useScrollPosition,
    useWindowHeight,
} from "../../utils/hooks";
import { useState } from "react";

const getAnimationElements = (type) => {
    switch (type) {
        case "venuesAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.venuesAnimation
                    )}
                >
                    <img
                        src={YellowStar}
                        className={styles.venuesYellowStar}
                        alt=""
                    />
                </div>
            );
        }
        case "workAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.workAnimation
                    )}
                >
                    <img src={YellowStar} className={styles.rightStar} alt="" />
                    <img
                        src={turquoiseStar}
                        className={styles.leftStar}
                        alt=""
                    />
                </div>
            );
        }
        case "foodAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.foodAnimation
                    )}
                >
                    <img
                        src={turquoiseSemiCircle}
                        className={styles.turquoiseSemiCircle}
                        alt=""
                    />
                    <img src={YellowStar} className={styles.smallStar} alt="" />
                </div>
            );
        }
        case "productsAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.productsAnimation
                    )}
                >
                    <div className={styles.pinkCircle} />
                    <img
                        src={YellowStar}
                        className={styles.productsFirstStar}
                        alt=""
                    />
                    <img
                        src={orangeStar}
                        className={styles.productsSecondStar}
                        alt=""
                    />
                    <img
                        src={turquoiseStar}
                        className={styles.productsThirdStar}
                        alt=""
                    />
                </div>
            );
        }
        case "logisticAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.logisticAnimation
                    )}
                >
                    <div className={styles.logisticPinkCircle} />
                    <img
                        src={turquoiseStar}
                        className={styles.turquoiseLogisticStar}
                        alt=""
                    />
                    <img
                        src={yellowSemiCircle}
                        className={styles.logisticYellowCircle}
                        alt=""
                    />
                </div>
            );
        }
        case "talentsAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.talentsAnimation
                    )}
                >
                    <img
                        src={turquoiseStar}
                        className={styles.turquoiseTalentsStar}
                        alt=""
                    />
                </div>
            );
        }
        case "eventAnimation": {
            return (
                <div
                    className={classnames(
                        styles.animationWrapper,
                        styles.eventAnimation
                    )}
                >
                    <img
                        src={turquoiseStar}
                        className={styles.turquoiseEventStar}
                        alt=""
                    />
                </div>
            );
        }

        default: {
            return null;
        }
    }
};
const AnimatedCard = ({ backgroundColor, children, type, onClick, index }) => {
    const elementRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const isMobile = useMediaQuery("(max-width: 600px)");
    const { t } = useTranslation();
    const data = t("cardsSection.titles", { returnObjects: true });

    const scroll = useScrollPosition();
    const height = useWindowHeight();

    useEffect(() => {
        const cardDimension = elementRef.current?.getBoundingClientRect();
        const cardBottom = cardDimension.bottom;
        const cardTop = cardDimension.top;
        setIsActive(cardTop >= 0 && cardBottom < height);
    }, [scroll, height]);

    return (
        <div
            className={classnames(styles.animatedCardWrapper, backgroundColor, {
                [styles.mobileAnimation]: isMobile && isActive,
            })}
            onClick={onClick}
            ref={elementRef}
        >
            <div className={styles.animatedCardText}>{data[index].text}</div>
            {children}
            {getAnimationElements(type)}
        </div>
    );
};

export default AnimatedCard;
