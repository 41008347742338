import React, { useState, useEffect } from "react";
import { getUser, editUserInfo, resetAuthState } from "store/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import Apartment from "assets/icons/apartment.svg";
import Check from "assets/icons/check-circle.svg";
import { Loader, AlertBox } from "components";
import { useTranslation } from "react-i18next";
import Person from "assets/icons/person.svg";
import { INDIVIDUAL, COMPANY } from "constants/forms";
import classnames from "classnames";
import {
  phoneNumberWithoutCountryCode,
} from "utils/validation";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { objWithoutFalsy, areObjectsEquals } from "utils/helpers"

// const IDENTITYTYPES = [
//   {id: 1, name: "nationalId", value: 1},
//   {id: 2, name: "iqama", value: 2}
// ]

const ProfileInput = ({ field, form, ...props }) => (
  <input {...field} {...props} value={field.value || ""}/>
)

const withAfter = (Component) => {
  return (props) => (
    <label className={styles.withAfter}>
      <Component {...props}/>
    </label>
  )
};

const AfteredInput = withAfter(ProfileInput);

const ProfileInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ editingProfile, setEditingProfile ] = useState(false);
  const { user, loaded, error, success } = useSelector(state => state.auth);
  const isAlertActive =  useSelector(({ alert }) => alert);

  const {
    full_name,
    company_name,
    user_type,
    email,
    mobile_number,
    identity_type,
    created_at,
    ...data
  } = user;

  const FIELDS_DATA = {
    full_name: {
      component: ProfileInput,
      lang:  user_type === COMPANY ? "company_name" : "full_name"
    },
    mobile_number: {
      component: AfteredInput,
      lang: "mobile_number"
    },
    email: {
      component: ProfileInput,
      lang: "email"
    }
  }
  useEffect(() => {
    dispatch(getUser.request());
    return () => {
      dispatch(resetAuthState())
    }
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .required(t("messages.required"))
      .test("len", t("messages.enterFullName"), val =>{
        if(user_type === COMPANY) return true;
        return val
          ? val?.trim().split(" ").length > 1
          : true;
        })
      .test('type', t("messages.lettersOnly"), value =>
        value?.trim().split(' ').map(word =>
          word.split('').some(letter =>
            Object.is(+letter, NaN)
          )
        ).every(res => res === true)
      ),
    email: Yup.string().email(t("messages.invalidEmail")).required(t("messages.required")),
    mobile_number: Yup.string().required(t("messages.required"))
    .matches(phoneNumberWithoutCountryCode, t("messages.invalidPhone"))
  });


  const successMessage = () => (
    <div className={classnames(styles.messageBox)}>
      <img src={Check} alt="" />
      <p>{t("messages.profileUpdateSuccess")}</p>
      <span className={styles.closeBtn} onClick={() => dispatch(resetAuthState())} >&times;</span>
    </div>
  );

  const editableData = {
    full_name: full_name || company_name || "",
    email,
    mobile_number: mobile_number?.slice(3),
    // identity_type,
  };
  return(
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      {success && successMessage()}
      {loaded && <Formik
        initialValues={{
          ...editableData,
          // identity_type,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const data = values;
          data.mobile_number = '966'+values.mobile_number
          const {full_name, ...rest} = data;

          if(user_type === COMPANY){
            rest.company_name = full_name;
            dispatch(editUserInfo.request(objWithoutFalsy(rest)))
          }
          else{
            dispatch(editUserInfo.request(objWithoutFalsy(data)))
          }
          setEditingProfile(false)
        }}
        enableReinitialize
      >
        {(props) => (
          <>
          <Form>
            <div className={styles.info}>
              <div className={styles.infoHeader}>
                <div className={styles.infoHeaderAvatar}>
                  <img src={user_type === INDIVIDUAL ? Person : Apartment} alt="" />
                </div>
                <p className={styles.userType}>{t(`signup.userTypes.${user_type}`)}</p>
              </div>
              <div className={styles.infoHeaderUser}>
                {Object.keys(editableData).map(kData => (
                  <div className={styles.infoItem} key={kData}>
                    <p className={styles.property}>{ t(`profile.${FIELDS_DATA?.[kData]?.lang || kData}`)}</p>
                    {/* { createElement(kData, props) } */}
                    <Field
                      name={kData}
                      component={FIELDS_DATA?.[kData]?.component}
                      disabled={!editingProfile}
                      className={classnames(styles.editableField, {[styles.saveableField]: editingProfile})}
                    />
                    {props.errors[kData] && <small className={styles.errorMessage}>{props.errors[kData]}</small>}
                    </div>
                ))}
                {Object.entries(data)?.map(([k, v]) => (
                  <div key={k} className={styles.infoItem}>
                    <label className={styles.property}>{t(`profile.${k}`)}</label>
                    <input value={v} disabled className={styles.editableField}/>
                  </div>
                ))}
              </div>
            </div>

          </Form>
          <div className={styles.saveBtnContainer}>
            {editingProfile ? (
              <button
                type="subtmit"
                onClick={props.handleSubmit}
                className={classnames(styles.profileBtn, {[styles.editBtn]: editingProfile } )}
                disabled={areObjectsEquals(props.initialValues, props.values)}
                >
                  {t("profile.save")}
                </button>
            ) : (
              <button
                type="button"
                onClick={() => setEditingProfile(true)}
                className={styles.profileBtn}
                >
                  {t("profile.edit")}
              </button>
            )}
          </div>
          </>
        )}
      </Formik>}
    </Loader>
  )
};

export default ProfileInfo;
