import React, { useEffect } from "react";
import Layout from "layouts/Admin";
import { useSelector } from "react-redux";
import { AdminCategoriesStatistics } from "components";
import { Loader, AlertBox } from "components";
import { getAllCategories } from "../../store/actions/adminElements.actions";
import { useDispatch } from "react-redux";

const AdminElementsCategories = () => {
  const dispatch = useDispatch();
  const { loaded, error, allCategories } = useSelector(
    ({ adminElements }) => adminElements
  );
  const isAlertActive = useSelector(({ alert }) => alert);

  useEffect(() => {
    dispatch(getAllCategories.request());
  }, [dispatch]);

  return (
    <Layout title="Elements Categories Statistics">
      <Loader loaded={loaded}>
        {isAlertActive && (
          <AlertBox error={error} additionalStyles="alert-danger" />
        )}
        <AdminCategoriesStatistics allCategories={allCategories} />
      </Loader>
    </Layout>
  );
};

export default AdminElementsCategories;
