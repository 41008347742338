import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import classnames from "classnames";
import styles from "./styles.module.scss";

const ElementsFilter = ({ typeSelected, data, filteredCategoriesType, formik, resetData, otherFilteredCategoriesTypes }) => {

  const generateInput = (item, id) => {
    switch(item.field){
      case "dropdown": 
        return (
            <div className="mb-3 ml-3" key={id}>
              <DropdownButton
                variant="light"
                id="dropdown-basic-button"
                title={ formik.values[item.value]?.name || item.name }
                className={styles.dropdown}
              >
                {
                  (item.data ? item.data : data[item.key])?.map((element, index) => (
                    <Dropdown.Item 
                      key={index}
                      onClick={() => formik.setFieldValue(item.value, element)}
                    >
                      {element.name}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
              {formik?.errors?.[item.value] && <div className={styles.errorMessage} >{formik?.errors?.[item.value]}</div> }
            </div>
        )
        case "number":
          return(
            <div className={styles.input} key={id}>
              <div className={styles.input_hidden} aria-hidden="true">
                {item.name}
              </div>
              <input 
                placeholder={item.name}
                className={classnames(styles.input_field, "form-control")}
                type='text'
                pattern="[0-9]*"
                name={item.value}
                value={formik.values[item.value]}
                onChange={(event) => {
                  const value = (event.target.validity.valid) ? event.target.value : formik.values[item.value]
                  formik.setFieldValue(item.value,value)
                }}
              />
              {formik?.errors?.[item.value] && <div className={styles.errorMessage} >{formik?.errors?.[item.value]}</div> }
            </div>
          )
        case "text":  
          return(
            <div className={styles.input} key={id}>
              <div className={styles.input_hidden} aria-hidden="true">
                {item.name}
              </div>
              <input 
                placeholder={item.name}
                className={classnames(styles.input_field, "form-control")}
                type={item.field}
                name={item.value}
                value={formik.values[item.value]}
                onChange={formik.handleChange}
              />
              {formik?.errors?.[item.value] && <div className={styles.errorMessage} >{formik?.errors?.[item.value]}</div> }
            </div>
          )
        case "date":
          return(
            <div className={styles.input} key={id}>
              <div className={styles.input_hidden} aria-hidden="true">
                {item.name}
              </div>
              <input 
                className={classnames(styles.input_field, "form-control")}
                placeholder={item.name}
                type="text"
                name={item.value}
                onFocus={(e) => e.currentTarget.type = "date"}
                value={formik.values[item.value]}
                onChange={formik.handleChange}
              />
            </div>
          )
      default: 
        return null  
    }
  }

  return (
    <>
      <div className="col-12">
        <div className="page-title-box">
          <h4 className="page-title">Filters</h4>
        </div>
      </div>
      <div className="card">
        <div className="card-body pl-0"> 
          <form onSubmit={formik.handleSubmit} className="d-flex flex-wrap">
            {
              otherFilteredCategoriesTypes?.map((item, index) => generateInput(item, index)) 
            }
            { 
              filteredCategoriesType[typeSelected.type]?.map((item, index) => generateInput(item, index)) 
            }
            <div className="w-100 d-flex justify-content-end">
              <button type="submit" className="btn btn-default btn-paua">Get Data</button>
              <button type="button" className="ml-2 btn btn-default btn-paua" onClick={resetData}>Clear</button>
            </div>
        </form>
        </div>   
      </div>
    </> 
  )   
}


export default ElementsFilter