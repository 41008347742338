import React from "react"
import Layout from "layouts/Admin";
import { MediaCenterImagesCarouselImagesHistory } from "components";

const AdminMediaCenterCarouselImages = () => {
  return (
    <Layout title="Carousel Images">
      <MediaCenterImagesCarouselImagesHistory/>
    </Layout>
  )
}

export default AdminMediaCenterCarouselImages;