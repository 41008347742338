import {
  UNDER_PROGRESS, 
  ACCEPTED, REQUIRES_UPDATES, 
  REJECTED, 
  OTHER,  
  RESTAURANT_OF_CAFE, 
  FOOD_TRUCK, 
  FOOD_AND_DRINKS_PROVIDER, 
  STORE,
  POP_UP_STORES, 
  PERSONAL_STORES, 
  TALENTS, 
  TEMPORARY_WORK, 
} from "constants/forms"

const RESTAURANT_FORMAT_ID = "fe5069f2-0fd6-41ab-8c50-599671f98df9";
const FOOD_TRUCK_FORMAT_ID = "7aaf1529-b122-4c1c-9e94-633ae66d79ff";
const FOOD_BEVERAGES_FORMAT_ID = "8262d47a-d01c-4e6a-9475-ef6e2fb5b176"
const STORE_FORMAT_ID = "f2300501-dafc-4aa5-8925-1496f0a2fecd";
const POP_UP_STORE_FORMAT_ID = "27612fae-aded-4b83-ae1e-249c681351cb";
const PERSONAL_STORE_FORMAT_ID = "b41ad0aa-9332-439f-8eb2-a29b012d7b71";
const TALENTS_FORMAT_ID = "0472cebe-9923-497b-9a64-09d3c2bbb943";
const TEMPORARY_WORK_FORMAT_ID = "66b1946e-e4c4-4254-8ce3-fdcb0b0726f3";
const STORE_FORMAT = "store";
const RESTAURANTS_FORMAT = "restaurant";

export const initialValues = {
  "name": "",
  "status": "",
  "start_date": "",
  "request_number": "",
  "end_date": "",
  "food_category_id" : "",
  "region_id": "",
  "is_other_branches": "",
  "num_branches_in_saudi": "",
  "branch_region_id" : "",
  "average_workers_per_branch" : "",
  "average_bill_per_person" : "",
  "establishment_date" : "",
  "is_participated_in_prev_events" : "",
  "num_of_worker_per_branch" : "",
  "product_category_id" : "",
  "min_price" : "",
  "max_price" : "",
  "category_id" : "",
  "submission_type" : "",
  "birthdate" : "",
  "gender" : "",
  "nationality_id" : "",
  "social_status" : "",
  "special_needs":"",
  "country_id" : "",
  "identity_type" : "",
  "worked_before" : "",
  "language" : "",
  "level" : "",
}

export const statuses = {
  0: UNDER_PROGRESS,
  1: ACCEPTED,
  2: REQUIRES_UPDATES,
  3: REJECTED,
  4: OTHER,
  5: "Cancelled"
}

export const REQUEST_DETAILS = "request-details"

export const elementsType = [
  {
    name: "Restaurant or Cafe", 
    type: RESTAURANT_OF_CAFE, 
    format_id: RESTAURANT_FORMAT_ID,
    format: RESTAURANTS_FORMAT
  },
  {
    name: "Food Truck", 
    type: FOOD_TRUCK, 
    format_id: FOOD_TRUCK_FORMAT_ID,
    format: RESTAURANTS_FORMAT
  },
  {
    name: "Food & Beverages Provider", 
    type: FOOD_AND_DRINKS_PROVIDER, 
    format_id: FOOD_BEVERAGES_FORMAT_ID,
    format: RESTAURANTS_FORMAT
  },
  {
    name: "Store", 
    type: STORE, 
    format_id: STORE_FORMAT_ID,
    format: STORE_FORMAT
  },
  {
    name: "Pop Up Stores", 
    type: POP_UP_STORES, 
    format_id: POP_UP_STORE_FORMAT_ID,
    format: STORE_FORMAT
  },
  {
    name: "Personal Store", 
    type: PERSONAL_STORES, 
    format_id: PERSONAL_STORE_FORMAT_ID,
    format: STORE_FORMAT
  },
  {
    name: "Talents", 
    type: TALENTS, 
    id: TALENTS_FORMAT_ID
  },
  {
    name: "Temporary Work", 
    type: TEMPORARY_WORK, 
    id: TEMPORARY_WORK_FORMAT_ID
  },
];