export const COUNTRY_CODE = 966;
export const PHONE_PREFIX = "00";
export const INDIVIDUAL = 1;
export const COMPANY = 2;
export const ADMIN = 3;
export const FORM_DATA = "form-data";
export const AR_LANG = "ar";
export const EN_LANG = "en";
export const PHOTO_TYPES = "image";
export const PDF_TYPE = ".pdf";
export const DATE_FORMAT = "YYYY-MM-DD";
export const ENGLISH_TITLE = "EN";
export const ARABIC_TITLE = "ع";
export const ENGLISH = "English";
export const ARABIC = "العربية";
export const MAX_INTEGER = 2147483647;
export const PDF = "pdf";
export const MAX_FILE_SIZE = 10;
export const SUBMIT = "Submit";
export const DELETE = "Delete";
export const EDITION = "edit";
export const CREATION = "create";
export const MAIN_ELEMENTS = "main-elements";
export const MAIN_ELEMENT = "main-element";
export const MATLOOB_USER = "matloob-user";
export const SUPPORTING_ELEMENTS_TYPE = "supporting-element";
export const NEXT = "Next";
export const PREVIOUS = "Previous";
export const INDIVIDUAL_USER = "Individual";
export const COMPANY_USER = "Company";
export const SUPER_ADMIN_TYPE = "Super Admin";
export const CUSTOMER_MANAGER = "Customer Services";
export const LOGISTICS_MANAGER = "Logistics Manager";
export const ADMIN_TYPE = "Admin";
export const DIRECTOR_TYPE = "Director";
export const DISABLED = "disabled";
export const UNDER_PROGRESS = "Under Progress";
export const CANCELLED = "Cancelled";
export const ACCEPTED = "Accepted";
export const REQUIRES_UPDATES = "Requires Updates";
export const REJECTED = "Rejected";
export const OTHER = "Other";
export const EVENTS = "events";
export const IDEAS = "ideas";
export const VENUES = "venues";
export const LOGISTICS = "logistics";
export const RESTAURANT_OF_CAFE = "restaurants/restaurant-or-cafe";
export const FOOD_TRUCK = "restaurants/food-truck";
export const FOOD_AND_DRINKS_PROVIDER = "restaurants/food-and-drinks-provider";
export const STORE = "stores/store";
export const POP_UP_STORES = "stores/pop-up-stores";
export const PERSONAL_STORES = "stores/personal-stores";
export const TALENTS = "talents";
export const TEMPORARY_WORK = "temporary-work";
export const SEASONS_REQUEST = "seasons-request";
export const EVENTS_REQUEST = "events-request";
export const REQUESTS = "requests";
export const DATE_TIME_FORMAT = "MM/D/YYYY, h:mm a";
export const RESTAURANT_OF_CAFE_KEY = "restaurant-or-cafe";
export const FOOD_TRUCK_KEY = "food-truck";
export const FOOD_AND_DRINKS_PROVIDER_KEY = "food-and-drinks-provider";
export const STORE_KEY = "store";
export const POP_UP_STORES_KEY = "pop-up-stores";
export const PERSONAL_STORES_KEY = "personal-stores";
export const SUPER_ADMIN_USER = 1;
export const ADMIN_USER = 2;
export const DIRECTOR_USER = 3;
export const VIEW = "VIEW";
export const DOWNLOAD = "DOWNLOAD";
export const TEXT = "Text";
export const SECRET = "6LfvKnsbAAAAAMp7IxBFDjAZep8VAuNaEX28Q-y2";
export const RECAPTCHA_KEY = "6LfvKnsbAAAAAG97eYIpJyjueBwdAkbQ0apA0tm8";

export const userType = {
    1: SUPER_ADMIN_TYPE,
    2: ADMIN_TYPE,
    3: DIRECTOR_TYPE,
    4: CUSTOMER_MANAGER,
    5: LOGISTICS_MANAGER,
};
