import React, { useEffect } from "react";
import { Accordion, Loader } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AdminLayout from "layouts/Admin";
import { getElementUserDetails } from "store/actions/adminElements.actions";
import {
  RESTAURANT_OF_CAFE,
  FOOD_TRUCK,
  FOOD_AND_DRINKS_PROVIDER,
  STORE,
  POP_UP_STORES,
  PERSONAL_STORES,
  TALENTS,
  TEMPORARY_WORK,
  EVENTS,
  IDEAS,
  VENUES,
  LOGISTICS,
} from "constants/forms";

const elementsType = {
  "restaurant-or-cafe": RESTAURANT_OF_CAFE,
  "food-truck": FOOD_TRUCK,
  "food-and-drinks-provider": FOOD_AND_DRINKS_PROVIDER,
  store: STORE,
  "pop-up-stores": POP_UP_STORES,
  "personal-stores": PERSONAL_STORES,
  talents: TALENTS,
  "temporary-work": TEMPORARY_WORK,
  events: EVENTS,
  ideas: IDEAS,
  venues: VENUES,
  logistics: LOGISTICS,
};

const flattenObject = function (ob) {
  let toReturn = {};
  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (ob[i] instanceof Array) {
      toReturn[i] = ob[i];
    }
    if (typeof ob[i] === "object") {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

const AdminViewElements = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const { elementsUsers, elementDetails, loaded, error, ...restState } =
    useSelector((state) => state.adminElements);

  useEffect(() => {
    const requestType = elementsType[type];
    dispatch(getElementUserDetails.request({ type: requestType, id }));
  }, [dispatch, id, type]);
  return (
    <AdminLayout>
      <Loader loaded={loaded}>
        <div className="pt-3">
          {
            <Accordion
              viewOnly={true}
              viewOnlyData={flattenObject(elementDetails)}
              restState={restState}
            />
          }
        </div>
      </Loader>
    </AdminLayout>
  );
};

export default AdminViewElements;
