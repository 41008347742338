import React,{ useContext, useEffect } from 'react'
import { Context } from "context";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { PHOTOS} from "constants/paths";
import { getMediaCenterPhotos } from "store/actions/mediaCenter.actions";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

import styles from './styles.module.scss'

const PhotoCarousel = () => {
    const dispatch = useDispatch();
    const { mediaCenterPhotos,loaded } = useSelector(({ mediaCenter }) => mediaCenter);
    const type = 1;
    useEffect(() => {
        dispatch(getMediaCenterPhotos.request(type));
    
    }, [dispatch]);

    const { rtl } = useContext(Context);
    const { t } = useTranslation();
    return (
        <div className={styles.photoCarouselWrapper}>
            <div className={styles.header}>
                <Link to={PHOTOS} className={styles.link}>{t("mediaCenter.photoLibrary")}</Link>
            </div>
            <Swiper
                pagination={{
                    clickable: true,
                    renderBullet: function(index,className) {
                        return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
                    }
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                }}
                modules={[Pagination,Autoplay]}
                className={styles.photoCarouselPagination}
                navigation={false}
                dir={rtl ? 'rtl' : 'ltr'}
            >    
            {loaded &&  mediaCenterPhotos?.data?.slice(0,3).map((photos,index) => (
                <SwiperSlide key={index}>
                    <img src={photos.images[0].image} alt='test'/>
                </SwiperSlide>
                )
            )}
            </Swiper>
        </div>
    )
}

export default PhotoCarousel