import React, { useContext, useEffect } from "react";
import ProfileLayout from "layouts/Profile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { Context } from "context";
import { getUser } from "store/actions/auth.actions";
import { getDashboardElementsRequest } from "store/actions/elements.actions";
import { Link } from "react-router-dom";
import { INTERESTS, SAUDI_SEASONS_EVENTS, REQUESTS } from "constants/paths";
import { PageTitle, Loader } from "components";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(Context);

  const { loaded, dashboardElements } = useSelector((state) => state.elements);
  const {
    participatingElementsNo = 0,
    saudiSeasonsCount = 0,
    saudiEventCount = 0,
    requests = 0
  } = dashboardElements;

  useEffect(() => {
    dispatch(getDashboardElementsRequest.request());
  }, [dispatch]);

  useEffect(() => {
    if (!Object.keys(user || {}).length) dispatch(getUser.request());
  }, [user, dispatch]);

  return (
    <ProfileLayout>
      <Loader loaded={loaded}>
        {loaded && (
          <div className={styles.dashboard}>
            <PageTitle title={t("dashboard.title")} />
            <div className={styles.dashboardMain}>
              <div className={styles.dashboardGreeting}>
                <h3>
                  {t("dashboard.hi")}, {user?.full_name || user?.company_name}!
                </h3>
                <p>{t("dashboard.ready")}</p>
              </div>
            </div>
            <div className={styles.dashboardStatistic}>
              <Link to={INTERESTS}>
                <div className={styles.dashboardStatisticItem}>
                  <p>{t("interests.title")}</p>
                  <p>{participatingElementsNo}</p>
                </div>
              </Link>

              <Link to={SAUDI_SEASONS_EVENTS}>
                <div className={styles.dashboardStatisticItem}>
                  <p>{t("seasons_events.title")}</p>
                  <p>{saudiSeasonsCount + saudiEventCount}</p>
                </div>
              </Link>

              <Link to={REQUESTS}>
                <div className={styles.dashboardStatisticItem}>
                  <p>{t("dashboard.requests")}</p>
                  <p>{requests}</p>
                </div>
              </Link>
            </div>
          </div>
        )}
      </Loader>
    </ProfileLayout>
  );
};

export default Dashboard;
