import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Button = ({
  title,
  type = "button",
  onClick = null,
  color,
  className
}) => (
  <button
    className={classnames(className, styles.Button, styles[color])}
    type={type}
    onClick={onClick}
  >
    {title}
  </button>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Button;
