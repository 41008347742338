import React,{useState} from "react";
import PropTypes, { bool } from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { NEXT, PREVIOUS, DISABLED } from "constants/forms";
import styles from "./styles.module.scss";

const Pagination = ({
  action,
  currentPage,
  lastPage,
  type,
  id,
  extraParams = {},
  goToPage = false,
}) => {
  const dispatch = useDispatch();
  const nextPage = currentPage < lastPage;
  const previousPage = currentPage > 1;
  const { params } = extraParams;
  const [pageNumber,setPageNumber] = useState('1')
  
  const pageLinks = [
    {
      value: PREVIOUS,
      class: previousPage ? null : DISABLED,
      isActive: true,
      onClick: () => changePage(PREVIOUS),
    },
    {
      value: currentPage - 1,
      class: previousPage ? null : DISABLED,
      isActive: previousPage,
      onClick: () => changePage(PREVIOUS),
    },
    {
      value: currentPage,
      class: `active ${DISABLED}`,
      isActive: true,
    },
    {
      value: currentPage + 1,
      class: nextPage ? null : DISABLED,
      isActive: nextPage,
      onClick: () => changePage(NEXT),
    },
    {
      value: NEXT,
      class: nextPage ? null : DISABLED,
      isActive: true,
      onClick: () => changePage(NEXT),
    },
  ];
  
  const changePage = (condition) => {
    if (condition === NEXT && nextPage) {
      dispatch(action.request({ page: currentPage + 1, type, id, params }));
    } else if (condition === PREVIOUS && previousPage) {
      dispatch(action.request({ page: currentPage - 1, type, id, params }));
    }
  };
  
  const changeCurrentPage = (e) =>{
    if(pageNumber <= lastPage){
      e.preventDefault();
      dispatch(action.request({ page: pageNumber, type, id, params }));
    }
  }
  const  handleChange = (e) => {
    if(e.target.value === '' || +e.target.value >= 1){
      setPageNumber(e.target.value);
    }else{
      setPageNumber("1");
    }
  }
  const  handleBlur = (e) => {
    if(!e.target.value || +e.target.value < 1){
      setPageNumber("1");
    }
    if(+e.target.value > lastPage){
      setPageNumber(lastPage.toString())
    }
  }

  return (
    <div className="w-100 d-flex justify-content-center mt-4 flex-column align-items-center">
      <nav aria-label="Page navigation example">
        <ul
          className={classNames(
            "pagination justify-content-end",
            styles.paginationContainer
          )}
        >
          {pageLinks.map(
            (item, index) =>
              item.isActive && (
                <li
                  className={classNames(`page-item ${item.class || ""}`)}
                  key={index}
                >
                  <button
                    className={classNames("page-link", styles.pageLink)}
                    onClick={item?.onClick}
                  >
                    {item.value}
                  </button>
                </li>
              )
          )}
        { goToPage && lastPage > 3 &&
        <form onSubmit ={changeCurrentPage} className={styles.pageSection}>
          <input type='number' value={pageNumber} onChange={handleChange} className={styles.pageInput} onBlur={handleBlur}/>
          <input type="submit" value="Go to page" className={classNames("page-link", styles.pageLink)} disabled={pageNumber > lastPage}/>
        </form>}
        </ul>
      </nav>
      <span>{`Page ${currentPage} of ${lastPage}`}</span>
    </div>
  );
};

Pagination.propTypes = {
  action: PropTypes.object.isRequired,
  currentPage: PropTypes.number,
  lastPage: PropTypes.number,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  extraParams: PropTypes.object,
  goToPage: bool
};

export default React.memo(Pagination);
