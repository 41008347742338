import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Context } from "context";
import { MediaCenterPlayer } from 'components';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

import styles from './styles.module.scss'
import { VIDEOS} from "constants/paths";
import { getMediaCenterVideos } from "store/actions/mediaCenter.actions";


const VideoCarousel = () => {
    const dispatch = useDispatch();
    const { mediaCenterVideos,loaded } = useSelector(({ mediaCenter }) => mediaCenter);
    const type = 2;
    useEffect(() => {
        dispatch(getMediaCenterVideos.request(type));
    }, [dispatch]);

    const { rtl } = useContext(Context);
    const { t } = useTranslation();
    return (
        <div className={styles.videoCarouselWrapper}>
            <div className={styles.header}>
                <Link to={VIDEOS} className={styles.link}>{t("mediaCenter.videoLibrary")}</Link>
            </div>
            <Swiper
                pagination={{
                    clickable: true,
                    renderBullet: function(index,className) {
                        return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
                    }
                }}
                modules={[Pagination,Autoplay]}
                className={styles.videoCarouselPagination}
                navigation={false}
                dir={rtl ? 'rtl' : 'ltr'}
            >
                {loaded &&  mediaCenterVideos?.data?.slice(0,3).map((videos) => (
                    <SwiperSlide key={videos.id}>
                        <div className={styles.videoWrapper}>
                            <MediaCenterPlayer video={videos.videos[0]} carousel/>
                        </div>
                    </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default VideoCarousel