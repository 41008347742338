import { combineReducers } from "redux";
import auth from "./auth.reducer";
import elements from "./elements.reducer";
import ideas from "./ideas.reducer";
import contact from "./contact.reducer";
import adminElements from "./adminElements.reducer"
import saudiSeasonsEvents from "./saudiSeasonsEvents.reducer";
import requests from "./requests.reducer";
import alert from "./alert.reducer";
import notifications from "./notifications.reducer";
import accordion from './accordion.reducer';
import mediaCenter from './mediaCenter.reducer'

const allReducers = combineReducers({
  auth,
  elements,
  ideas,
  contact,
  adminElements,
  saudiSeasonsEvents,
  requests,
  alert,
  notifications,
  accordion,
  mediaCenter
});

const rootReducers = (state, action) => {
  return allReducers(state, action);
};

export default rootReducers;
