import React from "react"
import Layout from "layouts/Admin";
import { NotificationsHistory } from "components"
const AdminNotificationsHistory = () => {
  return (
    <Layout title="Notifications">
      <NotificationsHistory />
    </Layout>
  )
}

export default AdminNotificationsHistory