import React from "react";
import Layout from "layouts/Admin";
import { SupportingElements } from "components";

const AdminSupportingElements = () => {
  return (
    <Layout title="Supporting Elements">
      <SupportingElements />
    </Layout>
  );
};

export default AdminSupportingElements;
