import { createRequestTypes, createActionTypes } from "utils/redux";

export const LOGIN = createRequestTypes("LOGIN");
export const SIGN_UP = createRequestTypes("SIGN_UP");
export const CONFIRM_SIGNUP = createRequestTypes("CONFIRM_SIGNUP");
export const GET_MOBILE_OTP = createRequestTypes("GET_MOBILE_OTP");
export const LOGOUT = createRequestTypes("LOGOUT");
export const GET_USER = createRequestTypes("GET_USER");
export const GET_GUEST_TOKEN = createRequestTypes("GET_GUEST_TOKEN");
export const TOGGLE_OTP_MODAL = "TOGGLE_MODAL";
export const TOGGLE_SIGNUP_MODAL = "TOGGLE_SIGNUP_MODAL";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const LOGIN_ADMIN = createRequestTypes("LOGIN_ADMIN");
export const CHANGE_MENU_STATUS = createRequestTypes("CHANGE_MENU_STATUS");
export const LOGOUT_ADMIN = createRequestTypes("LOGOUT_ADMIN");
export const FORGOT_PASSWORD = createRequestTypes("FORGOT_PASSWORD");
export const RESET_PASSWORD = createRequestTypes("RESET_PASSWORD");
export const GET_ADMIN = createRequestTypes("GET_ADMIN");
export const EDIT_ADMIN = createRequestTypes("EDIT_ADMIN");
export const CHANGE_PASSWORD = createRequestTypes("CHANGE_PASSWORD");
export const REMOVE_USER = "REMOVE_USER";
export const EDIT_USER_INFO = createRequestTypes("EDIT_USER_INFO");
export const LOGIN_ADMIN_OTP = createRequestTypes("LOGIN_ADMIN_WORKER")
export const INVALID_TOKEN_REDIRECT = createRequestTypes("INVALID_TOKEN_REDIRECT")
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const login = createActionTypes(LOGIN);

export const signUp = createActionTypes(SIGN_UP);

export const confirmSignup = createActionTypes(CONFIRM_SIGNUP);

export const getMobileOTP = createActionTypes(GET_MOBILE_OTP);

export const logout = createActionTypes(LOGOUT);

export const getUser = createActionTypes(GET_USER);

export const getGuestToken = createActionTypes(GET_GUEST_TOKEN);

export const toggleOtpModal = () => ({ type: TOGGLE_OTP_MODAL });

export const toggleSignUpModal = () => ({ type: TOGGLE_SIGNUP_MODAL });

export const toggleMenu = data => ({ type: TOGGLE_MENU, payload: data });

export const loginAdmin = createActionTypes(LOGIN_ADMIN);

export const logoutAdmin = createActionTypes(LOGOUT_ADMIN);

export const changeMenuStatus = createActionTypes(CHANGE_MENU_STATUS);

export const forgotPassword = createActionTypes(FORGOT_PASSWORD);

export const resetAuthState = () => ({ type: RESET_AUTH_STATE });

export const resetPassword = createActionTypes(RESET_PASSWORD);

export const getAdmin = createActionTypes(GET_ADMIN);

export const editAdmin = createActionTypes(EDIT_ADMIN);

export const changePassword = createActionTypes(CHANGE_PASSWORD);

export const removeUser = () => ({ type: REMOVE_USER });

export const editUserInfo = createActionTypes(EDIT_USER_INFO)
export const loginAdminOTP = createActionTypes(LOGIN_ADMIN_OTP)

export const invalidTokenRedirect =  createActionTypes(INVALID_TOKEN_REDIRECT)
export const clearError = () => ({type: CLEAR_ERROR})