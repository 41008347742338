import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUserNotifications } from "store/actions/notifications.actions";
import { PageTitle, Loader } from "components";
import ProfileLayout from "layouts/Profile";
import { getFormattedDateTime } from "utils/helpers";
import { ReactComponent as FileIcon } from "assets/icons/file-text.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as GridIcon } from "assets/icons/grid.svg";
import styles from "./styles.module.scss";
import { notificationsList } from "./constants";

const ICONS = {
    request: <FileIcon />,
    season: <GridIcon />,
    event: <GridIcon />,
    all: <BellIcon />,
};

const Notifications = () => {
    const [open, setOpen] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userNotifications, loaded } = useSelector(
        ({ notifications }) => notifications
    );

    useEffect(() => {
        dispatch(getUserNotifications.request());
    }, [dispatch]);


    const handleClick = (id) =>{ 
        if (id === open){
            setOpen(null)
            return;
        }
        setOpen(id)
    };

    const data = useMemo(
        () => notificationsList(userNotifications,getFormattedDateTime),
        [ userNotifications ]
    );

    const getInitialValue = () =>{
        if(data?.today.length ){
            setOpen('today')
            return;
        }
        if(data?.thisWeek.length ){
            setOpen('this-week')
            return;
        }
        if(data?.lastWeek.length ){
            setOpen('last-week')
            return;
        }
        if(data?.lastMonth.length ){
            setOpen('last-month')
            return;
        }
        if(data?.lastYear.length ){
            setOpen('last-year')
            return;
        }
    }

    useEffect(() => {
        getInitialValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    
    
    const todayList = () => {
        return data.today.map((notification, idx) => {
            const notificationDate =
                notification?.created_at ||
                notification?.createdAt ||
                notification?.updatedAt;
            return (
                <React.Fragment key={idx}>
                    <div className={styles.notificationItemSection}>
                        <div
                            className={styles.notificationItem}
                            key={`${notification.id}-${idx}`}
                        >
                            <div className={styles.notificationItemIcon}>
                                {ICONS[notification?.recipient || "all"]}
                            </div>
                            <div className={styles.notificationItemContent}>
                                <p className={styles.notificationItemText}>
                                    {notification?.message}
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                styles.notificationItemDateCircleTurquoise
                            }
                        >
                            <span>{t("Notifications.today")},</span>
                            <span>
                                {new Date(notificationDate)
                                    .toLocaleString(
                                        `${
                                            localStorage.getItem(
                                                "i18nextLng"
                                            ) === "en"
                                                ? "en-GB"
                                                : "ar-SA"
                                        }`,
                                        {
                                            hour: "2-digit",
                                            hour12: true,
                                            minute: "2-digit",
                                        }
                                    )
                                    .toLocaleUpperCase()}
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
    };

    const weekList = () => {
        if (data) {
            return data?.thisWeek?.map((notification, idx) => {
                const notificationDate =
                    notification?.created_at ||
                    notification?.createdAt ||
                    notification?.updatedAt;
                return (
                    <React.Fragment key={idx}>
                        <div className={styles.notificationItemSection}>
                            <div
                                className={styles.notificationItem}
                                key={`${notification.id}-${idx}`}
                            >
                                <div className={styles.notificationItemIcon}>
                                    {ICONS[notification?.recipient || "all"]}
                                </div>
                                <div className={styles.notificationItemContent}>
                                    <p className={styles.notificationItemText}>
                                        {notification?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    styles.notificationItemDateCirclePurple
                                }
                            >
                                <span>
                                    {new Date(notificationDate).toLocaleString(
                                        `${
                                            localStorage.getItem(
                                                "i18nextLng"
                                            ) === "en"
                                                ? "en-GB"
                                                : "ar-SA"
                                        }`,
                                        {
                                            weekday: "short",
                                        }
                                    )}
                                    ,
                                </span>
                                <span>
                                    {new Date(notificationDate)
                                        .toLocaleString(
                                            `${
                                                localStorage.getItem(
                                                    "i18nextLng"
                                                ) === "en"
                                                    ? "en-GB"
                                                    : "ar-SA"
                                            }`,
                                            {
                                                hour: "2-digit",
                                                hour12: true,
                                                minute: "2-digit",
                                            }
                                        )
                                        .toLocaleUpperCase()}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
    };

    const lastWeekList = () => {
        if (data) {
            return data?.lastWeek?.map((notification, idx) => {
                const notificationDate =
                    notification?.created_at ||
                    notification?.createdAt ||
                    notification?.updatedAt;
                return (
                    <React.Fragment key={idx}>
                        <div className={styles.notificationItemSection}>
                            <div
                                className={styles.notificationItem}
                                key={`${notification.id}-${idx}`}
                            >
                                <div className={styles.notificationItemIcon}>
                                    {ICONS[notification?.recipient || "all"]}
                                </div>
                                <div className={styles.notificationItemContent}>
                                    <p className={styles.notificationItemText}>
                                        {notification?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    styles.notificationItemDateCircleYellow
                                }
                            >
                                <span>
                                    {new Date(notificationDate).toLocaleString(
                                        `${
                                            localStorage.getItem(
                                                "i18nextLng"
                                            ) === "en"
                                                ? "en-GB"
                                                : "ar-SA"
                                        }`,
                                        {
                                            weekday: "short",
                                            day: "numeric",
                                        }
                                    )}
                                </span>
                                <span>
                                    {new Date(notificationDate)
                                        .toLocaleString(
                                            `${
                                                localStorage.getItem(
                                                    "i18nextLng"
                                                ) === "en"
                                                    ? "en-GB"
                                                    : "ar-SA"
                                            }`,
                                            {
                                                hour: "2-digit",
                                                hour12: true,
                                                minute: "2-digit",
                                            }
                                        )
                                        .toLocaleUpperCase()}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
    };

    const monthList = () => {
        if (data) {
            return data?.lastMonth?.map((notification, idx) => {
                const notificationDate =
                    notification?.created_at ||
                    notification?.createdAt ||
                    notification?.updatedAt;
                return (
                    <React.Fragment key={idx}>
                        <div className={styles.notificationItemSection}>
                            <div
                                className={styles.notificationItem}
                                key={`${notification.id}-${idx}`}
                            >
                                <div className={styles.notificationItemIcon}>
                                    {ICONS[notification?.recipient || "all"]}
                                </div>
                                <div className={styles.notificationItemContent}>
                                    <p className={styles.notificationItemText}>
                                        {notification?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    styles.notificationItemDateCirclePink
                                }
                            >
                                <span>
                                    {new Date(notificationDate).toLocaleString(
                                        `${
                                            localStorage.getItem(
                                                "i18nextLng"
                                            ) === "en"
                                                ? "en-GB"
                                                : "ar-SA"
                                        }`,
                                        {
                                            weekday: "short",
                                            month: "long",
                                            day: "numeric",
                                        }
                                    )}
                                    ,
                                </span>
                                <span>
                                    {new Date(notificationDate)
                                        .toLocaleString(
                                            `${
                                                localStorage.getItem(
                                                    "i18nextLng"
                                                ) === "en"
                                                    ? "en-GB"
                                                    : "ar-SA"
                                            }`,
                                            {
                                                hour: "2-digit",
                                                hour12: true,
                                                minute: "2-digit",
                                            }
                                        )
                                        .toLocaleUpperCase()}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
    };

    const yearList = () => {
        if (data) {
            return data?.lastYear?.map((notification, idx) => {
                const notificationDate =
                    notification?.created_at ||
                    notification?.createdAt ||
                    notification?.updatedAt;
                return (
                    <React.Fragment key={idx}>
                        <div className={styles.notificationItemSection}>
                            <div
                                className={styles.notificationItem}
                                key={`${notification.id}-${idx}`}
                            >
                                <div className={styles.notificationItemIcon}>
                                    {ICONS[notification?.recipient || "all"]}
                                </div>
                                <div className={styles.notificationItemContent}>
                                    <p className={styles.notificationItemText}>
                                        {notification?.message}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    styles.notificationItemDateCircleOrange
                                }
                            >
                                <span>
                                    {new Date(notificationDate).toLocaleString(
                                        `${
                                            localStorage.getItem(
                                                "i18nextLng"
                                            ) === "en"
                                                ? "en-GB"
                                                : "ar-SA"
                                        }`,
                                        {
                                            weekday: "short",
                                            month: "long",
                                            day: "numeric",
                                            year: "numeric",
                                        }
                                    )}
                                    ,
                                </span>
                                <span>
                                    {new Date(notificationDate)
                                        .toLocaleString(
                                            `${
                                                localStorage.getItem(
                                                    "i18nextLng"
                                                ) === "en"
                                                    ? "en-GB"
                                                    : "ar-SA"
                                            }`,
                                            {
                                                hour: "2-digit",
                                                hour12: true,
                                                minute: "2-digit",
                                            }
                                        )
                                        .toLocaleUpperCase()}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                );
            });
        }
    };

    return (
        <ProfileLayout>
            <Loader loaded={loaded}>
                <div className={styles.NotificationsTitle}>
                    <PageTitle title={t("Notifications.notifications")} />
                </div>
                <div className={styles.notificationItemWrapper}>
                    {data?.today.length ?
                    <>
                        <div className={styles.today} onClick={()=>handleClick('today')}>
                            {t("Notifications.today")}
                        </div>
                        <div className={styles.notificationItems}>
                            {open === 'today' && todayList()}
                        </div>
                    </> : null}
                    {data?.thisWeek.length ?
                    <>
                        <div className={styles.thisWeek} onClick={()=>handleClick('this-week')}>
                            {t("Notifications.this_week")}
                        </div>
                        <div className={styles.notificationItems}>
                            {open === 'this-week' && weekList()}
                        </div>
                    </> : null}
                    {data?.lastWeek.length ?
                    <>
                        <div className={styles.lastWeek} onClick={()=>handleClick('last-week')}>
                                {t("Notifications.last_week")}
                        </div>
                        <div className={styles.notificationItems}>
                            {open === 'last-week' && lastWeekList()}
                        </div>
                    </> : null}
                    {data?.lastMonth.length ?
                        <>
                            <div className={styles.lastMonth} onClick={()=>handleClick('last-month')}>
                                    {t("Notifications.last_months")}
                            </div>
                            <div className={styles.notificationItems}>
                                {open === 'last-month' && monthList()}
                            </div>
                        </> : null}
                    {data?.lastYear &&
                        <>
                            <div className={styles.lastYear} onClick={()=>handleClick('last-year')}>
                                {t("Notifications.last_years")}
                            </div>
                            <div className={styles.notificationItems}>
                                {open === 'last-year' && yearList()}
                            </div>
                        </>}
                </div>
            </Loader>
        </ProfileLayout>
    );
};

export default Notifications;
