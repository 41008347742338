import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Loader,
  TableComponent,
  AdminTopPanel,
  ElementsFilter,
  Pagination,
  AlertBox
} from "components";
import { compile } from "path-to-regexp";
import { ELEMENT_COMMENTS, ADMIN_VIEW_ELEMENTS } from "constants/paths";
import { useFormik } from "formik";
import { 
  getElementsUsers, 
  // addRemoveFavoriteUser, 
  getElementsUsersTarget, 
  clearUserRequestTarget, 
  changeCategoryAction,
  getAdminElementsDropDown
} from "store/actions/adminElements.actions";
import { filteredCategoriesType, initialValues } from "./constants";
import { getAdminMainElements } from "store/actions/elements.actions";
import { MAIN_ELEMENTS } from "constants/paths"
import moment from "moment";
import {
  RESTAURANT_OF_CAFE_KEY,
  MAIN_ELEMENT
} from "constants/forms"
import * as Yup from "yup";
import { phoneNumberRegExp } from "utils/validation";

const DATE_FORMAT = "l";

const MainElements = () => {
  const { elementsUsers, loaded, error, supportMessage,elementDropDown } = useSelector(({ adminElements }) => adminElements);
  const { loaded: elementsLoaded, error: elementsError, ...data } = useSelector(({ elements }) => elements)
  const isAlertActive =  useSelector(({ alert }) => alert);
  const [ typeSelected, setTypeSelected ] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { type }  = useParams();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      dispatch(getElementsUsers.request(setParams()));
    },
    validationSchema: Yup.object().shape({
      mobile_number: Yup.string().matches(phoneNumberRegExp, 'Mobile format is not correct, must be 9665XXXXXXXX'),
    }),
  });
  useEffect(()=>{
    dispatch(getAdminElementsDropDown.request())
  },[ dispatch ])

  useEffect(() => {
    const elementType = elementDropDown.find(item => item.key === type);
    if(!elementType){
      history.push(compile(MAIN_ELEMENTS)({type: RESTAURANT_OF_CAFE_KEY}))
      return
    }
    setTypeSelected(elementType)
    dispatch(getElementsUsers.request({type: elementType.type}));
      return () => dispatch(clearUserRequestTarget());
  }, [dispatch, history, type, elementDropDown]);

  useEffect(() => {
      dispatch(getAdminMainElements.request())
  },[dispatch])

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "User Name",
      key: "user_name"
    },
    {
      name: "Mobile Number",
      key: "mobile_number"
    },
    {
      name: "Status",
      key: "boolean",
      values: ['Incomplete', 'Complete'],
      field: "status"
    },
    {
      name: "Creation Date",
      key: "created_at"
    },
    {
      name: "Updating Date",
      key: "updated_at"
    },
    {
      name: "Comments",
      key: "action-btn",
      class: "fas fa-comments",
      field: 'comments',
      onClick: ({ id }) => {
        history.push(compile(ELEMENT_COMMENTS)({element_type: MAIN_ELEMENT}), {id})
      }
    },
    {
      name: "Category",
      key: "dropdown",
      field: 'favorite',
      onClick: ({id}, val) => {
        dispatch(changeCategoryAction.request({id, val}))
        dispatch(getElementsUsers.request(setParams()));
      }
    },
    // {
    //   name: "Favorite",
    //   key: "boolean-icon",
    //   field: "favorites",
    //   class: ['mdi-star-outline','mdi-star'],
    //   onClick: (index, userId) => {
    //     dispatch(addRemoveFavoriteUser.request(
    //       {
    //         form_id: userId,
    //         index,
    //       },
    //       )
    //     )
    //   },
    // },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: (({ id }) => {
        window.open(compile(ADMIN_VIEW_ELEMENTS)({element_type: MAIN_ELEMENT, type: typeSelected.key, id}), '_blank')
      })
    }
  ];
  
  const setParams = () => {
    const { type } = typeSelected;
    const params = {}
    for(let key in formik.values){
      if(formik.values[key])
        params[key] = formik.values[key]?.value || formik.values[key]?.id || formik.values[key]
    }
    return {type, params}
  }

  const tableBody = elementsUsers?.data && elementsUsers.data.map(el => ({
    id: el?.id,
    user_name: el?.user?.full_name || el?.user?.company_name || "",
    mobile_number: el?.user?.mobile_number || "",
    status: el?.status || "",
    comments: el?.comments || "",
    favorite: el?.favorite || "",
    created_at: el?.created_at ? moment(el.created_at).format(DATE_FORMAT) : "",
    updated_at: el?.updated_at ? moment(el.updated_at).format(DATE_FORMAT) : ""
  }));

  const onDropdownChange = (element) => {
    history.push(compile(MAIN_ELEMENTS)({type: element.key}));
    dispatch(clearUserRequestTarget());
    formik.resetForm()
  }

  const dropdownTools = {
    label: "Type",
    dropdownElements: elementDropDown,
    optionSelected: typeSelected,
    setOptionSelected: onDropdownChange
  };

  const buttonTools = {
    label: 'Export',
    onClick: () => {
      dispatch(getElementsUsersTarget.request({ ...setParams(), typeSelected }))
    }
  };

  const resetData = () => {
    formik.resetForm()
    const { type } = typeSelected;
    dispatch(getElementsUsers.request({type}));
  }

  return (
    <Loader loaded={loaded && elementsLoaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error || elementsError}
          additionalStyles='alert-danger'
        />
      }
      { supportMessage &&
        <div className='successAlert'>
          {supportMessage}
        </div>
      }
      <AdminTopPanel
        withDropdown
        dropdownTools={dropdownTools}
        withButton
        buttonTools={buttonTools}
        type={typeSelected.type}
        extraParams={setParams()}
      />
      <ElementsFilter
        typeSelected={typeSelected}
        data={data}
        filteredCategoriesType={filteredCategoriesType}
        formik={formik}
        resetData={resetData}
      />
      <section>
        <div className="card">
          <div className="card-body">
            <TableComponent tableHead={tableHead} tableBody={tableBody} />
            {elementsUsers?.data?.length ?
              <Pagination
                type={typeSelected.type}
                action = {getElementsUsers}
                currentPage = {elementsUsers?.current_page}
                lastPage = {elementsUsers?.last_page}
                extraParams={setParams()}
                goToPage
              />
              :
              null
            }
          </div>
        </div>
      </section>
    </Loader>
  );
};



export default MainElements;
