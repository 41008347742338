import React from "react";
import ReactApexChart from "react-apexcharts";
import { fillEmptyItems } from "./helpers";
import styles from "./styles.module.scss";

const CategoriesChart = ({ dataChart, fileName }) => {
  const { draft, complete, categories, title } = dataChart;
  delete draft["empty-category"];

  if (draft.other) {
    draft.others = draft.other;
    delete draft.other;
  }
  if (complete.other) {
    complete.others = complete.other;
    delete complete.other;
  }

  const chartCategories = categories?.map((item) =>
    item.name
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("a-", "")
      .replaceAll("other", "others")
  );

  const categoryNames = categories?.map((item) => item.name);

  const series = [
    {
      name: "Incomplete",
      data: fillEmptyItems(chartCategories, draft),
    },
    {
      name: "Complete",
      data: fillEmptyItems(chartCategories, complete),
    },
  ];
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: fileName,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: fileName,
          },
          png: {
            filename: fileName,
          },
        },
      },
    },
    colors: ["#000000", "#008000"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      position: "bottom",
      categories: categoryNames,
      labels: {
        minHeight:150,
        hideOverlappingLabels: false,
        show: true,
        rotate: -45,
        trim: true,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      title: {
        text: "Total",
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      <span>{title}</span>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={650}
        className={styles.chart}
      />
    </>
  );
};

export default React.memo(CategoriesChart);
