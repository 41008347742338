import React, { useEffect, useState, useContext } from "react";
import { PageTitle, Loader } from "components";
import {
  getUserRequest,
  getRequestInterest,
  cancelRequest,
} from "store/actions/requests.actions";
import { ReactComponent as Chevron } from "assets/icons/grey-chevron-right.svg";
import { ReactComponent as DowloadIcon } from "assets/icons/download.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getFormattedDateTime } from "utils/helpers";
import classnames from "classnames";
import { fakeOptions, REVIEW, getElementName } from "constants/seasonsEvents";
import { REQUESTS } from "constants/paths";
import CancelModal from "./../Modals/CancelModal/CancelModal";
import { BASE_URL } from "constants/api";
import { TEXT } from "constants/forms";
import styles from "./styles.module.scss";
import { Context } from "context";

const SeasonEventReview = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cancelRequestOpen, setCancelRequestOpen] = useState(false);
  const { rtl } = useContext(Context);

  const {
    userRequest,
    loaded,
    userElementTypes,
    regions = [],
    requestInterest,
  } = useSelector(({ requests }) => requests);

  const downloadViewPath = `${BASE_URL}/api/download`;

  const getRegionName = (regionId) => {
    return regions.find((region) => region.id === regionId)?.name ?? "";
  };

  useEffect(() => {
    dispatch(getUserRequest.request(id));
    return () => {
      localStorage.removeItem(REVIEW);
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (userRequest.participating_element_id) {
      dispatch(
        getRequestInterest.request(userRequest.participating_element_id)
      );
    }
  }, [dispatch, userRequest]);

  const getElementTypeName = (id) => {
    return userElementTypes.find((element) => element.id === id)?.name;
  };

  const handleBackButton = () => {
    history.push(REQUESTS);
  };

  const confirmCancel = () => {
    dispatch(cancelRequest.request(id));
    setCancelRequestOpen(false);
  };

  const getRequestName = (transcription, name) => (
    <>
      <p className={styles.subtitle}>{t(transcription)}</p>
      <p className={styles.subtitleContent}>{name}</p>
    </>
  );

  return (
    <Loader loaded={loaded}>
      <CancelModal
        isOpened={cancelRequestOpen}
        confirm={confirmCancel}
        close={() => setCancelRequestOpen(false)}
        fromAdmin={false}
      />
      {loaded && (
        <>
          <div>
            <button onClick={handleBackButton} className={styles.btnBack}>
              <Chevron />
              {t("dashboard.requests")}
            </button>
            <div className={styles.title}>
              <PageTitle
                title={
                  t("requests.request_number") +
                  `#${userRequest.request_number}`
                }
              />
            </div>
            <section className={styles.information}>
              <div className={styles.section}>
                {userRequest.event
                  ? getRequestName(
                      "requests.event_name",
                      userRequest.event?.name
                    )
                  : getRequestName(
                      "requests.season_name",
                      userRequest.season?.name
                    )}
              </div>
              <div className={styles.section}>
                <p className={styles.subtitle}>{t("requests.element")}</p>
                <p className={styles.subtitleContent}>
                  {getElementTypeName(userRequest.element_type_id)}
                </p>
              </div>
              {userRequest?.description && (
                <div className={`${styles.section}`}>
                  <p className={styles.subtitle}>{t("requests.description")}</p>
                  <p className={styles.subtitleContent}>
                    {userRequest.description}
                  </p>
                </div>
              )}
              <div className={`${styles.section} mb-2`}>
                <p className={styles.subtitle}>
                  {t("requests.participate_element")}
                </p>
                <div
                  className={`${styles.subtitleDropdown} ${styles.subtitleDropdownReview}`}
                >
                  <DropdownButton
                    variant="light"
                    id="dropdown-basic-button"
                    title={getElementName(
                      requestInterest,
                      getFormattedDateTime, 
                      rtl
                    )}
                    disabled
                  >
                    {fakeOptions.map((element) => (
                      <Dropdown.Item key={element.value}>
                        {element.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                {userRequest?.region_id && (
                  <div className={styles.section}>
                    <p className={styles.subtitle}>{t("requests.region")}</p>
                    <p className={styles.subtitleContent}>
                      {getRegionName(userRequest?.region_id)}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.section}>
                <p className={styles.subtitle}>{t("requests.status")} </p>
                <p
                  className={classnames(
                    styles.subtitleContent,
                    styles.withSmall
                  )}
                >
                  {t(`requests.statuses.${userRequest.status}`)}
                  {userRequest.other_status && userRequest.status ===4
                    ? ` - ${userRequest.other_status} `
                    : ""}
                  <small>
                    {t("requests.last_update")}{" "}
                    {getFormattedDateTime(userRequest.status_updated_at, "L")}
                    {" - "}
                    {getFormattedDateTime(userRequest.status_updated_at, "LT", rtl)}
                  </small>
                </p>
              </div>
              <div className={styles.section}>
                <p className={styles.subtitle}>{t("requests.date")}</p>
                <p className={styles.subtitleContent}>
                  {getFormattedDateTime(userRequest.created_at, "L")}
                  {" - "}
                  {getFormattedDateTime(userRequest.created_at, "LT", rtl)}
                </p>
              </div>
            </section>
            {userRequest?.application_requirements?.length > 0 && (
              <section className={styles.requirements}>
                <p className={styles.subtitle}>
                  {t("requests.application_requirements_files")}
                </p>
                <div className={styles.requirementsList}>
                  {userRequest?.application_requirements.map((req, index) => (
                    <div
                      key={req.id}
                      className={`d-flex ${
                        req.type === TEXT ? "flex-column" : ""
                      }`}
                    >
                      <div className={styles.requirementsDescription}>
                        <p className={styles.subtitle}>
                          {`${index + 1}`} - {req.name}:
                        </p>
                        <p className={styles.subtitleContent}>
                          {req.description}
                        </p>
                      </div>
                      {req.type === TEXT ? (
                        <div className={styles.requirementsTextarea}>
                          <div className={styles.textareaContainer}>
                            <textarea
                              type="text"
                              value={req.text || ""}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.requirementsView}>
                            <a
                              href={`${downloadViewPath}/${req.file}?view=1`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <i className="mdi mdi-eye"></i>
                            </a>
                          </div>
                          <div className={styles.requirementsDocs}>
                            <a
                              className={`${styles.downloadContainer} ${styles.downloadContainerReview}`}
                              href={`${downloadViewPath}/${req.file}`}
                            >
                              <span>{req.name}</span>
                              <DowloadIcon className={styles.downloadIcon} />
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            )}
          </div>
          {userRequest.status !== 5 && (
            <button
              className={styles.btnCancel}
              onClick={() => setCancelRequestOpen(true)}
            >
              {t("buttons.cancelRequest")}
            </button>
          )}
        </>
      )}
    </Loader>
  );
};

export default SeasonEventReview;
