import React from "react";
import EmptyLogo from "assets/images/loginImg/errorLogo.svg";
import { ReactComponent as Home } from "assets/icons/home.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DASHBOARD } from "constants/paths";
import styles from "./styles.module.scss";
import PublicBackground from "../../layouts/Public/PublicBackground";

const ErrorPage = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <PublicBackground turquoiseStarMain yellowStar>
            <div className={styles.errorPageContainer}>
                <div className={styles.boxContainer}>
                    <div className={styles.logo}>
                        <img src={EmptyLogo} alt="logo" />
                    </div>
                    <div className={styles.content}>
                        <h3>{t("error_page.smth_wrong")}</h3>
                        <p>{t("error_page.working_on")}</p>
                        <p>{t("error_page.try_again")}</p>
                    </div>
                    <div className={styles.btnBox}>
                        <button onClick={() => history.push(DASHBOARD)}>
                            <Home className={styles.btnRefresh} />
                            <span className="px-2">{t("dashboard.title")}</span>
                        </button>
                    </div>
                </div>
                <div className={styles.errorPageFooter}></div>
            </div>
        </PublicBackground>
    );
};

export default ErrorPage;
