import React, { useEffect } from "react";
import Logo from "assets/images/new-logo.svg";
import {
  Loader,
  OTPmodal,
  AlertBox
} from "components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError } from "utils/forms";
import { useDispatch, useSelector } from "react-redux";
import { invalidPhone } from "constants/messages";
import { loginAdminOTP, getGuestToken } from "store/actions/auth.actions";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD } from "constants/paths";
import styles from "./styles.module.scss";
import PhoneInput from "react-phone-input-2";
import classnames from "classnames";
import { phoneNumberRegExp } from "utils/validation";
import { useTranslation } from "react-i18next";
import { EN_LANG } from "constants/forms";

const AdminLoginForm = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { loaded, loginError: error, adminToken } = useSelector(({ auth }) => auth);
  const isAlertActive =  useSelector(({ alert }) => alert);


  const formik = useFormik({
    initialValues: {
      // email: "",
      mobile_number: "",
      password: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // email: Yup.string().email(incorrectEmail).required("Required"),
      mobile_number: Yup.string()
        .matches(phoneNumberRegExp, invalidPhone)
        .required("."),
      password: Yup.string().required("Required")
    }),
    onSubmit: values => {
      // dispatch(loginAdmin.request(values))
      localStorage.setItem("action", "login");
      localStorage.setItem("target", JSON.stringify(values));
      dispatch(loginAdminOTP.request(values));
    }
  });

  const getError = useError(formik);

  const handleFocus = (el) => {
    formik.setFieldTouched(el, true);
  }

  useEffect(() => {
    if(!adminToken)
      dispatch(getGuestToken.request());
  }, [adminToken, dispatch])

  useEffect(() => {
    localStorage.setItem("i18nextLng", EN_LANG)
    i18n.changeLanguage(EN_LANG);
  },[i18n])

  const handleChange = value => formik.setFieldValue("mobile_number", value);

  return (
    <div className={styles.loginForm}>
      <Loader loaded={loaded}>
        <div className={styles.loginFormHeader}>
          <img src={Logo} alt="" />
          <p>Enter your phone number and password to access control panel.</p>
        </div>

        {
          isAlertActive &&
          <AlertBox
            error={error}
            additionalStyles='alert-danger'
          />
        }

        <div className={styles.loginFormMain}>
          <form onSubmit={formik.handleSubmit}>

            <div className={styles.formGroup}>
            <label forhtml="mobile_number">Phone Number</label>
              <PhoneInput
                masks={{ sa: " ... ... ..." }}
                country={"sa"}
                disableDropdown
                countryCodeEditable={false}
                name="mobile_number"
                value={formik.values.mobile_number}
                onFocus={() => handleFocus("mobile_number")}
                onChange={handleChange}
                inputClass={classnames({
                  [styles.requiredField]:
                    formik.errors.mobile_number && formik.submitCount
                }, styles.phoneInput)}
                />
                {getError("mobile_number")}
            </div>

            <div className={styles.formGroup}>
              <label forhtml="password">Password</label>
              <input
                id="password"
                type="password"
                className="form-control"
                name="password"
                onChange={formik.handleChange}
                onFocus={() => handleFocus("password")}
              />
              {getError("password")}
            </div>

            <Link to={FORGOT_PASSWORD} className={styles.forgotPassword}>
              Forgot Password?
            </Link>

            <button type="submit" className={styles.submitBtn}>
              Log In
            </button>
          </form>
        </div>

        <OTPmodal data={formik.values} forAdmin={true}/>

      </Loader>
    </div>
  );
};

export default AdminLoginForm;
