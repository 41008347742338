import React, {useState, useEffect, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import styles from "./styles.module.scss";
import Cloud from "assets/icons/cloud-upload.svg";

const DragDropFile = ({ form, field, value, viewOnly }) => {
  const [noClickable, setNoClickable] = useState(false);
  const [containerHovered, setContainerHovered] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null)

  useEffect(() => {
    if(!value)
      setBackgroundImage(null)
    else if(typeof value === "string")
      setBackgroundImage(value)
    else 
      setBackgroundImage(URL.createObjectURL(value))
  },[value])

  const onDrop = useCallback(acceptedFile => {
    setBackgroundImage(URL.createObjectURL(acceptedFile[0]))
    form.setFieldValue(field.name, acceptedFile[0])
  },[ form, field]);

  const { getRootProps, getInputProps } = useDropzone({ accept: ["image/jpeg", "image/png"], onDrop, noClick: noClickable }); //noClickable

  const handleRemoveBtn = () => {
    setNoClickable(false);
    setBackgroundImage(null)
    form.setFieldValue(field.name, "")
  }

  return (
    <div className={styles.dnd_container}>
      <div {...getRootProps()} className={styles.dnd_innerContainer} onMouseEnter={() => setContainerHovered(true)} onMouseLeave={() => setContainerHovered(false)}>
        <input {...getInputProps()} disabled={viewOnly} />
        {!backgroundImage ? 
          (
            <div className={styles.dnd_preview}>
              <img src={Cloud} alt="" className={styles.dnd_icon}/>
                <p className={styles.dnd_text}>Drag and drop some files here, or click to upload</p>  
            </div>
          )
          :
          (
            <div className={styles.thumbContainer}>
              <div className={styles.thumbInner}>
                <img
                  src={backgroundImage}
                  className={styles.thumbImg}
                  alt=""
                />
              </div>
            </div>
          )
        }
        {backgroundImage && !viewOnly && 
          (
            <div className={`${containerHovered ? styles.dnd_covered : styles.dnd_cover}`} >
              <p className={styles.dnd_coverText}><b>Click here to upload a new file</b></p>
              <button 
                onClick={handleRemoveBtn}
                onMouseEnter={() => setNoClickable(true)} 
                onMouseLeave={() => setNoClickable(false)} 
                className={styles.dnd_removeBtn}
              >Remove</button>
            </div>
          )
        }
      </div>
    </div>  
  )
  }

export default React.memo(DragDropFile);

