import React from "react";
import Layout from "layouts/Admin";
import { AdminViewUsersRequests } from "components";

const AdminUsersRequests = () => (
  <Layout title="Matloob User Requests">
      <AdminViewUsersRequests/>
  </Layout>
);

export default AdminUsersRequests;