import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import {
  LOGIN,
  DASHBOARD,
  ADMIN_LOGIN,
  ADMIN_DASHBOARD_STATISTICS,
  ADMIN_USERS,
  ADMIN_SEASONS,
  ADMIN_EVENTS_LIST,
  NEW_CONTROL_PANEL,
  EDIT_SEASON,
  EDIT_EVENT,
  ADMIN_PROFILE,
  CHANGE_PASSWORD,
  ADMIN_VIEW_REQUEST,
  SUPPORTING_ELEMENTS_REQUESTS,
  SEASON_EVENT_REQUEST_INFO,
  EVENTS_REQUESTS,
  SEASONS_REQUESTS,
  ADMIN_VIEW_ELEMENTS,
  SUPPORTING_ELEMENTS,
  MAIN_ELEMENTS
} from "constants/paths";
import {
  PRIVATE,
  UNAUTHORIZED,
  ADMIN as ADMIN_ROUTE,
  ADMIN_UNAUTHORIZED,
} from "./config";
import { Context } from "context";
import { removeUser } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";

const ADMIN_TYPE = 2;
const DIRECTOR_TYPE = 3;

const DIRECTOR_PAGES = [
  MAIN_ELEMENTS,
  SUPPORTING_ELEMENTS,
  ADMIN_EVENTS_LIST,
  ADMIN_SEASONS,
  EDIT_SEASON,
  EDIT_EVENT,
  ADMIN_PROFILE,
  CHANGE_PASSWORD,
  ADMIN_VIEW_REQUEST,
  SUPPORTING_ELEMENTS_REQUESTS,
  SEASON_EVENT_REQUEST_INFO,
  EVENTS_REQUESTS,
  SEASONS_REQUESTS,
  ADMIN_VIEW_ELEMENTS,
];

const ProtectedRoute = ({ component: Component, ...protectedRouteProps }) => {
  const { token, user } = useContext(Context);
  const dispatch = useDispatch();
  const newUser = localStorage.getItem("newUser");
  useEffect(() => {
    if (
      protectedRouteProps.fieldName === UNAUTHORIZED &&
      token &&
      user?.admin
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      dispatch(removeUser());
    }
  }, [dispatch, token, user, protectedRouteProps]);

  return (
    <Route
      {...protectedRouteProps}
      render={(props) => {
        if (protectedRouteProps.fieldName === ADMIN_ROUTE && !user?.admin) {
          return <Redirect to={ADMIN_LOGIN} />;
        } else if (
          user?.admin &&
          protectedRouteProps.fieldName === ADMIN_UNAUTHORIZED
        ) {
          return user.user_type === 4 ? (
            <Redirect to={SEASONS_REQUESTS} />
          ) : user.user_type === 5 ? (
            <Redirect to={SUPPORTING_ELEMENTS} />
          ) : (
            <Redirect to={ADMIN_DASHBOARD_STATISTICS} />
          );
        } else if (!token && protectedRouteProps.fieldName === PRIVATE) {
          return <Redirect to={{ pathname: LOGIN, state: newUser }} />;
        } else if (token && protectedRouteProps.fieldName === UNAUTHORIZED) {
          return <Redirect to={DASHBOARD} />;
        } else if (
          user?.user_type === ADMIN_TYPE &&
          (protectedRouteProps.path === ADMIN_USERS ||
            protectedRouteProps.path === NEW_CONTROL_PANEL)
        ) {
          return <Redirect to={ADMIN_DASHBOARD_STATISTICS} />;
        } else if (
          user?.user_type === DIRECTOR_TYPE &&
          DIRECTOR_PAGES.every((item) => item !== protectedRouteProps.path)
        ) {
          return <Redirect to={ADMIN_SEASONS} />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
