import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { changeMenuStatus } from "store/actions/auth.actions";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./sideBar.scss";

const SideBarDropDown = ({ link, match }) => {
  const dispatch = useDispatch();
  const openStatus = useSelector(state => state.auth.menuStatus);

  const [menuStatus, setMenuStatus] = useState(openStatus);
  const [upsellStatus, setUpsellStatus] = useState(false);

  useEffect(() => {
    if (!openStatus) {
      setMenuStatus(false);
      setUpsellStatus(false);
    }
  }, [openStatus]);

  const active = ({ url }) => match.url === url;

  const secondLevel = () => (
    <ul className={classNames("nav-second-level collapse", menuStatus && "in")}>
      {link.children.map(child => {
        return (
          <div key={child.name}>
            {child.children ? (
              <span id="link" className="childItem">
                <a
                  id="link"
                  className={classNames(
                    child.children.some(active) && "active"
                  )}
                  onClick={() => {
                    setUpsellStatus(!upsellStatus);
                  }}
                  href="# "
                >
                  <i className={child.icon}></i>
                  <span>{child.name}</span>
                  <span
                    className={classNames(
                      openStatus &&
                        (upsellStatus ? "arrow-rotate" : "menu-arrow")
                    )}
                  ></span>
                </a>
                <span
                  className={classNames(
                    "nav-second-level",
                    upsellStatus ? "open" : "hide"
                  )}
                >
                  {child.children.map((childItem, index) => (
                    <li key={child.name + index} id="menu-item">
                      <NavLink
                        to={childItem.url}
                        onClick={() => dispatch(changeMenuStatus.failure())}
                        className="has-dropdown"
                        activeClassName="activeLink"
                      >
                        <i className={childItem.icon}></i>
                        <span>{childItem.name}</span>
                      </NavLink>
                    </li>
                  ))}
                </span>
              </span>
            ) : (
              <li key={child.name}>
                <NavLink
                  to={child.url}
                  onClick={() => dispatch(changeMenuStatus.failure())}
                  className="has-dropdown"
                  activeClassName="activeLink"
                >
                  {child.icon && <i className={child.icon}></i>}
                  <span className={classNames({"text-wrap": child.wrap})}>{child.name}</span>
                </NavLink>
              </li>
            )}
          </div>
        );
      })}
    </ul>
  );

  return (
    <li id="link">
      <a
        role="button"
        className={classNames(
          link.children.some(({ url }) => match.url === url) && "active",
          link.children.map(
            child =>
              child.children &&
              child.children.some(({ url }) => match.url === url) &&
              "active"
          )
        )}
        onClick={() => {
          if (openStatus) {
            setMenuStatus(!menuStatus);
          }
        }}
        href="# "
      >
        <i className={link.icon}></i>
        <span>{link.name}</span>
        <span
          className={classNames(menuStatus ? "arrow-rotate" : "menu-arrow")}
        ></span>
      </a>
      {secondLevel()}
    </li>
  );
};

export default React.memo(SideBarDropDown);
