import React from "react" 
import { Button, ModalBody } from "react-bootstrap";
import { Modal, ModalHeader } from "reactstrap";

const ArchivedModal = ({title, modalOpen, modalClose, modalValuePositive, modalValueNegative}) => (
  <Modal
    className="modal-dialog-centered modal-md rounded adminDeleteModal"
    isOpen={modalOpen}
    toggle={modalClose}
  >
    <ModalHeader toggle={modalClose} className="d-flex justify-content-center">
      <p className="text-center">{title}</p>
    </ModalHeader>
    <ModalBody className="d-flex justify-content-center">
      <Button className="mr-4 btn-paua" onClick={modalValuePositive}>Yes</Button>
      <Button className="ml-4 btn-gray" onClick={modalValueNegative}>No</Button>
    </ModalBody>
  </Modal>
)

export default ArchivedModal