import * as AdminsActions from "store/actions/adminElements.actions";

const initialState = {
  loaded: true,
  error: "",
  disabilityTypes: [],
  allCategories: [],
  elementsUsers: {},
  elementsUsersRequests: {},
  elementDetails: [],
  elementComments: [],
  elementComment: {},
  adminRegions: [],
  applicationRequirements: [],
  applicationRequirement: {},
  matloobUsers: {},
  matloobUserElements: {},
  events: {},
  seasons: {},
  cpUsers: {},
  dashboardStatistics: {},
  submittedFormsStatistics: {},
  seasonsArrangement: [],
  eventsArrangement: [],
  userActions: [],
  cpUserDetails: {},
  restaurantFormats: [],
  foodCategories: [],
  fileToDownload: "",
  fileToDownloadError: "",
  userDetails: {},
  adminMainElements: [],
  storeFormats: [],
  notificationsHistory: {},
  eventSeasonData: {},
  success: false,
  eventsSeasonsRequests: {},
  eventsSeasonsStatusRequests:{},
  eventSeasonRequest: {},
  supportingElementsRequests: {},
  eventSeasonRequestsInfo: {},
  updatedHistory: {},
  eventSeasonApplicationRequirements: [],
  supportMessage: "",
  reports: {},
  mediaCenterNewsHistory: {},
  mediaCenterAdminNewsDetails: {},
  mediaCenterImagesVideosHistory: {},
  mediaCenterAdminImagesVideosDetails:{},
  mediaCenterCarouselImagesHistory:{},
  mediaCenterCarouselImagesDetails:{},
  assignDirectorToElementsInformation:{},
  elementDropDown:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AdminsActions.EDIT_ELEMENT_COMMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.EDIT_ELEMENT_COMMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.EDIT_ELEMENT_COMMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.DELETE_ELEMENT_COMMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.DELETE_ELEMENT_COMMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.DELETE_ELEMENT_COMMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_ELEMENTS_USERS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENTS_USERS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementsUsers: action.payload,
      };

    case AdminsActions.GET_ELEMENTS_USERS.FAILURE:
      return {
        ...state,
        loaded: true,
        elementsUsers: [],
        error: action.payload,
      };

    case AdminsActions.GET_ELEMENT_USER_DETAILS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENT_USER_DETAILS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.GET_ELEMENT_USER_DETAILS.FAILURE:
      return {
        ...state,
        loaded: true,
        elementUserDetails: {},
        error: action.payload,
      };
    case AdminsActions.GET_USERS_REQUESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_USERS_REQUESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementsUsersRequests: action.payload,
      };

    case AdminsActions.GET_USERS_REQUESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        elementsUsersRequests: {},
        error: action.payload,
      };

    case AdminsActions.GET_ADMIN_REGIONS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ADMIN_REGIONS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        adminRegions: action.payload,
      };

    case AdminsActions.GET_ADMIN_REGIONS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
    case AdminsActions.GET_ELEMENT_COMMENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENT_COMMENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementComments: action.payload,
        elementComment: {},
      };

    case AdminsActions.GET_ELEMENT_COMMENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        elementComments: [],
      };

    case AdminsActions.CREATE_ELEMENT_COMMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.CREATE_ELEMENT_COMMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.CREATE_ELEMENT_COMMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_ELEMENT_COMMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENT_COMMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementComment: action.payload,
      };

    case AdminsActions.GET_ELEMENT_COMMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
    case AdminsActions.CHANGE_CATEGORY.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.CHANGE_CATEGORY.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.CHANGE_CATEGORY.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        applicationRequirements: action.payload,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.ADD_APPLICATION_REQUIREMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.ADD_APPLICATION_REQUIREMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.ADD_APPLICATION_REQUIREMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case AdminsActions.GET_APPLICATION_REQUIREMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        applicationRequirement: action.payload,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.EDIT_APPLICATION_REQUIREMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.EDIT_APPLICATION_REQUIREMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.EDIT_APPLICATION_REQUIREMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_MATLOOB_USERS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_MATLOOB_USERS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        matloobUsers: action.payload,
      };

    case AdminsActions.GET_MATLOOB_USERS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_MATLOOB_USER_ELEMENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_MATLOOB_USER_ELEMENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementsUsers: action.payload,
      };

    case AdminsActions.GET_MATLOOB_USER_ELEMENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.CHANGE_BLOCKED_STATUS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.CHANGE_BLOCKED_STATUS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.CHANGE_BLOCKED_STATUS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_EVENTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        events: action.payload,
        error: "",
      };

    case AdminsActions.GET_EVENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_SEASONS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_SEASONS.SUCCESS:
      return {
        ...state,
        loaded: true,
        seasons: action.payload,
        error: "",
      };

    case AdminsActions.GET_SEASONS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_CP_USERS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_CP_USERS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        cpUsers: action.payload,
      };

    case AdminsActions.GET_CP_USERS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.ADD_NEW_CP_USER.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.ADD_NEW_CP_USER.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.ADD_NEW_CP_USER.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.DELETE_CP_USER.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.DELETE_CP_USER.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.DELETE_CP_USER.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.DELETE_MATLOOB_USER.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.DELETE_MATLOOB_USER.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.DELETE_MATLOOB_USER.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_DASHBOARD_STATISTICS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_DASHBOARD_STATISTICS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        dashboardStatistics: action.payload,
      };

    case AdminsActions.GET_DASHBOARD_STATISTICS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_SUBMITTED_FORMS_STATISTICS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_SUBMITTED_FORMS_STATISTICS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        submittedFormsStatistics: action.payload,
      };

    case AdminsActions.GET_SUBMITTED_FORMS_STATISTICS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_ELEMENT_STATISTICS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENT_STATISTICS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        elementStatistics: action.payload,
      };

    case AdminsActions.GET_DISABILITY_TYPES.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_DISABILITY_TYPES.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        disabilityTypes: action.payload,
      };

    case AdminsActions.GET_DISABILITY_TYPES.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_ELEMENT_STATISTICS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.REORDER_SEASONS.SUCCESS:
      return {
        ...state,
        error: "",
      };

    case AdminsActions.REORDER_SEASONS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdminsActions.REORDER_EVENTS.SUCCESS:
      return {
        ...state,
        error: "",
      };

    case AdminsActions.REORDER_EVENTS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case AdminsActions.GET_USER_ACTIONS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_USER_ACTIONS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        userActions: action.payload,
      };

    case AdminsActions.GET_USER_ACTIONS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_CP_USER_DETAILS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_CP_USER_DETAILS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        cpUserDetails: action.payload,
      };

    case AdminsActions.GET_CP_USER_DETAILS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.EDIT_CP_USER_DETAILS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.EDIT_CP_USER_DETAILS.SUCCESS:
      return {
        ...state,
        loaded: true,
        success: true,
        error: "",
      };

    case AdminsActions.EDIT_CP_USER_DETAILS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
        success: false,
      };

    case AdminsActions.DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loaded: true,
        fileToDownloadError: action.payload,
      };

    case AdminsActions.GET_USER_DETAILS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_USER_DETAILS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        userDetails: action.payload,
      };

    case AdminsActions.GET_USER_DETAILS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_ADMIN_MAIN_ELEMENTS_TYPES.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ADMIN_MAIN_ELEMENTS_TYPES.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.GET_ADMIN_MAIN_ELEMENTS_TYPES.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.CLEAN_DATA:
      return {
        ...state,
        elementsUsers: [],
      };

    case AdminsActions.EXPORT_ADMIN_STATISTICS.REQUEST:
    case AdminsActions.CREATE_ADMIN_EVENT_SEASON.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.EXPORT_ADMIN_STATISTICS.SUCCESS:
    case AdminsActions.CREATE_ADMIN_EVENT_SEASON.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.EXPORT_ADMIN_STATISTICS.FAILURE:
    case AdminsActions.CREATE_ADMIN_EVENT_SEASON.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_NOTIFICATIONS_HISTORY.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_NOTIFICATIONS_HISTORY.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        notificationsHistory: action.payload,
      };

    case AdminsActions.GET_NOTIFICATIONS_HISTORY.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_NOTIFICATION_DATA.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_NOTIFICATION_DATA.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.GET_NOTIFICATION_DATA.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.CREATE_NOTIFICATION.REQUEST:
      return {
        ...state,
        loaded: false,
        success: false,
      };

    case AdminsActions.CREATE_NOTIFICATION.SUCCESS:
      return {
        ...state,
        loaded: true,
        success: true,
        error: "",
      };

    case AdminsActions.CREATE_NOTIFICATION.FAILURE:
      return {
        ...state,
        loaded: true,
        success: false,
        error: action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENT_SEASON.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        eventSeasonData: action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.EDIT_EVENT_SEASON.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.EDIT_EVENT_SEASON.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.EDIT_EVENT_SEASON.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.CLEAN_SUCCESS:
      return {
        ...state,
        success: false,
      };

    case AdminsActions.GET_ELEMENTS_USERS_TARGET.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_ELEMENTS_USERS_TARGET.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        supportMessage: action.payload,
      };

    case AdminsActions.GET_ELEMENTS_USERS_TARGET.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_EVENTS_SEASONS_REQUESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENTS_SEASONS_REQUESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        eventsSeasonsRequests: action.payload,
      };

    case AdminsActions.GET_EVENTS_SEASONS_REQUESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
    
    case AdminsActions.GET_EVENTS_SEASONS_STATUS_REQUESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENTS_SEASONS_STATUS_REQUESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        eventsSeasonsStatusRequests: action.payload,
      };

    case AdminsActions.GET_EVENTS_SEASONS_STATUS_REQUESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUEST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUEST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        eventSeasonRequest: action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUEST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.CHANGE_REQUEST_STATUS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.CHANGE_REQUEST_STATUS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.CHANGE_REQUEST_STATUS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
      case AdminsActions.UPDATE_BULK_EVENT_SEASON_STATUS.REQUEST:
        return {
          ...state,
          loaded: false,
        };
  
    case AdminsActions.UPDATE_BULK_EVENT_SEASON_STATUS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case AdminsActions.UPDATE_BULK_EVENT_SEASON_STATUS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_SUPPORTING_ELEMENTS_REQUESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_SUPPORTING_ELEMENTS_REQUESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.GET_SUPPORTING_ELEMENTS_REQUESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_EVENTS_ARRANGEMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_EVENTS_ARRANGEMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        eventsArrangement: action.payload,
      };

    case AdminsActions.GET_EVENTS_ARRANGEMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_SEASONS_ARRANGEMENT.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_SEASONS_ARRANGEMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        seasonsArrangement: action.payload,
      };

    case AdminsActions.GET_SEASONS_ARRANGEMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUESTS_INFO.REQUEST:
      return {
        ...state,
        error: "",
        loaded: false,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUESTS_INFO.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case AdminsActions.GET_EVENT_SEASON_REQUESTS_INFO.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_USERS_REQUESTS_TARGET.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case AdminsActions.GET_USERS_REQUESTS_TARGET.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        supportMessage: action.payload,
      };

    case AdminsActions.GET_USERS_REQUESTS_TARGET.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET.REQUEST:
      return {
        ...state,
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET.SUCCESS:
      return {
        ...state,
        error: "",
      };

    case AdminsActions.GET_APPLICATION_REQUIREMENT_REQUESTS_TARGET.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AdminsActions.CLEAR_USERS_REQUESTS_TARGET:
      return {
        ...state,
        supportMessage: "",
      };

    case AdminsActions.GET_ALL_CATEGORIES.SUCCESS:
      return {
        ...state,
        allCategories: action.payload,
      };

    case AdminsActions.GET_ALL_CATEGORIES.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AdminsActions.GET_ALL_CATEGORIES.REQUEST:
      return {
        ...state,
      };

      case AdminsActions.GET_REPORTS.REQUEST:
        return {
          ...state,
          loaded: false,
        };

      case AdminsActions.GET_REPORTS.SUCCESS:
        return {
          ...state,
          loaded: true,
          error: "",
          reports: action.payload,
        };

      case AdminsActions.GET_REPORTS.FAILURE:
        return {
          ...state,
          loaded: true,
          error: action.payload,
        };

      case AdminsActions.DELETE_REPORTS.REQUEST:
        return {
          ...state,
          loaded: false,
        };

      case AdminsActions.DELETE_REPORTS.SUCCESS:
        return {
          ...state,
          loaded: true,
          error: "",
          ...action.payload,
        };

      case AdminsActions.DELETE_REPORTS.FAILURE:
        return {
          ...state,
          loaded: true,
          error: action.payload,
        };

      case AdminsActions.GET_REPORTS_DOWNLOAD.REQUEST:
        return {
          ...state,
          loaded: false,
        };

      case AdminsActions.GET_REPORTS_DOWNLOAD.SUCCESS:
        return {
          ...state,
          loaded: true,
          error: "",
          ...action.payload,
        };

      case AdminsActions.GET_REPORTS_DOWNLOAD.FAILURE:
        return {
          ...state,
          loaded: true,
          error: action.payload,
        };

      case AdminsActions.GET_MEDIA_CENTER_NEWS_HISTORY.REQUEST:
        return {
          ...state,
          loaded: false,
        };

      case AdminsActions.GET_MEDIA_CENTER_NEWS_HISTORY.SUCCESS:
        return {
          ...state,
          loaded: true,
          error: "",
          mediaCenterNewsHistory: action.payload,
        };

      case AdminsActions.GET_MEDIA_CENTER_NEWS_HISTORY.FAILURE:
        return {
          ...state,
          loaded: true,
          error: action.payload,
        };

        case AdminsActions.ADD_MEDIA_CENTER_NEW_NEWS.REQUEST:
          return {
            ...state,
            loaded: false,
          };

        case AdminsActions.ADD_MEDIA_CENTER_NEW_NEWS.SUCCESS:
          return {
            ...state,
            loaded: true,
            error: "",
          };

        case AdminsActions.ADD_MEDIA_CENTER_NEW_NEWS.FAILURE:
          return {
            ...state,
            loaded: true,
            error: action.payload,
          };

          case AdminsActions.ADD_MEDIA_CENTER_NEW_ITEM.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.ADD_MEDIA_CENTER_NEW_ITEM.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };
      
          case AdminsActions.ADD_MEDIA_CENTER_NEW_ITEM.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.EDIT_MEDIA_CENTER_ITEM.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_ITEM.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_ITEM.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
            

          case AdminsActions.EDIT_MEDIA_CENTER_NEWS.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_NEWS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_NEWS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

        case AdminsActions.GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY.REQUEST:
          return {
            ...state,
            loaded: false,
          };

        case AdminsActions.GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY.SUCCESS:
          return {
            ...state,
            loaded: true,
            error: "",
            mediaCenterImagesVideosHistory: action.payload,
          };

        case AdminsActions.GET_MEDIA_CENTER_IMAGES_VIDEOS_HISTORY.FAILURE:
          return {
            ...state,
            loaded: true,
            error: action.payload,
          };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              mediaCenterAdminNewsDetails: action.payload,
            };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_NEWS_DETAILS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              mediaCenterAdminImagesVideosDetails: action.payload,
            };

          case AdminsActions.GET_ADMIN_MEDIA_CENTER_IMAGES_VIDEOS_DETAILS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_NEWS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_NEWS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              ...action.payload,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_NEWS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_ITEM.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_ITEM.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              ...action.payload,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_ITEM.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              mediaCenterCarouselImagesHistory: action.payload,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_HISTORY.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
          
          case AdminsActions.ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };
      
          case AdminsActions.ADD_MEDIA_CENTER_NEW_CAROUSEL_IMAGE.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.EDIT_MEDIA_CENTER_CAROUSEL_IMAGE.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_CAROUSEL_IMAGE.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };
      
          case AdminsActions.EDIT_MEDIA_CENTER_CAROUSEL_IMAGE.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              mediaCenterCarouselImagesDetails: action.payload,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.GET_MEDIA_CENTER_CAROUSEL_IMAGES_DETAILS.REQUEST:
            return {
              ...state,
              loaded: false,
            };
            
            
          case AdminsActions.DELETE_MEDIA_CENTER_CAROUSEL_IMAGE.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_CAROUSEL_IMAGE.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              ...action.payload,
            };

          case AdminsActions.DELETE_MEDIA_CENTER_CAROUSEL_IMAGE.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };

          case AdminsActions.POST_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.POST_ASSIGN_DIRECTOR_TO_ELEMENTS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };

          case AdminsActions.POST_ASSIGN_DIRECTOR_TO_ELEMENTS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
          case AdminsActions.GET_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.GET_ASSIGN_DIRECTOR_TO_ELEMENTS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              assignDirectorToElementsInformation: action.payload,
            };

          case AdminsActions.GET_ASSIGN_DIRECTOR_TO_ELEMENTS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
          case AdminsActions.UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS.REQUEST:
            return {
              ...state,
              loaded: false,
            };

          case AdminsActions.UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
            };

          case AdminsActions.UPDATE_ASSIGN_DIRECTOR_TO_ELEMENTS.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
          
          case AdminsActions.GET_ADMIN_ELEMENTS_DROP_DOWN.REQUEST:
            return {
              ...state,
              loaded: false,
            };
      
          case AdminsActions.GET_ADMIN_ELEMENTS_DROP_DOWN.SUCCESS:
            return {
              ...state,
              loaded: true,
              error: "",
              elementDropDown: action.payload,
            };
      
          case AdminsActions.GET_ADMIN_ELEMENTS_DROP_DOWN.FAILURE:
            return {
              ...state,
              loaded: true,
              error: action.payload,
            };
    default:
      return state;
  }
}
