import React, { useState, useEffect, useContext }  from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Context } from "context";
import {
  Loader,
  AdminCardItem,
  AdminTopPanel,
  Pagination,
  AlertBox
} from "components";
import { useSelector, useDispatch } from "react-redux";
import { getEvents, getAdminRegions } from "store/actions/adminElements.actions";
import { NEW_EVENT, EDIT_EVENT } from "constants/paths";
import { compile } from "path-to-regexp";
import { EDITION } from "constants/forms";

const SUB_ADMIN_TYPE = 3

const statuses = [
  { name: "Active", type: 0 },
  { name: "Archived", type: 1 }
];

const EventsList = () => {
  const dispatch = useDispatch();
  const [ status, setStatus ] = useState({ name: "Active", type: 0 });
  const { adminRegions, loaded, error, events } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);

  const [ eventsData, setEventsData ] = useState(events);
  const { user } = useContext(Context);

  const history = useHistory();

  const regionsData = Object.fromEntries(
    adminRegions.map(item => [item.id, item.name])
  );

  const handleChange = ({ target: { value } }) => {
    setEventsData(
      events.data?.filter(item =>
        item?.name_en?.toLowerCase()?.match(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    dispatch(getEvents.request({ type: status?.type }))
  }, [dispatch, status]);

  useEffect(() => {
    dispatch(getAdminRegions.request());
  }, [dispatch]);

  useEffect(() => {
    setEventsData(events?.data);
  }, [events]);

  const searchTools = {
    placeholder: "Search event",
    onChange: handleChange
  };

  const redirectTo = (action, id = null) => {
    if(action === EDITION){
      history.push(compile(EDIT_EVENT)({ id }));
      return;
    }
    history.push(compile(NEW_EVENT)());
  }

  const dropdownTools = {
    label: "Status",
    dropdownElements: statuses,
    optionSelected: status,
    setOptionSelected: setStatus,
    order: 0,
  };

  const buttonTools = {
    label: 'New Event',
    onClick: () => redirectTo(),
    order: 2,
  }

  return (
    <Loader loaded={loaded}>
      <AdminTopPanel
        withSearch
        searchTools={searchTools}
        withDropdown
        dropdownTools={dropdownTools}
        withButton={user?.user_type !== SUB_ADMIN_TYPE}
        buttonTools={buttonTools}
      />
        {
          isAlertActive &&
          <AlertBox
            error={error}
            additionalStyles='alert-danger'
          />
        }
        <Row>
        {eventsData?.length ? (
          eventsData.map(event => (
            <Col key={event.id} sm={12} md={6} lg={4} xl={4} className="my-2">
              <AdminCardItem
                img={event.logo}
                startDate={event.start_date}
                endDate={event.end_date}
                name={event.name_en}
                region={regionsData[event.region_id]}
                status={event.status}
                redirectTo={() => redirectTo(EDITION, event.id)}
              />
            </Col>
          ))
        ) : (
          <Col>
            <div className="text-center">Not Found</div>
          </Col>
        )}
      </Row>
      <Pagination
        action={getEvents}
        currentPage={events?.current_page}
        lastPage={events?.last_page}
        type={status.type}
        goToPage
      />
    </Loader>
  )
}

export default EventsList;