import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Logo from "assets/icons/logo.svg";
import MenuItem from "./MenuItem";
import * as Paths from "constants/paths";
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu } from "store/actions/auth.actions";
import { useOnClickOutside } from "utils/hooks";
import { useTranslation } from "react-i18next";

const ProfileSideBar = ({ modalOpen }) => {
  const { openMenu } = useSelector((state) => state.auth);
  const ref = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = () => dispatch(toggleMenu());

  const userMenuItems = [
    [
      {
        caption: t("seasons_events.user"),
        title: "dashboard.title",
        to: Paths.DASHBOARD,
        className: styles.home
      },
      {
        title: "interests.myElements",
        to: Paths.INTERESTS,
        className: styles.bookmark
      },
      {
        title: "interests.myRequests",
        to: Paths.REQUESTS,
        className: styles.fileText
      },
      {
        title: "howToUse.title",
        open: modalOpen,
        className: styles.alert
      }
    ],
    [
      {
        caption: t("seasons_events.apply"),
        title: "seasons_events.title",
        to: Paths.SAUDI_SEASONS_EVENTS,
        className: styles.grid
      },
      {
        title: "ideas.title",
        to: Paths.IDEAS,
        className: styles.zap
      }
    ]
  ];

  useOnClickOutside(ref, openMenu, () => dispatch(toggleMenu({ open: false })));

  return (
    <div ref={ref} className={styles.profileSidebarWrapper}>
      <div
        className={classnames(
          styles.profileSideBar,
          {
            [styles.profileSideBarOpen]: openMenu
          },
          "beta-v"
        )}
        data-before-content={t("beta.title")}
      >
        <button className={styles.toggleButton} onClick={handleClick}></button>
        <div className={styles.profileSideBarHeader}>
          <div className={styles.logo}>
            <Link to={Paths.DASHBOARD}>
              <img alt="logo" src={Logo} />
            </Link>
          </div>
        </div>
        <div className={styles.profileSideBarContent}>
          {userMenuItems.map((item, index) => (
            <div key={index} className={item[0].lastBlock && styles.lastBlock}>
              <div className={styles.caption}> {item[0].caption}</div>
              {item.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileSideBar;
