import React, { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { AdminTopbar, AdminSideBar } from "components";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const AdminLayout = ({ children, title }) => {
  const [open, setOpen] = useState(useSelector(state => state.auth.menuStatus));

  return (
    <div id="wrapper">
      <AdminTopbar open={open} setOpen={setOpen} />
      <AdminSideBar isCondensed={open} />
      <div className={classnames("content-page", open ? "mL" : "ml-10")}>
        <div className="content">
          <div className="container-fluid">
            {title && (
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className={classnames("page-title", styles.titleText)}>{title}</h4>
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
export default AdminLayout;
