import {SET_CURRENT_GROUP_MEMBER, SET_CURRENT_TAB, SET_SOCIAL_MEDIA_OPEN } from '../actions/accordion.actions';
const initialState = {
  currentGroupMember: null,
  socialMediaOpen: true,
  detailsSelected:{
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false
  }
}
export default function(state = initialState, action) {
  switch(action.type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        detailsSelected: action.payload
    }
    case SET_CURRENT_GROUP_MEMBER:
      return {
        ...state,
        currentGroupMember: action.payload
      }
    case SET_SOCIAL_MEDIA_OPEN:
      return {
        ...state,
        socialMediaOpen: action.payload
      }
    default:
    return state;
  }
}