import React from "react"
import Layout from "layouts/Admin";
import { SeasonEventRequestInfo } from "components"

const AdminSeasonEventRequestInfo = () => {
  return (
    <Layout title="Requests">
      <SeasonEventRequestInfo/>
    </Layout>
  )
}

export default AdminSeasonEventRequestInfo