import axios from "axios";
import { BASE_URL } from "constants/api";
import * as Paths from "constants/paths";
import { AR_LANG, EN_LANG } from "constants/forms";
import { ADMIN } from "components/protectedRoute/config";
import store from "../store/store";
import history from "../history";
// import { isDevTestMode } from "../utils/helpers";
import { alert } from "../store/actions/alert.actions";

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`,
});

const NO_REDIRECT_URLS = [
  "/auth/login",
  "/auth/confirm-signup",
  "/admin/auth/login",
  "/auth/generate-mobile-otp",
];

const requestHandler = (request) => {

  // const {contentLang} =  request.params ? request.params : EN_LANG;

  request.headers["Access-Control-Allow-Origin"] = "*";

  const token = localStorage.getItem("token");
  const guest_token = localStorage.getItem("guest_token");
  const admin_token = localStorage.getItem("admin_token");

  const lang = localStorage.getItem("i18nextLng");
  request.headers["Content-Language"] = lang || AR_LANG;
  if (window.location.pathname.split("/").includes(ADMIN)) {
    request.headers["Content-Language"] = EN_LANG;
  }
  if (
    admin_token &&
    (history?.location?.pathname.includes(ADMIN) || request.url.includes(ADMIN))
  ) {
    request.headers.Authorization = `Bearer ${admin_token}`;
  } else if (token && history?.location?.pathname !== "/admin") {
    request.headers.Authorization = `Bearer ${token}`;
  } else if (guest_token) {
    request.headers.Authorization = `Bearer ${guest_token}`;
  }
  request.headers["Content-Security-Policy"] =
    "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'";
  request.headers["X-Content-Type-Options"] = "nosniff";
  request.headers["X-XSS-Protection"] = 1;
  request.headers["Strict-Transport-Security"] = "max-age=31536000";
  request.headers["Accept"] = "application/json";
  return request;
};

const errorHandler = async (error) => {
  store.dispatch(alert.set());
  if (
    error.response.status === 401 &&
    !~NO_REDIRECT_URLS.indexOf(error.config.url)
  ) {
    store.dispatch({ type: "UNAUTH_USER", payload: error.response });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("admin_token");
    window.location.href = history?.location?.pathname.includes(ADMIN)
      ? Paths.ADMIN_LOGIN
      : Paths.LOGIN;
    return;
  }

  if (error.response.status === 403) {
    window.location.href = "/403";
  }

  if (error.response.status === 404 || error.response.status === 500) {
    if (!history?.location?.pathname.includes(ADMIN)) {
      window.location.href = Paths.ERROR_PAGE;
    }
  }

  if (error.response.status >= 400) {
    await Promise.reject(error.response);
  }
};

const successHandler = (response) => {
  store.dispatch(alert.remove());
  return response;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
