export const fakeOptions = [
  { name: "Element 1", value: 0 },
  { name: "Element 2", value: 1 },
  { name: "Element 3", value: 2 },
  { name: "Element 4", value: 3 },
];

export const TYPES = {
  season: "saudi-seasons",
  event: "saudi-events",
};

export const SEASONS_ENVENTS_TYPES = {
  season: "seasons",
  event: "events",
};

export const seasonEventStatuses = {
  0: "statusCommingSoon",
  1: "statusOpen",
  2: "statusClose",
};

export const statuses = {
  0: "statusUnderProgress",
  1: "statusAccepted",
  2: "statusRequiresUpdates",
  3: "statusRejected",
  4: "statusOther",
  5: "statusCancelled",
};

export const updatedFileTypes = {
  pdf: "PDF",
  text: "TEXT",
  image: "PNG/JPG/JPEG",
  excel: "XLSX/XLS/CSV/SHEET/EXCEL",
  ".jpeg, .jpg": "JPEG/JPG",
};
export const fileTypes = {
  pdf: "application/pdf",
  text: ".txt",
  image: "image/png, image/jpg, image/jpeg",
  excel:
    ".xlsx, .xls, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const APPLY = "apply";
export const REVIEW = "review";
export const EVENT = "event";
export const SEASON = "season";
export const SET = "SET";
export const REMOVE = "REMOVE";

export const getElementName = (info = {}, dateTimeFn, rtl) => {
  const { element_type, created_at, element_format } = info;
  const name = element_format?.name || element_type?.name;
  const date = `${dateTimeFn(created_at, "l")}, ${dateTimeFn(
    created_at,
    "LT",
    rtl
  )}`;
  return `${name} - ${date}`;
};
