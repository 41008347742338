import React from "react"
import Layout from "layouts/Admin";
import { Reports } from "components"

const AdminReports = () => {
  return (
    <Layout title="Reports">
      <Reports />
    </Layout>
  )
}

export default AdminReports