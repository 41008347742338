import React from "react";
import Layout from "layouts/Admin";
import { MainElements } from "components";

const AdminMainElements = () => {
  return (
    <Layout title="Main Elements">
      <MainElements />
    </Layout>
  );
};

export default AdminMainElements;
