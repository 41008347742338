import React from "react";
import DropDown from "react-dropdown-select";
import styles from "./styles.module.scss";

const items = [
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 }
];

const CountOfMembers = ({ formik }) => {
  const handleChange = e => {
    const count = e[0].id;
    const length = formik.values.personal_info?.length;
    if (count > length) {
      const arr = new Array(count - length).fill({
        birthdate: "",
        country_of_residence_id: "",
        region_id: "",
        full_name_ar: "",
        full_name_en: "", 
        gender: "",
        id: "",
        nationality_id: "", 
        social_status: "",
        special_needs:0
      });
      formik.setFieldValue("personal_info", [
        ...formik.values.personal_info,
        ...arr
      ]);
    } else {
      const arr = formik.values.personal_info?.slice(0, count);
      formik.setFieldValue("personal_info", arr);
    }
  };

  return (
    <div>
      <DropDown
        values={items.filter(i => i.id === formik.values.personal_info?.length)}
        options={items}
        valueField="id"
        labelField="name"
        className={styles.membersDropdown}
        onChange={handleChange}
        keepSelectedInList={false}
      />
    </div>
  );
};

export default CountOfMembers;
