import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

import { useSelector } from "react-redux";
import {
    Loader,
    VideoSection,
    FooterSection,
    HeaderMenu,
    Background,
} from "components";
import { useTranslation } from "react-i18next";
import AnimatedCardSection from "../../components/AnimatedCardSection/AnimatedCardSection";

const LoginLayout = ({ children }) => {
    const { loaded } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    return (
        <Loader loaded={loaded} color={"light"}>
            <Background>
                <div
                    className={`${styles.HomePage} beta-v`}
                    data-before-content={t("beta.title")}
                >
                    <div className={styles.header}>
                        <HeaderMenu />
                        <div>
                            <div
                                className={classnames(
                                    "container",
                                    styles.headerContent
                                )}
                            >
                                {/* <div className={styles.imgBlock}></div> */}
                                <div className={styles.childrenContainer}>
                                    {children}
                                </div>
                                <div className={styles.videoBlockContainer}>
                                    <VideoSection />
                                </div>
                            </div>
                        </div>
                    </div>
                    <AnimatedCardSection />
                    <FooterSection />
                </div>
            </Background>
        </Loader>
    );
};

export default LoginLayout;
