import React from "react";
import { Modal, ModalHeader, Button } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Select from "react-dropdown-select";
import * as Yup from "yup";
import { updateBulkEventSeasonStatusRequest } from "store/actions/adminElements.actions";
import { useFormik } from "formik";
import { useError, useFieldTouched } from "utils/forms";
import {UNDER_PROGRESS, ACCEPTED, REQUIRES_UPDATES, REJECTED, OTHER} from "constants/forms"

const statuses = [
  {id: 0, name: UNDER_PROGRESS},
  {id: 1, name: ACCEPTED},
  {id: 2, name: REQUIRES_UPDATES},
  {id: 3, name: REJECTED},
  {id: 4, name: OTHER},
]

const OTHER_TYPE = 4

const ChangeStatusModalSeasonRequest = ({ modalOpen, closeModal, modalBody, id, currentType,params }) => {
  const dispatch = useDispatch();
  const type = currentType;
  const formik = useFormik({
    initialValues: (modalBody && !modalBody.requests && modalBody.status_info.status !== 5) ? {
      status_from: statuses.filter(item => item.id === modalBody.status_info.status),
      other_status: modalBody.status_info.other_status || "",
      status_to: "",
    } : {
      status_from: [statuses[0]],
      other_status: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status_from: Yup.string().required("Required"),
      status_to: Yup.string().required("Required"),
      other_status: Yup.string().when("status", {
        is: () => showOtherFieldFrom || showOtherFieldTo,
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      })
    }),
    onSubmit: values => {
      closeModal()
      const {event_id, season_id } = params.params
      const paramsData = {
        typeId: event_id || season_id ,
        parameters: {
          element_type_id: event_id || season_id
        },
      }
      let data;
      values.other_status ?
      data = {
        requests:[{
          [`${type}_id`]:id,
          status_from: values.status_from[0].id,
          status_to: values.status_to[0].id,
          other_status: values.other_status ? values.other_status : '' ,
        }]
      }
    :
      data = {
        requests:[{
          [`${type}_id`]:id,
          status_from: values.status_from[0].id,
          status_to: values.status_to[0].id,
        }]
    }
      dispatch(updateBulkEventSeasonStatusRequest.request({type:type,data,params: paramsData}))
    }

  });
  const showOtherFieldFrom = formik.values.status_from[0].id === OTHER_TYPE;
  const otherFieldToArray = formik?.values?.status_to?.map((item) => item.id === OTHER_TYPE );
  const showOtherFieldTo = otherFieldToArray?.every(Boolean);
  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  return (
    <Modal
      className={'modal-dialog-centered modal-md rounded adminDeleteModal'}
      isOpen={modalOpen}
      toggle={closeModal}
    >
      <ModalHeader toggle={closeModal} className="d-flex justify-content-center">
        <p className="text-center">Change Status</p>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label className="control-label">Status - From</label>
            <Select
              name="status_from"
              valueField="id"
              labelField="name"
              values={formik.values.status_from}
              onChange={(value) => {
                formik.setFieldValue("status_from", value)
                formik.setFieldValue("other_status", "")
              }}
              options={statuses}
            />
            {getError("status_from")}
            {
            showOtherFieldFrom && <div className="form-group">
              <input
                className="form-control"
                placeholder="Status"
                type="text"
                name="other_status"
                value={formik.values.other_status}
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("other_status")}
            </div>
          }
          </div>
          <div className="form-group">
            <label className="control-label">Status - To</label>
            <Select
              name="status_to"
              valueField="id"
              labelField="name"
              values={formik.values.status_to}
              onChange={(value) => {
                formik.setFieldValue("status_to", value)
              }}
              options={statuses}
            />
            {getError("status_to")}
          </div>
          {
            showOtherFieldTo && <div className="form-group">
              <input
                className="form-control"
                placeholder="Status"
                type="text"
                name="other_status"
                value={formik.values.other_status}
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("other_status")}
            </div>
          }
          <div className="d-flex justify-content-center mt-4">
            <Button className="mr-4 btn-paua" type="submit">Confirm</Button>
            <Button type='button' onClick={() => closeModal()} className="ml-4 btn-gray">Cancel</Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ChangeStatusModalSeasonRequest;