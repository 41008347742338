import { call, delay, put, takeLatest } from "redux-saga/effects";
import { AuthApi } from "services/auth.service";
import i18next from 'i18next';
import {
  SIGN_UP,
  CONFIRM_SIGNUP,
  LOGIN,
  GET_MOBILE_OTP,
  LOGOUT,
  GET_USER,
  GET_GUEST_TOKEN,
  LOGIN_ADMIN,
  LOGOUT_ADMIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_ADMIN,
  EDIT_ADMIN,
  CHANGE_PASSWORD,
  EDIT_USER_INFO,
  LOGIN_ADMIN_OTP,
  INVALID_TOKEN_REDIRECT,
  signUp,
  login,
  confirmSignup,
  logout,
  getUser,
  getMobileOTP,
  getGuestToken,
  loginAdmin,
  logoutAdmin,
  forgotPassword,
  resetPassword,
  getAdmin,
  editAdmin,
  changePassword,
  editUserInfo,
  loginAdminOTP,
  invalidTokenRedirect,
  clearError
} from "store/actions/auth.actions";
import { getFile } from "store/actions/saudiSeasonsEvents.actions";
import history from "../../history";
import * as Paths from "constants/paths";
import { BAD_REQUEST, UNAUTHORIZED, UNPROCESSABLE_ENTITY } from "constants/api";
import {
  emailNotFound,
  invalidPasswordLink
} from "constants/messages";

const EXPIRATION_TIME = 1000 * 60 * 2;

function* signUpWorker(action) {
  try {
    yield call(AuthApi.signUp, action.payload);
    localStorage.setItem("action", "confirm");
    localStorage.setItem(
      "target",
      JSON.stringify({ mobile_number: action.payload.mobile_number })
    );
    yield put(signUp.success());
    localStorage.setItem("newUser", true)
  } catch (e) {
    yield put(signUp.failure(e.data));
  }
}

function* confirmSignupWorker(action) {
  try {
    const {
      data: { access_token }
    } = yield call(AuthApi.confirmSignUp, action.payload);
    localStorage.removeItem("target");
    localStorage.removeItem("action");
    localStorage.setItem("token", access_token);
    yield put(confirmSignup.success());
  } catch (e) {
    yield put(confirmSignup.failure(e.data));
  }
}

function* loginWorker({ payload }) {
  const dv = localStorage.getItem("dv");
  try {
    const { data } = yield call(AuthApi.login, payload);
    localStorage.setItem("token", data.access_token);
    localStorage.setItem("notification_channel", data.user_id);
    yield put(login.success());
    if (dv) {
      yield put(getFile.request(dv));
    }
  } catch (e) {
    yield put(login.failure(e.data));
  }
}

function* mobileOTPWorker({ payload }) {
  try {
    const { data } = yield call(AuthApi.generateMobileOTP, payload);
    localStorage.setItem(
      "otp_expiration",
      new Date().getTime() + EXPIRATION_TIME
    );
    yield put(getMobileOTP.success(data));
  } catch (e) {
    const payloadTmp = { error: {} };
    switch (e.status) {
      case BAD_REQUEST:
        payloadTmp.openOtpModal = true
        payloadTmp.error = {message: i18next.t('messages.previousOTPIsNotExpired')};
        break;
      case UNPROCESSABLE_ENTITY:
        payloadTmp.error = {message: i18next.t('messages.wrongCredentials')}
        break;
      case UNAUTHORIZED:
        localStorage.removeItem("guest_token");
        yield call(getGuestTokenWorker);
        yield put(getMobileOTP.request(payload));
        break;
      default:
        payloadTmp.error = e.data
        break;
    }

    yield put(getMobileOTP.failure(payloadTmp));
    yield delay(3000);
    yield put(clearError())
    // yield put(getMobileOTP.failure(e.data));
  }
}

function* logoutWorker() {
  try {
    yield call(AuthApi.logout);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    yield put(logout.success());
    history.push(Paths.LOGIN);
  } catch (e) {
    yield put(logout.failure(e.data));
  }
}

function* getUserWorker() {
  try {
    const { data } = yield call(AuthApi.getUser);
    localStorage.setItem("user", JSON.stringify(data));
    yield put(getUser.success(data));
  } catch (e) {
    yield put(getUser.failure(e.data));
  }
}

function* getGuestTokenWorker() {
  try {
    const { data: { guest_token } } = yield call(AuthApi.getGuestToken);
    localStorage.setItem("guest_token", guest_token);
    yield put(getGuestToken.success());
  } catch (e) {
    yield put(getGuestToken.failure(e.data));
  }
}

function* loginAdminWorker({ payload }) {
  try {
    const {
      data: { access_token }
    } = yield call(AuthApi.loginAdmin, payload);
    localStorage.setItem("admin_token", access_token);
    const { data } = yield call(AuthApi.getAdmin);
    const adminData = { ...data, admin: true, director: data?.user_type === 3 }
    localStorage.setItem("user", JSON.stringify(adminData));
    yield put(getAdmin.success(adminData));
    yield put(loginAdmin.success({
       user: adminData,
       adminToken: access_token
      }));
  } catch (e) {
    if (e.status === UNAUTHORIZED) e.data.message = i18next.t('messages.wrongCredentials');
    yield put(loginAdmin.failure(e.data));
  }
}

function* logoutAdminWorker() {
  try {
    yield call(AuthApi.logoutAdmin);
    localStorage.removeItem("admin_token");
    localStorage.removeItem("user");
    // history.push(Paths.ADMIN_LOGIN);
    yield put(logoutAdmin.success());
  } catch (e) {
    yield put(logoutAdmin.failure(e.data));
  }
}

function* forgotPasswordWorker({ payload }) {
  try {
    yield call(AuthApi.forgotPassword, payload);
    yield put(forgotPassword.success());
  } catch (e) {
    if (e.status === BAD_REQUEST) {
      e.data.message = emailNotFound;
      e.data.errors = null;
    }
    yield put(forgotPassword.failure(e.data));
  }
}

function* resetPasswordWorker({ payload }) {
  try {
    yield call(AuthApi.resetPassword, payload);
    yield put(resetPassword.success());
  } catch (e) {
    yield put(resetPassword.failure(e.data));
  }
}

function* getAdminWorker() {
  try {
    const { data } = yield call(AuthApi.getAdmin);
    localStorage.setItem("user", JSON.stringify({ ...data, admin: true, director: data?.user_type === 3 }));
    yield put(getAdmin.success({ ...data, admin: true, director: data?.user_type === 3 }));
  } catch (e) {
    yield put(getAdmin.failure(e.data));
  }
}

function* editAdminWorker({ payload }) {
  try {
    const { data } = yield call(AuthApi.editAdmin, payload);
    localStorage.setItem("user", JSON.stringify({ ...data, admin: true }));
    yield put(editAdmin.success({ ...data, admin: true }));
  } catch (e) {
    yield put(editAdmin.failure(e.data));
  }
}

function* changePasswordWorker({ payload }) {
  try {
    yield call(AuthApi.changePassword, payload);
    yield put(changePassword.success());
  } catch (e) {
    yield put(changePassword.failure(e.data));
  }
}

function* editUserInfoWorker({ payload }) {
  try {
    const { data } = yield call(AuthApi.editUserInfo, payload);
    localStorage.setItem("user", JSON.stringify({ ...data, admin: false }));
    // yield put(editAdmin.success({ ...data, admin: true }));
    yield put(editUserInfo.success(data));
  } catch (e) {
    yield put(editUserInfo.failure(e.data));
  }
}
function* loginAdminOTPWorker ({payload}) {
  try {
    const { data } = yield call(AuthApi.loginAdmin, payload);
    localStorage.setItem(
      "otp_expiration",
      new Date().getTime() + EXPIRATION_TIME
    );
    yield put(loginAdminOTP.success(data));
  } catch (e) {
    const payloadTmp = { error: {} };

    switch (e.status) {
      case BAD_REQUEST:
        payloadTmp.openOtpModal = true
        payloadTmp.error = e.data;
        break;
      case UNPROCESSABLE_ENTITY:
        payloadTmp.error = {message: i18next.t('messages.wrongCredentials')}
        break;
      case UNAUTHORIZED:
        // localStorage.removeItem("guest_token");
        // yield call(getGuestTokenWorker);
        // yield put(loginAdminOTP.request(payload));
        payloadTmp.errors = e.data.errors;
        payloadTmp.message = e.data.message;
        break;
      default:
        payloadTmp.error = e.data
        break;
    }

    yield put(loginAdminOTP.failure(payloadTmp));
  }
}

function* invalidTokenRedirectWorker() {
  history.push("/admin/forgot-password")
  yield put(invalidTokenRedirect.success(invalidPasswordLink))
}

function* authorizationSaga() {
  yield takeLatest(SIGN_UP.REQUEST, signUpWorker);
  yield takeLatest(LOGIN.REQUEST, loginWorker);
  yield takeLatest(GET_GUEST_TOKEN.REQUEST, getGuestTokenWorker);
  yield takeLatest(GET_MOBILE_OTP.REQUEST, mobileOTPWorker);
  yield takeLatest(CONFIRM_SIGNUP.REQUEST, confirmSignupWorker);
  yield takeLatest(LOGOUT.REQUEST, logoutWorker);
  yield takeLatest(GET_USER.REQUEST, getUserWorker);
  yield takeLatest(LOGIN_ADMIN.REQUEST, loginAdminWorker);
  yield takeLatest(LOGOUT_ADMIN.REQUEST, logoutAdminWorker);
  yield takeLatest(FORGOT_PASSWORD.REQUEST, forgotPasswordWorker);
  yield takeLatest(RESET_PASSWORD.REQUEST, resetPasswordWorker);
  yield takeLatest(GET_ADMIN.REQUEST, getAdminWorker);
  yield takeLatest(EDIT_ADMIN.REQUEST, editAdminWorker);
  yield takeLatest(CHANGE_PASSWORD.REQUEST, changePasswordWorker);
  yield takeLatest(EDIT_USER_INFO.REQUEST, editUserInfoWorker);
  yield takeLatest(LOGIN_ADMIN_OTP.REQUEST, loginAdminOTPWorker);
  yield takeLatest(INVALID_TOKEN_REDIRECT.REQUEST, invalidTokenRedirectWorker);
}

export default authorizationSaga;
