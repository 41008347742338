import { call, put, takeLatest } from "redux-saga/effects";
import { MediaCenterApi } from "services/mediaCenter.service";
import {
    GET_MEDIA_CENTER_NEWS,
    GET_MEDIA_CENTER_NEWS_DETAILS,
    GET_MEDIA_CENTER_PHOTOS,
    GET_MEDIA_CENTER_VIDEOS,
    GET_MEDIA_CENTER_PHOTOS_DETAILS,
    GET_MEDIA_CENTER_VIDEOS_DETAILS,
    GET_MEDIA_CENTER_CAROUSEL_IMAGES,
    getMediaCenterNews,
    getMediaCenterNewsDetails,
    getMediaCenterPhotos,
    getMediaCenterVideos,
    getMediaCenterPhotosDetails,
    getMediaCenterVideosDetails,
    getMediaCenterCarouselImages

} from "store/actions/mediaCenter.actions";

function* getMediaCenterNewsWorker() {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterNews);
        yield put(getMediaCenterNews.success(data));
    } catch (e) {
        yield put(getMediaCenterNews.failure(e.data));
    }
}

function* getMediaCenterNewsDetailsWorker({ payload }) {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterNewsDetails, payload);
        yield put(getMediaCenterNewsDetails.success(data));
    } catch (e) {
        yield put(getMediaCenterNewsDetails.failure(e.data));
    }
}

function* getMediaCenterPhotosWorker({ payload }) {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterPhotos, payload);
        yield put(getMediaCenterPhotos.success(data));
    } catch (e) {
        yield put(getMediaCenterPhotos.failure(e.data));
    }
}

function* getMediaCenterPhotosDetailsWorker({ payload }) {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterPhotosDetails, payload);
        yield put(getMediaCenterPhotosDetails.success(data));
    } catch (e) {
        yield put(getMediaCenterPhotosDetails.failure(e.data));
    }
}

function* getMediaCenterVideosWorker({ payload }) {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterVideos, payload);
        yield put(getMediaCenterVideos.success(data));
    } catch (e) {
        yield put(getMediaCenterVideos.failure(e.data));
    }
}

function* getMediaCenterVideosDetailsWorker({ payload }) {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterVideosDetails, payload);
        yield put(getMediaCenterVideosDetails.success(data));
    } catch (e) {
        yield put(getMediaCenterVideosDetails.failure(e.data));
    }
}

function* getMediaCenterCarouselImagesWorker() {
    try {
        const { data } = yield call(MediaCenterApi.getMediaCenterCarouselImages);
        yield put(getMediaCenterCarouselImages.success(data));
    } catch (e) {
        yield put(getMediaCenterCarouselImages.failure(e.data));
    }
}

function* mediaCenterSaga() {
    yield takeLatest(GET_MEDIA_CENTER_NEWS.REQUEST, getMediaCenterNewsWorker);
    yield takeLatest(GET_MEDIA_CENTER_NEWS_DETAILS.REQUEST, getMediaCenterNewsDetailsWorker);
    yield takeLatest(GET_MEDIA_CENTER_PHOTOS.REQUEST, getMediaCenterPhotosWorker);
    yield takeLatest(GET_MEDIA_CENTER_PHOTOS_DETAILS.REQUEST, getMediaCenterPhotosDetailsWorker);
    yield takeLatest(GET_MEDIA_CENTER_VIDEOS.REQUEST, getMediaCenterVideosWorker);
    yield takeLatest(GET_MEDIA_CENTER_VIDEOS_DETAILS.REQUEST, getMediaCenterVideosDetailsWorker);
    yield takeLatest(GET_MEDIA_CENTER_CAROUSEL_IMAGES.REQUEST, getMediaCenterCarouselImagesWorker);
}

export default mediaCenterSaga;

