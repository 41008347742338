import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { alert } from "store/actions/alert.actions";
import { useDispatch } from "react-redux";

const AdminTopPanel = ({
  withSearch,
  searchTools,
  withDropdown,
  dropdownTools,
  withButton,
  withButtons,
  buttonTools,
  buttonsTools,
  extraParams = {},
  type,
}) => {
  const { params } = extraParams;
  const dispatch = useDispatch();
  return (
    <div className="card">
      <div className="card-body d-flex justify-content-between align-items-center">
        {withSearch &&
          (searchTools.items ? (
            <div
              className={classNames("d-flex", {
                [`order-${searchTools.order}`]: searchTools.order,
              })}
            >
              {searchTools.items.map((item, idx) => (
                <input
                  name={item.key}
                  key={idx}
                  value={item.value}
                  className="form-control mx-2"
                  placeholder={item.placeholder}
                  onChange={item.setValue}
                />
              ))}
              <input type="submit" className="d-none" />
            </div>
          ) : (
            <div className="mx-3 flex-grow-0 order-1 b-dark">
              <input
                onChange={searchTools.onChange}
                className="form-control"
                placeholder={searchTools.placeholder}
              />
            </div>
          ))}

        {withDropdown && (
          <div
            className={classNames("d-flex mx-3 align-items-center", {
              [`order-${dropdownTools.order}`]: dropdownTools.order,
            })}
          >
            <p
              className={classNames("mb-0 pr-1 m-0", {
                requiredField: dropdownTools.required,
              })}
            >
              {dropdownTools.label}:
            </p>
            <DropdownButton
              variant="light"
              id="dropdown-basic-button"
              title={dropdownTools.optionSelected?.name || "Select a type"}
            >
              {dropdownTools.dropdownElements.map((element) => (
                <Dropdown.Item
                  key={element.type}
                  onClick={() => dropdownTools.setOptionSelected(element)}
                >
                  {element.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        )}

        {withButton && (
          <div
            className={classNames("ml-auto mx-3", {
              [`order-${buttonTools.order}`]: buttonTools.order,
            })}
          >
            <button
              onClick={() => {
                if (
                  (dropdownTools?.optionSelected?.name === "All" ||
                    !dropdownTools?.optionSelected?.name) &&
                  dropdownTools?.required
                ) {
                  dispatch(alert.set());
                } else {
                  buttonTools.onClick(params, type);
                }
              }}
              className={classNames(
                "btn btn-default ml-auto",
                styles.exportBtn
              )}
            >
              {buttonTools.label}
            </button>
          </div>
        )}
        {withButtons && (
          <div className={styles.buttonSection}>
              {buttonsTools.map((element,index) => (
                <button
                key={index}
                  onClick={() => {
                      element.onClick(params, type);
                  }}
                  className={classNames(
                    "btn btn-default ml-auto",
                    styles.exportBtn
                  )}
                >
                  {element.label}
                </button>
          ))}
          </div>
        )}
      </div>
    </div>
  );
};

AdminTopPanel.propTypes = {
  withSearch: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchTools: PropTypes.exact({
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.exact({
        placeholder: PropTypes.string,
        value: PropTypes.string,
        setValue: PropTypes.func,
        key: PropTypes.string,
      })
    ),
    order: PropTypes.number,
  }),
  withDropdown: PropTypes.bool,
  dropdownTools: PropTypes.exact({
    label: PropTypes.string,
    required: PropTypes.bool,
    dropdownElements: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.string,
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      })
    ),
    optionSelected: PropTypes.exact({
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number,PropTypes.bool]),
    }),
    setOptionSelected: PropTypes.func,
    order: PropTypes.number,
  }),
  withButton: PropTypes.bool,
  withButtons: PropTypes.bool,
  buttonTools: PropTypes.exact({
    label: PropTypes.string,
    onClick: PropTypes.func,
    order: PropTypes.number,
    extraParams: PropTypes.object,
  }),
  buttonsTools: PropTypes.arrayOf(
    PropTypes.exact({
    label: PropTypes.string,
    onClick: PropTypes.func,
    order: PropTypes.number,
    extraParams: PropTypes.object,
  })),
};

export default AdminTopPanel;