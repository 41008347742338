import React, { useEffect, useContext } from "react";
import { Loader,AlertBox } from "components";
import {
  getAdminMainElementsTypes,
  getAllCategories,
  postAssignDirectorToElements,
  getAssignDirectorToElements,
  updateAssignDirectorToElements
} from "store/actions/adminElements.actions";
import { Context } from "context";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serialize } from "object-to-formdata";
import { checkCategories,checkSubCategories } from "./helpers";

const  AssignDirectorToElement = ({id}) => {
  const { loaded, error, adminMainElements, allCategories, assignDirectorToElementsInformation, ...data } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const { user } = useContext(Context);
  const viewOnly = user?.user_type === 3
  const dispatch = useDispatch();

useEffect(() => {
    dispatch(getAdminMainElementsTypes.request({viewOnly,type: null}));
}, [dispatch, viewOnly]);

useEffect(() => {
    if(id){
      dispatch(getAssignDirectorToElements.request(id))
    }
},[ dispatch,id])

useEffect(() => {
   dispatch(getAllCategories.request());
}, [dispatch]);

  const FORMATS = [
    { id: "42fb5884-5a36-48e0-b7b8-5a96acc4f15a", formats: "restaurant-formats", data: "restaurantFormats"},
    { id: "a0558093-1d16-4bc6-a4ad-ad7aa9994198", formats: "store-formats", data: "storeFormats"}
  ]

  const showErrorMessage = (name) => (
    <ErrorMessage
      name={name}
      render={msg =>
        <span className="text-danger font-weight-normal">
          {msg}
        </span>
      }
    />
  )
  const submitForm = (values) => {
    const data = {
    director_id:values.director_id,
    elements: values.elements.map((element) => {
      let data = {
        element: element.element?.length ? element.element.map((el)=>({id:el.id, name:el.name})) : [],
        format: element.format?.length ? element.format.map((el)=>({id:el.id, name:el.name})) : [],
        categories: element.categories?.length ? element.categories.map((el)=>({id:el.id,name:el.name,children:el.children})) : [],
        sub_categories:  element.sub_categories?.length ? element.sub_categories.map((el)=>({id:el.id, name:el.name})) : [],
      }
      data = {
        ...data,
      }
      return data
    })
  }
    if(assignDirectorToElementsInformation.director_id){
      dispatch(updateAssignDirectorToElements.request({id, data:serialize({...data, _method: 'PUT'}, { indices: true })}));
    }else{
      dispatch(postAssignDirectorToElements.request(serialize({...data}, { indices: true })))
    }
  }

  const validationSchema =  Yup.object().shape({
    director_id: Yup.string().required("Required"),
    elements: Yup.array().of(
      Yup.object().shape({
          element: Yup.array().required("Required"),
      })
  ),
  })

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
            error={error}
            additionalStyles='alert-danger'
        />
      }
      <div className="pb-4">
        <Formik
            initialValues={{
              director_id: id ? id : assignDirectorToElementsInformation.director_id,
              elements: (id && assignDirectorToElementsInformation.director_id ? assignDirectorToElementsInformation?.elements?.map((item, index) => ({
                element: assignDirectorToElementsInformation?.elements[index]?.element || [],
                format: assignDirectorToElementsInformation?.elements[index]?.format || [],
                categories: assignDirectorToElementsInformation?.elements[index]?.categories || [],
                sub_categories:assignDirectorToElementsInformation?.elements[index]?.sub_categories || [],
              }))
              :[
                  {
                    element: "",
                    format: "",
                    categories:'',
                    sub_categories:''
                  }
                ]
              )
            }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={values => submitForm(values, true)}
          >{ ({ values, errors, setFieldValue }) => (
              <Form>
              <section>
              <FieldArray name="elements">
                {({remove, push, form}) => (
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h4>Assign Director to Element</h4>
                        {!viewOnly && <Button className="btn-paua" onClick={() => push({element: "", format: "",categories:'', sub_categories: ''})}>
                          New Element
                        </Button>}
                      </div>
                      {
                        values.elements.map((element, index) => {
                          return (
                            <div className={`border border-light rounded p-2 my-2 ${styles.element__container}`} key={index}>
                            {values.elements?.length > 1 && !viewOnly &&
                                <span
                                  className={classNames(`font-weight-bold ${styles.image__close__elements}`)}
                                  onClick={() => {
                                    remove(index)
                                  }}
                                >
                                  ×
                                </span>
                              }
                              <div className="form-group">
                                <label className="control-label">Elements:</label>
                                <Select
                                  className={`${styles.element__dropdown}`}
                                  name={`elements[${index}].element`}
                                  valueField="id"
                                  labelField="name"
                                  values={values?.elements[index].element || []}
                                  onChange={(value) => {
                                    form.setFieldValue(`elements[${index}].element`, value)
                                    if(values?.elements[index].element !== value){
                                      form.setFieldValue(`elements[${index}].categories`, [])
                                      form.setFieldValue(`elements[${index}].format`, [])
                                      form.setFieldValue(`elements[${index}].sub_categories`, [])
                                    }
                                  }}
                                  options={adminMainElements}
                                  disabled={viewOnly}
                                />
                                {showErrorMessage(`element[${index}].id`)}
                              </div>
                              {data[FORMATS.find(item => item.id === values?.elements[index]?.element[0]?.id)?.data]?.length && <div className="form-group">
                                <label className="control-label">Formats:</label>
                                <Select
                                  className={`${styles.element__dropdown}`}
                                  name={`elements[${index}].format`}
                                  valueField="id"labelField="name"
                                  onChange={(value) => {
                                    form.setFieldValue(`elements[${index}].format`, value)
                                    if(values.elements[index]?.categories?.length){
                                      form.setFieldValue(`elements[${index}].categories`, [])
                                    }
                                  }}
                                  values={values.elements[index].format || []}
                                  options={ data[FORMATS.find(item => item.id === values?.elements[index]?.element[0]?.id)?.data] || [] }
                                  disabled={viewOnly}
                                />
                                {showErrorMessage(`elements[${index}].format`)}
                              </div>}
                              {values?.elements[index].element?.length > 0 && <div className="form-group">
                                <label className="control-label">Categories:</label>
                                <Select
                                  className={`${styles.element__dropdown}`}
                                  name={`elements[${index}].categories`}
                                  valueField="id"
                                  labelField="name"
                                  onChange={(value) => {
                                    form.setFieldValue(`elements[${index}].categories`, value)
                                    if(values.elements[index]?.sub_categories?.length){
                                      form.setFieldValue(`elements[${index}].sub_categories`, [])
                                    }
                                  }}
                                  values={values.elements[index].categories || []}
                                  options={checkCategories(values,index,allCategories)}
                                  disabled={viewOnly}
                                />
                                {showErrorMessage(`element[${index}].categories`)}
                              </div>}
                              {values?.elements[index]?.categories[0]?.children?.length > 0 ?
                              <div className="form-group">
                                <label className="control-label">Sub Categories:</label>
                                <Select
                                  className={`${styles.element__dropdown}`}
                                  name={`elements[${index}].sub_categories`}
                                  valueField="id"
                                  labelField="name"
                                  onChange={(value) => form.setFieldValue(`elements[${index}].sub_categories`, value)}
                                  values={values.elements[index].sub_categories || []}
                                  options={checkSubCategories(values,index,allCategories)}
                                  disabled={viewOnly}
                                  multi
                                />
                                {showErrorMessage(`elements[${index}].sub_categories`)}
                              </div> : null}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )}
              </FieldArray>
            </section>
            {!viewOnly && <section className="d-flex">
              <Button className="btn-paua ml-auto" type="submit">Save</Button>
            </section>
            }
          </Form>
          )}
        </Formik>
      </div>
    </Loader>
  )
}
export default React.memo(AssignDirectorToElement);