import React, { useState, useContext } from "react";
import styles from "./styles.module.scss";
import { Modal } from "reactstrap";
import Chevron from "assets/icons/Blue_Arrow.svg";
import WhiteChevron from "assets/icons/White_Arrow.svg";
import { Context } from "context";
import classnames from "classnames";
import PropTypes from "prop-types";

const InterestModal = ({ isOpened, toggle, data, img }) => {
    const { rtl } = useContext(Context);
    const [active, setActive] = useState(0);

    const scrollTop = () => {
        toggle();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const next = () => {
        if (active < data?.items?.length - 1) setActive(active + 1);
    };

    const prev = () => {
        if (active > 0) setActive(active - 1);
    };

    const getDescription = (data) =>
        data?.map((item, index) => {
            return item.bold ? (
                <b key={index}>{item.bold} </b>
            ) : !item.link ? (
                <span key={index} className={styles.spacedText}>
                    {item.text}
                </span>
            ) : (
                <span className={styles.link} key={index} onClick={scrollTop}>
                    {item.link}
                </span>
            );
        });
    return (
        <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={isOpened}
            toggle={toggle}
            contentClassName="interest-modal"
            returnFocusAfterClose={false}
        >
            {data?.items && (
                <img
                    className={classnames(styles.chevron, {
                        [styles.leftChevron]: !rtl,
                    })}
                    src={active === 0 ? WhiteChevron : Chevron}
                    alt=""
                    onClick={prev}
                    role="button"
                />
            )}
            <div className={styles.interestModalBox}>
                <button className={styles.closeBtn} onClick={toggle}>
                    &times;
                </button>
                <div className={styles.modalContent}>
                    <div className={styles.imgBox}>
                        <img src={img?.subImages?.[active] || img} alt="" />
                    </div>
                    <div className={styles.modalInfo}>
                        <p className={styles.modalTitle}>
                            {data?.items?.[active]?.title || data?.title}
                        </p>
                        <p className={styles.modalDescription}>
                            {getDescription(
                                data?.items?.[active]?.description ||
                                    data?.description
                            )}
                        </p>
                    </div>
                </div>
            </div>
            {data?.items && (
                <img
                    src={
                        active !== data?.items?.length - 1
                            ? Chevron
                            : WhiteChevron
                    }
                    className={classnames(styles.chevron, {
                        [styles.leftChevron]: rtl,
                    })}
                    alt=""
                    onClick={next}
                />
            )}
            <div className={styles.pagination}>
                {data?.items?.map((el, index) => (
                    <div
                        key={el.title}
                        className={classnames(styles.paginationItem, {
                            [styles.activeItem]: active === index,
                        })}
                    />
                ))}
            </div>
        </Modal>
    );
};

InterestModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    img: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    data: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        items: PropTypes.array,
    }),
};

export default InterestModal;
