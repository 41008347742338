import React from "react";
import { ResetPasswordForm } from "components";
import Layout from "layouts/AdminLogin";

const ResetPassword = () => (
  <Layout>
    <ResetPasswordForm />
  </Layout>
);

export default ResetPassword;
