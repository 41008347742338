import React from "react";
import { Modal, ModalHeader, Button } from "reactstrap";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Select from "react-dropdown-select";
import { changeRequestStatus } from "store/actions/adminElements.actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError, useFieldTouched } from "utils/forms";
import {UNDER_PROGRESS, ACCEPTED, REQUIRES_UPDATES, REJECTED, OTHER, AR_LANG, EN_LANG} from "constants/forms"

const statuses = [
  {id: 0, name: UNDER_PROGRESS},
  {id: 1, name: ACCEPTED},
  {id: 2, name: REQUIRES_UPDATES},
  {id: 3, name: REJECTED},
  {id: 4, name: OTHER},
]

const languages = [
  {name: AR_LANG, value: 'Arabic'},
  {name: EN_LANG, value: 'English'}
]

const OTHER_TYPE = 4

const ChangeStatusModal = ({ modalOpen, closeModal, modalBody, params }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: (modalBody && !modalBody.requests && modalBody.status_info.status !== 5) ? {
      status: statuses.filter(item => item.id === modalBody.status_info.status),
      other_status: modalBody.status_info.other_status || "",
      language: languages.filter(item => item.name === AR_LANG)
    } : {
      status: [statuses[0]],
      other_status: "",
      language: languages.filter(item => item.name === AR_LANG)
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status: Yup.string().required("Required"),
      language: Yup.string().required("Required"),
      other_status: Yup.string().when("status", {
        is: () => showOtherField,
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),
    }),
    onSubmit: values => {
      closeModal()
      const {event_id, season_id,season_event_id } = params.params
      const paramsData = {
        typeId: event_id || season_id || season_event_id ,
        parameters: {
          element_type_id: event_id || season_id || season_event_id
        },
        contentLang: values.language[0].name
      }
      let data;
      if(modalBody.requests){
        data = {
          requests: modalBody.requests.map((request) => {
            let obj = {
              ...request,
              status: values.status[0].id,
            }
            if(values.other_status){
              obj.other_status = values.other_status
            }
            return obj
          })
        }
      } else{
        data = {
          status: values.status[0].id,
        }
        if(values.other_status){
          data.other_status = values.other_status
        }
      }
      dispatch(changeRequestStatus.request({data, id: modalBody.id, params: paramsData}))
    }
  });
  const showOtherField = formik.values.status[0].id === OTHER_TYPE

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  return (
    <Modal
      className={'modal-dialog-centered modal-md rounded adminDeleteModal'}
      isOpen={modalOpen}
      toggle={closeModal}
    >
      <ModalHeader toggle={closeModal} className="d-flex justify-content-center">
        <p className="text-center">Change Status</p>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label className="control-label">Status</label>
            <Select
              name="status"
              valueField="id"
              labelField="name"
              values={formik.values.status}
              onChange={(value) => {
                formik.setFieldValue("status", value)
                formik.setFieldValue("other_status", "")
              }}
              options={statuses}
            />
            {getError("status")}
          </div>
          {/* <div className="form-group">
          <label className="control-label">Email Language</label>
            <Select
              name="language"
              valueField="name"
              labelField="value"
              values={formik.values.language}
              onChange={(value) => {
                formik.setFieldValue("language", value)
              }}
              options={languages}
            />
            {getError("language")}
          </div> */}
          {
            showOtherField && <div className="form-group">
              <input
                className="form-control"
                placeholder="Status"
                type="text"
                name="other_status"
                value={formik.values.other_status}
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("other_status")}
            </div>
          }
          <div className="d-flex justify-content-center mt-4">
            <Button className="mr-4 btn-paua" type="submit">Confirm</Button>
            <Button type='button' onClick={() => closeModal()} className="ml-4 btn-gray">Cancel</Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ChangeStatusModal