import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

const RadioButton = ({
  name,
  value,
  onChange,
  onClick,
  formik,
  required,
  checked,
  className,
  disabled,
}) => {
  return (
    <input
      type="radio"
      name={name}
      value={value}
      className={classnames(className, styles.radio)}
      onChange={onChange}
      onClick={onClick}
      checked={
        checked || formik?.values?.[name]?.toString() === value?.toString()
      }
      required={required}
      disabled={disabled}
    />
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  formik: PropTypes.object,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioButton;
