import React from "react";
import { useDispatch } from "react-redux";
import { toggleMenu } from "store/actions/auth.actions";
import { NotificationsBox } from "components";
import styles from "./styles.module.scss";
import Logo from "assets/images/MatloobLogoBlack.svg";

const ProfileTopBar = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(toggleMenu());

  return (
    <div className={styles.topBar}>
      <div className={styles.topBarContent}>
        <div className={styles.info}>
          <button className={styles.toggleButton} onClick={handleClick}>
            <div></div>
            <div></div>
            <div></div>
          </button>
          <div>
            <img alt="logo" src={Logo} />
          </div>
          <NotificationsBox />
        </div>
      </div>
    </div>
  );
};

export default ProfileTopBar;
