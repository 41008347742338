import React from "react";
import styles from "./styles.module.scss";
import { seasonEventStatuses } from "constants/seasonsEvents";
import cn from "classnames";

export const ProgressBar = ({
  createdAt,
  endDate,
  startDate,
  dayTo,
  status,
}) => {
  const periodBetween =
    new Date(status === 0 ? startDate : endDate) - new Date(createdAt);
  const diffDays = Math.ceil(periodBetween / (1000 * 60 * 60 * 24));

  const pers = (100 * dayTo) / diffDays;

  return (
    <div className={styles.mainContainer}>
      <div className={cn(styles.container, styles[seasonEventStatuses[status]])}>
        <div
          style={{ width: `${pers}%` }}
          className={styles.fillerStyles}
        ></div>
      </div>
    </div>
  );
};
