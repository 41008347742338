import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PageTitle, Loader, ApplyModal } from "components";
import { ReactComponent as Chevron } from "assets/icons/grey-chevron-right.svg";
import { Carousel } from "react-bootstrap";
import { compile } from "path-to-regexp";
import { SAUDI_SEASON_EVENT_APPLY } from "constants/paths";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getSaudiSeasonEvent,
  element,
} from "store/actions/saudiSeasonsEvents.actions";
import { getUserRequests } from "store/actions/requests.actions";
import { seasonEventStatuses, TYPES, APPLY, EVENT } from "constants/seasonsEvents";
import styles from "./styles.module.scss";

const SeasonEventDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const { userRequests, loaded: elementsLoaded } = useSelector(
    ({ requests }) => requests
  );
  const [applyAlertOpen, setApplyAlertOpen] = useState(false);

  const {
    saudiSeasonEvent,
    regions,
    loaded: seasonsEventsLoaded,
  } = useSelector(({ saudiSeasonsEvents }) => saudiSeasonsEvents);
  const handleToApply = (elementId, elementTypeId) => {
    if (!saudiSeasonEvent.control) {
      setApplyAlertOpen(true);
    } else {
      dispatch(element.set({ elementId, elementTypeId }));
      history.push(
        compile(SAUDI_SEASON_EVENT_APPLY)({ type, id, elementId, elementTypeId, action: APPLY })
      );
    }
  };

  const getRegionName = (regionId) => {
    return regions.find((region) => region.id === regionId)?.name ?? "";
  };
  const carouselItems = saudiSeasonEvent.images?.length;

  useEffect(() => {
    dispatch(getSaudiSeasonEvent.request({ type: TYPES[type], id }));
  }, [dispatch, id, type]);

  useEffect(() => {
    dispatch(getUserRequests.request());
  }, [dispatch]);

  const isApplied = (elId) => {
    if (type === EVENT) {
      return userRequests.find((req) => req.event_id === elId);
    } else {
      return userRequests.find((req) => req.season_id === elId);
    }
  };

  const getModalTitle = () => {
    const name = saudiSeasonEvent.name;
    return `${t("seasons_events.cant_apply.start")} ${name} ${t(
      "seasons_events.cant_apply.end"
    )}`;
  };

  const createElements = (element) => {
    const values = [
      {
        subtitle: "seasons_events.element",
        subContent: element?.element_type?.name,
        notNull: false
      },
      {
        subtitle: "seasons_events.category",
        subContent: element?.format?.name,
        notNull: true,
      },
      {
        subtitle: "seasons_events.description",
        subContent: element?.description,
        notNull: false
      },
    ];

    return values.filter(item => item.notNull ? item.subContent ? item : null : item).map((val) => (
      <div className={styles.elementContentItem} key={val.subtitle}>
        <p className={styles.subtitle}>{t(val.subtitle)}</p>
        <p className={styles.subtitleContent}>{val.subContent}</p>
      </div>
    ));
  };

  return (
    <Loader loaded={seasonsEventsLoaded && elementsLoaded}>
      <ApplyModal
        title={getModalTitle()}
        modalOpen={applyAlertOpen}
        modalClose={() => setApplyAlertOpen(false)}
        closeText={t("seasons_events.cant_apply.close")}
      />
      {seasonsEventsLoaded && elementsLoaded && (
        <div>
          <Link to="/saudi-seasons-events" className={styles.btnBack}>
            <Chevron />
            {t("seasons_events.events_saudi_seasons")}
          </Link>
          <div className={styles.title}>
            <PageTitle title={saudiSeasonEvent?.name ?? ""} />
          </div>
          <section className={styles.information}>
            <div className={styles.informationItem}>
              <p className={styles.subtitle}>{t("seasons_events.region")}</p>
              <p className={styles.subtitleContent}>
                {getRegionName(saudiSeasonEvent.region_id)}
              </p>
            </div>
            <div className={styles.informationItem}>
              <p className={styles.subtitle}>
                {t("seasons_events.description")}
              </p>
              <p className={styles.subtitleContent}>
                {saudiSeasonEvent?.description ?? ""}
              </p>
            </div>
            <div className={styles.informationItem}>
              <p className={styles.subtitle}>{t("seasons_events.status")}</p>
              <p
                className={`${seasonEventStatuses[saudiSeasonEvent.status]} ${
                  styles.subtitleContent
                }`}
              >
                {t(`seasons_events.statuses.${saudiSeasonEvent.status}`)}
              </p>
            </div>
          </section>
          <section className={styles.elements}>
            <p className={styles.subtitle}>
              {t("seasons_events.main_element")}
            </p>
            {saudiSeasonEvent?.main_elements?.map((element) => (
              <div key={element.id} className={styles.elementItem}>
                <div className={styles.elementContent}>
                  {createElements(element)}
                  <div className={styles.elementFooter}>
                    <p className={styles.elementNote}>
                      {t("seasons_events.note")}
                    </p>
                  </div>
                </div>
                <div className={styles.elementAside}>
                  <button
                    disabled={isApplied(element.id)}
                    onClick={() =>
                      handleToApply(element.id, element.element_type.id)
                    }
                    className={styles.elementBtnApply}
                  >
                    {t(
                      isApplied(element.id)
                        ? "seasons_events.applied"
                        : "seasons_events.apply"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </section>
          <section className={styles.gallery}>
            <Carousel
              // fade
              controls={carouselItems > 1}
              indicators={false}
              nextIcon={
                <span
                  aria-hidden="true"
                  className={`${styles.btnNext} ${styles.btnControl}`}
                >
                  <Chevron />
                </span>
              }
              prevIcon={
                <span
                  aria-hidden="true"
                  className={`${styles.btnPrev} ${styles.btnControl}`}
                >
                  <Chevron />
                </span>
              }
            >
              {saudiSeasonEvent?.images?.map(({ id, image, description }) => (
                <Carousel.Item key={id}>
                  <img
                    className="d-block w-100"
                    src={image}
                    alt={description}
                  />
                  <Carousel.Caption>
                    <p>{description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </section>
        </div>
      )}
    </Loader>
  );
};

export default SeasonEventDetails;
