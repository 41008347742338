import * as IdeasActions from "store/actions/ideas.actions";

const initialState = {
  loaded: true,
  error: "",
  success: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IdeasActions.GET_IDEAS.REQUEST:
      return { ...state, loaded: false };

    case IdeasActions.GET_IDEAS.SUCCESS:
      return { ...state, loaded: true, error: "" };

    case IdeasActions.GET_IDEAS.FAILURE:
      return { ...state, loaded: true, error: action.payload };

    case IdeasActions.CREATE_IDEA.REQUEST:
      return { ...state, loaded: false };

    case IdeasActions.CREATE_IDEA.SUCCESS:
      return { ...state, loaded: true, error: "", success: true };

    case IdeasActions.CREATE_IDEA.FAILURE:
      return { ...state, loaded: true, error: action.payload };

    case IdeasActions.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
