import moment from "moment";
const invalidCountElemets = ["comments", "favorites"];

export const filterSocialMedia = (
  condition,
  elementsArray,
  elementToFilter
) => {
  return condition
    ? elementsArray
    : elementsArray.filter((el) => el.label !== elementToFilter);
};

export const calculateSum = (obj) => {
  return obj
    ? Object.entries(obj).reduce(
        (prev, curr) =>
          invalidCountElemets.includes(curr[0]) ? prev : prev + curr[1],
        0
      )
    : 0;
};

export const isDevTestMode =
  process.env.REACT_APP_NODE_ENV === "development" ||
  process.env.REACT_APP_NODE_ENV === "testing";

export const isDevOnly = process.env.REACT_APP_NODE_ENV === "development";
// export const vsPath = isDevOnly ? "venue" : "site";
export const vsPath = "venue";

// export const vsCategory = isDevOnly ? "venueCategories" : "siteCategories";
// export const vsTopography = isDevOnly ? "venueTopographies" : "siteTopographies";
// export const vsLandTypes = isDevOnly ? "venueLandTypes" : "siteLandTypes";

export const vsCategory = "venueCategories";
export const vsTopography = "venueTopographies";
export const vsLandTypes = "venueLandTypes";

export const objWithoutFalsy = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value));
};

export const getFormattedDateTime = (data, format, rtl) => {
  if (!data || !format) {
    return "";
  }
  else if(rtl){
    return moment.utc(data).local()
      .format(format)
      .replace("AM", "صباحًا")
      .replace("PM", "مساءً");
  }
  return moment.utc(data).local().format(format)
};

export const areObjectsEquals = (a, b) => {
  for (let key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (let key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

export const autoCloseAccordioTabs = (tabs, tabIndex) => {
  const newData = { ...tabs };
  Object.keys(newData).forEach((data) =>
    +data === tabIndex ? (newData[data] = true) : (newData[data] = false)
  );
  return newData;
};

export const isFieldWithError = (formik, name) =>
  formik.submitCount > 0 && Object.keys(formik.errors).includes(name);

export const filterFields = (formikValues, options) => {
  if (formikValues) {
    return options?.filter(({ dependency, dependenceValue, type }) => {
      if (dependency && dependenceValue !== undefined) {
        if (typeof dependenceValue !== "object") {
          if (Array.isArray(dependency)) {
            return dependency.some((item, index) =>
              item.names.every((i, idx) => {
                if (Array.isArray(formikValues[i]))
                  return formikValues[i]?.includes(
                    dependency[index].values[idx]
                  );
                return (
                  formikValues[i]?.toString() ===
                  dependency[index].values[idx].toString()
                );
              })
            );
          } else
            return (
              formikValues?.[dependency]?.toString() ===
              dependenceValue.toString()
            );
        } else if (Array.isArray(dependenceValue)) {
          if (formikValues[dependency] === null && type) {
            return true;
          } else {
            return dependenceValue?.includes(formikValues[dependency]);
          }
        } else {
          return true;
        }
      } else if (dependency) {
        return !!formikValues[dependency];
      } else if (!type) {
        return false;
      } else return true;
    });
  }
};

export const getDaysFromDates = (date) => {
  const periodBetween = new Date() - new Date(date);
  return Math.abs(Math.ceil(periodBetween / (1000 * 60 * 60 * 24)));
};

export const dateFormatter = (rtl, date) => {
  if (!rtl) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  } else {
    return moment(date)
      .format("YYYY-MM-DD hh:mm A")
      .replace("AM", "صباحًا")
      .replace("PM", "مساءً");
  }
};

export const removeFieldIfNotEmpty = (fields, array) => {
  for (const [key, value] of Object.entries(fields)) {
    if (typeof value === "object") {
      function hasNull(element) {
        return Object.keys(element).some((key) => element[key] === null);
      }
      if (value && !value?.lastModified && !value.some(hasNull)) {
        for (let i = 0; i < array.length; i++) {
          if (array[i] === key) {
            array.splice(i, 1);
          }
        }
      }
    }
  }
};
