import React,{ useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RadioButton, TextArea, DatePicker, Select } from "components";
import {
  gender,
  social_statuses,
  special_needs,
  disability_period,
} from "../Accordion/contants";
import { DATE_FORMAT } from "constants/forms";
import moment from "moment";
import BlueChevron from "assets/icons/blue-chevron-down.svg";
import { setCurrentGroupMember } from "store/actions/accordion.actions";
import { useFieldTouched } from "utils/forms";
import styles from "./styles.module.scss";
import { getAdminRegions } from "store/actions/adminElements.actions";

const PersonalInfo = ({
  formik,
  name,
  index,
  styles: incomingStyles,
  title,
}) => {
  const { countries, disabilityTypes } = useSelector((state) => state.elements);
  const { currentGroupMember } = useSelector((state) => state.accordion);
  const {  adminRegions } = useSelector(({ adminElements }) => adminElements);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminRegions.request());
  }, [dispatch]);

  const handleChangeDate = (e) =>
    formik.setFieldValue(
      `${name}[${index}].birthdate`,
      moment.utc(e).local().format(DATE_FORMAT)
    );
  const setFieldTouched = useFieldTouched(formik);

  const errorMessage = (field) => (
    <p className="mt-2 text-danger form-field-error">
      {formik.errors[name]?.[index]?.[field] &&
        t(`messages.${formik.errors[name]?.[index]?.[field]}`)}
    </p>
  );

  const handleClick = (index) => {
    dispatch(
      setCurrentGroupMember(index === currentGroupMember ? null : index)
    );
  };
  const isFieldWithError = (fieldName) => {
    return (
      formik.submitCount > 0 && formik.errors?.[name]?.[index]?.[fieldName]
    );
  };
  return (
    <div
      className={classnames(styles.infoBox, {
        [styles.infoBoxOpen]: currentGroupMember === index,
      })}
    >
      <div
        className={styles.infoHeader}
        onClick={() => {
          handleClick(index);
        }}
      >
        <p>{t("member_info.member", { index: index + 1 })}</p>
        <img src={BlueChevron} alt="" />
      </div>
      <div className={styles.infoContent}>
        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.full_name_en`)}</span>
          </p>
          <input
            className={classnames(`form-control`, incomingStyles.optionInpt, {
              invalidField: isFieldWithError("full_name_en"),
            })}
            name={`${name}[${index}].full_name_en`}
            placeholder={t(`${title}.full_name_en`)}
            onChange={formik.handleChange}
            value={formik.values[name]?.[index]?.full_name_en || ""}
            onFocus={setFieldTouched}
          />
          <div>{errorMessage("full_name_en")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.full_name_ar`)}</span>
          </p>
          <input
            className={classnames(`form-control`, incomingStyles.optionInpt, {
              invalidField: isFieldWithError("full_name_ar"),
            })}
            name={`${name}[${index}].full_name_ar`}
            placeholder={t(`${title}.full_name_ar`)}
            onChange={formik.handleChange}
            value={formik.values[name]?.[index]?.full_name_ar || ""}
            onFocus={setFieldTouched}
          />
          <div>{errorMessage("full_name_ar")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.birthdate`)}</span>
          </p>
          <DatePicker
            formik={formik}
            styles={incomingStyles}
            name={`${name}[${index}].birthdate`}
            onChange={handleChangeDate}
            selected={
              formik.values[name]?.[index]?.birthdate &&
              moment(
                formik.values[name]?.[index]?.birthdate,
                DATE_FORMAT
              ).toDate()
            }
          />
          <div>{errorMessage("birthdate")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.gender`)}</span>
          </p>
          {gender?.map((item) => (
            <label
              className={incomingStyles.optionsListLabel}
              htmlFor={item.name}
              key={item.name}
            >
              <div>
                <RadioButton
                  name={`${name}[${index}].gender`}
                  value={item.value ?? item.name}
                  onChange={formik.handleChange}
                  formik={formik}
                  checked={
                    formik?.values?.[name]?.[index]?.gender?.toString() ===
                    (item.value || item.name)?.toString()
                  }
                />
                {t(`${title}.${item.name}`)}
              </div>
            </label>
          ))}
          <div>{errorMessage("gender")}</div>
        </div>
        
        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">
              {t(`${title}.region_id`)}
            </span>
          </p>
          <Select
            styles={incomingStyles}
            formik={formik}
            name={`${name}[${index}].region_id`}
            data={adminRegions}
            placeholder={t(`${title}.region_id`)}
            className={classnames(incomingStyles.optionDropdown)}
            value={formik.values[name]?.[index]?.region_id}
          />
          <div>{errorMessage("region_id")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">
              {t(`${title}.nationality_id`)}
            </span>
          </p>
          <Select
            styles={incomingStyles}
            formik={formik}
            name={`${name}[${index}].nationality_id`}
            data={countries}
            placeholder={t(`${title}.nationality_id`)}
            className={classnames(incomingStyles.optionDropdown)}
            value={formik.values[name]?.[index]?.nationality_id}
          />
          <div>{errorMessage("nationality_id")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">
              {t(`${title}.country_of_residence_id`)}
            </span>
          </p>
          <Select
            styles={incomingStyles}
            formik={formik}
            name={`${name}[${index}].country_of_residence_id`}
            data={countries}
            placeholder={t(`${title}.country_of_residence_id`)}
            className={classnames(incomingStyles.optionDropdown)}
            value={formik.values[name]?.[index]?.country_of_residence_id}
          />
          <div>{errorMessage("country_of_residence_id")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.social_status`)}</span>
          </p>
          <>
            {social_statuses?.map((item) => (
              <label
                className={incomingStyles.optionsListLabel}
                htmlFor={item.name}
                key={item.name}
              >
                <div>
                  <RadioButton
                    name={`${name}[${index}].social_status`}
                    value={item.value ?? item.name}
                    onChange={formik.handleChange}
                    formik={formik}
                    checked={
                      formik?.values?.[name]?.[
                        index
                      ]?.social_status?.toString() ===
                      (item.value || item.name)?.toString()
                    }
                  />
                  {t(`${title}.${item.name}`)}
                </div>
              </label>
            ))}
          </>
          <div>{errorMessage("social_status")}</div>
        </div>

        <div className={incomingStyles.optionsList}>
          <p className={incomingStyles.optionsTitle}>
            <span className="requiredField">{t(`${title}.special_needs`)}</span>
          </p>
          <>
            {special_needs?.map((item) => (
              <label
                className={incomingStyles.optionsListLabel}
                htmlFor={item.name}
                key={item.name}
              >
                <div>
                  <RadioButton
                    name={`${name}[${index}].special_needs`}
                    value={item.value ?? item.name}
                    onChange={formik.handleChange}
                    formik={formik}
                    checked={
                      formik?.values?.[name]?.[
                        index
                      ]?.special_needs?.toString() ===
                      (item.value?.toString() || item.name?.toString())
                    }
                  />
                  {t(`${title}.${item.name}`)}
                </div>
              </label>
            ))}
          </>
          <div>{errorMessage("special_needs")}</div>
        </div>

        {formik?.values?.[name]?.[index]?.special_needs.toString() === "1" && (
          <>
            <div className={incomingStyles.optionsList}>
              <p className={incomingStyles.optionsTitle}>
                <span className="requiredField">
                  {t(`${title}.disability_period`)}
                </span>
              </p>
              <>
                {disability_period?.map((item) => (
                  <label
                    className={incomingStyles.optionsListLabel}
                    htmlFor={item.name}
                    key={item.name}
                  >
                    <div>
                      <RadioButton
                        name={`${name}[${index}].disability_period`}
                        value={item.value ?? item.name}
                        onChange={formik.handleChange}
                        formik={formik}
                        checked={
                          formik?.values?.[name]?.[
                            index
                          ]?.disability_period?.toString() ===
                          (item.value?.toString() || item.name?.toString())
                        }
                      />
                      {t(`${title}.${item.name}`)}
                    </div>
                  </label>
                ))}
              </>
              <div>{errorMessage("disability_period")}</div>
            </div>

            <div className={incomingStyles.optionsList}>
              <p className={incomingStyles.optionsTitle}>
                <span className="requiredField">
                  {t(`${title}.disability_type`)}
                </span>
              </p>
              <Select
                multiple={true}
                styles={incomingStyles}
                formik={formik}
                name={`${name}[${index}].disability_type`}
                data={disabilityTypes}
                placeholder={t(`${title}.disability_type`)}
                className={classnames(incomingStyles.optionDropdown)}
                value={formik.values[name]?.[index]?.disability_type}
              />
              <div>{errorMessage("disability_type")}</div>
            </div>

            {formik.values[name]?.[index]?.disability_type?.includes(
              "00000000-0000-0000-0005-00000000000f"
            ) && (
              <div className={incomingStyles.optionsList}>
                <p className={incomingStyles.optionsTitle}>
                  <span className="requiredField">
                    {t(`${title}.disability_other_category`)}
                  </span>
                </p>
                <input
                  className={classnames(
                    `form-control`,
                    incomingStyles.optionInpt,
                    {
                      invalidField: isFieldWithError(
                        "disability_other_category"
                      ),
                    }
                  )}
                  name={`${name}[${index}].disability_other_category`}
                  placeholder={t(`${title}.disability_other_category`)}
                  onChange={formik.handleChange}
                  value={
                    formik.values[name]?.[index]?.disability_other_category ||
                    ""
                  }
                  onFocus={setFieldTouched}
                />
                <div>{errorMessage("disability_other_category")}</div>
              </div>
            )}

            <div className={incomingStyles.optionsList}>
              <p className={incomingStyles.optionsTitle}>
                <span className="requiredField">
                  {t(`${title}.additional_support`)}
                </span>
              </p>
              <>
                {special_needs?.map((item) => (
                  <label
                    className={incomingStyles.optionsListLabel}
                    htmlFor={item.name}
                    key={item.name}
                  >
                    <div>
                      <RadioButton
                        name={`${name}[${index}].additional_support`}
                        value={item.value ?? item.name}
                        onChange={formik.handleChange}
                        formik={formik}
                        checked={
                          formik?.values?.[name]?.[
                            index
                          ]?.additional_support?.toString() ===
                          (item.value?.toString() || item.name?.toString())
                        }
                      />
                      {t(`${title}.${item.name}`)}
                    </div>
                  </label>
                ))}
              </>
              <div>{errorMessage("disability_period")}</div>
            </div>
            {formik.values[name]?.[index]?.additional_support?.toString() ===
              "1" && (
              <div className={incomingStyles.optionsList}>
                <p className={incomingStyles.optionsTitle}>
                  <span className="requiredField">
                    {t(`${title}.support_description`)}
                  </span>
                </p>
                <TextArea
                  formik={formik}
                  index={index}
                  name={`${name}[${index}].support_description`}
                  value={formik.values.personal_info[index].support_description}
                  placeholder={t(`${title}.support_description`)}
                  onChange={formik.handleChange}
                  onFocus={setFieldTouched}
                />
                <div>{errorMessage("support_description")}</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(PersonalInfo);
