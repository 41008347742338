import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DraggableList } from "components";
import {
  getEventsArrangement,
  reorderEvents,
} from "store/actions/adminElements.actions";


const EventsArrangement = () => {
  const dispatch = useDispatch();
  const { eventsArrangement } = useSelector(
    ({ adminElements }) => adminElements
  );

  useEffect(() => {
    dispatch(getEventsArrangement.request());
  }, [dispatch]);


  const headerList = [
    {
      name: "Event Image",
      key: "image",
      w: 20,
    },
    {
      name: "Event Status",
      key: "status",
      w: 40,
    },
    {
      name: "Event name",
      key: "name",
      w: 40,
    },
  ];

  return (
    <div className="card">
      <div className="card-body d-flex flex-wrap">
        <div className="w-100">
          {eventsArrangement.length ? 
            <DraggableList 
              headerList={headerList} 
              bodyList={eventsArrangement} 
              reorderHandler={reorderEvents}
            />
            :
            <div className="notFound">Not Found</div>
          }
        </div>
      </div>
    </div>
  )
}

export default EventsArrangement;