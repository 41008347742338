import React from "react";
import Layout from "layouts/Admin";
import { NewSeason } from "components";
import { useParams } from 'react-router-dom';

const AdminNewSeason = () => {
  const { id } = useParams()

  return (
    <Layout title={`${id ? "Edit" : "New"} Season`}>
      <NewSeason id={id}/>
    </Layout>
  );
};

export default AdminNewSeason;
