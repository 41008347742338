import { call, put, takeLatest } from "redux-saga/effects";
import { ContactApi } from "services/contact.service";
import { CONTACT_US, contactUs } from "store/actions/contact.actions";

function* contactUsWorker(action) {
  try {
    yield call(ContactApi.contactUs, action.payload);

    yield put(contactUs.success());
  } catch (e) {
    yield put(contactUs.failure(e.data));
  }
}

function* contactSaga() {
  yield takeLatest(CONTACT_US.REQUEST, contactUsWorker);
}

export default contactSaga;
