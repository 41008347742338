import React from "react";
import { ForgotPasswordForm } from "components";
import Layout from "layouts/AdminLogin";

const ForgotPassword = () => (
  <Layout>
    <ForgotPasswordForm />
  </Layout>
);

export default ForgotPassword;
