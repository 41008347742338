import { createRequestTypes, createActionTypes } from "utils/redux";

export const GET_MEDIA_CENTER_NEWS = createRequestTypes("GET_MEDIA_CENTER_NEWS");
export const GET_MEDIA_CENTER_NEWS_DETAILS = createRequestTypes("GET_MEDIA_CENTER_NEWS_DETAILS");
export const GET_MEDIA_CENTER_PHOTOS = createRequestTypes("GET_MEDIA_CENTER_PHOTOS");
export const GET_MEDIA_CENTER_VIDEOS = createRequestTypes("GET_MEDIA_CENTER_VIDEOS");
export const GET_MEDIA_CENTER_PHOTOS_DETAILS = createRequestTypes("GET_MEDIA_CENTER_PHOTOS_DETAILS");
export const GET_MEDIA_CENTER_VIDEOS_DETAILS = createRequestTypes("GET_MEDIA_CENTER_VIDEOS_DETAILS");
export const GET_MEDIA_CENTER_CAROUSEL_IMAGES = createRequestTypes("GET_MEDIA_CENTER_CAROUSEL_IMAGES");

export const getMediaCenterNews = createActionTypes(GET_MEDIA_CENTER_NEWS);
export const getMediaCenterNewsDetails = createActionTypes(GET_MEDIA_CENTER_NEWS_DETAILS);
export const getMediaCenterPhotos = createActionTypes(GET_MEDIA_CENTER_PHOTOS);
export const getMediaCenterVideos = createActionTypes(GET_MEDIA_CENTER_VIDEOS);
export const getMediaCenterPhotosDetails = createActionTypes(GET_MEDIA_CENTER_PHOTOS_DETAILS);
export const getMediaCenterVideosDetails = createActionTypes(GET_MEDIA_CENTER_VIDEOS_DETAILS);
export const getMediaCenterCarouselImages = createActionTypes(GET_MEDIA_CENTER_CAROUSEL_IMAGES);