import React, { useEffect, useContext } from "react";
import { Accordion, Loader, AlertBox } from "components";
import { Context } from "context";
import AdminLayout from "layouts/Admin";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventSeasonRequestsInfo } from "store/actions/adminElements.actions";
import flatten from "flat";

const AdminViewRequest = () => {
  const { loaded, error, eventSeasonRequestsInfo, ...restState } = useSelector(
    (state) => state.adminElements
  );
  const { user } = useContext(Context);
  const isAlertActive = useSelector(({ alert }) => alert);
  const dispatch = useDispatch();
  const { typeId, id } = useParams();

  useEffect(() => {
    dispatch(
      getEventSeasonRequestsInfo.request({
        typeId,
        id,
        user_type: user.user_type,
      })
    );
  }, [dispatch, typeId, id, user.user_type]);

  return (
    <AdminLayout>
      <Loader loaded={loaded}>
        <div className="pt-3">
          {isAlertActive && (
            <AlertBox error={error} additionalStyles="alert-danger" />
          )}
          {loaded && (
            <Accordion
              viewOnly={true}
              viewOnlyData={flatten(eventSeasonRequestsInfo?.data?.[0] || {}, {
                safe: true,
              })}
              restState={restState}
            />
          )}
        </div>
      </Loader>
    </AdminLayout>
  );
};

export default AdminViewRequest;
