import React from "react";
import FooterImage from "assets/images/footerLogoNew.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { QUESTIONS, POLICY, TERMS, LOGIN, CONTACT_US,MEDIA_CENTER } from "constants/paths";
import classnames from "classnames";
import styles from "./styles.module.scss";
import Pdf from "assets/documents/User_Manual.pdf";

const FooterSection = ({ withPadding }) => {
    const { t } = useTranslation();
    return (
        <footer className={styles.footer}>
            <div
                className={classnames(
                    styles.footerContainer,
                    withPadding && styles.footerPadding
                )}
            >
                <div className={styles.footerLogoWrapper}>
                    <div className={styles.footerLine} />
                    <img
                        src={FooterImage}
                        className={styles.footerLogo}
                        alt="img"
                    />
                    <div className={styles.footerLine} />
                </div>

                <div className={styles.footerContent}>
                    <div className={styles.footerNav}>
                        <Link to={LOGIN}>{t("homepage.Home")}</Link>
                        <a
                            href="https://nec.gov.sa/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t("homepage.Events_center")}
                        </a>
                        <a href={Pdf} target="_blank" rel="noopener noreferrer">
                            {t("homepage.UserManual")}
                        </a>
                        <Link to={QUESTIONS}>{t("main.questions")}</Link>
                        <Link to={TERMS}>{t("terms.title")}</Link>
                        <Link to={POLICY}>{t("main.privacy")}</Link>
                        <Link to={CONTACT_US}>
                            {t("homepage.Connect_with_us")}
                        </Link>
                        <Link to={MEDIA_CENTER}>
                            {t("homepage.MediaCenter")}
                        </Link>
                    </div>
                    <p className={styles.footerCopyright}>
                        {t("homepage.copyright")} {new Date().getFullYear()}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;
