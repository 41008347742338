import React, { useContext } from "react";
import ProfileLayout from "layouts/Profile";
import Public from "layouts/Public";
import { useTranslation } from "react-i18next";
import { Context } from "context";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Terms = () => {
    const { t } = useTranslation();
    const { user } = useContext(Context);

    const getData = () => (
        <div
            className={classnames(styles.policyMain, {
                [styles.policyMainPublic]: !user,
                [styles.policyMainPrivate]: !!user,
            })}
        >
            {t("terms.data", { returnObjects: true }).map((item, index) => (
                <div key={index}>
                    <p className={styles.policyMainTitle}>{item.title}</p>
                    {item.data?.map((item, index) => (
                        <p key={index} className={styles.policyMainText}>
                            {item}
                        </p>
                    ))}
                    {item.list && (
                        <ul>
                            {item.list.map((item, index) => (
                                <li
                                    key={index}
                                    className={styles.policyMainText}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}
                    {item.links?.map(({ title, to, description }, index) => (
                        <p key={index} className={styles.policyMainText}>
                            <a
                                href={to}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {title}
                            </a>
                            :<span>{description}</span>
                        </p>
                    ))}
                </div>
            ))}
        </div>
    );

    return user ? (
        <ProfileLayout>
            <div className={styles.policy}>
                <div className={styles.policyHeader}>
                    <h3 className={styles.policyTitle}>{t("terms.title")}</h3>
                </div>
                {getData()}
            </div>
        </ProfileLayout>
    ) : (
        <Public title={t("terms.title")}>
            <div className={styles.wrapper}> {getData()}</div>
        </Public>
    );
};

export default React.memo(Terms);
