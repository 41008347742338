import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableComponent,
  ChangeStatusModal,
  ChangeStatusModalSeasonRequest,
  Loader,
  Pagination,
  AdminTopPanel,
  ElementsFilter,
  AlertBox,
} from "components";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { compile } from "path-to-regexp";
import { ADMIN_VIEW_REQUEST } from "constants/paths";
import { requiredElementType } from "../../constants/messages";
import { getEventSeasonRequest } from "store/actions/adminElements.actions";
import moment from "moment";
import { clearUserRequestTarget } from "store/actions/adminElements.actions";
import { getAdminMainElements } from "store/actions/elements.actions";
import { alert } from "store/actions/alert.actions";
import {
  otherFilteredCategoriesTypes,
  initialValues,
  statuses,
  elementsType,
  REQUEST_DETAILS,
  filteredCategoriesType,
} from "./constants";
import { getUsersRequestTarget } from "store/actions/adminElements.actions";
import { DATE_TIME_FORMAT } from "constants/forms";
import { Context } from "context";

const SeasonEventRequestInfo = () => {
  const { user } = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSeasonEventRequest, setModalOpenSeasonEventRequest] = useState(false);
  const { id: typeId } = useParams();
  const { loaded, error, eventSeasonRequest, supportMessage } = useSelector(
    ({ adminElements }) => adminElements
  );
  const {
    loaded: elementsLoaded,
    error: elementsError,
    ...data
  } = useSelector(({ elements }) => elements);
  const isAlertActive = useSelector(({ alert }) => alert);
  const [typeSelected, setTypeSelected] = useState({});
  const [modalBody, setModalBody] = useState(null);
  const [modalBodySeasonEventRequest, setModalBodySeasonEventRequest] = useState(null);
  const dispatch = useDispatch();

  const addToRequestList = (item) => {
    const { id } = item;
    const selectedRequest = { id };
    if (modalBody) {
      setModalBody((prev) => ({
        requests: [...prev.requests, selectedRequest],
      }));
    } else {
      setModalBody({ requests: [selectedRequest] });
    }
  };

  const selectAllToRequestList = (flag) => {
    if (flag) {
      setModalBody({
        requests: eventSeasonRequest?.data.map((el) => ({ id: el.id })),
      });
    } else {
      setModalBody(null);
    }
  };

  const removeFromRequestList = (item) => {
    setModalBody((prev) => ({
      requests: prev.requests.filter((req) => req.id !== item.id),
    }));
  };

  const clearData = () => {
    dispatch(getEventSeasonRequest.request({ typeId }));
    setTypeSelected({});
    formik.resetForm();
  };
  const openModalSeasonEventRequest = () => {
    (!modalBodySeasonEventRequest || modalBodySeasonEventRequest.requests.length === 0) && setModalBodySeasonEventRequest({requests: eventSeasonRequest?.data?.map((el) => ({ season_id:typeId, status_from:el.status}))});
    setModalOpenSeasonEventRequest(true);
  };
  const openModal = (item) => {
    (!modalBody || modalBody.requests.length === 0) && setModalBody(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalBody(null);
  };
  const closeModalSeasonEventRequest = () => {
    setModalOpenSeasonEventRequest(false);
    setModalBodySeasonEventRequest(null);
  };

  useEffect(() => {
    dispatch(getEventSeasonRequest.request({ typeId }));
  }, [dispatch, typeId]);

  useEffect(() => {
    dispatch(getAdminMainElements.request());
    return () => {
      dispatch(alert.remove());
    };
  }, [dispatch]);

  const dataExist = eventSeasonRequest?.data?.length ? true : false;
  const currentType = eventSeasonRequest?.data?.[0]?.type;
  const setParams = () => {
    return {
      params: Object.keys(formik.values).reduce((params, currVal) => {
        if (formik.values[currVal]) {
          params[currVal] =
            formik.values[currVal]?.value ||
            formik.values[currVal]?.id ||
            formik.values[currVal];
        } else if (typeSelected.format_id && typeSelected.type_id) {
          params[`${typeSelected.format}_format_id`] = typeSelected.format_id;
          params["element_type_id"] = typeSelected.type_id;
        } else if (typeSelected.type_id) {
          params["element_type_id"] = typeSelected.type_id;
        } else if (typeSelected.format_id) {
          params[`${typeSelected.format}_format_id`] = typeSelected.format_id;
        }
        params[`${currentType}_id`] = typeId;
        return params;
      }, {}),
    };
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      dispatch(getEventSeasonRequest.request({ typeId, ...setParams() }));
    },
  });

  const tableHead = [
    {
      name: "#",
      key: "#",
    },
    {
      name: "Select All",
      key: "checkbox",
      onClick: (item, checked, index) => {
        checked ? addToRequestList(item) : removeFromRequestList(item);
      },
    },
    {
      name: "Request Number",
      key: "request_number",
    },
    {
      name: "User Name",
      key: "full_name",
    },
    {
      name: "User Phone Number",
      key: "mobile_number",
    },
    {
      name: "Date & Time",
      key: "created_at",
    },
    {
      name: "Element Name | En",
      key: "name_en",
    },
    {
      name: "Element Format | En",
      key: "format_en",
    },
    {
      name: "Status",
      key: "status",
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({ id }) => {
        window.open(
          compile(ADMIN_VIEW_REQUEST)({ type: REQUEST_DETAILS, typeId, id }),
          "_blank"
        );
      },
    },
  ];

  user.user_type !== 4 &&
    tableHead.push({
      name: "Change Status",
      key: "action-btn",
      class: "mdi mdi-pencil",
      onClick: (item) => openModal(item),
    });

  const tableBody = eventSeasonRequest?.data?.map((el) => ({
    id: el?.id,
    request_number: el?.request_number || "",
    full_name: el?.user?.full_name || el?.user?.company_name || "",
    mobile_number: el?.user?.mobile_number || "",
    created_at: moment(el?.created_at).format(DATE_TIME_FORMAT) || "",
    format_en: el?.participating_element?.element_format?.name_en || "",
    name_en: el?.participating_element?.element_type?.name_en || "",
    status: el?.other_status || statuses[el?.status] || "",
    status_info: {
      status: el?.status,
      other_status: el?.other_status,
    },
  }));

  const buttonsTools = [
    {label: "Export",
    onClick: () => {
      const { params } = setParams();
      dataExist &&
        dispatch(
          getUsersRequestTarget.request({
            ...params,
            [`${currentType}_id`]: typeId,
          })
        );
    },
  },
    {label: "Status - Bulk Update",
    onClick: () => openModalSeasonEventRequest(),
    order:1
    }
  ];

  const onDropdownChange = (element) => {
    setTypeSelected(element);
    dispatch(
      getEventSeasonRequest.request({
        typeId,
        params: {
          element_type_id: element?.type_id || null,
          [`${element.format}_format_id`]: element?.format_id || null,
        },
      })
    );
    formik.resetForm();
    dispatch(clearUserRequestTarget());
  };

  const dropdownTools = {
    label: "Select Element Type",
    dropdownElements: elementsType,
    optionSelected: typeSelected,
    required: true,
    setOptionSelected: onDropdownChange,
  };

  return (
    <Loader loaded={loaded && elementsLoaded}>
      {isAlertActive && (
        <AlertBox
          error={error || elementsError}
          message={requiredElementType}
          additionalStyles="alert-danger"
          extraParams={setParams()}
        />
      )}
      {supportMessage && <div className="successAlert">{supportMessage}</div>}
      <AdminTopPanel
        withButtons
        buttonsTools={buttonsTools}
        withDropdown
        dropdownTools={dropdownTools}
      />
      <ElementsFilter
        typeSelected={typeSelected}
        data={data}
        filteredCategoriesType={filteredCategoriesType}
        formik={formik}
        resetData={clearData}
        otherFilteredCategoriesTypes={otherFilteredCategoriesTypes}
      />

      <div className="card">
        <div className="card-body">
          <TableComponent
            selectAllToRequestList={selectAllToRequestList}
            tableHead={tableHead}
            tableBody={tableBody}
            modalBody={modalBody}
          />
          {eventSeasonRequest?.data?.length ? (
            <Pagination
              action={getEventSeasonRequest}
              currentPage={eventSeasonRequest?.current_page}
              lastPage={eventSeasonRequest?.last_page}
              id={typeId}
              type={typeSelected.type}
              extraParams={setParams()}
            />
          ) : null}
          {modalOpen && (
            <ChangeStatusModal
              modalOpen={modalOpen}
              closeModal={closeModal}
              modalBody={modalBody}
              params={setParams()}
            />
          )}
          {modalOpenSeasonEventRequest && (
            <ChangeStatusModalSeasonRequest
              modalOpen={modalOpenSeasonEventRequest}
              closeModal={closeModalSeasonEventRequest}
              modalBody={modalBodySeasonEventRequest}
              params={setParams()}
              id={typeId}
              currentType={currentType}
            />
          )}
        </div>
      </div>
    </Loader>
  );
};

export default SeasonEventRequestInfo;