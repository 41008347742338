import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextArea = ({
  styles,
  formik,
  setFieldTouched,
  name,
  maxLength,
  placeholder,
  value,
}) => {
  return (
    <>
      <textarea
        className={classnames("form-control", styles?.textArea)}
        name={name}
        placeholder={placeholder}
        onChange={formik?.handleChange}
        value={value ? value : formik?.values[name] || ""}
        onFocus={setFieldTouched}
        maxLength={maxLength}
      ></textarea>
      {maxLength && (
        <p
          className={classnames(styles.textCounter, {
            [styles.textCounterMax]: formik.values[name]?.length >= maxLength,
          })}
        >
          {formik.values[name]?.length || 0}
          {" / "}
          {maxLength}
        </p>
      )}
    </>
  );
};

TextArea.propTypes = {
  formik: PropTypes.object.isRequired,
  styles: PropTypes.object,
  name: PropTypes.string.isRequired,
  setFieldTouched: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
};

export default React.memo(TextArea);
