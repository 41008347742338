import React from "react";
import PublicLayout from "layouts/Public";
import { useTranslation } from "react-i18next";
import QuestionItem from "./QuestionItem";
import styles from "./styles.module.scss";

const Questions = () => {
    const { t } = useTranslation();

    const data = t("questions.data", { returnObjects: true });

    return (
        <PublicLayout title={t("main.questions")}>
            <div className={styles.questionsContent}>
                <div className={styles.questionsBox}>
                    {data.map((item, index) => (
                        <QuestionItem key={index} {...item} />
                    ))}
                </div>
            </div>
        </PublicLayout>
    );
};

export default React.memo(Questions);
