import { PDF_TYPE } from "constants/forms";
import { filterSocialMedia } from "utils/helpers";
import {
  UNDER_PROGRESS,
  ACCEPTED,
  REQUIRES_UPDATES,
  REJECTED,
  OTHER,
  SUPER_ADMIN_USER,
  CANCELLED,
  ADMIN_USER,
  MAIN_ELEMENT,
  SUPPORTING_ELEMENTS_TYPE,
  TALENTS,
} from "constants/forms";
export const SINGLE = "single";
export const MULTIPLE = "multiple";
export const LINK = "link";
export const TEXT = "text";
export const PROFILE = "profile";
export const DROPDOWN = "dropdown";
export const DATE = "date";
export const PHONE_INPUT = "phone_input";
export const LANGUAGES = "languages";
export const FILE = "file";
export const TEXT_AREA = "text-area";
export const RADIO_LIST = "radio-list";
export const ARRAY = "array";
export const PERSONAL_INFO = "personal-info";
export const COUNT_OF_MEMBERS = "count-of-members";
export const SOCIAL_MEDIA = "social-media";
export const CHECKBOX = "checkbox";
export const CONTACT_LIST = "contact-list";
export const NUMBER = "number";
export const STATUS = "status";
export const COMMENT_TABLE = "comment-table";
export const SOCIAL_MEDIA_OTHER = "Other";
export const FOOD_TRUCK = "7aaf1529-b122-4c1c-9e94-633ae66d79ff";
export const RESTAURANT_OR_CAFE = "fe5069f2-0fd6-41ab-8c50-599671f98df9";
export const MOBILE_STORE = "27612fae-aded-4b83-ae1e-249c681351cb";
export const STORE = "f2300501-dafc-4aa5-8925-1496f0a2fecd";
export const FREE_LAND = "054078dd-fc94-4611-b398-a39218de2139";
export const OUTDOOR_AREA = "1fe1ee1f-e209-4622-a25a-f95b6dbb948c";
export const MULTIUSE_HALL = "978316df-fb13-4513-a065-3350a47cee85";
export const INDOOR_STADIUM = "6ed33a52-c9a4-40fe-ba23-697dc5e9136f";
export const OUTDOOR_STADIUM = "0145ecfe-3996-4f71-a0de-1caf78bb7d89";
export const STAGE = "eacf1ac3-934b-449e-b7cb-e30cf0eb0af7";
export const EXHIBITION = "8d6f13e3-c90b-4d19-a018-84af2a66fd15";
export const SERVICES_PLACES = "288d973b-0587-4124-9ed8-bbfcf8e1c5d0";
export const REDIRECT_BUTTON = "REDIRECT_BUTTON";
export const TABLE = "TABLE";
export const APPLICATION_REQUIREMENTS_TABLE = "APPLICATION_REQUIREMENTS_TABLE";
export const OTHER_TEMPORARY_WORKS = "d42e1210-3c90-4db8-b1a5-d9411785a2f0";
export const OTHER_CATEGORY = "fdd6eb5e-4c7b-42ff-be68-c7158af1ceeb";

export const gender = [
  { name: "male", value: 1 },
  { name: "female", value: 2 },
];

export const social_statuses = [
  { name: "single", value: 1 },
  { name: "married", value: 2 },
  { name: "other", value: 3 },
];

export const special_needs = [
  { name: "yes", value: 1 },
  { name: "no", value: 0 },
];

export const disability_period = [
  { name: "disability_period_temporary", value: 0 },
  { name: "disability_period_permanent", value: 1 },
];

export const FULL_WIDTH = "fullWidth";
export const WIDTH80 = "w-30";
export const MIN_LENGTH = 2;
export const LENGTH254 = 254;
export const LENGTH200 = 200;
export const LENGTH400 = 400;
export const LENGTH500 = 500;
export const MAX_NUMBER_LENGTH = 9;
export const ID_LENGTH = 10;

export const TYPES = {
  "temporary-work": "temporary-works",
  talents: "talents",
  events: "events",
  "support-services": "support-services",
  stores: "stores",
  restaurants: "restaurants",
  sites: "sites",
  venues: "venues",
  logistics: "logistics",
};

export const SUBTITLES = {
  sites: "private_sector",
  events: "events_seasons",
};

export const ELEMENTS_STATUSES = {
  0: UNDER_PROGRESS,
  1: ACCEPTED,
  2: REQUIRES_UPDATES,
  3: REJECTED,
  4: OTHER,
  5: CANCELLED,
};

export const OTHER_SOCIAL_MEDIA = "other";

export const fileInputNames = [
  "profile",
  "trade_mark",
  "venue_photos",
  "layout_plan_three_dimensions",
  "aerial_photography",
  "ceiling_hanging_points",
  "electric_current_info",
  "seating_plan",
  "food_menu",
  "proposal",
  "layout_plan_two_dimensions",
];

const socialMedia = [
  { label: "name", type: TEXT },
  { label: "mobile_number", type: PHONE_INPUT },
  { label: "phone_number", type: PHONE_INPUT },
  { label: "email", type: TEXT },
  { label: "twitter" },
  { label: "instagram" },
  { label: "google_maps" },
  { label: "website" },
  { type: SOCIAL_MEDIA },
];

const adminSocialMedia = [
  { label: "name", type: TEXT },
  { label: "mobile_number", type: PHONE_INPUT },
  { label: "phone_number", type: PHONE_INPUT },
  { label: "email", type: TEXT },
  { label: "twitter" },
  { label: "instagram" },
  { label: "google_maps" },
  { label: "website" },
];

const commentsTableElements = {
  id: 6,
  title: "Comments",
  adminAccesType: [SUPER_ADMIN_USER, ADMIN_USER],
  options: [
    {
      id: 601,
      type: COMMENT_TABLE,
      label: "comment",
    },
  ],
};

const participatingElementDetails = {
  id: 0,
  title: "Participating Element Details",
  forViewOnly: true,
  options: [
    {
      label: "status",
      type: STATUS,
    },
    {
      label: "created_at",
      type: DATE,
    },
    {
      label: "updated_at",
      type: DATE,
    },
  ],
};

export const data = {
  stores: [
    {
      id: 1,
      title: "Store Details",
      name: "store_type",
      options: [
        {
          label: "store_format_id",
          type: RADIO_LIST,
          data: "storeFormats",
        },
        {
          label: "product_category_id",
          type: RADIO_LIST,
          data: "productCategories",
          subCategory: "product_child_category_id",
        },
        {
          label: "product_child_category_id",
          data: "productCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "product_category_id",
          dependenceValue: "923e41c3-b9df-4319-97a8-e401acb6e379",
        },
      ],
    },
    {
      id: 2,
      title: "Shop Information",
      name: "store_detail",
      dependency: "store_format_id",
      dependencyData: "storeFormats",
      accessType: [2],
      options: [
        {
          id: 200,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 202, label: "name_en", type: TEXT },
        { id: 203, label: "name_ar", type: TEXT },
        {
          id: 204,
          label: "other_branches",
          items: [
            { id: 2041, name: "yes", value: 1 },
            { id: 2042, name: "no", value: 0 },
          ],
          type: SINGLE,
          dependency: "store_format_id",
          dependenceValue: STORE,
        },
        {
          id: 205,
          label: "saudi_branches",
          items: [
            { id: 2051, name: "yes", value: 1 },
            { id: 2052, name: "no", value: 0 },
          ],
          type: SINGLE,
          dependency: "store_format_id",
          dependenceValue: MOBILE_STORE,
        },
        {
          id: 206,
          label: "saudi_branches_count",
          type: NUMBER,
          dependency: [
            {
              names: ["saudi_branches", "store_format_id"],
              values: [1, MOBILE_STORE],
            },
            {
              names: ["other_branches", "store_format_id"],
              values: [1, STORE],
            },
          ],
          dependenceValue: 1,
        },
        {
          id: 207,
          label: "branches_regions",
          multiple: true,
          type: DROPDOWN,
          data: "regions",
          dependency: "saudi_branches",
          dependenceValue: 1,
        },
        {
          id: 209,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 210,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 211,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
        {
          id: 212,
          label: "min_price",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 213,
          label: "max_price",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 2121,
          label: "popup_store_size",
          type: TEXT,
          dependency: "store_format_id",
          dependenceValue: MOBILE_STORE,
        },
        { id: 214, label: "establishment_date", type: DATE },
        {
          id: 215,
          label: "participated_in_previous_events",
          items: [
            { id: 2151, name: "yes", value: 1 },
            { id: 2152, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 216,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 3,
      title: "Project Information",
      name: "project_detail",
      dependency: "store_format_id",
      dependencyData: "storeFormats",
      accessType: [1],
      options: [
        {
          id: 300,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 301, label: "name_en", type: TEXT },
        { id: 302, label: "name_ar", type: TEXT },
        {
          id: 303,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 306,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
        {
          id: 304,
          label: "min_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 305,
          label: "max_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 307,
          label: "participated_in_previous_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 308,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 5,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
    },
  ],
  talents: [
    {
      id: 1,
      title: "Talent Details",
      name: "talent_detail",
      options: [
        {
          id: 201,
          label: "talent_category_id",
          type: RADIO_LIST,
          data: "talentCategories",
          subCategory: "talent_child_category_id",
        },
        {
          id: 202,
          label: "talent_child_category_id",
          data: "talentCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "talent_category_id",
          dependenceValue: "fdd6eb5e-4c7b-42ff-be68-c7158af1ceeb",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependency: "talent_child_category_id",
          dependenceValue: "add6eb5e-cc7b-42ff-be68-c7158af1ceeb",
        },

        {
          id: 101,
          label: "submission_type",
          items: [
            { id: 1011, name: "individual", value: 1 },
            { id: 1011, name: "group", value: 2 },
          ],
          type: SINGLE,
        },
        {
          id: 110,
          label: "count_of_members",
          type: COUNT_OF_MEMBERS,
          dependency: "submission_type",
          dependenceValue: 2,
          required: false,
        },
        {
          id: 102,
          label: "personal_info",
          type: PERSONAL_INFO,
        },
        {
          id: 109,
          label: "video_link",
          subTitle: "link_placeholder",
          type: TEXT,
          placeholder: null,
          class: FULL_WIDTH,
        },
        {
          id: 115,
          label: "is_participated_in_prev_events",
          items: [
            { id: 2151, name: "yes", value: 1 },
            { id: 2152, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 116,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 2,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(true, socialMedia, OTHER_SOCIAL_MEDIA),
    },
  ],
  "temporary-works": [
    {
      id: 1,
      title: "Temporary Work Details",
      name: "temporary_detail",
      options: [
        {
          label: "temporary_category_id",
          type: RADIO_LIST,
          data: "temporaryWorksCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "temporary_category_id",
          dependenceValue: "d42e1210-3c90-4db8-b1a5-d9411785a2f0",
        },
      ],
    },
    {
      id: 2,
      title: "Personal Information",
      name: "personal_information",
      options: [
        { id: 100, label: "name_ar", type: TEXT },
        { id: 101, label: "name_en", type: TEXT },
        {
          id: 102,
          label: "gender",
          items: gender,
          type: SINGLE,
        },
        {
          id: 109,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 103,
          label: "nationality_id",
          type: DROPDOWN,
          data: "countries",
        },
        { id: 104, label: "birthdate", type: DATE },
        {
          id: 105,
          label: "identity_type",
          items: [
            { id: 1051, name: "nationalId", value: 1 },
            { id: 1052, name: "iqama", value: 2 },
          ],
          type: SINGLE,
        },
        { id: 106, label: "id_number", type: TEXT },
        {
          id: 107,
          label: "occupation",
          items: [
            { id: 1011, name: "student", value: 1 },
            { id: 1012, name: "employee", value: 2 },
            { id: 1013, name: "unemployed", value: 3 },
          ],
          type: SINGLE,
        },
        {
          id: 108,
          label: "social_status",
          items: social_statuses,
          type: SINGLE,
        },
        {
          id: 109,
          label: "special_needs",
          items: special_needs,
          type: SINGLE,
        },
        {
          id: 112,
          label: "disability_period",
          items: disability_period,
          type: SINGLE,
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 110,
          label: "disability_type",
          type: DROPDOWN,
          multiple: true,
          data: "disabilityTypes",
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 111,
          label: "disability_other_category",
          type: TEXT,
          dependency: [
            {
              names: ["disability_type", "special_needs"],
              values: ["00000000-0000-0000-0005-00000000000f", 1],
            },
          ],
          dependenceValue: 1,
        },
        {
          id: 113,
          label: "additional_support",
          items: special_needs,
          type: SINGLE,
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 114,
          label: "support_description",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: [
            {
              names: ["additional_support", "special_needs"],
              values: [1, 1],
            },
          ],
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 3,
      title: "Skills and Experiences",
      name: "skill_experience",
      options: [
        {
          id: 300,
          label: "worked_before",
          items: [
            { id: 3001, name: "yes", value: "1" },
            { id: 3002, name: "no", value: "0" },
          ],
          type: SINGLE,
        },
        {
          id: 301,
          label: "work_field",
          type: TEXT_AREA,
          length: LENGTH254,
          dependency: "worked_before",
          dependenceValue: "1",
        },
        {
          id: 302,
          label: "previous_experiences",
          type: TEXT_AREA,
          length: LENGTH254,
        },
        {
          id: 303,
          label: "skills",
          type: TEXT_AREA,
          length: LENGTH254,
        },
        {
          id: 304,
          label: "languages",
          type: LANGUAGES,
        },
      ],
    },
    {
      id: 4,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
      adminOptions: adminSocialMedia,
    },
  ],
  venues: [
    {
      id: 1,
      title: "Venue Information",
      name: "venue_address",
      options: [
        {
          label: "venue_category_id",
          type: RADIO_LIST,
          data: "venueSiteCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "venue_category_id",
          dependenceValue: "494aaa96-317e-457c-bfb4-97572d9f8b9b",
        },
        {
          id: 406,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 407,
          label: "city_id",
          type: DROPDOWN,
          data: "cities",
        },
        { label: "venue_location", type: TEXT },
        { label: "district", type: TEXT },
        { label: "street_name", type: TEXT },
        { label: "zip_code", type: NUMBER, required: false },
        { label: "building_number", type: NUMBER, required: false },
        { label: "additional_number", type: NUMBER, required: false },
        {
          label: "venue_photos",
          type: FILE,
          dataType: ".jpeg, .jpg",
          output: ARRAY,
          multiple: true,
          required: false,
          optional: true,
        },
      ],
    },
    {
      id: 2,
      title: "Venue Details",
      dependency: "venue_category_id",
      dependencyData: "venueSiteCategories",
      name: "venue_detail",
      options: [
        { label: "venue_area", type: NUMBER },
        {
          label: "topography_id",
          type: DROPDOWN,
          data: "venueSiteTopographies",
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA],
        },
        {
          label: "layout_plan_three_dimensions",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          label: "layout_plan_two_dimensions",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA, OUTDOOR_STADIUM],
        },
        {
          label: "aerial_photography",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA],
        },

        {
          label: "parking_spaces",
          items: [
            { id: 1131, name: "yes", value: 1 },
            { id: 1132, name: "no", value: 0 },
          ],
          type: SINGLE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_AREA,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 113,
          label: "count_parking_spaces",
          type: NUMBER,
          dependency: "parking_spaces",
          dependenceValue: 1,
        },
        {
          id: 114,
          label: "roof_load_capacity",
          type: NUMBER,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          id: 115,
          label: "ceiling_hanging_points",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          required: false,
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          id: 116,
          label: "max_ground_carrying_weight",
          type: NUMBER,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 117,
          label: "electric_current_info",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          required: false,
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        // {
        //   id: 120,
        //   label: "land_type_id",
        //   type: DROPDOWN,
        //   data: "venueLandTypes",
        //   // dependency: "venue_category_id",
        //   dependenceValue: [
        //     MULTIUSE_HALL,
        //     INDOOR_STADIUM,
        //     STAGE,
        //     EXHIBITION,
        //     SERVICES_PLACES,
        //     OUTDOOR_STADIUM
        //   ]
        // },
        {
          id: 119,
          label: "seating_plan",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 118,
          label: "services",
          type: TEXT_AREA,
          length: LENGTH500,
          required: false,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: [
        { label: "name", type: TEXT },
        { label: "mobile_number", type: PHONE_INPUT },
        { label: "phone_number", type: PHONE_INPUT },
        { label: "email", type: TEXT },
      ],
      optional: true,
    },
  ],
  restaurants: [
    {
      id: 2,
      title: "Restaurant Details",
      name: "restaurant_type",
      accessType: [1, 2],
      options: [
        {
          label: "restaurant_format_id",
          type: RADIO_LIST,
          data: "restaurantFormats",
        },
        {
          label: "food_category_id",
          type: RADIO_LIST,
          data: "foodCategories",
          subCategory: "food_category_child_id",
        },
        {
          label: "food_category_child_id",
          data: "foodCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "food_category_id",
          dependenceValue: "72294f9d-64dc-4d88-92e9-b3877aa507eb",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependency: "food_category_child_id",
          dependenceValue: "4b5f4768-c658-4129-282e-663678c3a36c",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependency: "food_category_child_id",
          dependenceValue: "4b5f4768-c658-4129-1829-663678c3a36c",
        },
      ],
    },
    {
      id: 3,
      title: "Booth Information",
      name: "booth_detail",
      dependency: "restaurant_format_id",
      dependencyData: "restaurantFormats",
      accessType: [1],
      options: [
        {
          id: 406,
          label: "region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 201, label: "name_en", type: TEXT },
        { id: 202, label: "name_ar", type: TEXT },
        {
          id: 310,
          label: "best_selling_item",
          required: true,
          type: TEXT,
          placeholder: null,
        },
        {
          id: 200,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 201,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
        {
          id: 200,
          label: "food_menu",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 308,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "restaurant_format_id",
          dependenceValue: FOOD_TRUCK,
        },
        { id: 314, label: "establishment_date", type: DATE },
        {
          id: 3111,
          label: "foodtruck_size",
          type: TEXT,
          dependency: "restaurant_format_id",
          dependenceValue: FOOD_TRUCK,
        },
        {
          id: 312,
          label: "num_of_worker_per_branch",
          type: NUMBER,
          dependency: "restaurant_format_id",
          dependenceValue: FOOD_TRUCK,
        },
        {
          id: 200,
          label: "average_bill_per_person",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 306,
          label: "is_participated_in_prev_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 4,
      title: "Restaurant Information",
      dependency: "restaurant_format_id",
      dependencyData: "restaurantFormats",
      accessType: [2],
      name: "restaurant_detail",
      options: [
        {
          id: 406,
          label: "region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 302, label: "name_en", type: TEXT },
        { id: 303, label: "name_ar", type: TEXT },
        {
          id: 317,
          label: "best_selling_item",
          required: true,
          type: TEXT,
          placeholder: null,
        },
        {
          id: 318,
          label: "picture",
          type: FILE,
          dataType: PDF_TYPE,
          dependenceValue: RESTAURANT_OR_CAFE,
        },
        {
          id: 304,
          label: "is_other_branches",
          dependency: "restaurant_format_id",
          dependenceValue: RESTAURANT_OR_CAFE,
          items: [
            { id: 3041, name: "yes", value: 1 },
            { id: 3042, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 306,
          label: "num_branches_in_saudi",
          type: NUMBER,
          dependency: "is_other_branches",
          dependenceValue: 1,
        },
        {
          id: 307,
          label: "branches_regions",
          type: DROPDOWN,
          data: "regions",
          dependency: "is_other_branches",
          dependenceValue: 1,
          multiple: true,
          singleCondition: "num_branches_in_saudi",
        },
        {
          id: 308,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "restaurant_format_id",
          dependenceValue: FOOD_TRUCK,
        },
        {
          id: 309,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 310,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
          placeholder: null,
        },
        {
          id: 311,
          label: "food_menu",
          type: FILE,
          dataType: PDF_TYPE,
        },
        // {
        //   id: 3111,
        //   label: "foodtruck_size",
        //   type: TEXT,
        //   dependency: "restaurant_format_id",
        //   dependenceValue: FOOD_TRUCK
        // },
        // {
        //   id: 312,
        //   label: "num_of_worker_per_branch",
        //   type: NUMBER,
        //   dependency: "restaurant_format_id",
        //   dependenceValue: FOOD_TRUCK
        // },
        {
          id: 3121,
          label: "average_workers_per_branch",
          type: NUMBER,
          dependency: "restaurant_format_id",
          dependenceValue: RESTAURANT_OR_CAFE,
        },
        {
          id: 313,
          label: "average_bill_per_person",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
          required: false,
        },
        { id: 314, label: "establishment_date", type: DATE },
        {
          id: 315,
          label: "is_participated_in_prev_events",
          items: [
            { id: 3151, name: "yes", value: 1 },
            { id: 3152, name: "no", value: 0 },
          ],
          type: SINGLE,
          required: false,
        },
        {
          id: 316,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: 1,
          required: false,
        },
      ],
    },
    {
      id: 5,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
      accessType: [1, 2],
    },
  ],
  events: [
    {
      id: 1,
      title: "Event Details",
      name: "event_detail",
      options: [
        {
          id: 100,
          label: "event_category_id",
          type: RADIO_LIST,
          data: "eventCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "event_category_id",
          dependenceValue: "c1f45ebd-f82a-4b63-9abd-d8b195c2948f",
        },
        { id: 99, label: "event_name", type: TEXT },
        {
          id: 102,
          label: "description",
          type: TEXT_AREA,
          length: LENGTH400,
        },
        {
          id: 103,
          label: "target_audience",
          items: [
            { id: 1031, name: "male", value: "1" },
            { id: 1032, name: "female", value: "2" },
            { id: 1033, name: "kids", value: "3" },
          ],
          type: CHECKBOX,
        },
        {
          id: 104,
          label: "recommended_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 105,
          label: "duration",
          type: NUMBER,
          class: WIDTH80,
          tag: "day",
        },
        {
          id: 106,
          label: "proposal",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "financial_offer_link",
          fileMessage: "fileMessage",
          link_label: "financial_offer_link",
          subTitle: "link_placeholder",
        },
        {
          id: 107,
          label: "proposal_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
      ],
    },
    {
      id: 2,
      title: "Company Details",
      name: "company_detail",
      options: [
        { id: 200, label: "name_en", type: TEXT },
        { id: 201, label: "name_ar", type: TEXT },
        {
          id: 302,
          label: "located_saudi_arabia",
          items: [
            { id: 3021, name: "yes", value: 1 },
            { id: 3022, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 303,
          label: "country_id",
          type: DROPDOWN,
          data: "countries",
          dependency: "located_saudi_arabia",
          dependenceValue: 0,
        },
        {
          id: 304,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
          dependenceValue: 1,
          dependency: "located_saudi_arabia",
        },
        {
          id: 305,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 306,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
        {
          id: 307,
          label: "previous_notable_achievements",
          type: TEXT_AREA,
          length: LENGTH400,
        },
        {
          id: 308,
          label: "previous_business_references_contact_list",
          type: CONTACT_LIST,
          required: false,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
    },
  ],
  logistics: [
    {
      id: 1,
      title: "Logistic Details",
      name: "service_type",
      options: [
        {
          label: "service_category_id",
          type: RADIO_LIST,
          data: "serviceCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependency: "service_category_id",
          dependenceValue: "87601d85-bb05-47a4-8eec-ee810f2d7a3e",
        },
        {
          label: "explanation",
          type: TEXT_AREA,
          length: LENGTH400,
        },
      ],
    },
    {
      id: 2,
      title: "Company Details",
      name: "company_detail",
      options: [
        { id: 200, label: "name_en", type: TEXT },
        { id: 201, label: "name_ar", type: TEXT },
        {
          id: 302,
          label: "located_saudi_arabia",
          items: [
            { id: 3021, name: "yes", value: 1 },
            { id: 3022, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 303,
          label: "country_id",
          type: DROPDOWN,
          data: "countries",
          dependency: "located_saudi_arabia",
          dependenceValue: 0,
        },
        {
          id: 304,
          label: "saudi_region_id",
          dependency: "located_saudi_arabia",
          dependenceValue: 1,
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 305,
          label: "profile",
          type: PROFILE,
          dataType: PDF_TYPE,
          mightbeReplaced: "profile_shared_link",
          fileMessage: "fileMessage",
          link_label: "profile_shared_link",
          subTitle: "link_placeholder",
        },
        {
          id: 307,
          label: "profile_shared_link",
          required: false,
          subTitle: "link_placeholder",
          type: TEXT,
        },
        {
          id: 400,
          label: "commercial_register",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },
        {
          id: 401,
          label: "commerce_membership",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },
        {
          id: 402,
          label: "security_certificate",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },
        {
          id: 403,
          label: "zakat_certificate",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 403,
          label: "saudization_certificate",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 404,
          label: "vat_certificate",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 405,
          label: "bank_statement",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 406,
          label: "classification_certificate",
          type: FILE,
          dataType: PDF_TYPE,
          required: false,
        },

        {
          id: 407,
          label: "projects_list",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 408,
          label: "financial_statements",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },

        {
          id: 409,
          label: "signatory_letter",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },
        {
          id: 410,
          label: "association_article",
          type: FILE,
          dataType: PDF_TYPE,
          required: true,
        },
        {
          id: 306,
          label: "previous_notable_achievements",
          type: TEXT,
        },
        {
          id: 308,
          label: "previous_business_references_contact_list",
          type: CONTACT_LIST,
          required: false,
          amount: 5,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
    },
  ],
  ideas: [
    {
      id: 0,
      title: "Participating Element Details",
      options: [
        {
          label: "created_at",
          type: DATE,
        },
      ],
    },
    {
      id: 1,
      title: "Ideas and Suggestions Details",
      name: "ideas",
      options: [
        { id: 100, label: "title" },
        { id: 101, label: "description" },
        { id: 103, label: "saudi_regions", data: "regions" },
      ],
    },
    {
      id: 2,
      title: "User Details",
      name: "user",
      adminOptions: [
        {
          id: 111,
          label: "user_type",
          items: [
            { id: 1111, name: "individual", value: 1 },
            { id: 1112, name: "company", value: 2 },
          ],
        },
        {
          id: 112,
          label: "company_name",
          dependency: "user_type",
          dependenceValue: [2],
        },
        {
          id: 113,
          label: "name",
          dependency: "user_type",
          dependenceValue: [1],
        },
        { id: 114, label: "email" },
        { id: 115, label: "mobile" },
        {
          id: 115,
          label: "id_number",
          dependency: "user_type",
          dependenceValue: [1],
        },
        {
          id: 115,
          label: "commercial_record",
          dependency: "user_type",
          dependenceValue: [2],
        },
      ],
    },
    commentsTableElements,
  ],
  "request-details": [
    {
      id: 1,
      title: "Request Details",
      adminOptions: [
        { id: 100, label: "request_number" },
        { id: 101, label: "created_at", type: DATE },
        {
          id: 106,
          label: "status.status",
          titleText: "Status",
          items: [
            { id: 0, name: UNDER_PROGRESS, value: 0 },
            { id: 1, name: ACCEPTED, value: 1 },
            { id: 2, name: REQUIRES_UPDATES, value: 2 },
            { id: 3, name: REJECTED, value: 3 },
            { id: 4, name: OTHER, value: 4 },
            { id: 5, name: CANCELLED, value: 5 },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "User Details",
      adminOptions: [
        {
          id: 111,
          label: "user.user_type",
          titleText: "User Type",
          items: [
            { id: 1111, name: "individual", value: 1 },
            { id: 1112, name: "company", value: 2 },
          ],
        },
        {
          id: 112,
          label: "user.company_name",
          titleText: "Company Name",
          dependency: "user.user_type",
          dependenceValue: [2],
        },
        {
          id: 113,
          label: "user.full_name",
          titleText: "User Name",
          dependency: "user.user_type",
          dependenceValue: [1],
        },
        {
          id: 114,
          label: "user.email",
          titleText: "Email",
        },
        {
          id: 115,
          label: "user.mobile_number",
          titleText: "Mobile Number",
        },
        {
          id: 115,
          label: "user.id_number",
          titleText: "Id Number",
          dependency: "user.user_type",
          dependenceValue: [1],
        },
        {
          id: 115,
          label: "user.commercial_record",
          titleText: "Commercial Record",
          dependency: "user.user_type",
          dependenceValue: [2],
        },
      ],
    },
    {
      id: 3,
      title: "Element Details",
      adminOptions: [
        {
          id: 100,
          label: "participating_element.element_type.name_ar",
          titleText: "Element Name | Ar",
        },
        {
          id: 101,
          label: "participating_element.element_type.name_en",
          titleText: "Element Name | En",
        },
        {
          id: 102,
          label: "participating_element.element_type.name_ar",
          titleText: "Element Format | Ar",
          notNullable: true,
        },
        {
          id: 103,
          label: "participating_element.element_type.name_en",
          titleText: "Element Format | En",
          notNullable: true,
        },
        {
          id: 104,
          label: "participating_element.created_at",
          titleText: "Creation Date & Time",
          type: DATE,
        },
        {
          id: 105,
          label: "participating_element.updated_at",
          titleText: "Updating Date & Time",
          type: DATE,
        },
        {
          type: REDIRECT_BUTTON,
          text: "View Element Details",
          label: "element_details",
          titleText: "Element Details",
          slug: "participating_element.element_type.slug",
          elementId: "participating_element.id",
        },
      ],
    },
    {
      id: 4,
      title: "Application Requirements",
      adminOptions: [
        {
          id: 1,
          titleText: " ",
          label: "application_requirements",
          type: APPLICATION_REQUIREMENTS_TABLE,
        },
      ],
    },
    {
      id: 5,
      title: "Update History",
      adminOptions: [
        {
          id: 1,
          tableHead: [
            {
              name: "#",
              key: "#",
            },
            {
              name: "Admin Name",
              key: "status_updated_by",
            },
            {
              name: "Previous Status",
              key: "old_value",
            },
            {
              name: "Current Status",
              key: "new_value",
            },
            {
              name: "Updating Date & Time",
              key: "created_at",
            },
          ],
          titleText: " ",
          type: TABLE,
          stateField: "updatedHistory",
        },
      ],
    },
  ],
  "food-truck": [
    participatingElementDetails,
    {
      id: 2,
      title: "Food-Truck Details",
      name: "restaurant_type",
      adminOptions: [
        {
          label: "restaurant_format_id",
          type: RADIO_LIST,
          data: "restaurantFormats",
        },
        {
          label: "food_category_id",
          type: RADIO_LIST,
          data: "foodCategories",
          subCategory: "food_category_child_id",
        },
        {
          label: "food_category_child_id",
          type: RADIO_LIST,
          data: "foodCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependenceValue: ["72294f9d-64dc-4d88-92e9-b3877aa507eb"],
          dependency: "food_category_id",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependenceValue: [
            "4b5f4768-c658-4129-1829-663678c3a36c",
            "4b5f4768-c658-4129-282e-663678c3a36c",
          ],
          dependency: "food_category_child_id",
        },
        {
          id: 406,
          label: "region_id",
          type: DROPDOWN,
          data: "regions",
          viewOnly: true,
        },
        { id: 201, label: "name_en", type: TEXT },
        { id: 202, label: "name_ar", type: TEXT },
        {
          id: 212,
          label: "best_selling_item",
          type: TEXT,
        },
        {
          id: 200,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 200,
          label: "food_menu",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 308,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
        },
        { id: 314, label: "establishment_date", type: DATE },
        {
          id: 3111,
          label: "foodtruck_size",
          type: TEXT,
        },
        {
          id: 312,
          label: "num_of_worker_per_branch",
          type: NUMBER,
        },
        {
          id: 200,
          label: "average_bill_per_person",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 306,
          label: "is_participated_in_prev_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: [1],
        },
        {
          id: 308,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "food-and-drinks-provider": [
    participatingElementDetails,
    {
      id: 2,
      title: "Food & Beverages Provider Details",
      name: "restaurant_type",
      adminOptions: [
        {
          label: "restaurant_format_id",
          type: RADIO_LIST,
          data: "restaurantFormats",
        },
        {
          label: "food_category_id",
          type: RADIO_LIST,
          data: "foodCategories",
          subCategory: "food_category_child_id",
        },
        {
          label: "food_category_child_id",
          type: RADIO_LIST,
          data: "foodCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependenceValue: ["72294f9d-64dc-4d88-92e9-b3877aa507eb"],
          dependency: "food_category_id",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependenceValue: [
            "4b5f4768-c658-4129-1829-663678c3a36c",
            "4b5f4768-c658-4129-282e-663678c3a36c",
          ],
          dependency: "food_category_child_id",
        },
        {
          id: 406,
          label: "region_id",
          type: DROPDOWN,
          data: "regions",
          viewOnly: true,
        },
        { id: 201, label: "name_en", type: TEXT },
        { id: 202, label: "name_ar", type: TEXT },
        {
          id: 212,
          label: "best_selling_item",
          type: TEXT,
        },
        {
          id: 200,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 200,
          label: "food_menu",
          type: FILE,
          dataType: PDF_TYPE,
        },
        { id: 314, label: "establishment_date", type: DATE },
        {
          id: 200,
          label: "average_bill_per_person",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 306,
          label: "is_participated_in_prev_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: [1],
        },
        {
          id: 308,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "personal-stores": [
    participatingElementDetails,
    {
      id: 1,
      title: "Personal Store Details",
      name: "store_type",
      adminOptions: [
        {
          label: "store_format_id",
          type: RADIO_LIST,
          data: "storeFormats",
        },
        {
          label: "product_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "product_child_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          notNullable: true,
        },
        {
          id: 300,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 301, label: "name_en", type: TEXT },
        { id: 302, label: "name_ar", type: TEXT },
        {
          id: 303,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
          required: false,
        },
        {
          id: 304,
          label: "min_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 305,
          label: "max_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 306,
          label: "participated_in_previous_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: [1],
        },
        {
          id: 308,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 2,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "pop-up-stores": [
    participatingElementDetails,
    {
      id: 1,
      title: "Pop up Store Details",
      name: "store_type",
      adminOptions: [
        {
          label: "store_format_id",
          type: RADIO_LIST,
          data: "storeFormats",
        },
        {
          label: "product_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "product_child_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          notNullable: true,
        },
        {
          id: 300,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 301, label: "name_en", type: TEXT },
        { id: 302, label: "name_ar", type: TEXT },
        {
          id: 304,
          label: "min_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 305,
          label: "max_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 306,
          label: "participated_in_previous_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: [1],
        },
        // {
        //   id: 304,
        //   label: "other_branches",
        //   items: [
        //     { id: 3041, name: "yes", value: 1 },
        //     { id: 3042, name: "no", value: 0 }
        //   ],
        //   type: SINGLE
        // },
        {
          id: 306,
          label: "saudi_branches",
          items: [
            { id: 3041, name: "yes", value: 1 },
            { id: 3042, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "saudi_branches_count",
          type: NUMBER,
          dependency: "saudi_branches",
          dependenceValue: [1],
        },
        // {
        //   id: 207,
        //   label: "branches_regions",
        //   multiple: true,
        //   type: DROPDOWN,
        //   data: "regions",
        //   dependency: "saudi_branches",
        //   dependenceValue: [1]
        // },
        {
          id: 209,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 210,
          label: "profile",
          titleText: "Business Profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 2121,
          label: "popup_store_size",
          type: TEXT,
        },
        {
          id: 214,
          label: "establishment_date",
          type: DATE,
        },
        {
          id: 215,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 2,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: filterSocialMedia(false, socialMedia, OTHER_SOCIAL_MEDIA),
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "admin-restaurants": [
    participatingElementDetails,
    {
      id: 2,
      title: "Restaurant Details",
      name: "restaurant_type",
      adminOptions: [
        {
          label: "restaurant_format_id",
          type: RADIO_LIST,
          data: "restaurantFormats",
        },
        {
          label: "food_category_id",
          type: RADIO_LIST,
          data: "foodCategories",
          subCategory: "food_category_child_id",
        },
        {
          label: "food_category_child_id",
          type: RADIO_LIST,
          data: "foodCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          dependenceValue: ["72294f9d-64dc-4d88-92e9-b3877aa507eb"],
          dependency: "food_category_id",
        },
        {
          label: "child_other_category",
          type: TEXT,
          dependenceValue: [
            "4b5f4768-c658-4129-1829-663678c3a36c",
            "4b5f4768-c658-4129-282e-663678c3a36c",
          ],
          dependency: "food_category_child_id",
        },
        {
          id: 406,
          label: "region_id",
          type: DROPDOWN,
          data: "regions",
          viewOnly: true,
        },
        { id: 201, label: "name_en", type: TEXT },
        { id: 202, label: "name_ar", type: TEXT },
        {
          id: 212,
          label: "best_selling_item",
          type: TEXT,
        },
        {
          id: 200,
          label: "picture",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 200,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 200,
          label: "food_menu",
          type: FILE,
          dataType: PDF_TYPE,
        },
        { id: 314, label: "establishment_date", type: DATE },
        {
          id: 312,
          label: "average_workers_per_branch",
          type: NUMBER,
        },
        {
          id: 200,
          label: "average_bill_per_person",
          type: NUMBER,
          tag: "sr",
          class: WIDTH80,
        },
        {
          id: 306,
          label: "is_participated_in_prev_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: [1],
        },
        {
          id: 304,
          label: "is_other_branches",
          items: [
            { id: 3041, name: "yes", value: 1 },
            { id: 3042, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 306,
          label: "num_branches_in_saudi",
          type: NUMBER,
          dependency: "is_other_branches",
          dependenceValue: [1],
        },
        {
          id: 307,
          label: "branches_regions",
          type: DROPDOWN,
          data: "regions",
          dependency: "is_other_branches",
          dependenceValue: [1],
          multiple: true,
          singleCondition: "num_branches_in_saudi",
        },
        {
          id: 308,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 5,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "admin-talents": [
    participatingElementDetails,
    {
      id: 1,
      title: "Talent Details",
      name: "talent_detail",
      adminOptions: [
        {
          id: 100,
          label: "talent_category_id",
          type: RADIO_LIST,
          data: "talentCategories",
        },
        {
          id: 100,
          label: "talent_child_category_id",
          type: RADIO_LIST,
          data: "talentCategories",
        },
        {
          id: 103,
          label: "other_category",
          type: TEXT,
          notNullable: true,
        },
        {
          id: 3000,
          label: "child_other_category",
          type: TEXT,
          dependenceValue: "add6eb5e-cc7b-42ff-be68-c7158af1ceeb",
          dependency: "talent_child_category_id",
        },
        {
          id: 101,
          label: "submission_type",
          items: [
            { id: 1011, name: "individual", value: 1 },
            { id: 1011, name: "group", value: 2 },
          ],
          type: SINGLE,
        },
        {
          id: 110,
          label: "count_of_members",
          type: TEXT,
          required: false,
        },
        {
          id: 102,
          label: "personal_info",
          type: PERSONAL_INFO,
          required: false,
        },
        {
          id: 109,
          label: "video_link",
          subTitle: "link_placeholder",
          type: TEXT,
          placeholder: null,
          class: FULL_WIDTH,
        },
        {
          id: 115,
          label: "is_participated_in_prev_events",
          items: [
            { id: 2151, name: "yes", value: 1 },
            { id: 2152, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 116,
          label: "activities_during_event",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "is_participated_in_prev_events",
          dependenceValue: [1],
        },
      ],
    },
    {
      id: 2,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: [
        { label: "name", type: TEXT },
        { label: "mobile_number", type: PHONE_INPUT },
        { label: "phone_number", type: PHONE_INPUT },
        { label: "email", type: TEXT },
        { label: "twitter" },
        { label: "instagram" },
        { label: "google_maps" },
        { label: "website" },
      ],
    },
    commentsTableElements,
  ],
  "admin-temporary-works": [
    participatingElementDetails,
    {
      id: 2,
      title: "Temporary Work Details",
      name: "temporary_detail",
      options: [
        {
          id: 300,
          label: "temporary_category_id",
          type: TEXT,
          data: "temporaryWorksCategories",
        },
      ],
    },
    {
      id: 2,
      title: "Personal Information",
      name: "personal_information",
      options: [
        { id: 100, label: "name_ar", type: TEXT },
        { id: 101, label: "name_en", type: TEXT },
        {
          id: 102,
          label: "gender",
          items: gender,
          type: SINGLE,
        },
        {
          id: 109,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 103,
          label: "nationality_id",
          type: DROPDOWN,
          data: "countries",
        },
        { id: 104, label: "birthdate", type: DATE },
        {
          id: 105,
          label: "identity_type",
          items: [
            { id: 1051, name: "nationalId", value: 1 },
            { id: 1052, name: "iqama", value: 2 },
          ],
          type: SINGLE,
        },
        { id: 106, label: "id_number", type: TEXT },
        {
          id: 107,
          label: "occupation",
          items: [
            { id: 1011, name: "student", value: 1 },
            { id: 1012, name: "employee", value: 2 },
            { id: 1013, name: "unemployed", value: 3 },
          ],
          type: SINGLE,
        },
        {
          id: 108,
          label: "social_status",
          items: social_statuses,
          type: SINGLE,
        },
        {
          id: 109,
          label: "special_needs",
          items: special_needs,
          type: SINGLE,
        },
        {
          id: 112,
          label: "disability_period",
          items: disability_period,
          type: SINGLE,
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 110,
          label: "disability_type",
          type: DROPDOWN,
          multiple: true,
          data: "disabilityTypes",
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 111,
          label: "disability_other_category",
          type: TEXT,
          dependency: [
            {
              names: ["disability_type", "special_needs"],
              values: ["00000000-0000-0000-0005-00000000000f", 1],
            },
          ],
          dependenceValue: 1,
        },
        {
          id: 113,
          label: "additional_support",
          items: special_needs,
          type: SINGLE,
          dependency: "special_needs",
          dependenceValue: 1,
        },
        {
          id: 114,
          label: "support_description",
          type: TEXT,
          dependency: [
            {
              names: ["additional_support", "special_needs"],
              values: [1, 1],
            },
          ],
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 3,
      title: "Skills and Experiences",
      name: "skill_experience",
      adminOptions: [
        {
          id: 300,
          label: "worked_before",
          items: [
            { id: 3001, name: "yes", value: 1 },
            { id: 3002, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 301,
          label: "work_field",
          type: TEXT_AREA,
          length: LENGTH254,
          dependency: "worked_before",
          dependenceValue: [1],
        },
        {
          id: 302,
          label: "previous_experiences",
          type: TEXT_AREA,
          length: LENGTH254,
        },
        {
          id: 303,
          label: "skills",
          type: TEXT_AREA,
          length: LENGTH254,
        },
        {
          id: 304,
          label: "languages",
          type: LANGUAGES,
        },
      ],
    },
    {
      id: 4,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "admin-events": [
    participatingElementDetails,
    {
      id: 1,
      title: "Event Details",
      name: "event_detail",
      adminOptions: [
        {
          id: 100,
          label: "event_category_id",
          type: RADIO_LIST,
          data: "eventCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          notNullable: true,
        },
        { id: 101, label: "name", type: TEXT },
        {
          id: 102,
          label: "description",
          type: TEXT_AREA,
          length: LENGTH400,
        },
        {
          id: 103,
          label: "target_audience",
          optionValues: [
            { id: 1031, name: "Men", value: "1" },
            { id: 1032, name: "Women", value: "2" },
            { id: 1033, name: "Kids", value: "3" },
          ],
          multiple: true,
        },
        {
          id: 104,
          label: "recommended_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 105,
          label: "duration",
          type: NUMBER,
          class: WIDTH80,
          tag: "day",
        },
        {
          id: 106,
          label: "financial_offer",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 107,
          label: "financial_offer_link",
          type: TEXT,
        },
      ],
    },
    {
      id: 2,
      title: "Company Details",
      name: "company_detail",
      adminOptions: [
        { id: 200, label: "name_en", type: TEXT },
        { id: 201, label: "name_ar", type: TEXT },
        {
          id: 302,
          label: "located_saudi_arabia",
          items: [
            { id: 3021, name: "yes", value: 1 },
            { id: 3022, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 303,
          label: "country_id",
          type: DROPDOWN,
          data: "countries",
          dependency: "located_saudi_arabia",
          dependenceValue: [0],
        },
        {
          id: 304,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
          dependenceValue: [1],
          dependency: "located_saudi_arabia",
        },
        {
          id: 305,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 306,
          label: "previous_notable_achievements",
          type: TEXT_AREA,
          length: LENGTH400,
        },
        {
          id: 306,
          label: "previous_business_references_contact_list",
          type: CONTACT_LIST,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "admin-venues": [
    participatingElementDetails,
    {
      id: 1,
      title: "Venue Information",
      name: "venue_address",
      adminOptions: [
        {
          label: "venue_category_id",
          type: RADIO_LIST,
          data: "venueCategories",
        },
        {
          id: 406,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        {
          label: "city_id",
          type: DROPDOWN,
          data: "cities",
        },
        { label: "venue_location", type: TEXT },
        { label: "district", type: TEXT },
        { label: "street_name", type: TEXT },
        { label: "zip_code", type: NUMBER, required: false },
        { label: "building_number", type: NUMBER, required: false },
        { label: "additional_number", type: NUMBER, required: false },
        {
          label: "venue_photos",
          type: FILE,
          dataType: PDF_TYPE,
          output: ARRAY,
          multiple: true,
          required: false,
        },
      ],
    },
    {
      id: 2,
      title: "Venue Details",
      dependency: "venue_category_id",
      dependencyData: "venueCategories",
      name: "venue_detail",
      adminOptions: [
        { label: "venue_area", type: NUMBER },
        {
          label: "topography_id",
          type: DROPDOWN,
          data: "venueTopographies",
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA],
        },
        {
          label: "layout_plan_three_dimensions",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          label: "layout_plan_two_dimensions",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA, OUTDOOR_STADIUM],
        },
        {
          label: "aerial_photography",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [FREE_LAND, OUTDOOR_AREA],
        },

        {
          label: "parking_spaces",
          items: [
            { id: 1131, name: "yes", value: 1 },
            { id: 1132, name: "no", value: 0 },
          ],
          type: SINGLE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_AREA,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 113,
          label: "count_parking_spaces",
          type: NUMBER,
          dependency: "parking_spaces",
          dependenceValue: [1],
        },
        {
          id: 114,
          label: "roof_load_capacity",
          type: NUMBER,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          id: 115,
          label: "ceiling_hanging_points",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          required: false,
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
          ],
        },
        {
          id: 116,
          label: "max_ground_carrying_weight",
          type: NUMBER,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 117,
          label: "electric_current_info",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          required: false,
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 120,
          label: "land_type_id",
          type: DROPDOWN,
          data: "venueLandTypes",
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 119,
          label: "seating_plan",
          type: FILE,
          dataType: PDF_TYPE,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
        {
          id: 118,
          label: "services",
          type: TEXT_AREA,
          length: LENGTH500,
          required: false,
          dependency: "venue_category_id",
          dependenceValue: [
            MULTIUSE_HALL,
            INDOOR_STADIUM,
            STAGE,
            EXHIBITION,
            SERVICES_PLACES,
            OUTDOOR_STADIUM,
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      options: [
        { label: "name", type: TEXT },
        { label: "mobile_number", type: PHONE_INPUT },
        { label: "phone_number", type: PHONE_INPUT },
        { label: "email", type: TEXT },
      ],
    },
    commentsTableElements,
  ],
  "admin-logistics": [
    participatingElementDetails,
    {
      id: 1,
      title: "Logistic Details",
      name: "service_type",
      adminOptions: [
        {
          label: "service_category_id",
          data: "serviceCategories",
        },
        {
          label: "other_category",
          notNullable: true,
        },
        {
          label: "explanation",
        },
      ],
    },
    {
      id: 2,
      title: "Company Details",
      name: "company_detail",
      adminOptions: [
        { id: 200, label: "name_en", type: TEXT },
        { id: 201, label: "name_ar", type: TEXT },
        {
          id: 302,
          label: "located_saudi_arabia",
          items: [
            { id: 3021, name: "yes", value: 1 },
            { id: 3022, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 303,
          label: "country_id",
          type: DROPDOWN,
          data: "countries",
          dependency: "located_saudi_arabia",
          dependenceValue: [0],
        },
        {
          id: 304,
          label: "saudi_region_id",
          dependency: "located_saudi_arabia",
          dependenceValue: [1],
          type: DROPDOWN,
          data: "regions",
        },
        {
          id: 305,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 308,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
        {
          id: 400,
          label: "commercial_register",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 401,
          label: "commerce_membership",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 402,
          label: "security_certificate",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 403,
          label: "zakat_certificate",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 403,
          label: "saudization_certificate",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 404,
          label: "vat_certificate",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 405,
          label: "bank_statement",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 406,
          label: "classification_certificate",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 407,
          label: "projects_list",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 408,
          label: "financial_statements",
          type: FILE,
          dataType: PDF_TYPE,
        },

        {
          id: 409,
          label: "signatory_letter",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 410,
          label: "association_article",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 306,
          label: "previous_notable_achievements",
          type: TEXT,
        },
        {
          id: 307,
          label: "previous_business_references_contact_list",
          type: CONTACT_LIST,
        },
      ],
    },
    {
      id: 3,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
  "admin-stores": [
    participatingElementDetails,
    {
      id: 1,
      title: "Store Details",
      name: "store_type",
      adminOptions: [
        {
          label: "store_format_id",
          type: RADIO_LIST,
          data: "storeFormats",
        },
        {
          label: "product_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "product_child_category_id",
          type: RADIO_LIST,
          data: "productCategories",
        },
        {
          label: "other_category",
          type: TEXT,
          notNullable: true,
        },
        {
          id: 300,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 301, label: "name_en", type: TEXT },
        { id: 302, label: "name_ar", type: TEXT },
        {
          id: 304,
          label: "min_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 305,
          label: "max_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 306,
          label: "participated_in_previous_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: [1],
        },
        {
          id: 304,
          label: "other_branches",
          items: [
            { id: 3041, name: "yes", value: 1 },
            { id: 3042, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 306,
          label: "saudi_branches_count",
          type: NUMBER,
          dependency: "other_branches",
          dependenceValue: [1],
        },
        // {
        //   id: 207,
        //   label: "branches_regions",
        //   multiple: true,
        //   type: DROPDOWN,
        //   data: "regions",
        //   dependency: "other_branches",
        //   dependenceValue: [1]
        // },
        {
          id: 209,
          label: "trade_mark",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 210,
          label: "profile",
          titleText: "Business Profile",
          type: FILE,
          dataType: PDF_TYPE,
        },
        {
          id: 214,
          label: "establishment_date",
          type: DATE,
        },
        {
          id: 215,
          label: "profile_shared_link",
          required: false,
          type: LINK,
        },
      ],
    },
    {
      id: 3,
      title: "Project Information",
      name: "project_detail",
      dependency: "store_format_id",
      dependencyData: "storeFormats",
      options: [
        {
          id: 300,
          label: "saudi_region_id",
          type: DROPDOWN,
          data: "regions",
        },
        { id: 301, label: "name_en", type: TEXT },
        { id: 302, label: "name_ar", type: TEXT },
        {
          id: 303,
          label: "profile",
          type: FILE,
          dataType: PDF_TYPE,
          required: false,
        },
        {
          id: 304,
          label: "min_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 305,
          label: "max_price",
          type: NUMBER,
          class: WIDTH80,
          tag: "sr",
        },
        {
          id: 306,
          label: "participated_in_previous_events",
          items: [
            { id: 3061, name: "yes", value: 1 },
            { id: 3062, name: "no", value: 0 },
          ],
          type: SINGLE,
        },
        {
          id: 307,
          label: "event_activities",
          type: TEXT_AREA,
          length: LENGTH200,
          dependency: "participated_in_previous_events",
          dependenceValue: 1,
        },
      ],
    },
    {
      id: 5,
      title: "Contact & Social Media",
      name: "contact_social_media",
      adminOptions: adminSocialMedia,
    },
    commentsTableElements,
  ],
};

export const PRODUCT_TYPES = {
  "restaurant-or-cafe": "admin-restaurants",
  "food-truck": "food-truck",
  store: "admin-stores",
  "pop-up-stores": "pop-up-stores",
  "personal-stores": "personal-stores",
  talents: "admin-talents",
  "temporary-work": "admin-temporary-works",
  "food-and-drinks-provider": "food-and-drinks-provider",
  events: "admin-events",
  ideas: "ideas",
  venues: "admin-venues",
  logistics: "admin-logistics",
  "request-details": "request-details",
};

export const INVERETED_TYPES = {
  restaurants: "restaurant-or-cafe",
  "food-truck": "food-truck",
  stores: "pop-up-stores",
  "store": "store",
  "personal-stores": "personal-stores",
  talents: "talents",
  "temporary-work": "temporary-work",
  "food-and-drinks-provider": "food-and-drinks-provider",
  events: "events",
  ideas: "ideas",
  venues: "venues",
  logistics: "logistics",
  "request-details": "request-details",
};

export const ELEMENT_TYPES = {
  "restaurant-or-cafe": MAIN_ELEMENT,
  "food-truck": MAIN_ELEMENT,
  stores: MAIN_ELEMENT,
  store:MAIN_ELEMENT,
  "personal-stores": MAIN_ELEMENT,
  "pop-up-stores": MAIN_ELEMENT,
  "temporary-work": MAIN_ELEMENT,
  "food-and-drinks-provider": MAIN_ELEMENT,
  events: SUPPORTING_ELEMENTS_TYPE,
  ideas: SUPPORTING_ELEMENTS_TYPE,
  venues: SUPPORTING_ELEMENTS_TYPE,
  logistics: SUPPORTING_ELEMENTS_TYPE,
  talents: TALENTS,
};

export const languageLevel = {
  good: "Good",
  very_good: "Very Good",
  excellent: "Excellent",
};

export const status = ["Incomplete", "Complete"];

export const MISSING_DATA = "Un Defined";
export const personalInfoDependencies = [
  {
    key: "disability_other_category",
    dependency: "disability_type",
    dependenceValue: "00000000-0000-0000-0005-00000000000f",
  },
  {
    key: "support_description",
    dependency: "additional_support",
    dependenceValue: 1,
  },
];
