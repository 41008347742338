import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const DownloadModal = () => {
  const { t } = useTranslation();
  return <div className={styles.modalWrapper}>
    <div className={styles.modal}>
      <div className={styles.title}>{t('downloadPage.title')}</div>
      <div className={styles.buttonsWrappers}>
        <button className={styles.back} onClick={() => {window.history.back()}}>{t('downloadPage.back')}</button>
        <button className={styles.reload} onClick={() => {window.location.reload()}}>{t('downloadPage.reload')}</button>
      </div>
    </div>
  </div>
}

export default DownloadModal;