import React from "react";
import ProfileLayout from "layouts/Profile";
import { useTranslation } from "react-i18next";
import { ProfileInfo, PageTitle } from "components";
import styles from "./styles.module.scss";

const Profile = () => {
  const { t } = useTranslation();

  return (
    <ProfileLayout>
      <div className={styles.profile}>
        <PageTitle title={t("profile.title")} />
        <div className={styles.profileMain}>
          <ProfileInfo />
        </div>
      </div>
    </ProfileLayout>
  );
};

export default Profile;
