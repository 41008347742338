import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminDashboardStatisticsElements } from "pages/AdminStatistics/constants";
import { AdminStatisticsItem, AdminTopPanel } from "components";
import { getDashboardStatistics, exportAdminStatistics } from "store/actions/adminElements.actions";

const AdminDashboardStatistics = () => {
  const { dashboardStatistics } = useSelector(({ adminElements }) => adminElements)
  const dispatch = useDispatch()

  const buttonTools = {
    label: 'Export',
    onClick: () => {
      dispatch(exportAdminStatistics.request())
    }
  };

  useEffect(()=>{
    dispatch(getDashboardStatistics.request())
  },[ dispatch ])

    return(
      <>
        <AdminTopPanel  
          withButton
          buttonTools={buttonTools}
        />
        {adminDashboardStatisticsElements.map(({ title, label, children, value }, index) =>(
            <AdminStatisticsItem
              data={dashboardStatistics}
              title={title}
              label={label}
              value={value}  
              children={children}
              key={index}
            />
        ))}
      </>  
    )
}

export default AdminDashboardStatistics