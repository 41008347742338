import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import SeaSerpentChevron from "assets/icons/sea-serpent-chevron.svg";
import PurpleChevron from "assets/icons/purple-chevron.svg";
import Slide1 from "assets/images/instructions/1.svg";
import Slide2 from "assets/images/instructions/2.svg";
import Slide3 from "assets/images/instructions/3.svg";
import Slide4 from "assets/images/instructions/4.png";
import Slide5 from "assets/images/instructions/5.svg";
import cn from "classnames";
import styles from "./styles.module.scss";

const slides = [Slide1, Slide2, Slide3, Slide4, Slide5];

const InstructionsModal = ({ modalOpen, modalClose }) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const closeModal = () => {
    setCurrentSlide(0);
    modalClose();
    localStorage.removeItem("newUser");
  };
  const image = slides[currentSlide];
  return (
    <Modal
      className={`modal-dialog-centered modal-md ${styles.modalWrapper}`}
      isOpen={modalOpen}
      toggle={closeModal}
    >
      <div className={styles.closeBtn} onClick={closeModal}>
        &times;{" "}
      </div>
      <ModalHeader className={styles.headerWrapper}>
        <img src={image} alt="checked" className={styles.imageWrapper} />
      </ModalHeader>
      <ModalBody className={styles.bodyWrapper}>
        <p className={styles.title}>
          {t(`instructionsModal.title_${currentSlide + 1}`)}
        </p>
        <p className={styles.subtitle}>
          {t(`instructionsModal.subtitle_${currentSlide + 1}`)}
        </p>
      </ModalBody>
      <div className={styles.paginatioWrapper}>
        {slides.map((el, i) => (
          <div
            key={i}
            className={cn(
              styles.paginationItem,
              i === currentSlide && styles.activeItem
            )}
            onClick={() => setCurrentSlide(i)}
          />
        ))}
      </div>
      <div className={styles.navigationButtons}>
        <div
          className={styles.back}
          hidden={currentSlide === 0}
          onClick={() => setCurrentSlide((prev) => --prev)}
        >
          <img src={PurpleChevron} alt="Prev" />
          <span>{t("instructionsModal.back")}</span>
        </div>
        <div
          className={styles.next}
          hidden={currentSlide === 4}
          onClick={() => setCurrentSlide((prev) => ++prev)}
        >
          <span>{t("instructionsModal.next")}</span>
          <img src={SeaSerpentChevron} alt="Next" />
        </div>
        <div
          className={styles.start}
          hidden={currentSlide !== 4}
          onClick={closeModal}
        >
          <span>{t("instructionsModal.start")}</span>
        </div>
      </div>
    </Modal>
  );
};

export default InstructionsModal;
