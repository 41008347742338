import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { arabicToEnglishNums, digits } from "utils/validation";
import {isFieldWithError} from 'utils/helpers';

const TAGS = {
  "sr": "priceTag",
  "day": "dayTag"
}

const NumberInput = ({
  formik,
  onFocus,
  styles,
  title,
  name,
  placeholder,
  className,
  tag
}) => {
  const { t } = useTranslation();

  const handleChange = e => {
    e.target.value = arabicToEnglishNums(e.target.value);
    if (digits.test(e.target.value) || !e.target.value) {
      formik.handleChange(e);
    }
  };

  return (
    <div className="d-flex align-items-center flex-wrap">
      <input
        className={classnames(
          `form-control`,
          styles.optionInpt,
          styles[className],{'invalidField': isFieldWithError(formik, name)}
        )}
        name={name}
        placeholder={
          placeholder === null ? "" : t(`${title}.${placeholder || name}`)
        }
        onChange={handleChange}
        value={formik.values[name] || ""}
        onFocus={onFocus}
        type="tel"
        inputMode="decimal"
      />
      {tag && (
        <span className="mx-2">
          {t(`${TAGS[tag]}`)}
        </span>
      )}
    </div>
  );
};

NumberInput.propTypes = {
  formik: PropTypes.object.isRequired,
  onFocus: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
};

export default NumberInput;
