import React, { useEffect } from "react";
import { AdminLoginForm } from "components";
import Layout from "layouts/AdminLogin";
import { useDispatch } from "react-redux";
import { getFile } from "store/actions/saudiSeasonsEvents.actions.js";
import { DownloadModal } from "../../components";

const Verify = () => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const param = searchParams.get("redirect");
  const token = localStorage.getItem("admin_token");

  useEffect(() => {
    if (token) {
      dispatch(getFile.request(param));
    }
  }, [dispatch, param, token]);
  return (
    token ?
      <DownloadModal />
      :
      <Layout>
        <AdminLoginForm />
      </Layout>
  );
};

export default Verify;