import React from "react";
import Layout from "layouts/Admin";
import { NewControlPanel } from "components";

const AdminNewControlPanel = () => (
  <Layout title="New Control Panel User">
    <NewControlPanel />
  </Layout>
);

export default AdminNewControlPanel;
