import React from "react"
import { Button, ModalBody } from "react-bootstrap";
import { Modal, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ApplyModal = ({title, modalOpen, modalClose, closeText}) => (
  <Modal
    className={`modal-dialog-centered modal-md ${styles.applyModalWrapper}`}
    isOpen={modalOpen}
    toggle={modalClose}
  >
    <ModalHeader toggle={modalClose} className={styles.modalHeader}>
      <p className="text-center">{title}</p>
    </ModalHeader>
    <ModalBody className={styles.applyModalBody}>
      <Button className="ml-4 btn-paua" onClick={modalClose}>{closeText}</Button>
    </ModalBody>
  </Modal>
);

ApplyModal.propTypes = {
  title: PropTypes.string,
  modalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
  closeText: PropTypes.string
}

export default ApplyModal;