import React from "react";
import Layout from "layouts/Admin";
import { AddEditComment } from "components";
import { useHistory, useParams } from 'react-router-dom';

const AdminAddEditComment = () => {
  const history = useHistory();
  const {comment_id} = useParams();

  return(
    <Layout title={`${comment_id ? "Edit" : "New"} Comment`}>
      <AddEditComment element_id={history?.location?.state?.elementId} comment_id={comment_id}/>
    </Layout>
  )
};

export default AdminAddEditComment;
