import React from "react";
import Layout from "layouts/Admin";
import { EventsArrangement } from "components";

const AdminEventsArrangement = () => {
  return (
    <Layout title="Events Arrangement">
      <EventsArrangement />
    </Layout>
  );
};

export default AdminEventsArrangement;
