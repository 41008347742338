import React, { useEffect } from "react";
import LoginLayout from "layouts/Login";
import { LoginForm } from "components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getFile } from "store/actions/saudiSeasonsEvents.actions.js";
import { useHistory } from "react-router";
import { DownloadModal } from "../../components";

const Verify = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const param = searchParams.get("redirect");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(getFile.request(param));
      // history.goBack();
    }
  }, [dispatch, history, param, token]);

  return (
    token
    ? <DownloadModal />
    : <LoginLayout title={t("login.title")} subTitle={t("login.subTitle")}>
        <LoginForm />
      </LoginLayout>
  );
};

export default Verify;
