import React, { useEffect, useState, useMemo, useRef } from "react";
import DropDown from "react-dropdown-select";
import PropTypes from "prop-types";
import "./styles.module.scss";

const Select = ({
  data,
  formik,
  name,
  multiple = false,
  placeholder,
  className,
  dependency,
  condition,
  value,
  singleCondition,
  isAllSelected,
  selectedHandler,
}) => {
  const [multipleField, setMultiple] = useState(multiple);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleChange = (values) => {
    if (
      singleCondition &&
      formik.values[singleCondition] &&
      values?.length > formik.values[singleCondition]
    ) {
      ref.current.state.values = selectedValue;
      return false;
    }

    if (multipleField) {
      formik.setFieldValue(
        name,
        values.map((item) => item.id || item)
      );
    } else {
      formik.setFieldValue(name, values[0]?.id);
    }
  };

  const handleFocus = () => {
    formik.setFieldTouched(name, true);
    setOpen(true);
  };

  useEffect(() => {
    if (dependency && !multiple && condition) formik.setFieldValue(name, "");
    // eslint-disable-next-line
  }, [formik.values[dependency]]);

  useEffect(() => {
    if (Number(formik.values[singleCondition]) === 1) setMultiple(false);
    else setMultiple(multiple);
    if (multipleField) {
      const value = selectedValue.slice(
        0,
        formik.values[singleCondition] || selectedValue.length
      );
      ref.current.state.values = value;
      formik.setFieldValue(
        name,
        value.map((i) => i.id || i)
      );
    }
    // eslint-disable-next-line
  }, [formik.values[singleCondition]]);

  useEffect(() => {
    selectedHandler && selectedHandler(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const selectedValue = useMemo(
    () =>
      data?.filter((i) =>
        (multipleField
          ? value?.map((item) => item.id || item)
          : value
        )?.includes(i.id)
      ),
       // eslint-disable-next-line react-hooks/exhaustive-deps
    [multipleField, value]
  );
  //check slectedValues when be fixes it.

  useEffect(() => {
    formik.validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <DropDown
      ref={ref}
      values={selectedValue}
      className={className}
      options={data}
      valueField="id"
      labelField="name"
      onChange={handleChange}
      placeholder={placeholder}
      multi={multipleField}
      searchBy="name"
      keepSelectedInList={false}
      onDropdownOpen={handleFocus}
      onDropdownClose={() => setOpen(false)}
      dropdownHeight={isAllSelected ? "40px" : "300px"}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  dependency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  singleCondition: PropTypes.string,
};
export default React.memo(Select);
