import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Loader,
  TableComponent,
  Pagination,
  AdminTopPanel,
  AlertBox
} from "components";
import { ADD_APPLICATION_REQUIREMENT, EDIT_APPLICATION_REQUIREMENT } from "constants/paths";
import { getApplicationRequirements } from "store/actions/adminElements.actions";
import { compile } from "path-to-regexp";
import { EDITION } from "constants/forms";

const hiddenOptions = {
  0: "No",
  1: "Yes"
}

const AdminApplicationRequirements = () => {

  const { applicationRequirements, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectTo = (action, id = null) => {
    if(action === EDITION){
      history.push(compile(EDIT_APPLICATION_REQUIREMENT)({ id }));
      return;
    }
    history.push(compile(ADD_APPLICATION_REQUIREMENT)());
  }

  useEffect(() => {
    dispatch(getApplicationRequirements.request());
  }, [dispatch]);

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "Name",
      key: "name"
    },
    {
      name: "Description",
      key: "description"
    },
    {
      name: "Type",
      key: "type"
    },
    {
      name: "Hidden",
      key: "hidden"
    },
    {
      name: "Edit",
      key: "editWithRedirect",
      onClick: ({ id }) => redirectTo("edit", id)
    },
  ];

  const tableBody = applicationRequirements?.data && applicationRequirements.data.map(el => ({
    id: el?.id,
    name: el?.name_en || "",
    description: el?.description_en || "",
    type: el?.type || "",
    hidden: hiddenOptions[el?.hidden] || ""
  }));

  const buttonTools = {
    label: 'New Application Requirement',
    onClick: () => redirectTo('add')
  }

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }

      <AdminTopPanel
        withButton
        buttonTools={buttonTools}
      />

      <div className="card">
        <div className="card-body">
          <TableComponent
            tableHead={tableHead}
            tableBody={tableBody}
          />
          <Pagination
            action = {getApplicationRequirements}
            currentPage = {applicationRequirements?.current_page}
            lastPage = {applicationRequirements?.last_page}
            goToPage
          />
        </div>
      </div>
    </Loader>
  );
}

export default React.memo(AdminApplicationRequirements);
