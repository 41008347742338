import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FileInput, Select } from "components";
import { useSelector } from "react-redux";
import { LENGTH400 } from "components/Accordion/contants";
import styles from "./styles.module.scss";

const IdeasForm = ({ formik, getError, setFieldTouched }) => {
  const { t } = useTranslation();
  const { regions } = useSelector((state) => state.elements);
  const [visible, setVisible] = useState(false);

  const isAllSelected = formik.values.saudi_regions.length === regions.length;

  const checkError = (name) =>
    formik.errors[name] && formik.touched[name] && formik.submitCount;

  return (
    <div className={styles.ideasForm}>
      <div className={styles.formGroup}>
        <p className={styles.optionsTitle}>
          <span className="requiredField">{t("ideas.comment")}</span>
        </p>
        <input
          className={classnames(`form-control`, styles.optionInpt, {
            invalidField: checkError("title")
          })}
          name="title"
          placeholder={t("ideas.comment")}
          onChange={formik.handleChange}
          value={formik.values.title}
          onFocus={setFieldTouched}
        />
        <div className="mt-2">{getError("title")}</div>
      </div>

      <div className={styles.formGroup}>
        <p className={styles.optionsTitle}>
          <span className="requiredField">{t("ideas.explain")}</span>
        </p>
        <textarea
          className={classnames(`form-control`, styles.optionInpt, {
            invalidField: checkError("description")
          })}
          name="description"
          placeholder={t("ideas.explain")}
          onChange={formik.handleChange}
          value={formik.values.description}
          onFocus={setFieldTouched}
          rows={5}
          maxLength={LENGTH400}
        ></textarea>
        <p
          className={classnames(styles.textCounter, {
            [styles.textCounterMax]:
            formik?.values?.description?.length >= LENGTH400
          })}
        >
          {formik?.values?.description?.length || 0}
          {" / "}
          {LENGTH400}
        </p>
        <div className="mt-2">{getError("description")}</div>
      </div>

      <div className={styles.formGroup}>
        <p className={styles.optionsTitle}>
          <span className="requiredField">{t("ideas.region")}</span>
        </p>
        <Select
          formik={formik}
          keepOpen
          name="saudi_regions"
          multiple={true}
          data={regions}
          placeholder={t("ideas.region")}
          value={formik.values.saudi_regions}
          className={classnames({
            invalidField: checkError("saudi_regions")
          })}
          isAllSelected={isAllSelected}
          selectedHandler={(val) => setVisible(val)}
        />
        {isAllSelected && visible && (
          <span className={styles.emptyText}>{t("ideas.allSelected")}</span>
        )}
        <div className="mt-2">{getError("saudi_regions")}</div>
      </div>

      <div className={styles.formGroup}>
        <p className={styles.optionsTitle}>{t("ideas.file")}</p>
        <FileInput name="file" formik={formik} accept=".pdf" />
      </div>
    </div>
  );
};

IdeasForm.propTypes = {
  formik: PropTypes.object.isRequired,
  getError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default IdeasForm;
