import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableComponent,
  Loader,
  AdminTopPanel,
  AlertBox,
  Pagination
} from "components";
import { useParams } from 'react-router-dom';
import { getSupportingElementsRequests } from "store/actions/adminElements.actions";
import moment from "moment"
import { DATE_TIME_FORMAT } from "constants/forms"
import { ADMIN_VIEW_ELEMENTS } from "constants/paths";
import { SUPPORTING_ELEMENTS_TYPE } from "constants/forms"
import { compile } from "path-to-regexp";

const filteredDropdownItems = (dropdownItems) => {
  return dropdownItems
    .map(item => ({
      ...item,
      type: item.id
    }))
    .filter(({ is_main }) => !is_main)
}

const SupportingElementsRequests = () => {
  const { id } = useParams();
  const { loaded, error, supportingElementsRequests, adminMainElements } = useSelector(({ adminElements }) => adminElements)
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch()
  const [typeSelected, setTypeSelected] = useState({});

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "User Name",
      key: "full_name"
    },
    {
      name: "User Phone Number",
      key: "mobile_number"
    },
    {
      name: "Date & Time",
      key: "created_at"
    },
    {
      name: "Element Name | Ar",
      key: "name_ar",
    },
    {
      name: "Element Name | En",
      key: "name_en",
    },
    {
      name: "Status",
      key: "boolean",
      values: ['Incomplete', 'Complete'],
      field: "status"
    },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: (({ slug, id }) => {
        window.open(compile(ADMIN_VIEW_ELEMENTS)({ element_type: SUPPORTING_ELEMENTS_TYPE, type: slug, id }), '_blank')
      })
    },
  ];

  const tableBody = supportingElementsRequests?.data?.map(el => ({
    id: el?.id,
    full_name: el?.user?.full_name || el?.user?.company_name || "",
    mobile_number: el?.user?.mobile_number || "",
    created_at: moment(el?.created_at).format(DATE_TIME_FORMAT) || "",
    name_ar: el?.element_type?.name_ar || "",
    name_en: el?.element_type?.name_en || "",
    slug: el?.element_type?.slug
  }));

  useEffect(() => {
    dispatch(getSupportingElementsRequests.request({ id }))
  },[dispatch, id])

  const onDropdownChange = (element) => {
    setTypeSelected(element)
    dispatch(getSupportingElementsRequests.request({ id, elementId: element.id }))
  }

  const dropdownTools = {
    label: "Type",
    dropdownElements: filteredDropdownItems(adminMainElements),
    optionSelected: typeSelected,
    setOptionSelected: onDropdownChange
  }

  return (
    <Loader loaded={loaded}>
      {
      isAlertActive &&
      <AlertBox
        error={error}
        additionalStyles='alert-danger mt-2'
      />
      }
      <AdminTopPanel
        withDropdown
        dropdownTools={dropdownTools}
      />
      <div className="card">
        <div className="card-body">
          <TableComponent
            tableHead={tableHead}
            tableBody={tableBody}
          />
          <Pagination
            action={getSupportingElementsRequests}
            currentPage={supportingElementsRequests?.current_page}
            lastPage={supportingElementsRequests?.last_page}
            id={id}
          />
        </div>
      </div>
    </Loader>
  )
}

export default SupportingElementsRequests