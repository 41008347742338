export const phoneNumberRegExp = /\+?9665\d{8}$/g;
export const phoneNumberWithoutCountryCode = /\+?5\d{8}$/g;
export const digits = /^\d+$/;
export const ArEnDigits = /^\d|[٠-٩]+$/;
export const arabicNumbers = /[٠-٩]/;
export const excludeArChars = /[\u0600-\u06FF]/;
export const excludeEngChars = /[a-z]/i;
export const arabicChars = /^[\u0600-\u06FF \s]*$/gi;
export const englishChars = /^[a-z \s]*$/gi;
export const emailChars = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-.]+\.[a-zA-Z0-9]+$/;
export const linkExp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const dimensionExp = /\((\d*(\.?\d)+(?:,\s*\d*(\.?\d+)){2})\)/;
export const englishOrArabic = /[a-zA-Z\u0600-\u06FF]/;
export const passwordExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&_-])[A-Za-z\d.@$!%*?&_-]{8,}$/;
export const sizeToMB = size => Number(size / 1024 / 1024);
export const arabicToEnglishNums = s =>
  s.replaceAll(/[٠-٩]/g, d => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
