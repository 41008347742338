import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "./styles.module.scss";
import BlueChevron from "assets/icons/blue-chevron-down.svg";
import WhiteChevron from "assets/icons/white-chevron-down.svg";
import Twitter from "assets/icons/twitter.svg";
import Map from "assets/icons/map-pin.svg";
import Layout from "assets/icons/layout.svg";
import Instagram from "assets/icons/instagram.svg";
import { useFieldTouched } from "utils/forms";
import PropTypes from "prop-types";
import { filterSocialMedia, isFieldWithError } from "utils/helpers";
import { OTHER_SOCIAL_MEDIA } from "components/Accordion/contants"
import { setSocialMediaOpen } from 'store/actions/accordion.actions';
import { useDispatch, useSelector } from "react-redux";
const fields = [
  { label: "twitter", icon: Twitter, required: false },
  { label: "instagram", icon: Instagram, required: false },
  { label: "google_maps", icon: Map, required: false },
  { label: "website", icon: Layout, required: false }
];

const TALENTS = "talents"

const SocialMedia = ({ formik, title, type }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {socialMediaOpen} = useSelector(state => state.accordion);

  const toggle = () => dispatch(setSocialMediaOpen(!socialMediaOpen));

  const setFieldTouched = useFieldTouched(formik);

  const errorMessage = name => (
    <p className="mt-2 text-danger form-field-error">
      {formik.errors[name] && t(`messages.${formik.errors[name]}`)}
    </p>
  );

  const filterCondition = type !== TALENTS;
  const filteredFields = filterSocialMedia(filterCondition, fields, OTHER_SOCIAL_MEDIA);

  return (
    <div
      className={classnames(styles.infoBox, { [styles.infoBoxOpen]: socialMediaOpen })}
    >
      <div className={styles.infoHeader} onClick={toggle}>
        <p>{t(`${title}.social_media`)}</p>
        <img src={socialMediaOpen ? WhiteChevron : BlueChevron} alt="" />
      </div>

      <p className={styles.infoSubTitle}>{t(`${title}.provide`)}</p>

      {filteredFields.map(({ label, icon, required = true }, index) => (
        <div key={index} className={styles.infoContent}>
          <div className={styles.optionsList}>
            <p className={styles.optionsTitle}>
              <span className={classnames({'requiredField': required})}>
                {icon && <img src={icon} alt="" />}

                {t(`${title}.${label}`)}
              </span>
            </p>
            <div>
              <input
                className={
                  classnames(
                    `form-control`,
                    styles.optionInpt,
                    {'invalidField': isFieldWithError(formik, label)}
                  )
                }
                name={label}
                placeholder={t(`${title}.${label}_placeholder`)}
                onChange={formik.handleChange}
                value={formik.values[label] || ""}
                onFocus={setFieldTouched}
              />
              {errorMessage(label)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

SocialMedia.propTypes = {
  formik: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default React.memo(SocialMedia);
