import React, { useState } from "react";
import classnames from "classnames";
import Chevron from "assets/icons/white-chevron-down.svg";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const QuestionItem = ({ question, answer }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClick = () => setOpen(!open);

    const links = {
        events_center: (
            <a
                href={t("license.links.events_center.href")}
                target="_blank"
                rel="noopener noreferrer"
            >
                {` ${t("license.links.events_center.title")} `}
            </a>
        ),
    };

    return (
        <div
            onClick={handleClick}
            className={classnames(styles.questionItem, {
                [styles.questionItemOpen]: open,
            })}
        >
            <p className={styles.question}>
                <span>{question}</span>
                <img src={Chevron} alt="" />
            </p>
            {answer instanceof Array ? (
                <p className={styles.answer}>
                    {answer.map((el, idx) =>
                        el.list ? (
                            el.list.map((el, idx) => (
                                <p key={idx} className={styles.answer}>
                                    {el.text}
                                </p>
                            ))
                        ) : (
                            <span key={idx} className={styles.answer}>
                                {el.text || links[el.link]}
                            </span>
                        )
                    )}
                </p>
            ) : (
                <p className={styles.answer}>{answer}</p>
            )}
        </div>
    );
};

QuestionItem.propTypes = {
    // answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
};

export default React.memo(QuestionItem);
