import React, { useContext } from "react";
import styles from "./styles.module.scss";
import moment from "moment";
import { Context } from "context";


const DATE_FORMAT = "ll";
const DIRECTOR_TYPE = 3; 
const statuses = ["Coming Soon", "Open", "Closed", "Cancelled"];

const AdminCardItem = ({ img, name, region, startDate, endDate, status, redirectTo }) => {
  const getDate = date => moment.utc(date).format(DATE_FORMAT);
  const { user } = useContext(Context)
  
  return (
    <div className={styles.card_innerContainer}>
      <section className={styles.card_top}>
        <i className={user?.user_type === DIRECTOR_TYPE ? "fas fa-eye" : "fas fa-pen"} onClick={redirectTo}/>
      </section>
      <section className={styles.card_image}>
        <img src={img} alt={name} />
      </section>
      <section className="d-flex flex-column">
        <p>
          {<span className="badge badge-success ">{statuses[status]}</span>}
        </p>
        <p className="font-weight-bold mb-1">{name}</p>
        <p className="font-weight-bold ">{region}</p>
        {!!startDate && (<p className="font-weight-light mb-0">
          Start Date: {getDate(startDate)}
        </p>)}
        {!!endDate && <p className="font-weight-light mb-0">End Date: {getDate(endDate)}</p>}
      </section>
    </div>
  );
};

export default AdminCardItem;
