import * as saudiSeasonsEvents from 'store/actions/saudiSeasonsEvents.actions';


const element = JSON.parse(localStorage.getItem("element"));

const initialState = {
  saudiSeasonsEvents: {
    saudiSeasons: [],
    saudiEvents: []
  },
  saudiSeasonEvent: {},
  regions: [],
  loaded: true,
  storeFormats: [],
  restaurantFormats: [],
  saudiSeasonEventElement: {},
  element: element || {},
  requestInterests: {}
};

export default function (state = initialState, action) {
  switch(action.type){
    case saudiSeasonsEvents.GET_SAUDI_SEASONS_EVENTS.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASONS_EVENTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        saudiSeasonsEvents: action.payload
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASONS_EVENTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };
      case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT_ELEMENT.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT_ELEMENT.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        saudiSeasonEventElement: action.payload
      };

    case saudiSeasonsEvents.GET_SAUDI_SEASON_EVENT_ELEMENT.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case saudiSeasonsEvents.MANAGE_ELEMENT_SET:
      return {
        ...state,
        element: action.payload
      }

    case saudiSeasonsEvents.MANAGE_ELEMENT_REMOVE:
      return {
        ...state,
        element: null
      }

      case saudiSeasonsEvents.GET_REQUEST_INTERESTS.REQUEST:
        return {
          ...state,
          loaded: false
        };

      case saudiSeasonsEvents.GET_REQUEST_INTERESTS.SUCCESS:
        return {
          ...state,
          loaded: true,
          error: "",
          requestInterests: action.payload
        };

      case saudiSeasonsEvents.GET_REQUEST_INTERESTS.FAILURE:
        return {
          ...state,
          loaded: true,
          error: action.payload
        };
      case saudiSeasonsEvents.GET_FILE.REQUEST:
        return {
          ...state,
          loaded: false
        }
      case saudiSeasonsEvents.GET_FILE.SUCCESS:
        return {
          ...state,
          loaded: true
      }
      case saudiSeasonsEvents.GET_FILE.FAILURE:
        return {
          ...state,
          loaded: true,
          error:action.payload
        }

    default:
      return state;
  }
}