import React from "react";
import { AdminLoginForm } from "components";
import Layout from "layouts/AdminLogin";

const AdminLogin = () => (
  <Layout>
    <AdminLoginForm />
  </Layout>
);

export default AdminLogin;
