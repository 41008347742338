import * as Yup from "yup";
import {
  phoneNumberWithoutCountryCode,
  arabicChars,
  englishChars,
  linkExp,
  dimensionExp,
  englishOrArabic,
  digits,
  excludeArChars,
  excludeEngChars,
} from "utils/validation";
import { MIN_LENGTH, MAX_NUMBER_LENGTH, ID_LENGTH } from "./contants";
import { sizeToMB } from "utils/validation";
import { PDF, MAX_FILE_SIZE } from "constants/forms";

const MAX_CONTACT_LENGTH = 12;
const VALID_PHONE_LENGTH = 12;
const googleMapsPage = [
  "https://www.google.com/maps/",
  "https://g.page/",
  "https://goo.gl/maps/",
];

let identity_type = null;
const numberValidation = Yup.number()
  .test(
    "len",
    "numbers9",
    (val) => String(val || "")?.split("").length <= MAX_NUMBER_LENGTH
  )
  .typeError("invalidCode");

const phoneValidation = Yup.string()
  .matches(phoneNumberWithoutCountryCode, "invalidPhone")
  .max(VALID_PHONE_LENGTH, "invalidPhone")
  .min(VALID_PHONE_LENGTH, "invalidPhone");

const fileValidation = Yup.mixed()
  .test("size", "fileSize", (val) =>
    val?.size ? sizeToMB(val.size) <= MAX_FILE_SIZE : true
  )
  .test("extension", "", (val) =>
    val?.name ? val?.name?.split(".")?.reverse()?.[0] === PDF : true
  );

const contactListValidation = Yup.array().of(
  Yup.object().shape({
    company_name: Yup.string()
      .matches(englishOrArabic, "arabicOrEnglish")
      .nullable(),
    contact_number: Yup.string()
      .matches(digits, "invalidCode")
      .test(
        "len",
        "numbers12",
        (val) => String(val || "")?.split("").length <= MAX_CONTACT_LENGTH
      )
      .nullable(),
  })
);
const social_media = {
  google_maps: Yup.string()
    .matches(linkExp, "link")
    .max(255, "maxLength")
    .test("validLink", "googleMapsMessage", (val) =>
      val
        ? googleMapsPage.map((el) => val.startsWith(el)).some((el) => el)
        : true
    ),
  instagram: Yup.string()
    .matches(linkExp, "link")
    .max(255, "maxLength")
    .test("validLink", "instagramMessage", (val) =>
      val
        ? val?.includes("https://www.instagram.com/") ||
          val?.includes("https://instagram.com/")
        : true
    ),
  twitter: Yup.string()
    .matches(linkExp, "link")
    .max(255, "maxLength")
    .test("validLink", "twitterMessage", (val) =>
      val
        ? val?.includes("https://twitter.com/") ||
          val?.includes("https://www.twitter.com/")
        : true
    ),
  website: Yup.string().matches(linkExp, "link").max(255, "maxLength"),
  mobile_number: phoneValidation,
  name: Yup.string(),
  phone_number: phoneValidation.nullable(),
  email: Yup.string().email("invalidEmail"),
};

const fullNameValidation = (chars, name) => {
  return Yup.string()
    .matches(chars, name)
    .test("len", "enterFullName", (val) =>
      val ? val?.split(" ").filter((i) => !!i).length >= MIN_LENGTH : true
    )
    .nullable();
};
const excludeLetters = (lan) => {
  const error = lan === "en" ? "name_en" : "name_ar";
  const excludeLeng = lan === "en" ? excludeArChars : excludeEngChars;
  return Yup.string().test("letters", error, (val) =>
    val
      ? val
          .trim()
          .split("")
          .some((el) => excludeLeng.test(el))
        ? false
        : true
      : true
  );
};

export const validations = {
  talents: {
    personal_info: Yup.array().of(
      Yup.object().shape({
        region_id:Yup.string().nullable(),
        country_of_residence_id: Yup.string().nullable(),
        full_name_ar: fullNameValidation(arabicChars, "name_ar"),
        full_name_en: fullNameValidation(englishChars, "name_en"),
        gender: Yup.number().nullable(),
        social_status: Yup.number().nullable(),
        birthdate: Yup.string().nullable(),
        nationality_id: Yup.string().nullable(),
        special_needs: Yup.number().nullable(),
        additional_support: Yup.number().nullable(),
      })
    ),
    submission_type: Yup.string(),
    talent_category_id: Yup.string(),
    video_link: Yup.string().matches(linkExp, "link").max(255, "maxLength"),
    ...social_media,
  },
  "temporary-works": {
    temporary_works_category_id: Yup.string(),
    birthdate: Yup.string(),
    gender: Yup.number(),
    id_number: Yup.string()
      .test("len", "numbers10", (val) =>
        val ? String(val || "")?.split("").length === ID_LENGTH : true
      )
      .typeError("invalidCode")
      .test("isNationalId", "invalidIdNumber1", (value) => {
        const firstLetter = value ? String(value)[0] : null;
        if (!value) return true;
        return +identity_type === 1 ? firstLetter === "1" : true;
      })
      .test("isIqama", "invalidIdNumber2", (value) => {
        const firstLetter = value ? String(value)[0] : null;
        if (!value) return true;
        return +identity_type === 2
          ? firstLetter === "2" || firstLetter === "3"
          : true;
      }),
    identity_type: Yup.number().test("", "", (val) => {
      identity_type = val;
      return true;
    }),
    languages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().nullable(),
        level: Yup.string().nullable(),
      })
    ),
    name_ar: fullNameValidation(arabicChars, "name_ar"),
    name_en: fullNameValidation(englishChars, "name_en"),
    nationality_id: Yup.string(),
    occupation: Yup.string(),
    previous_experiences: Yup.string().matches(
      englishOrArabic,
      "arabicOrEnglish"
    ),
    saudi_region_id: Yup.string(),
    skills: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    social_status: Yup.number(),
    special_needs: Yup.number(),
    work_field: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    worked_before: Yup.string(),
    disability_other_category: Yup.string().max(200, "maxLength"),
    disability_type: Yup.array().when("special_needs", {
      is: (special_needs) => special_needs !== 0,
      then: Yup.array().min(1, "required"),
    }),
    additional_support: Yup.string().max(200, "maxLength"),
    ...social_media,
  },
  events: {
    name_ar: excludeLetters("ar"),
    name_en: excludeLetters("en"),
    country_id: Yup.string(),
    description: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    duration: numberValidation.min(1, "minLength1"),
    event_category_id: Yup.string(),
    located_saudi_arabia: Yup.boolean(),
    previous_business_references_contact_list: contactListValidation,
    previous_notable_achievements: Yup.string().matches(
      englishOrArabic,
      "arabicOrEnglish"
    ),
    profile: fileValidation,
    proposal: fileValidation,
    financial_offer: fileValidation,
    recommended_region_id: Yup.string(),
    saudi_region_id: Yup.string(),
    target_audience: Yup.array().of(Yup.string()).nullable(),
    proposal_shared_link: Yup.string()
      .matches(linkExp, "link")
      .max(255, "maxLength")
      .nullable(),
    financial_offer_link: Yup.string()
      .matches(linkExp, "link")
      .max(255, "maxLength")
      .nullable(),
    ...social_media,
  },
  logistics: {
    service_category_id: Yup.string(),
    explanation: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    name_ar: excludeLetters("ar"),
    name_en: excludeLetters("en"),
    located_saudi_arabia: Yup.boolean(),
    saudi_region_id: Yup.string(),
    country_id: Yup.string(),
    profile: fileValidation,
    previous_notable_achievements: Yup.string(),
    previous_business_references_contact_list: contactListValidation,
    profile_shared_link: Yup.string()
      .matches(linkExp, "link")
      .max(255, "maxLength")
      .nullable(),
    ...social_media,
  },
  stores: {
    store_format_id: Yup.string(),
    product_category_id: Yup.string(),
    saudi_region_id: Yup.string(),
    name_ar: excludeLetters("ar"),
    name_en: excludeLetters("en"),
    min_price: numberValidation,
    max_price: numberValidation.when("min_price", {
      is: (val) => (val ? true : false),
      then: Yup.number().moreThan(Yup.ref("min_price"), "priceCompare"),
    }),
    participated_in_previous_events: Yup.bool(),
    event_activities: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    other_branches: Yup.bool(),
    saudi_branches: Yup.bool(),
    saudi_branches_count: Yup.string().test("morethan1", "moreThan1", (val) =>
      val ? val > 1 : true
    ),
    branches_regions: Yup.string(),
    trade_mark: fileValidation,
    profile: fileValidation,
    establishment_date: Yup.string(),
    popup_store_size: Yup.string()
      .matches(dimensionExp, "sizeTreeDimensions")
      .typeError("sizeTreeDimensions"),
    profile_shared_link: Yup.string()
      .matches(linkExp, "link")
      .max(255, "maxLength")
      .nullable(),
    ...social_media,
  },
  restaurants: {
    restaurant_format_id: Yup.string(),
    food_category_id: Yup.string(),
    best_selling_item: Yup.string(),
    name_ar: excludeLetters("ar"),
    name_en: excludeLetters("en"),
    region_id: Yup.string(),
    average_bill_per_person: numberValidation,
    is_participated_in_prev_events: Yup.bool(),
    activities_during_event: Yup.string().matches(
      englishOrArabic,
      "arabicOrEnglish"
    ),
    food_menu: fileValidation,
    is_other_branches: Yup.bool(),
    num_branches_in_saudi: Yup.string().test("morethan1", "moreThan1", (val) =>
      val ? val > 1 : true
    ),
    branches_region_id: Yup.string(),
    trade_mark: fileValidation,
    profile: fileValidation,
    picture: fileValidation,
    num_of_worker_per_branch: numberValidation,
    foodtruck_size: Yup.string()
      .matches(dimensionExp, "sizeTreeDimensions")
      .typeError("sizeTreeDimensions"),
    establishment_date: Yup.string(),
    average_workers_per_branch: numberValidation,
    profile_shared_link: Yup.string()
      .matches(linkExp, "link")
      .max(255, "maxLength")
      .nullable(),
    ...social_media,
  },
  venues: {
    venue_area: numberValidation.min(1, "minLength1"),
    layout_plan_two_dimensions: fileValidation,
    layout_plan_three_dimensions: fileValidation,
    aerial_photography: fileValidation,
    topography_id: Yup.string(),
    land_type_id: Yup.string(),
    parking_spaces: Yup.bool(),
    count_parking_spaces: numberValidation.min(1, "minLength1"),
    roof_load_capacity: numberValidation.min(1, "minLength1"),
    ceiling_hanging_points: fileValidation,
    max_ground_carrying_weight: numberValidation.min(1, "minLength1"),
    electric_current_info: fileValidation,
    services: Yup.string(),
    seating_plan: fileValidation,
    site_category_id: Yup.string(),
    saudi_region_id: Yup.string(),
    city_id: Yup.string(),
    venue_location: Yup.string()
      .matches(linkExp, "link")
      .test("validLink", "googleMapsMessage", (val) =>
        val
          ? googleMapsPage.map((el) => val.startsWith(el)).some((el) => el)
          : true
      ),
    district: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    street_name: Yup.string().matches(englishOrArabic, "arabicOrEnglish"),
    zip_code: numberValidation,
    building_number: numberValidation,
    additional_number: numberValidation,
    site_photos: Yup.array()
      .test("len", "fileSize", (val) =>
        Array.isArray(val)
          ? val.every((item) =>
              item?.size ? sizeToMB(item.size) <= MAX_FILE_SIZE : true
            )
          : true
      )
      .max(3, "maxLength3"),
    mobile_number: phoneValidation,
    name: Yup.string(),
    phone_number: phoneValidation.nullable(),
    email: Yup.string().email("invalidEmail"),
  },
};
