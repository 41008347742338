import { call, put, takeLatest } from "redux-saga/effects";
import { NotificationsApi } from "services/notifications.service";
import {
  GET_USER_NOTIFICATIONS,
  getUserNotifications,
} from "store/actions/notifications.actions";

function* getUserNotificationsWorker() {
  try {
    const { data } = yield call(NotificationsApi.getUserNotifications);
    yield put(getUserNotifications.success(data));
  } catch (e) {
    yield put(getUserNotifications.failure(e.data));
  }
}

function* notificationsSaga() {
  yield takeLatest(GET_USER_NOTIFICATIONS.REQUEST, getUserNotificationsWorker);
}

export default notificationsSaga;
