import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProfileLayout from "layouts/Profile";
import { SeasonEventItem, Loader } from "components";
import styles from "./styles.module.scss";
import Img from "assets/images/emptyList.svg";
import { getSaudiSeasonsEvents } from "store/actions/saudiSeasonsEvents.actions";
import cn from "classnames";

const Seasons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const {
    saudiSeasonsEvents: { saudiSeasons, saudiEvents },
    loaded
  } = useSelector(({ saudiSeasonsEvents }) => saudiSeasonsEvents);

  const getSeasons = () => (
    <div className={styles.collectionList}>
      {saudiSeasons?.map((season) => (
        <SeasonEventItem key={season.id} {...season} type="season" />
      ))}
    </div>
  );

  const getEvents = () => (
    <div className={styles.collectionList}>
      {saudiEvents?.map((event) => (
        <SeasonEventItem key={event.id} {...event} type="event" />
      ))}
    </div>
  );

  const emptyList = () => (
    <div className={styles.collectionEmptyList}>
      <img src={Img} alt="" />
    </div>
  );

  useEffect(() => {
    dispatch(getSaudiSeasonsEvents.request());
  }, [dispatch]);

  const saudiSeasonsNum = saudiSeasons.filter(
    ({ status }) => status === 1
  ).length;
  const saudiEventsNum = saudiEvents.filter(
    ({ status }) => status === 1
  ).length;

  return (
    <ProfileLayout>
      <Loader loaded={loaded}>
        <div className={styles.collection}>
          <div className={styles.tabContainer}>
            <div
              className={cn(styles.seasons, tab === 0 && styles.active)}
              onClick={() => {
                setTab(0);
              }}
            >
              {t("seasons_events.seasons")} ({saudiSeasonsNum})
            </div>
            <div
              className={cn(styles.events, tab === 1 && styles.active)}
              onClick={() => {
                setTab(1);
              }}
            >
              {t("seasons_events.events")} ({saudiEventsNum})
            </div>
          </div>
          {tab === 0 ? (
            <section>
              <div>{saudiSeasons?.length ? getSeasons() : emptyList()}</div>
            </section>
          ) : (
            <section>
              <div>{saudiEvents?.length ? getEvents() : emptyList()}</div>
            </section>
          )}
        </div>
      </Loader>
    </ProfileLayout>
  );
};

export default Seasons;
