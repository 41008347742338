import React,{ useContext, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "context";
import PublicLayout from "layouts/Public";
import { getMediaCenterPhotos } from "store/actions/mediaCenter.actions";
import { PhotoVideoCard } from 'components'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { Mousewheel } from 'swiper';
import { compile } from "path-to-regexp";
import {PHOTOS_DETAILS} from "constants/paths";

import styles from './styles.module.scss'

const PhotosLibrary = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { rtl } = useContext(Context);
    const dispatch = useDispatch();
    const { mediaCenterPhotos,loaded } = useSelector(({ mediaCenter }) => mediaCenter);
    const type = 1;
    useEffect(() => {
        dispatch(getMediaCenterPhotos.request(type));
    }, [dispatch]);
    
    const openNewsPage = (id) => {
        history.push(compile(PHOTOS_DETAILS)({id}));
    };

    return (
        <PublicLayout title={t("mediaCenter.photoLibrary")}>
            <div className='container'>
                <div className={styles.photosWrapper}>
                <Swiper
                    modules={[Mousewheel]}
                    spaceBetween={150}
                    slidesPerView='auto'
                    breakpoints={{
                        576: {
                            slidesPerView: '1.5',
                        },
                        768: {
                            slidesPerView: 1.5,
                            spaceBetween: 80
                        },
                        900:{
                            slidesPerView: '1.5',
                            spaceBetween: 100
                        },
                        1200:{
                            slidesPerView: '1.5',
                            spaceBetween: 100
                        },
                        1400:{
                            slidesPerView: 'auto',
                            spaceBetween: 130
                        },
                    }}
                    centeredSlides
                    roundLengths
                    mousewheel
                    loop
                    loopedSlides={30}
                    loopAdditionalSlides={30}
                    dir={rtl ? 'rtl' : 'ltr'}
                >
                    {mediaCenterPhotos?.data?.map((photo, index) => (
                        <div key={photo.id}>
                            <SwiperSlide
                                key={photo.id}
                                virtualIndex={index}
                                className={styles.swiperSlide}
                            >
                                <div className={styles.imageWrapper} onClick={()=>openNewsPage(photo.id)}>
                                    <img src={photo.main_image} alt='test' className={styles.image}/>
                                </div>   
                            </SwiperSlide>
                        </div>
                    ))}
                </Swiper>
                </div>
            </div>
            <div className={styles.cardsSection}>
                    {loaded && mediaCenterPhotos?.data?.map((image) => (
                        <PhotoVideoCard
                        key={image.id}
                        cardImage={image.main_image}
                        item={image}
                        openDetails={openNewsPage}
                        />
                    ))}
            </div>
        </PublicLayout>
    )
}
export default PhotosLibrary;