import React, { useEffect, useState, useContext, useRef } from "react";
import ProfileLayout from "layouts/Profile";
import { FocusError } from "focus-formik-error";
import styles from "./styles.module.scss";
import {
  Accordion,
  Loader,
  DeleteModal,
  Checkbox,
  PageTitle,
  Button,
  SaveModal,
  LicenseModal,
  AlertBox,
} from "components";
import HistoryTable from "../../components/HistoryTable";
import { useParams } from "react-router-dom";
import {
  getInterest,
  editInterest,
  getCities,
  deleteInterest,
} from "store/actions/elements.actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  data,
  PHONE_INPUT,
  FILE,
  TYPES,
  ARRAY,
  DROPDOWN,
  SUBTITLES,
} from "components/Accordion/contants";
import { validations } from "components/Accordion/validations";
import { useTranslation } from "react-i18next";
import { serialize } from "object-to-formdata";
import { Context } from "context";
import { SUBMIT, DELETE } from "constants/forms";
import isEqual from "lodash.isequal";
import BeforeUnloadComponent from "react-beforeunload-component";
import {
  setCurrentTab,
  setCurrentGroupMember,
  setSocialMediaOpen,
} from "store/actions/accordion.actions";
import { autoCloseAccordioTabs } from "utils/helpers";
import { checkedValues } from "./constants";
import { getUserRequests } from "store/actions/requests.actions";
import {
  TEMPORARY_WORKS,
  ADDITIONAL_NUMBER,
  EMAIL,
  INDIVIDUAL,
} from "./constants";

const EditInterest = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useContext(Context);
  const { type, id } = useParams();
  const { loaded, error, interest, ...state } = useSelector(
    (state) => state.elements
  );
  const isAlertActive = useSelector(({ alert }) => alert);
  const { detailsSelected } = useSelector(({ accordion }) => accordion);
  const { userRequests } = useSelector((state) => state.requests);
  const [initialData, setInitialData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validations[type]),
  });

  const toggleModal = () => setOpenModal(!openModal);
  const toggleLicenseModal = () => setOpenLicenseModal(!openLicenseModal);

  const myRef = useRef(null);

  useEffect(() => {
    if (userRequests.length) {
      executeScroll();
      let newData = { ...detailsSelected };
      Object.keys(newData).forEach((data) => (newData[data] = false));
      dispatch(setCurrentTab(newData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRequests]);

  const executeScroll = () => myRef?.current?.scrollIntoView();

  const submitForm = (e) => {
    e.preventDefault();
    const { values, errors } = formik;

    const obj = Object.fromEntries(
      data[type]
        .filter(({ accessType }) =>
          accessType ? accessType?.includes(user?.user_type) : true
        )
        .map(({ name, options }) => [
          name,
          Object.fromEntries(
            options
              .filter(
                ({ type, label }) =>
                  !(
                    type === FILE &&
                    (typeof values[label] === "string" ||
                      typeof values[label]?.[0] === "string")
                  )
              )
              .map(({ type, label, items: [item] = [], output, multiple }) => {
                switch (type) {
                  case DROPDOWN:
                    if (multiple) {
                      return [
                        label,
                        values[label]?.map((item) => item.id || item) || [],
                      ];
                    } else return [label, values[label] || null];
                  default:
                    if (typeof item?.value === "number") {
                      return [
                        label,
                        values[label] === null || values[label] === undefined
                          ? null
                          : +values[label],
                      ];
                    }
                    if (output === ARRAY) {
                      return [label, [values[label]].flat()];
                    } else {
                      return [label, values[label] ?? null];
                    }
                }
              })
          ),
        ])
    );

    obj._method = "PUT";
    if (values.agreement) {
      obj.agreement = +values.agreement;
    }

    for (let key in obj.contact_social_media) {
      if (key === "undefined") {
        delete obj.contact_social_media[key];
      }
    }

    //dont send file when it not changed
    if (type === "events") {
      for (let key of Object.keys(values)) {
        if (values[key] === initialData[key]) {
          delete obj?.event_detail[`${key}`];
        }
      }
    }
    if (values?.profile === initialData?.profile) {
      for (let val of checkedValues) {
        obj[val] && delete obj[val].profile;
      }
    }

    if (values?.proposal === initialData?.proposal) {
      delete obj?.event_detail?.proposal;
    }
    if (obj?.event_detail?.event_name) {
      obj.event_detail.name = obj.event_detail.event_name;
      delete obj.event_detail.event_name;
    }
    if (
      (!values.languages?.length && type === TEMPORARY_WORKS) ||
      values?.languages?.every(({ language, level }) => !language && !level)
    ) {
      obj.skill_experience.languages = [];
    }

    //tmp solution, waiting for be answer
    if (type === "talents") {
      obj.personal_info = values.personal_info.map((pI) => {
        if (pI.country_of_residence_id instanceof Array) {
          pI.country_of_residence_id = null;
        }
        if (pI.nationality_id instanceof Array) {
          pI.nationality_id = null;
        }
        return pI;
      });
    }
    checkTabWithEror();
    formik.handleSubmit(e);
    !Object.keys(errors).length &&
      dispatch(
        editInterest.request({
          id,
          type,
          data:
            type === TYPES.sites ||
            type === TYPES.events ||
            type === TYPES.stores ||
            type === TYPES.restaurants ||
            type === TYPES.logistics ||
            type === TYPES.venues
              ? serialize(obj, { indices: true })
              : obj,
        })
      );
  };

  const confirmDelete = () => {
    toggleModal();
    dispatch(
      deleteInterest.request({
        id,
        type: TYPES[interest.element_type?.slug],
      })
    );
  };

  useEffect(() => {
    dispatch(getUserRequests.request({ participating_element_id: id }));
    dispatch(getInterest.request({ id, type }));
  }, [dispatch, type, id]);

  useEffect(() => {
    if (formik.values.saudi_region_id?.length) {
      dispatch(getCities.request({ region_id: formik.values.saudi_region_id }));
    }
  }, [dispatch, formik.values.saudi_region_id]);

  useEffect(() => {
    if (formik.values.region_id?.length) {
      dispatch(getCities.request({ region_id: formik.values.region_id }));
    }
  }, [dispatch, formik.values.region_id]);

  // Sets initial values for interest
  useEffect(() => {
    const obj = {};
    obj.agreement = interest?.agreement;

    for (let key in obj) {
      if (key === undefined) {
        delete obj[key];
      }
    }
    data[type]
      .filter(({ accessType }) =>
        accessType ? accessType?.includes(user?.user_type) : true
      )
      .map(({ options, name }) =>
        options.map(({ type, label, multiple, data, inObject }) => {
          if (type === PHONE_INPUT) {
            return (obj[label] =
              interest[name]?.[label] ||
              (label === ADDITIONAL_NUMBER ? null : user?.mobile_number));
          } else if (label === "name") {
            return (obj[label] =
              interest[name]?.[label] || user?.full_name || user?.company_name);
          } else if (label === EMAIL) {
            return (obj[label] = interest[name]?.[label] || user?.email);
          } else if (type === DROPDOWN && multiple) {
            const incomingData = interest[name]?.[label]?.map(
              (item) => item.id || item
            );

            const values = state[data]?.filter((item) =>
              incomingData?.includes(item.id)
            );
            return (obj[label] = [...(values || [])]);
          } else {
            return (obj[label] = interest[name]?.[label] ?? undefined);
          }
        })
      );
    if (interest.event_detail) {
      obj.event_name = interest?.event_detail?.name;
    }
    setInitialData(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, interest, user]);

  useEffect(() => {
    +formik.values.submission_type === INDIVIDUAL
      ? formik.setFieldValue(
          "personal_info",
          interest?.talent_detail?.personal_info?.slice(0, 1)
        )
      : formik.setFieldValue(
          "personal_info",
          interest?.talent_detail?.personal_info
        );
    // eslint-disable-next-line
  }, [formik.values.submission_type, interest]);

  const blockRoute = () => {
    if (
      isEqual(formik.initialValues, formik.values) &&
      !formik.isSubmitting &&
      interest.created_at === interest.updated_at
    ) {
      return true;
    } else if (
      !isEqual(formik.initialValues, formik.values) &&
      !formik.isSubmitting
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clickHere = (e) => {
    e.preventDefault();
    toggleLicenseModal();
  };

  const findFieldWithError = () => {
    const res = checkTabAccessType()
      .map(({ options }) => {
        return options.some((el) => {
          return el.label in formik.errors;
        });
      })
      .findIndex((el) => el === true);
    return res;
  };

  const checkGroupMember = () => {
    const index = formik.errors?.personal_info?.findIndex((el) => el);
    index !== -1 && dispatch(setCurrentGroupMember(index));
  };

  const checkTabWithEror = () => {
    const index = findFieldWithError();
    let accordionTabs = autoCloseAccordioTabs(detailsSelected, index);
    dispatch(setCurrentTab(accordionTabs));
    !Object.keys(formik.errors).length &&
      Object.values(accordionTabs).every((tab) => !tab) &&
      (accordionTabs[0] = true);
    checkGroupMember();
    Object.keys(formik.errors).length && dispatch(setSocialMediaOpen(true));
  };

  const checkTabAccessType = () => {
    return data[type].filter((el) =>
      el.accessType ? el.accessType.includes(user.user_type) : true
    );
  };

  return (
    <ProfileLayout>
      <BeforeUnloadComponent
        blockRoute={blockRoute()}
        modalComponentHandler={({ handleModalCancel }) => (
          <SaveModal
            save={(e) => {
              if (Object.values(formik.errors).length) handleModalCancel(e);
              submitForm(e);
            }}
            cancel={handleModalCancel}
          />
        )}
      >
        <Loader loaded={loaded}>
          {isAlertActive && (
            <AlertBox error={error} additionalStyles="alert-danger" />
          )}
          {
            <>
              <section className={styles.interestHeader}>
                <PageTitle title={interest?.element_type?.name || ""} />
                {SUBTITLES[type] && <p>{t(`interests.${SUBTITLES[type]}`)}</p>}
              </section>

              <form onSubmit={submitForm}>
                <FocusError formik={formik} />
                <section className={styles.interestBody}>
                  <Accordion formik={formik} />
                </section>

                {t(`license.${type}.title`) && (
                  <div>
                    <div className={styles.agreement}>
                      <span className="px-2 requiredField"></span>
                      <Checkbox formik={formik} name="agreement" value="1" />
                      <label htmlFor="agreement">
                        {t(`license.${type}.title`)}
                        {typeof t(`license.${type}.modal`, {
                          returnObjects: true,
                        }) === "object" && (
                          <span
                            role="button"
                            className="text-primary"
                            onClick={clickHere}
                          >
                            {t("license.click")}
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                )}

                <section className={styles.interestFooter}>
                  <Button
                    className={styles.save}
                    title={t("buttons.save&close")}
                    type="submit"
                    color={SUBMIT}
                  />
                  <Button
                    className={styles.save}
                    title={t("buttons.delete")}
                    onClick={toggleModal}
                    color={DELETE}
                  />
                </section>
              </form>

              {userRequests.length !== 0 && (
                <div ref={myRef}>
                  <section className={styles.interestHeader}>
                    <PageTitle title={t(`interests.historyElements`)} />
                  </section>
                  <section className={styles.interestBody}>
                    <HistoryTable tableBody={userRequests} />
                  </section>
                </div>
              )}
            </>
          }
        </Loader>
        <DeleteModal
          isOpened={openModal}
          toggle={toggleModal}
          data={interest}
          confirm={confirmDelete}
        />
        <LicenseModal
          isOpened={openLicenseModal}
          toggle={toggleLicenseModal}
          type={type}
        />
      </BeforeUnloadComponent>
    </ProfileLayout>
  );
};

export default EditInterest;
