import * as RequestsActions from "store/actions/requests.actions";

const initialState = {
  loaded: true,
  userRequests: [],
  userRequest: {},
  error: "",
  userElementTypes: [],
  requestInterest: {},
  applyError: false,
  current_page: 1,
  last_page: 1,
  userRequestPending: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case RequestsActions.GET_USER_REQUESTS.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case RequestsActions.GET_USER_REQUESTS.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case RequestsActions.GET_USER_REQUESTS.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case RequestsActions.GET_USER_REQUEST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case RequestsActions.GET_USER_REQUEST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        ...action.payload,
      };

    case RequestsActions.GET_USER_REQUEST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case RequestsActions.APPLY_USER_REQUEST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case RequestsActions.APPLY_USER_REQUEST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case RequestsActions.APPLY_USER_REQUEST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case RequestsActions.PORTAL_DOWNLOAD_FILE.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case RequestsActions.PORTAL_DOWNLOAD_FILE.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
      };

    case RequestsActions.PORTAL_DOWNLOAD_FILE.FAILURE:
      return {
        ...state,
        loaded: false,
        error: action.payload,
      };

    case RequestsActions.GET_REQUEST_INTEREST.REQUEST:
      return {
        ...state,
        loaded: false,
      };

    case RequestsActions.GET_REQUEST_INTEREST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        requestInterest: action.payload,
      };

    case RequestsActions.GET_REQUEST_INTEREST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case RequestsActions.IS_APPLIED:
      return {
        ...state,
        applyError: true,
      };

    case RequestsActions.CLEAR_REQ_DATA:
      return {
        ...state,
        applyError: false,
      };
    case RequestsActions.GET_USER_REQUESTS_PENDING.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case RequestsActions.GET_USER_REQUESTS_PENDING.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        userRequestPending: action.payload,
      };
    case RequestsActions.GET_USER_REQUESTS_PENDING.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    case RequestsActions.CANCEL_REQUEST.REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case RequestsActions.CANCEL_REQUEST.SUCCESS:
      return {
        ...state,
        loaded: true,
        error: "",
        userRequest: action.payload,
      };
    case RequestsActions.CANCEL_REQUEST.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
}
