import { call, put, takeLatest } from "redux-saga/effects";
import { IdeasApi } from "services/ideas.service";
import {
  GET_IDEAS,
  CREATE_IDEA,
  getIdeas,
  createIdea
} from "store/actions/ideas.actions";

function* createIdeaWorker({ payload }) {
  const { data, resetFn } = payload;
  try {
    yield call(IdeasApi.createIdea, data);
    yield put(createIdea.success());
    resetFn({})
  } catch (e) {
    yield put(createIdea.failure(e.data));
  }
}

function* getIdeasWorker() {
  try {
    const { data } = yield call(IdeasApi.getIdeas);
    yield put(getIdeas.success(data));
  } catch (e) {
    yield put(getIdeas.failure(e.data));
  }
}

function* ideasSaga() {
  yield takeLatest(CREATE_IDEA.REQUEST, createIdeaWorker);
  yield takeLatest(GET_IDEAS.REQUEST, getIdeasWorker);
}

export default ideasSaga;
