export const historyHeadings = [
    {
        key: "request_number",
        value: "requestNumber"
    },
    {
        key: "name",
        value: "type"
    },
    {
        key: "status",
        value: "requestStatus"
    },
    {
        key: "created_at",
        value: "requestDate"
    },
    {
        key: "request_details",
        value: "requestDetails",
    },
];
