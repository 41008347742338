import * as Paths from "constants/paths";

export const PRIVATE = "private";
export const PUBLIC = "public";
export const UNAUTHORIZED = "unauthorized";
export const ADMIN = "admin";
export const ADMIN_UNAUTHORIZED = "admin-unauthorized";

export default [
  {
    path: Paths.DASHBOARD,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/Dashboard").default
  },
  {
    path: Paths.LOGIN,
    fieldName: UNAUTHORIZED,
    exact: true,
    component: () => require("pages/Login").default
  },
  {
    path: Paths.LOGOUT,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/Logout").default
  },
  {
    path: Paths.PROFILE,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/Profile").default
  },
  {
    path: Paths.SAUDI_SEASONS_EVENTS,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/SaudiSeasonsEvents").default
  },
  {
    path: Paths.SAUDI_SEASON_EVENT,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/SaudiSeasonEventDetails").default
  },
  {
    path: Paths.SAUDI_SEASON_EVENT_APPLY,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/SaudiSeasonEventApplyReview").default
  },
  {
    path: Paths.SAUDI_SEASON_EVENT_REVIEW,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/SaudiSeasonEventApplyReview").default
  },
  {
    path: Paths.REQUESTS,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/MyRequests").default
  },
  {
    path: Paths.EDIT_INTEREST,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/EditInterest").default
  },
  {
    path: Paths.INTERESTS,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/Interests").default
  },
  {
    path: Paths.IDEAS,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/Ideas").default
  },
  {
    path: Paths.NOTIFICATIONS,
    fieldName: PRIVATE,
    exact: true,
    component: () => require("pages/Notifications").default
  },
  {
    path: Paths.TERMS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/Terms&Conditions").default
  },
  {
    path: Paths.POLICY,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/Policy").default
  },
  {
    path: Paths.QUESTIONS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/Questions").default
  },
  {
    path: Paths.CONTACT_US,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/ContactUs").default
  },
  {
    path: Paths.MEDIA_CENTER,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/MediaCenter").default
  },
  {
    path: Paths.ADMIN_REPORTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminReports").default
  },
  {
    path: Paths.NEWS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/News").default
  },
  {
    path: Paths.NEWS_DETAILS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/NewsDetails").default
  },
  {
    path: Paths.PHOTOS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/PhotosLibrary").default
  },
  {
    path: Paths.PHOTOS_DETAILS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/PhotosDetails").default
  },
  {
    path: Paths.VIDEOS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/VideosLibrary").default
  },
  {
    path: Paths.VIDEOS_DETAILS,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/VideosDetails").default
  },
  {
    path: Paths.ADMIN_LOGIN,
    fieldName: ADMIN_UNAUTHORIZED,
    exact: true,
    component: () => require("pages/AdminLogin").default
  },
  {
    path: Paths.ADMIN_HOME,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminHome").default
  },
  {
    path: Paths.FORGOT_PASSWORD,
    fieldName: ADMIN_UNAUTHORIZED,
    exact: true,
    component: () => require("pages/ForgotPassword").default
  },
  {
    path: Paths.RESET_PASSWORD,
    fieldName: ADMIN_UNAUTHORIZED,
    exact: true,
    component: () => require("pages/ResetPassword").default
  },
  {
    path: Paths.ADMIN_PROFILE,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminProfile").default
  },
  {
    path: Paths.CHANGE_PASSWORD,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminChangePassword").default
  },
  {
    path: Paths.ADMIN_USERS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminUsers").default
  },
  {
    path: Paths.ADMIN_MATLOOB_USERS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMatloobUsers").default
  },
  {
    path: Paths.ADMIN_MATLOOB_USER_ELEMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMatloobUserElements").default
  },
  {
    path: Paths.ADMIN_VIEW_ELEMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminViewElements").default
  },
  {
    path: Paths.MAIN_ELEMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMainElements").default
  },
  {
    path: Paths.SUPPORTING_ELEMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSupportingElements").default
  },
  {
    path: Paths.ELEMENT_COMMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminElementComments").default
  },
  {
    path: Paths.ADD_EDIT_COMMENT_ID,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminAddEditComment").default
  },
  {
    path: Paths.ADD_EDIT_COMMENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminAddEditComment").default
  },
  {
    path: Paths.EDIT_ADMIN,
    fieldName: ADMIN,
    exac: true,
    component: () => require("pages/EditAdmin").default
  },
  {
    path: Paths.ADMIN_USERS_DETAILS,
    fieldName: ADMIN,
    exac: true,
    component: () => require("pages/AdminUsersDetails").default
  },
  {
    path: Paths.NEW_CONTROL_PANEL,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminNewControlPanel").default
  },
  {
    path: Paths.ADMIN_SEASONS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSeasons").default
  },
  {
    path: Paths.SEASONS_ARRANGEMENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSeasons/SeasonsArrangement").default
  },
  {
    path: Paths.ADMIN_EVENTS_LIST,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEvents/AdminEventsList").default
  },
  {
    path: Paths.ADMIN_EVENTS_ARRANGEMENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEvents/AdminEventsArrangement").default
  }
  ,
  {
    path: Paths.ADMIN_APPLICATION_REQUIREMENTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminApplicationRequirements").default
  },
  {
    path: Paths.ADMIN_CATEGORIES_STATISTICS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminElementsCategories").default
  },
  {
    path: Paths.EDIT_APPLICATION_REQUIREMENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminAddEditApplicationRequirement").default
  },
  {
    path: Paths.ADD_APPLICATION_REQUIREMENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminAddEditApplicationRequirement").default
  },
  {
    path: Paths.ADMIN_DASHBOARD_STATISTICS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminStatistics").default
  },

  {
    path: Paths.NEW_SEASON,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSeasons/NewSeason").default
  },
  {
    path: Paths.NEW_EVENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEvents/NewEvent").default
  },
  {
    path: Paths.ADMIN_NOTIFICATIONS_HISTORY,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminNotificationsHistory").default
  },
  {
    path: Paths.ADMIN_CREATE_NOTIFICATION,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminCreateNotification").default
  },
  {
    path: Paths.EDIT_EVENT,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEvents/NewEvent").default
  },
  {
    path: Paths.EDIT_SEASON,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSeasons/NewSeason").default
  },
  {
    path: Paths.EVENTS_REQUESTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEventsSeasonsRequests").default
  },
  {
    path: Paths.SEASONS_REQUESTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminEventsSeasonsRequests").default
  },
  {
    path: Paths.SEASON_EVENT_REQUEST_INFO,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSeasonEventRequestInfo").default
  },
  {
    path: Paths.ADMIN_VIEW_REQUEST,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminViewRequest").default
  },
  {
    path: Paths.SUPPORTING_ELEMENTS_REQUESTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminSupportingElementsRequests").default
  },
  {
    path: Paths.ADMIN_MATLOOB_USER_REQUESTS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminUsersRequests").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_NEWS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterNews").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_NEW_NEWS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditNews").default
  },
  {
    path: Paths.ADMIN_MEDIA_NEWS_DETAILS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditNews").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_NEW_PHOTOS_VIDEOS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditPhotosVideos").default
  },
  {
    path: Paths.ADMIN_MEDIA_PHOTOS_VIDEOS_DETAILS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditPhotosVideos").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_IMAGES_VIDEOS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterImagesVideos").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_CAROUSEL_IMAGES,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterCarouselImages").default
  },
  {
    path: Paths.ADMIN_MEDIA_CENTER_NEW_CAROUSEL_IMAGES,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditCarouselImage").default
  },
    {
    path: Paths.ADMIN_MEDIA_CAROUSEL_IMAGE_DETAILS,
    fieldName: ADMIN,
    exact: true,
    component: () => require("pages/AdminMediaCenterAddEditCarouselImage").default
  },
  {
    path: Paths.VERIFY,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/Verify").default
  },
  {
    path: Paths.ADMIN_VERIFY,
    fieldName: PUBLIC,
    exact: true,
    component: () => require("pages/AdminVerify").default
  },
  {
    path: "*",
    fieldName: PUBLIC,
    exact: false,
    component: () => require("pages/ErrorPage").default
  },
];
