import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Loader, TableComponent, Pagination } from 'components';
import { 
  changeCategoryAction,
  cleanData,
  getMatloobUserElements
} from "store/actions/adminElements.actions";
import { ELEMENT_COMMENTS, ADMIN_VIEW_ELEMENTS } from "constants/paths";
import { MATLOOB_USER } from "constants/forms";
import { compile } from "path-to-regexp";
import moment from "moment";

const DATE_FORMAT = "l";

// const ELEMENTS_TYPES = {
//   "restaurants": "restaurant-or-cafe", 
//   "food-truck": "food-truck",
//   "temporary-work": "temporary-work",
//   "events": "events", 
//   "ideas": "ideas",
//   "venues": "venues", 
//   "logistics": "logistics", 
//   "talents": "talents", 
//   "stores": "stores", 
//   "pop-up-stores": "pop-up-stores", 
//   "personal-stores": "personal-stores",
//   "food-and-drinks-provider": "food-and-drinks-provider",
// }

const MatloobUserElements = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { elementsUsers, loaded } = useSelector(({ adminElements }) => adminElements);

  const tableHead = [
    {
      name: "#",
      key: "#"
    },
    {
      name: "User Name",
      key: "user_name"
    },
    {
      name: "Mobile Number",
      key: "mobile_number"
    },
    {
      name: "Element Type",
      key: "element_type"
    },
    {
      name: "Element Format",
      key: "element_format"
    },
    {
      name: "Status",
      key: "boolean",
      values: ['Incomplete', 'Complete'],
      field: "status"
    },
    {
      name: "Creation Date",
      key: "created_at"
    },
    {
      name: "Updating Date",
      key: "updated_at"
    },
    {
      name: "Comments",
      key: "action-btn",
      class: "fas fa-comments",
      field: 'comments',
      onClick: ({ id }) => {
        history.push(compile(ELEMENT_COMMENTS)({element_type: MATLOOB_USER}), {id})
      }
    },
    {
      name: "Category",
      key: "dropdown",
      field: 'favorite',
      onClick: ({elId}, val) => {
        dispatch(changeCategoryAction.request({id: elId, val}))
        dispatch(getMatloobUserElements.request({ id }));
      }
    },
    // {
    //   name: "Favorite",
    //   key: "boolean-icon",
    //   field: "favorites",
    //   class: ['mdi-star-outline','mdi-star'],
    //   onClick: (index, userId) => {
    //     dispatch(addRemoveFavoriteUser.request(
    //       {
    //         form_id: userId,
    //         index,
    //       },
    //       )
    //     )
    //   },
    // },
    {
      name: "Details",
      key: "action-btn",
      class: "mdi mdi-eye",
      onClick: ({elId, type, format}) => {
        if(format === '' && (type === 'restaurants' || type === 'stores')){
          return 
        } else {
          format ? window.open(compile(ADMIN_VIEW_ELEMENTS)({element_type: MATLOOB_USER, type: format, id: elId}), '_blank')
          : window.open(compile(ADMIN_VIEW_ELEMENTS)({element_type: MATLOOB_USER, type, id: elId}), '_blank')
        }
      },
    }
  ];

  useEffect(() => {
    if(id){
      dispatch(getMatloobUserElements.request({ id }));
    }
    return () => {
      dispatch(cleanData('elementsUsers'));
    }
  }, [dispatch, id])

  // TOFIX: type member will be element after BE update
  const tableBody = elementsUsers?.data?.map(el => ({
    elId: el?.id,
    user_name: el?.user?.company_name ||  el?.user?.full_name || "",
    mobile_number: el?.user?.mobile_number || "",
    status: el?.status || "",
    comments: el?.comments || "",
    favorite: el?.favorite || "",
    type: el?.element_type?.slug || "",
    created_at: el?.created_at ? moment(el.created_at).format(DATE_FORMAT) : "",
    updated_at: el?.updated_at ? moment(el.updated_at).format(DATE_FORMAT) : "",
    format: el?.element_format?.slug || "",
    element_type: el?.element_type?.name_en || 'Undefined',
    element_format: (el?.element_format === null && (el?.element_type?.slug === 'restaurants' ||  el?.element_type?.slug === 'stores'))
      ? 'Undefined' 
      : el?.element_format?.name_en 
        ? el?.element_format?.name_en
        : 'Not Required',
  }));

  return (
    <Loader loaded={loaded}>
      <section>
        <div className="card">
          <div className="card-body">
            <TableComponent tableHead={tableHead} tableBody={tableBody}/>
            {elementsUsers.data?.length ?
              <Pagination
                id={id}
                action = {getMatloobUserElements}
                currentPage = {elementsUsers?.current_page}
                lastPage = {elementsUsers?.last_page}
              />
              :
              null
          }
          </div>
        </div>
      </section>
    </Loader>
  )
}

export default MatloobUserElements;
