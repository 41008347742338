import React, { useEffect } from "react";
import { Router, Switch } from "react-router-dom";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import { ScrollToTop } from "components";
import history from "./history";
import { Context } from "context";
import { useSelector, useDispatch } from "react-redux";
import { AR_LANG, EN_LANG } from "constants/forms";
import ProtectedRoute from "components/protectedRoute";
import Routes, { ADMIN } from "components/protectedRoute/config";
import { getGuestToken } from "store/actions/auth.actions";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/new-logo.svg";
import { isDevTestMode } from "./utils/helpers";

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storageUser = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("i18nextLng");
  const rtl = lang === AR_LANG || !lang;
  const { user: stateUser } = useSelector(state => state.auth);
  const location = window.location.pathname.split("/");
  const host = window.location.host;

  const changeLang = () => {
    localStorage.setItem("i18nextLng", rtl ? EN_LANG : AR_LANG);
    window.location.reload();
  };

  useEffect(() => {
    if (location.includes(ADMIN)) document.body.setAttribute("dir", "ltr");
    else if (rtl || !lang) document.body.setAttribute("dir", "rtl");
  }, [rtl, lang, location]);

  useEffect(() => {
    if (!token) {
      dispatch(getGuestToken.request());
    }
  }, [token, dispatch]);

  // useEffect(() => {
  //   async function adminStyles() {
  //     if ((storageUser?.admin || stateUser?.admin) && history?.location?.pathname.includes(ADMIN)) {
  //       await import("assets/scss/DefaultTheme.scss");
  //     }
  //   }
  //   adminStyles();
  // }, [stateUser, storageUser]);

  let routesFiltered = Routes;
  if (location.includes(ADMIN) && host.includes(ADMIN)) {
    routesFiltered = routesFiltered.filter(el => el.path !== "*");
  }

  let routesByEnv = routesFiltered;
  if (!isDevTestMode) {
    // routesByEnv = routesByEnv.filter(route => !route.path.includes('admin'));
    // routesByEnv = routesByEnv.filter(route => !route.fieldName.includes('admin'));
  }
  if (!host.includes(ADMIN)) {
    routesByEnv = routesByEnv.filter(route => !route.path.includes("admin"));
  }

  return (
    <Context.Provider
      value={{
        user: Object.keys(stateUser).length ? stateUser : storageUser,
        lang,
        changeLang,
        token,
        rtl,
      }}>
      <div className={`App ${!location.includes(ADMIN) ? "portalOnly" : ""}`}>
        <MetaTags>
          <title>{t("meta.websiteTitle")}</title>
          <meta name="description" content={t("meta.description")} />
          <meta property="og:title" content={t("meta.title")} />
          <meta property="og:image" content={Logo} />
        </MetaTags>
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {routesByEnv.map(route => (
              <ProtectedRoute
                key={route.path}
                exact={route.exact}
                path={route.path}
                fieldName={route.fieldName}
                component={route.component()}
              />
            ))}
          </Switch>
        </Router>
      </div>
    </Context.Provider>
  );
}

export default App;
