import React from "react";
import ProfileLayout from "layouts/Profile";
import { SeasonEventDetails } from "components"; 

const SaudiSeasonEventDetails = () => {
  return (
    <ProfileLayout withBack={true}>
      <SeasonEventDetails/>
    </ProfileLayout>
  )
};

export default SaudiSeasonEventDetails;
