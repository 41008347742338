import React from "react";
import styles from "./styles.module.scss";
import { alert } from "store/actions/alert.actions";
import { useDispatch } from "react-redux";
import { getErrorsList } from "utils/redux";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';

const AlertBox = ({ error, additionalStyles = "", message = false, link='' }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inAdmin = location.pathname.includes("admin");
  if(!error && !message) return null;
  return (
    <div className={`${styles.alertBox} alert ${additionalStyles}`}>
      {!inAdmin && (
        <button
          className={styles.closeBtn}
          onClick={() => dispatch(alert.remove())}
        >
          &times;
        </button>
      )}
      <div className={styles.message}>
        {message ||
          getErrorsList(error)
            ?.split(",")
            .map((item, index) => <p key={index}>{item}</p>)}
      </div>
      {
        link && <Link to={link} className={styles.link}> Login here</Link>
      }
    </div>
  );
};
export default AlertBox;
