/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileDropdown from "../ProfileDropdown";
import { changeMenuStatus, getAdmin } from "store/actions/auth.actions";
import { clearUserRequestTarget } from "store/actions/adminElements.actions";
import logo from "assets/images/new-white-logo.svg";
import { Loader } from "components";
import { Context } from "context";
import "assets/scss/DefaultTheme.scss";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Topbar = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [countMenuOpen, setCountMenuOpen] = useState(0);
  const { menuStatus, loaded } = useSelector(state => state.auth);
  const { user } = useContext(Context);

  useEffect(() => {
    setOpen(menuStatus);
    if (!menuStatus) {
      document.body.classList.add("sidebar-enable");
      document.body.classList.add("enlarged");
      if (countMenuOpen === 0) {
        document.body.classList.add("no-transition");
      }
    } else {
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("enlarged");
      document.body.classList.remove("no-transition");
    }
    setCountMenuOpen(countMenuOpen + 1);
  }, [menuStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearUserRequestTarget())
    };
  },[])

  const toggleMenu = () => dispatch(changeMenuStatus.request());

  useEffect(() => {
    if (localStorage.getItem("admin_token")) dispatch(getAdmin.request());
  }, []);

  return (
    <Loader loaded={loaded}>
      <div className={classnames("navbar-custom", styles.navbar)}>
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li>
            <ProfileDropdown username={user?.name} />
          </li>
        </ul>
        <img src={logo} width="90" alt="alt" className="mt-1" />
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              type="button"
              className="button-menu-mobile waves-effect waves-light"
              onClick={toggleMenu}
            >
              <i className="fe-menu"></i>
            </button>
          </li>
        </ul>
      </div>
    </Loader>
  );
};

export default Topbar;
