import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const Chart = ({ dataChart: { categories, data }, fileName }) => {
  const series = [{ name: "Total", data }];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: fileName,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: fileName,
          },
          png: {
            filename: fileName,
          }
        },
      },
    },
    colors: ["#000000"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        columnWidth: "55%",
        // distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        rotate: 0,
        // rotateAlways: false,
        trim: true,
      },
    },
    yaxis: {
      title: {
        text: "Total",
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} className={styles.chart}/>
  );
};

Chart.propTypes = {
  dataChart: PropTypes.shape({
    categories: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
  }),
  fileName: PropTypes.string.isRequired
};

export default React.memo(Chart);
