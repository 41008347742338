import React from "react";
import Layout from "layouts/Admin";
import { ElementComments, Loader } from "components";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

const AdminElementComments = () => {
  const history = useHistory();
  const { loaded } = useSelector(({ adminElements }) => adminElements)

  return (
    <Loader loaded={loaded}>
      <Layout title="Comments">
        <div className="card">
          <div className="card-body d-flex flex-column">
            <ElementComments elementId={history?.location?.state?.id}/>
          </div>
        </div>
      </Layout>
    </Loader>
  );
};

export default AdminElementComments;
