import { call, put, takeLatest, all } from "redux-saga/effects";
import { saudiSeasonsEventsApi } from "services/saudiSeasonsEvents.service";
import {
  GET_SAUDI_SEASONS_EVENTS,
  GET_SAUDI_SEASON_EVENT,
  GET_SAUDI_SEASON_EVENT_ELEMENT,
  ELEMENT_SET,
  ELEMENT_REMOVE,
  GET_REQUEST_INTERESTS,
  GET_FILE,
  getSaudiSeasonsEvents,
  getSaudiSeasonEvent,
  getSaudiSeasonEventElement,
  manageElement,
  getRequestInterests,
  getFile,
} from "store/actions/saudiSeasonsEvents.actions";
import { clearReqData } from "store/actions/requests.actions"
// import history from "../../history";

function* getSaudiSeasonsEventsWorker() {
  try {
    const [seasons, events] = yield all([
      call(saudiSeasonsEventsApi.getSaudiSeasons),
      call(saudiSeasonsEventsApi.getSaudiEvents),
    ]);
    yield put(
      getSaudiSeasonsEvents.success({
        saudiSeasons: seasons?.data?.data || [],
        saudiEvents: events?.data?.data || [],
      })
    );
  } catch (e) {
    yield put(getSaudiSeasonsEvents.failure(e.data));
  }
}

function* getSaudiSeasonEventWorker({ payload }) {
  try {
    const [seasonEvent, regions] = yield all([
      call(saudiSeasonsEventsApi.getSaudiSeasonEvent, payload),
      call(saudiSeasonsEventsApi.getRegions),
    ]);
    yield put(
      getSaudiSeasonEvent.success({
        saudiSeasonEvent: seasonEvent.data,
        regions: regions.data.saudiRegions,
      })
    );
  } catch (e) {
    yield put(getSaudiSeasonEvent.failure(e.data));
  }
}

function* getSaudiSeasonEventElementWorker({ payload }) {
  try {
    const { data } = yield call(
      saudiSeasonsEventsApi.getSaudiSeasonEventElement,
      payload
    );
    yield put(getSaudiSeasonEventElement.success(data));
  } catch (e) {
    yield put(getSaudiSeasonEventElement.failure(e.data));
  }
}

function* manageElementSetWorker({ payload }) {
  localStorage.setItem("element", JSON.stringify(payload));
  yield put(manageElement.set(payload));
}

function* manageElementRemoveWorker() {
  localStorage.removeItem("element");
  yield put(clearReqData());
  yield put(manageElement.remove());
}

function* getRequestInterestsWorker({ payload }) {
  try {
    const { data } = yield call(
      saudiSeasonsEventsApi.getRequestInterests,
      payload
    );
    yield put(getRequestInterests.success(data));
  } catch (e) {
    yield put(getRequestInterests.failure(e.data));
  }
}

function* getFileWorker({ payload }) {
  try {
    const { data } = yield call(saudiSeasonsEventsApi.getFile, payload);
    window.location.assign(data.redirect_to);
    yield put(getFile.success());
    localStorage.removeItem("dv");
  } catch (e) {
    yield put(getFile.failure(e.data));
  }
}

function* saudiSeasonsEventsSaga() {
  yield takeLatest(GET_SAUDI_SEASONS_EVENTS.REQUEST, getSaudiSeasonsEventsWorker);
  yield takeLatest(GET_SAUDI_SEASON_EVENT.REQUEST, getSaudiSeasonEventWorker);
  yield takeLatest(GET_SAUDI_SEASON_EVENT_ELEMENT.REQUEST, getSaudiSeasonEventElementWorker);
  yield takeLatest(GET_REQUEST_INTERESTS.REQUEST, getRequestInterestsWorker);
  yield takeLatest(ELEMENT_SET, manageElementSetWorker);
  yield takeLatest(ELEMENT_REMOVE, manageElementRemoveWorker);
  yield takeLatest(GET_FILE.REQUEST, getFileWorker);
}

export default saudiSeasonsEventsSaga;