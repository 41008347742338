import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const Card = ({ img, index, onClick }) => {
  const { t } = useTranslation();
  const handleClick = () => onClick(index);
  const data = t("cardsSection.titles", { returnObjects: true });
  return (
    <div className={styles.cardItem} onClick={handleClick}>
      <img src={img} alt="" />
      <button className={styles.cardBtn} >
        {data[index].text}
      </button>
    </div>
  );
};

Card.propTypes = {
  img: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Card;
