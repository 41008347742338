import React, {useEffect, useContext, useState} from "react";
import { compile } from "path-to-regexp";
import { useHistory } from 'react-router-dom';
import {
  Loader,
  Pagination,
  AlertBox,
  AdminTopPanel,
} from "components";
import { SEASON_EVENT_REQUEST_INFO, SUPPORTING_ELEMENTS_REQUESTS } from "constants/paths";
import { getEventsSeasonsRequests,getEventsSeasonsStatusRequests } from "store/actions/adminElements.actions";
import { useSelector, useDispatch } from "react-redux";
import { REQUESTS, SUPPORTING_ELEMENTS_TYPE } from "constants/forms";
import { Context } from "context";

import styles from "./styles.module.scss";

const SeasonEventRequests = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loaded, error, eventsSeasonsRequests,eventsSeasonsStatusRequests } = useSelector(({ adminElements }) => adminElements)
  const isAlertActive =  useSelector(({ alert }) => alert);
  const { user } = useContext(Context);
  const [ statusSelected, setStatusSelected ] = useState({ name: "Active", type: false });
  useEffect(() => {
    dispatch(getEventsSeasonsRequests.request({ type }))
  },[dispatch, type])

  useEffect(() => {
    dispatch(getEventsSeasonsStatusRequests.request({ type,seasonType: statusSelected?.type}))
  },[ dispatch,statusSelected, type])

  const statusTypes = [
    { name: "Active", type: false },
    { name: 'Archived', type: true }
  ];

  const redirectTo = (id, param) => {
    if(param === REQUESTS)
      history.push(compile(SEASON_EVENT_REQUEST_INFO)({ id }));
    else
      history.push(compile(SUPPORTING_ELEMENTS_REQUESTS)({type, id }));
  }
  const dropdownTools = {
    label: "Status:",
    dropdownElements: statusTypes,
    optionSelected: statusSelected,
    setOptionSelected: setStatusSelected,
  };

  return(
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
      <AdminTopPanel   
        withDropdown
        dropdownTools={dropdownTools}
        />
      <div className={styles.cards}>
        {statusSelected ? eventsSeasonsStatusRequests.data?.map(item => (
          <div key={item.id} className={styles.cards_item}>
            <h3 className={styles.cards_item_title}>{item.name_en + " | " + item.name_ar}</h3>
            <p className="mb-0">
              Request: {item.request_no.allRequests}
            </p>
            <div className={styles.cards_item_progress}>
              <span>Under Progress: {item.request_no.underProgress}</span>
              <span>Accepted: {item.request_no.accepted}</span>
            </div>
            <div className={styles.cards_item_progress}>
              <span>Rejected: {item.request_no.rejected}</span>
              <span>Required Updates: {item.request_no.requiredUpdates}</span>
            </div>
            <span className="mb-2 mt-3">
              Other: {item.request_no.other}
            </span>
            <div className={styles.cards_item_buttons}>
              <button className="btn btn-default btn-paua" onClick={() => redirectTo(item.id, REQUESTS)} disabled={!item.request_no.allRequests}>
                Requests
              </button>
              {user.user_type !== 4 && <button className="btn btn-default btn-paua" onClick={() => redirectTo(item.id, SUPPORTING_ELEMENTS_TYPE)}>
                Supporting Elements
              </button>}
            </div>
          </div>
        ))
      : eventsSeasonsRequests.data?.map(item => (
        <div key={item.id} className={styles.cards_item}>
          <h3 className={styles.cards_item_title}>{item.name_en + " | " + item.name_ar}</h3>
          <p className="mb-0">
            Request: {item.request_no.allRequests}
          </p>
          <div className={styles.cards_item_progress}>
            <span>Under Progress: {item.request_no.underProgress}</span>
            <span>Accepted: {item.request_no.accepted}</span>
          </div>
          <div className={styles.cards_item_progress}>
            <span>Rejected: {item.request_no.rejected}</span>
            <span>Required Updates: {item.request_no.requiredUpdates}</span>
          </div>
          <span className="mb-2 mt-3">
            Other: {item.request_no.other}
          </span>
          <div className={styles.cards_item_buttons}>
            <button className="btn btn-default btn-paua" onClick={() => redirectTo(item.id, REQUESTS)} disabled={!item.request_no.allRequests}>
              Requests
            </button>
            {user.user_type !== 4 && <button className="btn btn-default btn-paua" onClick={() => redirectTo(item.id, SUPPORTING_ELEMENTS_TYPE)}>
              Supporting Elements
            </button>}
          </div>
        </div>
      ))
      }
      </div>
      {eventsSeasonsRequests.data?.length ?
        <Pagination
          type={type}
          action = {statusSelected ? getEventsSeasonsStatusRequests:  getEventsSeasonsRequests}
          currentPage = {statusSelected ? eventsSeasonsStatusRequests?.current_page : eventsSeasonsRequests?.current_page}
          lastPage = {statusSelected ? eventsSeasonsStatusRequests?.last_page : eventsSeasonsRequests?.last_page}
          goToPage
        />
        :
        null
            }
    </Loader>
  )
}
export default SeasonEventRequests
