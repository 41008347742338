import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader, AlertBox } from "components";
import { createElementComment ,editElementComment, getElementComment } from "store/actions/adminElements.actions";
import PropTypes from "prop-types";
import classnames from "classnames";

const AddEditComment = ({ element_id, comment_id }) => {

  const [ comment, setComment ] = useState("");
  const { elementComment, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (comment_id) {
      dispatch(getElementComment.request(comment_id));
    }
  },[dispatch, comment_id])

  useEffect(() => {
    if (elementComment) {
      setComment(elementComment.comment)
    }
  },[elementComment])

  const handleAddEditCommentSave = () => {
    if (comment_id) {
      dispatch(editElementComment.request({ comment_id, comment }));
    } else if (element_id) {
      dispatch(createElementComment.request({ form_id: element_id, comment }));
    } else if (!comment_id && !element_id) {
      history.goBack();
    }
  }

  return (
    <Loader loaded={loaded}>
      <div className="col-12">
      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }
        <div className="card">
          <div className="card-body p-5">
            <p>Comment:</p>
            <section className="d-flex flex-column">
              <textarea value={comment} onChange={e => setComment(e.target.value)}/>
              <Button className={classnames("ml-auto my-2", "admin-submit-btn-black")} onClick={handleAddEditCommentSave}>Save</Button>
            </section>
          </div>
        </div>
      </div>
    </Loader>
  )
};

AddEditComment.propTypes = {
  element_id: PropTypes.string,
  comment_id: PropTypes.string
}

export default React.memo(AddEditComment);