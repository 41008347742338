import React,{ useContext,useEffect } from 'react'
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Switch from "react-switch";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "context";
import { DragDropFile, Loader, AlertBox} from "components";
import { addMediaCenterNewNews,editMediaCenterNews,getAdminMediaCenterNewsDetails } from "store/actions/adminElements.actions";
import { serialize } from "object-to-formdata";

const MediaCenterAddEditNews = ({id}) => {
    const isAlertActive =  useSelector(({ alert }) => alert);
    const { mediaCenterAdminNewsDetails, loaded, error } = useSelector(({ adminElements }) => adminElements);
    const dispatch = useDispatch();
    const { user } = useContext(Context);
    const viewOnly = user?.user_type === 3
    useEffect(() => {
        if(id){
            dispatch(getAdminMediaCenterNewsDetails.request({id}))
        }
    },[ dispatch,id])
    const validationSchema = Yup.object().shape({
        title_ar: Yup.string().required('Required'),
        title_en: Yup.string().required('Required'),
        description_en: Yup.string().required('Required'),
        description_ar: Yup.string().required('Required'),
        status: Yup.number().required('Required'),
        news_images: Yup.array().of(
            Yup.object().shape({
                image: Yup.mixed().required('Required'),
            })
        ),
    });
    const showErrorMessage = (name) => (
        <ErrorMessage
        name={name}
        render={msg =>
            <span className="text-danger font-weight-normal">
            {msg}
            </span>
        }
        />
    )

    const TextArea = ({ field }) => (
        <>
            <textarea {...field} disabled={viewOnly} className="form-control"/>
            {showErrorMessage(field.name)}
        </>
    )

    const submitForm = (values) => {
        const data = {
            news_images: values.news_images?.map(({id, image, description_ar, description_en}) => {
            if(typeof(image) === "string"){
                return {
                    id,
                    description_ar,
                    description_en
                }
                }
                else if(id){
                    return{
                        id,
                        image,
                        description_ar,
                        description_en
                    }
                }
                else{
                    return{
                        image,
                        description_ar,
                        description_en
                    }
                }
            }),
            title_ar: values.title_ar,
            title_en: values.title_en,
            description_en: values.description_en,
            description_ar: values.description_ar,
            status:values.status,
        }
        if(id){
            dispatch(editMediaCenterNews.request({id, data:serialize({...data, _method: 'PUT'}, { indices: true })}));
        }else{
            dispatch(addMediaCenterNewNews.request(serialize({...values}, { indices: true })));
        }
    }
    return (
        <Loader loaded={loaded}>
        {
            isAlertActive &&
            <AlertBox
                error={error}
                additionalStyles='alert-danger'
            />
        }
        <div className="pb-4">
            <Formik
            initialValues={{
                news_images: id && mediaCenterAdminNewsDetails?.images ?
                mediaCenterAdminNewsDetails.images?.map(item => ({
                    id: item.id,
                    description_ar: item.description_ar,
                    description_en: item.description_en,
                    image: `${item?.image}`
            }))
            :
            [
                {
                    image: "",
                    description_ar: "",
                    description_en: ""
                }
            ],
                title_ar: id ? mediaCenterAdminNewsDetails.title_ar : "",
                title_en: id ? mediaCenterAdminNewsDetails.title_en : "",
                description_ar: id ? mediaCenterAdminNewsDetails.description_ar : "",
                description_en:  id ? mediaCenterAdminNewsDetails.description_en : "",
                status:  id ? mediaCenterAdminNewsDetails.status : 0,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={values => submitForm(values, true)}
            >{ ({ values, setFieldValue }) => (

            <Form>
                <section>
                <FieldArray name="news_images">
                    {({remove, push, form}) => (
                    <div className="card">
                        <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h4>News Images</h4>
                            {!viewOnly && <Button className="btn-paua" onClick={() => push({news_images: "", description_ar: "", description_en: ""})}>
                                New Image
                            </Button>}
                        </div>
                        {
                        values?.news_images?.map((image, index) => {
                            return (
                                <div key={index} className={`border border-light rounded my-2 p-2 ${styles.image__container}`}>
                                    {values.news_images.length > 1 && !viewOnly &&
                                    <span
                                        className={classNames(`font-weight-bold ${styles.image__close}`)}
                                        onClick={() => {
                                            remove(index)
                                        }}
                                    >
                                        ×
                                    </span>
                                    }
                                <div className="p-1">
                                <h4>News Image</h4>
                                <code>Image Type: JPG or PNG</code>
                                <Field name={`news_images[${index}].image`}>
                                    {(props) => {
                                    const { field, form } = props;
                                        return(
                                            <DragDropFile idx={index} form={form} field={field} value={values.news_images[index].image} viewOnly={viewOnly} onRemove={() => remove(index)}/>
                                        )
                                    }}
                                </Field>
                                {showErrorMessage(`news_images[${index}].image`)}
                                </div>
                                <div className="d-flex p-1">
                                    <label className="flex-grow-1 mr-1">
                                    Description Ar
                                    <Field
                                        name={`news_images[${index}].description_ar`}
                                        component={TextArea}
                                        className="form-control"
                                    />
                                    </label>
                                    <label className="flex-grow-1 ml-1">
                                        Description En
                                        <Field
                                            name={`news_images[${index}].description_en`}
                                            component={TextArea}
                                            className="form-control"
                                        />
                                    </label>
                                </div>
                            </div>
                            )
                            })
                        }
                        </div>
                    </div>
                )}
                </FieldArray>
                </section>
                <section>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group">
                                <label className="control-label">News Title Ar</label>
                                <Field name="title_ar" placeholder="News Title Ar" className="form-control" />
                                {showErrorMessage('title_ar')}
                            </div>
                            <div className="form-group">
                                <label className="control-label">News Title En</label>
                                <Field name="title_en" placeholder="News Title En" className="form-control" />
                                {showErrorMessage('title_en')}
                            </div>
                            <div className="form-group">
                                <label className="control-label">News Description Ar</label>
                                <Field
                                    name="description_ar"
                                    className="form-control"
                                    component={TextArea}
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">News Description En</label>
                                <Field
                                    name="description_en"
                                    className="form-control"
                                    component={TextArea}
                                />
                            </div>
                            <div className="d-flex flex-column form-group">
                            <label className="control-label">Status</label>
                            <Switch
                                checked={Boolean(values.status)}
                                onChange={item => setFieldValue("status", +item)}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={40}
                                height={20}
                                activeBoxShadow=""
                                disabled={viewOnly}
                            />
                                {showErrorMessage('status')}
                            </div>
                        </div>
                    </div>
                </section> 
                {!viewOnly && <section className="d-flex">
                    <Button className="btn-paua ml-auto" type="submit">Save</Button>
                </section>
                }
                </Form>
                )}
                </Formik>
            </div>
            </Loader>
    )
}

export default MediaCenterAddEditNews;