import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_USER_REQUESTS,
  GET_USER_REQUEST,
  APPLY_USER_REQUEST,
  PORTAL_DOWNLOAD_FILE,
  GET_REQUEST_INTEREST,
  GET_USER_REQUESTS_PENDING,
  CANCEL_REQUEST,
  applyUserRequest,
  getUserRequests,
  getUserRequest,
  portalDownloadFile,
  getRequestInterest,
  getUserRequestsPending,
  isApplied,
  cancelRequest
} from "store/actions/requests.actions";
import { requestsApi } from "services/requests.service";
import { ElementsApi } from "services/elements.service";
import { saveAs } from "file-saver";
import { compile } from "path-to-regexp";
import { SAUDI_SEASON_EVENT_REVIEW } from "constants/paths";
import { UNPROCESSABLE_ENTITY } from "constants/api";
import { REVIEW } from "constants/seasonsEvents";
import history from "../../history";

function* getUserRequestsWorker({ payload }) {
  try {
    const [requests, elements] = yield all([
      call(requestsApi.getUserRequests, payload),
      call(ElementsApi.getElements),
    ]);
    const {
      data: { data: requests_data, current_page, last_page, total },
    } = requests;
    yield put(
      getUserRequests.success({
        userRequests: requests_data,
        userElementTypes: elements.data.userElementTypes,
        current_page,
        last_page,
        total,
      })
    );
  } catch (e) {
    yield put(getUserRequests.failure(e.data));
  }
}

function* getUserRequestWorker({ payload }) {
  try {
    const [request, elements] = yield all([
      call(requestsApi.getUserRequest, payload),
      call(ElementsApi.getElements),
    ]);
    yield put(
      getUserRequest.success({
        userRequest: request.data,
        userElementTypes: elements.data.userElementTypes,
      })
    );
  } catch (e) {
    yield put(getUserRequest.failure(e.data));
  }
}
function* getUserRequestsPendingWorker({ payload }) {
  try {
    const { data } = yield call(requestsApi.getUserRequestsPending, payload);
    yield put(getUserRequestsPending.success(data));
  } catch (e) {
    yield put(getUserRequestsPending.failure(e.data));
  }
}
function* applyUserRequestWorker({ payload }) {
  try {
    const {
      data: { type, id },
    } = yield call(requestsApi.applyUserRequest, payload);
    yield put(applyUserRequest.success());
    history.push(
      compile(SAUDI_SEASON_EVENT_REVIEW)({ type, id, action: REVIEW })
    );
  } catch (e) {
    if (e.status === UNPROCESSABLE_ENTITY) {
      yield put(isApplied());
    }
    yield put(applyUserRequest.failure(e.data));
  }
}

function* downloadFileWorker({ payload }) {
  try {
    const { data } = yield call(requestsApi.portalDownloadFile, payload);
    saveAs(data, "downloaded.pdf");
    yield put(portalDownloadFile.success());
  } catch (e) {
    yield put(portalDownloadFile.failure(e.data));
  }
}

function* getRequestInteresWorker({ payload }) {
  try {
    const { data } = yield call(requestsApi.getRequestInterest, payload);
    yield put(getRequestInterest.success(data));
  } catch (e) {
    yield put(getRequestInterest.failure(e.data));
  }
}

function* cancelRequestInteresWorker({ payload }) {
  try {
    const { data } = yield call(requestsApi.cancelRequest, payload);
    yield put(cancelRequest.success(data));
  } catch (e) {
    yield put(cancelRequest.failure(e.data));
  }
}

function* requestsSaga() {
  yield takeLatest(GET_USER_REQUESTS.REQUEST, getUserRequestsWorker);
  yield takeLatest(GET_USER_REQUEST.REQUEST, getUserRequestWorker);
  yield takeLatest(APPLY_USER_REQUEST.REQUEST, applyUserRequestWorker);
  yield takeLatest(PORTAL_DOWNLOAD_FILE.REQUEST, downloadFileWorker);
  yield takeLatest(GET_REQUEST_INTEREST.REQUEST, getRequestInteresWorker);
  yield takeLatest(
    GET_USER_REQUESTS_PENDING.REQUEST,
    getUserRequestsPendingWorker
  );
  yield takeLatest(CANCEL_REQUEST.REQUEST, cancelRequestInteresWorker);
}
export default requestsSaga;
