import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DraggableList } from "components";
import {
  getSeasonsArrangement,
  reorderSeasons,
} from "store/actions/adminElements.actions";


const SeasonsArrangement = () => {
  const dispatch = useDispatch();
  const { seasonsArrangement } = useSelector(
    ({ adminElements }) => adminElements
  );

  useEffect(() => {
    dispatch(getSeasonsArrangement.request());
  }, [dispatch]);

  const headerList = [
    {
      name: "Season Image",
      key: "image",
      w: 20,
    },
    {
      name: "Season Status",
      key: "status",
      w: 40,
    },
    {
      name: "Season name",
      key: "name",
      w: 40,
    },
  ];

  return (
    <div className="card">
      <div className="card-body d-flex flex-wrap">
        <div className="w-100">
          {seasonsArrangement.length ? 
            <DraggableList
              headerList={headerList}
              bodyList={seasonsArrangement}
              reorderHandler={reorderSeasons}
            />
            :
            <div className="notFound">Not Found</div>
          }
        </div>
      </div>
    </div>
  );
};

export default SeasonsArrangement;
