import React, { useEffect,  useState, useContext } from "react";
import { DragDropFile, Loader } from "components";
import {
  getAdminMainElementsTypes,
  createAdminEventSeason,
  getEventSeason,
  editEventSeason
} from "store/actions/adminElements.actions";
import { Context } from "context";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import Select from "react-dropdown-select";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serialize } from "object-to-formdata";
import moment from "moment";
import { BASE_URL } from "constants/api";
import { ArchivedModal, AlertBox } from "components";

const FORMATS = [
  { id: "42fb5884-5a36-48e0-b7b8-5a96acc4f15a", formats: "restaurant-formats", data: "restaurantFormats"},
  { id: "a0558093-1d16-4bc6-a4ad-ad7aa9994198", formats: "store-formats", data: "storeFormats"},
]

const DATE_FORMAT = "YYYY-MM-DD";

const statuses = [
  { value: 0, label: "Coming Soon" },
  { value: 1, label: "Open" },
  { value: 2, label: "Close" },
  { value: 3, label: "Cancel" }
];

const NewEventSeason = ({ type, id }) => {
  const { loaded, error, adminRegions, adminMainElements, adminMainElementFormats, eventSeasonApplicationRequirements, cpUsers, eventSeasonData, ...data } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false)
  const [archivedModalOpen, setArchivedModalOpen] = useState(false)
  const { user } = useContext(Context);
  const viewOnly = user?.user_type === 3
  const createStatusList = () => {
    if(id){
      return statuses
    }
    else {
      return statuses.filter((item) => item.value < 2)
    }
  }

  const minCloseDateFormater = (date) => {
    return moment(new Date(new Date(date).getTime() + (24 * 60 * 60 * 1000))).format(DATE_FORMAT)
  }

  const submitForm = (values, archived) => {
    const requestType = type === "Season" ? "season" : "event"
    
    const data = {
      [`${requestType}_images`]: values.images.map(({id, image, description_ar, description_en}) => {
        if(typeof(image) === "string"){
          return {
            id,
            description_ar,
            description_en
          }
        }
        else if(id){
          return{
            id,
            image,
            description_ar,
            description_en
          }
        }
        else{
          return{
            image,
            description_ar,
            description_en
          }
        }
      }),
      ...values.details,
      main_element: values.main_element.map(({id, format, description_ar, description_en, application_requirement}) => {
        let data = {
          id: id[0].id,
          format: format[0]?.id || [],
          application_requirement: application_requirement.length ? application_requirement.map(({ id }) => id) : []
        }
        if(description_ar){
          data = {
            ...data,
            description_ar
          }
        }
        if(description_en){
          data = {
            ...data,
            description_en
          }
        }
        return data
      }),
      supporting_elements: values.supporting_elements.map(({ id }) => id),
      directors: values.directors.map(({ id }) => id ),
      reqManagers: values.reqManagers.map(({ id }) => id ),
    }
    const filteredData = {};
    Object.keys(data).forEach((key) => {
      if(data[key].toString()){
        filteredData[key] =  data[key]
      }
    })
    const dataWithLogo = typeof(values.logo) === "string" ? {...filteredData} : {...filteredData, logo: values.logo}
    if(id){
      if(!eventSeasonData?.archived && archived && (filteredData.status === 2 || filteredData.status === 3))
        setModalOpen(true)
      else if(eventSeasonData?.archived && archived && (filteredData.status === 0 || filteredData.status === 1))
        setArchivedModalOpen(true)
      else
        dispatch(editEventSeason.request({type: `${requestType}s`, id, data: serialize({...dataWithLogo, _method: 'PUT'}, { indices: true })}))
    }
    else{
      dispatch(createAdminEventSeason.request({
        data: serialize(dataWithLogo, { indices: true }),
        type: `${requestType}s`
      }))
    }
  }

  const setModalValue = (setFieldValue, value, values) => {
    setFieldValue("details.archived", value)
    setModalOpen(false)
    setArchivedModalOpen(false)
    const data = {...values, details: {...values.details, archived: value}}
    submitForm(data, false)
  }

  useEffect(() => {
    if(id)
      dispatch(getEventSeason.request({ type, id }))
  },[dispatch, id, type])

  useEffect(() => {
    dispatch(getAdminMainElementsTypes.request({viewOnly, type: null}));
  }, [dispatch, viewOnly]);

  const validationSchema = Yup.object().shape({
    logo: Yup.mixed().required('Required'),
    images: Yup.array().of(
      Yup.object().shape({
        image: Yup.mixed().required('Required'),
        description_ar: Yup.string().required('Required'),
        description_en: Yup.string().required('Required')
      })
    ),
    details: Yup.object().shape({
      region_id: Yup.string().required('Required'),
      name_ar: Yup.string().required('Required'),
      name_en: Yup.string().required('Required'),
      description_ar: Yup.string().required('Required'),
      description_en: Yup.string().required('Required'),
      control: Yup.number().required('Required'),
      status: Yup.number().required('Required'),
      start_date: Yup.date().nullable(),
      end_date: Yup.date().nullable(),
      disclaimer_ar: Yup.string().required('Required'),
      disclaimer_en: Yup.string().required('Required'),
      archived: Yup.number().required('Required'),
    }),
    main_element: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Required'),
        format: Yup.string().when("haveFormat", {
          is: true,
          then: Yup.string().required("Required"),
          otherwise: Yup.string()
        }),
        description_ar: Yup.string().nullable(),
        description_en: Yup.string().nullable(),
      }),
    ),
    supporting_elements: Yup.array().required('Required'),
    directors: Yup.array().required('Required')
  });

  const showErrorMessage = (name) => (
    <ErrorMessage
      name={name}
      render={msg =>
        <span className="text-danger font-weight-normal">
          {msg}
        </span>
      }
    />
  )

  const TextArea = ({ field }) => (
      <>
        <textarea {...field} disabled={viewOnly} className="form-control"/>
        {showErrorMessage(field.name)}
      </>
  )

  const ImageInput = ({ field, form }) => {
    return (
      <>
        <DragDropFile field={field} form={form} value={form.values.logo} viewOnly={viewOnly}/>
        {showErrorMessage(field.name)}
      </>
    )
  }

  const customItem = ({methods}, data) => (
    data.map( item => (
      <span key={item.id} className={classNames(styles.applications_items, item.hidden && styles.applications_items_hidden)}>
        <span>{item.hidden ? `${item.name} - Hidden` : item.name}</span>
        <span className={styles.applications_items_remove} onClick={(event) => methods.removeItem(event, item, true)}>×</span>
      </span>
    ))
  )
  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
            error={error}
            additionalStyles='alert-danger'
        />
      }
      <div className="pb-4">
        <Formik
          initialValues={{
            logo:  (id && `${BASE_URL}/${eventSeasonData?.logo}`) || "",
            images: id && eventSeasonData?.images ?
              eventSeasonData.images?.map(item => ({
                id: item.id,
                description_ar: item.description_ar,
                description_en: item.description_en,
                image: `${BASE_URL}/${item?.image}`
            }))
            :
            [
              {
                image: "",
                description_ar: "",
                description_en: ""
              }
            ],
            details: {
              region_id: id ? eventSeasonData?.region_id : "",
              name_ar: id ? eventSeasonData?.name_ar : "",
              name_en: id ? eventSeasonData?.name_en : "",
              description_ar: id ? eventSeasonData?.description_ar : "",
              description_en: id ? eventSeasonData?.description_en : "",
              control: id ? eventSeasonData?.control : 0,
              status: id ? eventSeasonData?.status : "",
              start_date: id && eventSeasonData?.start_date ? moment(eventSeasonData.start_date).format(DATE_FORMAT) : "",
              end_date: id && eventSeasonData?.end_date ? moment(eventSeasonData.end_date).format(DATE_FORMAT) : "",
              disclaimer_ar: id ? eventSeasonData?.disclaimer_ar : "",
              disclaimer_en: id ? eventSeasonData?.disclaimer_en : "",
              archived: (id && eventSeasonData?.archived) || 0
            },
            main_element: (id && eventSeasonData?.main_elements?.map((item, index) => ({
              description_ar: item.description_ar || "",
              description_en: item.description_en || "",
              id: adminMainElements?.filter(element => element.id === eventSeasonData?.main_elements[index]?.element_type?.id) || [],
              format: data[FORMATS.find(el => el.id === eventSeasonData?.main_elements[index]?.element_type?.id)?.data]?.filter(element => element.id === eventSeasonData?.main_elements[index]?.format?.id) || [],
              application_requirement: eventSeasonApplicationRequirements.filter(element => eventSeasonData?.main_elements[index]?.application_requirement?.find(item => item.id === element.id))?.map(({id, name_en, hidden}) => ({id, name: name_en, hidden})) || [],
              haveFormat: !!item.format
            })))
             ||
            [
              {
                id: "",
                format: "",
                description_ar: "",
                description_en: "",
                application_requirement: [],
                haveFormat: true
              }
            ],
            supporting_elements: id && adminMainElements.length && eventSeasonData?.supporting_elements?.length ? adminMainElements.filter(element => !element.is_main && eventSeasonData.supporting_elements.find(item => element.id === item.element_type_id)) : [],
            directors: id ? eventSeasonData.directors : [],
            reqManagers: id ? eventSeasonData.reqManagers : [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={values => submitForm(values, true)}
          >{ ({ values, errors, setFieldValue, handleChange }) => (
            <Form>
              <section>
                <div className="card">
                  <div className="card-body">
                    <h4>{`${type} Logo`}</h4>
                    <code>Image Type: JPG or PNG | Image Size: 114px x 130px</code>
                    <div className="d-flex flex-column px-3 pb-3">
                      <Field name="logo" component={ImageInput}/>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <FieldArray name="images">
                  {({remove, push, form}) => (
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h4>{`${type} Images`}</h4>
                          {!viewOnly && <Button className="btn-paua" onClick={() => push({image: "", description_ar: "", description_en: ""})}>
                            New Image
                          </Button>}
                        </div>
                        {
                          values.images.map((image, index) => {
                            return (
                              <div key={index} className={`border border-light rounded my-2 p-2 ${styles.image__container}`}>
                                {values.images.length > 1 && !viewOnly &&
                                  <span
                                    className={classNames(`font-weight-bold ${styles.image__close}`)}
                                    onClick={() => {
                                      remove(index)
                                    }}
                                  >
                                    ×
                                  </span>
                                }
                                <div className="p-1">
                                  <h4>{`${type} Image`}</h4>
                                  <code>Image Type: JPG or PNG | Image Size: 1000px x 500px</code>
                                  <Field name={`images[${index}].image`}>
                                    {(props) => {
                                      const { field, form } = props;
                                      return(
                                        <DragDropFile idx={index} form={form} field={field} value={values.images[index].image} viewOnly={viewOnly} onRemove={() => remove(index)}/>
                                      )
                                    }}
                                  </Field>
                                  {showErrorMessage(`images[${index}].image`)}
                                  </div>
                                  <div className="d-flex p-1">
                                    <label className="flex-grow-1 mr-1">
                                      Description Ar
                                      <Field
                                        name={`images[${index}].description_ar`}
                                        component={TextArea}
                                        className="form-control"
                                      />
                                    </label>
                                    <label className="flex-grow-1 ml-1">
                                      Description En
                                      <Field
                                        name={`images[${index}].description_en`}
                                        component={TextArea}
                                        className="form-control"
                                      />
                                    </label>
                                </div>
                            </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )}
                </FieldArray>
              </section>

              <section>
                <div className="card">
                  <div className="card-body">
                    <h4>{`${type} Details`}</h4>
                    <div className={`border border-light rounded p-2 my-2 ${styles.element__container}`}>
                      <div className="form-group">
                        <label className="control-label">{`${type} Region`}</label>
                        <Select
                          className={`${styles.element__dropdown}`}
                          name={`details.region_id`}
                          valueField="id"
                          labelField="name"
                          values={values.details.region_id && adminRegions.length ? [adminRegions.find(({ id }) => id === values.details.region_id)] : []}
                          onChange={(value) => setFieldValue(`details.region_id`, value[0].id)}
                          options={adminRegions}
                          disabled={viewOnly}
                        />
                        {showErrorMessage('details.region_id')}
                      </div>

                      <div className="form-group">
                        <label className="control-label">{`${type} Name Ar`}</label>
                        <Field name="details.name_ar" placeholder={`${type} name Ar`} className="form-control" disabled={viewOnly}/>
                        {showErrorMessage('details.name_ar')}
                      </div>
                      <div className="form-group">
                        <label className="control-label">{`${type} Name En`}</label>
                        <Field name="details.name_en" placeholder={`${type} name En`} className="form-control" disabled={viewOnly}/>
                        {showErrorMessage('details.name_en')}
                      </div>
                      <div className="form-group">
                        <label className="control-label">{`${type} Description Ar`}</label>
                        <Field
                          name="details.description_ar"
                          className="form-control"
                          component={TextArea}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">{`${type} Description En`}</label>
                        <Field
                          name="details.description_en"
                          className="form-control"
                          component={TextArea}
                        />
                      </div>
                      <div className="d-flex flex-column form-group">
                        <label className="control-label">{`${type} Control Status`}</label>
                        <Switch
                          checked={Boolean(values.details.control)}
                          onChange={item => setFieldValue("details.control", +item)}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          width={40}
                          height={20}
                          activeBoxShadow=""
                          disabled={viewOnly}
                        />
                        {showErrorMessage('details.control')}
                      </div>

                      {
                        modalOpen &&
                          <ArchivedModal
                            modalClose={() => setModalOpen(false)}
                            modalOpen={modalOpen}
                            title="Do you want to move it archive?"
                            modalValuePositive={() => setModalValue(setFieldValue, 1, values)}
                            modalValueNegative={() => setModalValue(setFieldValue, 0, values)}
                          />
                      }
                      {
                        archivedModalOpen &&
                          <ArchivedModal
                            modalClose={() => setArchivedModalOpen(false)}
                            modalOpen={archivedModalOpen}
                            title="Do you want to move it to the unarchive list?"
                            modalValuePositive={() => setModalValue(setFieldValue, 0, values)}
                            modalValueNegative={() => setModalValue(setFieldValue, 1, values)}
                          />
                      }
                      <div className="form-group">
                        <label className="control-label">{`${type} Status`}</label>
                        <DropdownButton
                          disabled={viewOnly}
                          variant="light"
                          id="dropdown-basic-button"
                          title={
                            statuses.find((st) => st.value === values.details.status)?.label ||
                            "Status"
                          }
                        >
                          {[...createStatusList()].map((status, i) => (
                            <Dropdown.Item
                              key={i}
                              onClick={() => setFieldValue("details.status", status.value)}
                            >
                              {status.label}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                        {showErrorMessage('details.status')}
                      </div>

                      <div className="d-flex align-items-start form-group">
                        <label htmlFor="" className="mr-5">
                          Start Date:
                          <input
                            className="form-control"
                            placeholder="Start Date"
                            type="date"
                            name="details.start_date"
                            value={values.details.start_date}
                            onChange={handleChange}
                            disabled={viewOnly}
                          />
                          {showErrorMessage('details.start_date')}
                        </label>
                        <label htmlFor="">
                          Close Date:
                          <input
                            className="form-control"
                            placeholder="Close Date"
                            type="date"
                            name="details.end_date"
                            value={values.details.end_date}
                            min={minCloseDateFormater(values.details.start_date)}
                            onChange={handleChange}
                            disabled={viewOnly}
                          />
                          {showErrorMessage('details.end_date')}
                        </label>
                      </div>

                      <div className="form-group">
                        <label className="control-label">{`${type} Disclaimer Ar`}</label>
                        <Field
                          name="details.disclaimer_ar"
                          className="form-control"
                          component={TextArea}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">{`${type} Disclaimer En`}</label>
                        <Field
                          name="details.disclaimer_en"
                          className="form-control"
                          component={TextArea}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <FieldArray name="main_element">
                  {({remove, push, form}) => (
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h4>{`${type} Main Elements`}</h4>
                          {!viewOnly && <Button className="btn-paua" onClick={() => push({id: "", format: "", description_en: "", description_ar: "", application_requirement: [], haveFormat: true})}>
                            New Main Element
                          </Button>}
                        </div>
                        {
                          values.main_element.map((element, index) => {
                            return (
                              <div className={`border border-light rounded p-2 my-2 ${styles.element__container}`} key={index}>
                              {values.main_element.length > 1 && !viewOnly &&
                                  <span
                                    className={classNames(`font-weight-bold ${styles.image__close__elements}`)}
                                    onClick={() => {
                                      remove(index)
                                    }}
                                  >
                                    ×
                                  </span>
                                }
                                <div className="form-group">
                                  <label className="control-label">{`${type} Main Element`}</label>
                                  <Select
                                    className={`${styles.element__dropdown}`}
                                    name={`main_element[${index}].id`}
                                    valueField="id"
                                    labelField="name"
                                    values={values.main_element[index].id || []}
                                    onChange={(value) => {
                                      if(value?.length){
                                        form.setFieldValue(`main_element[${index}].haveFormat`, FORMATS.find((item, id) => item.id === value[0].id) ? true : false)
                                      }
                                      form.setFieldValue(`main_element[${index}].id`, value.map(({id, name}) => ({id, name, index})))

                                      if(value.length && values.main_element[index].format.length && data[FORMATS.find(el => el.id === value[0].id)?.data]?.filter(element => element.id === values.main_element[index].format[0].id).length &&
                                      data[FORMATS.find(el => el.id === value[0].id)?.data]?.filter(element => element.id === values.main_element[index].format[0].id)[0].id === values.main_element[index].format[0].id)
                                      {
                                        return
                                      }
                                      else if(values.main_element[index].format.length)
                                        form.setFieldValue(`main_element[${index}].format`, [])
                                    }}
                                    options={adminMainElements.filter(({is_main}) => is_main)}
                                    disabled={viewOnly}
                                  />
                                  {showErrorMessage(`main_element[${index}].id`)}
                                </div>

                              {element?.haveFormat && (<div className="form-group">
                                  <label className="control-label">{`${type} Main Element format`}</label>
                                  <Select
                                    className={`${styles.element__dropdown}`}
                                    name={`main_element[${index}].format`}
                                    valueField="id"
                                    labelField="name"
                                    onChange={(value) => form.setFieldValue(`main_element[${index}].format`, value)}
                                    values={values.main_element[index].format || []}
                                    options={ data[FORMATS.find(item => item.id === values?.main_element[index]?.id[0]?.id)?.data] || [] }
                                    disabled={viewOnly}
                                  />
                                  {showErrorMessage(`main_element[${index}].format`)}
                                </div>)}

                                <div className="form-group">
                                  <label className="control-label">{`${type} Main Element Description | Ar`}</label>
                                  <Field
                                    name={`main_element[${index}].description_ar`}
                                    className="form-control"
                                    component={TextArea}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">{`${type} Main Element Description | En`}</label>
                                  <Field
                                    name={`main_element[${index}].description_en`}
                                    className="form-control"
                                    component={TextArea}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">{`${type} Main Element Application Requirements`}</label>
                                  <Select
                                    className={`${styles.element__dropdown}`}
                                    name={`main_element[${index}].application_requirement`}
                                    valueField="id"
                                    labelField="name"
                                    multi={true}
                                    values={values.main_element[index].application_requirement}
                                    onChange={(value) => form.setFieldValue(`main_element[${index}].application_requirement`, value)}
                                    options={eventSeasonApplicationRequirements.map(item => ({
                                      ...item,
                                      name: item.name_en,
                                      hidden: item.hidden
                                    }))}
                                    contentRenderer={(innerMethods) => customItem(innerMethods, values.main_element[index].application_requirement)}
                                    disabled={viewOnly}
                                  />
                                  {showErrorMessage(`main_element[${index}].application_requirement`)}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )}
                </FieldArray>
              </section>

              <section>
                <div className="card">
                  <div className="card-body">
                    <h4>{`${type} Supporting Elements`}</h4>
                    <div className="py-2">
                    <Select
                      className={`${styles.element__dropdown}`}
                      name="supporting_elements"
                      valueField="id"
                      labelField="name"
                      multi={true}
                      values={values.supporting_elements}
                      onChange={(value) => setFieldValue("supporting_elements", value)}
                      options={adminMainElements.filter(({is_main}) => !is_main)}
                      disabled={viewOnly}
                      />
                      {showErrorMessage('supporting_elements')}
                    </div>
                  </div>
                </div>
              </section>

              {!viewOnly && <section>
                <div className="card">
                  <div className="card-body">
                    <h4>{`${type} Directors`}</h4>
                    <div className="py-2">
                      <Select
                        className={`${styles.element__dropdown}`}
                        name="directors"
                        valueField="id"
                        labelField="name"
                        multi={true}
                        searchable={true}
                        searchBy={'name'}
                        values={values.directors}
                        dropdownHeight={'100px'}
                        onChange={(value) => setFieldValue("directors", value)}
                        options={cpUsers?.data?.filter(({user_type})=>user_type===3)}
                        disabled={viewOnly}
                      />
                      {showErrorMessage('directors')}
                    </div>
                  </div>
                </div>
              </section>}

              {!viewOnly && <section>
                <div className="card">
                  <div className="card-body">
                    <h4>Customer Service Managers</h4>
                    <div className="py-2">
                      <Select
                        className={`${styles.element__dropdown}`}
                        name="reqManagers"
                        valueField="id"
                        labelField="name"
                        multi={true}
                        searchable={true}
                        searchBy={'name'}
                        values={values.reqManagers}
                        dropdownHeight={'100px'}
                        onChange={(value) => setFieldValue("reqManagers", value)}
                        options={cpUsers?.data?.filter(({user_type})=>user_type===4)}
                        disabled={viewOnly}
                      />
                      {showErrorMessage('reqManagers')}
                    </div>
                  </div>
                </div>
              </section>}

              {!viewOnly && <section className="d-flex">
                <Button className="btn-paua ml-auto" type="submit">Save</Button>
              </section>
              }
            </Form>
          )}
          </Formik>
      </div>
    </Loader>
  );
};

export default NewEventSeason;
