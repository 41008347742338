import * as ContactActions from "store/actions/contact.actions";

const initialState = {
  loaded: true,
  error: "",
  success: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ContactActions.CONTACT_US.REQUEST:
      return {
        ...state,
        loaded: false
      };

    case ContactActions.CONTACT_US.SUCCESS:
      return {
        ...state,
        loaded: true,
        success: true
      };

    case ContactActions.CONTACT_US.FAILURE:
      return {
        ...state,
        loaded: true,
        error: action.payload
      };

    case ContactActions.RESET_REDUCER:
      return initialState;

    default:
      return state;
  }
}
