import React from "react";
import Layout from "layouts/Admin";
import { SeasonsArrangement } from "components";

const AdminSeasonsArrangement = () => {
  return (
    <Layout title="Seasons Arrangement">
      <SeasonsArrangement />
    </Layout>
  );
};

export default AdminSeasonsArrangement;
