import * as Paths from "constants/paths";
import { ADMIN } from "components/protectedRoute/config";

const SUPER_ADMIN_TYPE = 1;
const ADMIN_TYPE = 2;
const DIRECTOR_TYPE = 3;
const CUSTOMER_TYPE = 4;
const LOGISTICS_TYPE = 5;

export const Links = [
  {
    name: "Statistics",
    icon: "fe-target",
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE],
    children: [
      {
        name: "Dashboard",
        url: Paths.ADMIN_DASHBOARD_STATISTICS,
        icon: ""
      },
      {
        name: "Elements Categories",
        url: Paths.ADMIN_CATEGORIES_STATISTICS,
        icon: ""
      }
    ]
  },
  {
    name: "Elements",
    icon: "fe-bookmark",
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE,DIRECTOR_TYPE],
    children: [
      {
        name: "Main Elements",
        url: Paths.MAIN_ELEMENTS,
        icon: ""
      },
      {
        name: "Supporting Elements",
        url: Paths.SUPPORTING_ELEMENTS,
        icon: ""
      }
    ]
  },
  {
    name: "Elements",
    icon: "fe-bookmark",
    fieldName: ADMIN,
    user_types: [LOGISTICS_TYPE],
    children: [
      {
        name: "Supporting Elements",
        url: Paths.SUPPORTING_ELEMENTS,
        icon: ""
      }
    ]
  },
  {
    name: "CP Users",
    icon: "fe-user-check",
    url: Paths.ADMIN_USERS,
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE]
  },
  {
    name: "Matloob Users",
    icon: "fe-user",
    url: Paths.ADMIN_MATLOOB_USERS,
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE]
  },
  {
    name: "Application Requirements",
    icon: "fe-check-square",
    url: Paths.ADMIN_APPLICATION_REQUIREMENTS,
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE]
  },
  {
    name: "Seasons",
    icon: "fe-grid",
    url: Paths.ADMIN_SEASONS,
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE],
    children: [
      {
        name: "Seasons List",
        url: Paths.ADMIN_SEASONS ,
        icon: ""
      },
      {
        name: "Seasons Arrangement",
        url: Paths.SEASONS_ARRANGEMENT,
        icon: ""
      }
    ]
  },
  {
    name: "Events",
    icon: "fe-calendar",
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE],
    children: [
      {
        name: "Events List",
        url: Paths.ADMIN_EVENTS_LIST ,
        icon: ""
      },
      {
        name: "Events Arrangement",
        url: Paths.ADMIN_EVENTS_ARRANGEMENT,
        icon: ""
      }
    ]
  },
  {
    name: "Notifications",
    icon: "fe-bell",
    fieldName: ADMIN,
    url: Paths.ADMIN_NOTIFICATIONS_HISTORY,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE]
  },
  {
    name: "Seasons List",
    icon: "fe-grid",
    fieldName: ADMIN,
    url: Paths.ADMIN_SEASONS,
    user_types: [DIRECTOR_TYPE]
  },
  {
    name: "Events List",
    icon: "fe-calendar",
    fieldName: ADMIN,
    url: Paths.ADMIN_EVENTS_LIST,
    user_types: [DIRECTOR_TYPE]
  },
  {
    name: "Requests",
    icon: "fe-file-text",
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE, DIRECTOR_TYPE, CUSTOMER_TYPE],
    children: [
      {
        name: "Seasons",
        url: Paths.SEASONS_REQUESTS ,
        icon: ""
      },
      {
        name: "Events",
        url: Paths.EVENTS_REQUESTS,
        icon: ""
      }
    ]
  },
  {
    name: "Media Center",
    icon: "fe-image",
    fieldName: ADMIN,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE],
    children: [
      {
        name: "News",
        url: Paths.ADMIN_MEDIA_CENTER_NEWS ,
        icon: ""
      },
      {
        name: "Carousel Images",
        url: Paths.ADMIN_MEDIA_CENTER_CAROUSEL_IMAGES,
        icon: ""
      },
      {
        name: "Images & Videos",
        url: Paths.ADMIN_MEDIA_CENTER_IMAGES_VIDEOS,
        icon: ""
      }
    ]
  },
  {
    name: "Reports",
    icon: "fe-flag",
    fieldName: ADMIN,
    url: Paths.ADMIN_REPORTS,
    user_types: [SUPER_ADMIN_TYPE, ADMIN_TYPE],
  },
];
