import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Modal, ModalHeader, Button } from "reactstrap";
import PropTypes from "prop-types";
import { ModalBody } from "react-bootstrap";

const DeleteModal = ({ isOpened, toggle, data, confirm, fromAdmin = false }) => {
  const { t } = useTranslation();

  const portalModal = <>
    <div className={styles.deleteModalBox}>
        <div className={styles.deleteModalBoxHeader}>
          <p>{t("deleteModal.title")}</p>
        </div>
        <div className={styles.deleteModalBoxMain}>
          <p>{t("deleteModal.question", { type: data?.element_type?.name })}</p>
        </div>
        <div className={styles.deleteModalBoxButtons}>
          <button className={styles.Cancel} onClick={toggle}>
            {t("buttons.cancel")}
          </button>
          <button className={styles.Confirm} onClick={confirm}>
            {t("buttons.confirm")}
          </button>
        </div>
      </div>
  </>

  const adminModal = <>
    <ModalHeader toggle={toggle} className="d-flex justify-content-center">
      <p className="text-center">{data}</p>
    </ModalHeader>
    <ModalBody className="d-flex justify-content-center">
      <Button className="mr-4 btn-paua" onClick={confirm}>Yes</Button>
      <Button className="ml-4 btn-gray" onClick={toggle}>No</Button>
    </ModalBody>
  </>

  return (
    <Modal
      className={`modal-dialog-centered modal-md rounded ${fromAdmin && 'adminDeleteModal'}`}
      isOpen={isOpened}
      toggle={toggle}
    >
      { fromAdmin ? adminModal : portalModal }
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    }),
    PropTypes.string
  ]),
  fromAdmin: PropTypes.bool
};

export default DeleteModal;
