import React from "react";
import PublicLayout from "layouts/Public";
import { ContactUsForm } from 'components';
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout title={t("contactUs.title")}>
      <div className="container">
        <ContactUsForm />
      </div>
    </PublicLayout>
  );
};

export default React.memo(ContactUs);
