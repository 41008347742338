import React,{useContext,useEffect} from 'react'
import PublicLayout from "layouts/Public";
import {  useParams } from "react-router-dom";
import { Context } from "context";
import { useDispatch, useSelector } from "react-redux";
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import styles from './styles.module.scss'
import {ReactComponent as Calendar} from 'assets/icons/calendar.svg'
import { getMediaCenterNewsDetails } from "store/actions/mediaCenter.actions";


const NewsDetails = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const { newsDetails } = useSelector(({ mediaCenter }) => mediaCenter);
    const { rtl } = useContext(Context);

    useEffect(() => {
        dispatch(getMediaCenterNewsDetails.request(id));
    }, [dispatch,id]);

    return (
        <PublicLayout>
            <div className='container'>
                <div className={styles.newsDetailsWrapper}>
                <div className={styles.infoSection}>
                    <div className={styles.title}>{newsDetails.title}</div>
                    <div className={styles.data}><Calendar/>
                        {new Date(newsDetails.created_date).toLocaleString(
                        `${localStorage.getItem( "i18nextLng") === "en" ? "en-GB" : "ar-SA"}`,
                        {month: "long", day: "numeric"})}
                    </div>
                </div>
                    <div>
                    <Swiper
                        modules={[Mousewheel]}
                        spaceBetween={150}
                        slidesPerView='auto'
                        breakpoints={{
                            576: {
                                slidesPerView: '1.5',
                            },
                            768: {
                                slidesPerView: 1.5,
                                spaceBetween: 80
                            },
                            900:{
                                slidesPerView: '1.5',
                                spaceBetween: 100
                            },
                            1200:{
                                slidesPerView: '1.5',
                                spaceBetween: 100
                            },
                            1400:{
                                slidesPerView: 'auto',
                                spaceBetween: 130
                            },
                        }}
                        centeredSlides
                        roundLengths
                        mousewheel
                        loop={newsDetails?.images?.length === 1 ? false : true}
                        loopAdditionalSlides={30}
                        loopedSlides={30}
                        dir={rtl ? 'rtl' : 'ltr'}
                    >
                        {newsDetails?.images?.map((news, index) => (
                            <div key={news.id}>
                            <SwiperSlide
                                key={news.id}
                                virtualIndex={index}
                                className={styles.swiperSlide}
                            >   
                                <div className={styles.imageWrapper}>
                                    <img src={news.image} alt='test' className={styles.image}/>
                                </div>
                                <div className={styles.text}>{news.description}</div>
                            </SwiperSlide>
                            </div>
                    ))}
                    </Swiper>
                    </div>
                </div>
            </div>
        </PublicLayout>
    )
}

export default NewsDetails