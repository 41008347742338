import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serialize } from "object-to-formdata";
import { Button } from "react-bootstrap";
import { Context } from "context";
import { DragDropFile, Loader, AlertBox } from "components";
import { getAdminMediaCenterCarouselImagesDetails, addMediaCenterNewCarouselImage, editMediaCenterCarouselImage } from "store/actions/adminElements.actions";
import styles from './styles.module.scss'

const MediaCenterAddEditCarouselImage = ({ id }) => {
    const { mediaCenterCarouselImagesDetails, loaded, error } = useSelector(
        ({ adminElements }) => adminElements
    );
    const isAlertActive = useSelector(({ alert }) => alert);
    const { user } = useContext(Context);
    const viewOnly = user?.user_type === 3;

    const dispatch = useDispatch();


    useEffect(() => {
        if (id) {
            dispatch(getAdminMediaCenterCarouselImagesDetails.request({ id }));
        }
    }, [dispatch, id]);

    const showErrorMessage = (name) => (
        <ErrorMessage
            name={name}
            render={(msg) => (
                <span className="text-danger font-weight-normal">{msg}</span>
            )}
        />
    );
    const ImageInput = ({ field, form }) => {
        return (
            <>
                <DragDropFile field={field} form={form} value={form.values.media_center_carousel_image} viewOnly={viewOnly}/>
                {showErrorMessage(field.name)}
            </>
            )
    }
    const submitForm = (values) => {
        const imageWithMainImage = {media_center_carousel_image: values.media_center_carousel_image};
        if(!id){
            dispatch(addMediaCenterNewCarouselImage.request(serialize({...imageWithMainImage}, { indices: true })));
        }
        if(id){
            dispatch(editMediaCenterCarouselImage.request({id, data:serialize({...imageWithMainImage, _method: 'PUT'}, { indices: true })}));
        }
    };

    return (
        <Loader loaded={loaded}>
            {isAlertActive && (
                <AlertBox error={error} additionalStyles="alert-danger" />
            )}
            <div className="pb-4">
                <Formik
                    initialValues={{
                        media_center_carousel_image: id ? mediaCenterCarouselImagesDetails?.image : "" ,
                    }}
                    enableReinitialize={true}
                    validationSchema={ 
                        Yup.object().shape({
                            media_center_carousel_image:Yup.string().required('Required')
                        })
                    }
                    onSubmit={(values) => submitForm(values, true)}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <section>
                                <div className="card">
                                <div className="card-body">
                                    <h4>Carousel Image</h4>
                                    <code>Image Type: JPG or PNG | Image Size: 1920px x 1080px</code>
                                    <div className="d-flex flex-column px-3 pb-3">
                                    <Field name="media_center_carousel_image" component={ImageInput}/>
                                    </div>
                                </div>
                                </div>
                            </section>
                            {!viewOnly && (
                                <div className={styles.buttonSection}>
                                    <section className="d-flex">
                                        <Button
                                            className="btn-paua ml-auto"
                                            type="submit"
                                        >
                                            {id ? 'Edit' : 'Save'}
                                        </Button>
                                    </section>
                                </div>
                            )}
                                {id && (
                                    <section>
                                        <div className="card">
                                            <div className="card-body">
                                            <label htmlFor="created_at">Creation Date</label>
                                                <input
                                                type="text"
                                                className="form-control"
                                                id="created_ats"
                                                name="created_at"
                                                placeholder="Creation Date"
                                                disabled={true}
                                                value={new Date(mediaCenterCarouselImagesDetails?.created_at).toLocaleString(
                                                    {
                                                        month: "long",
                                                        day: "numeric",
                                                        hour: "2-digit",
                                                        hour12: true,
                                                        minute: "2-digit",
                                                    }
                                                )}
                                                />
                                                <label htmlFor="updated_at">Update Date</label>
                                                <input
                                                type="text"
                                                className="form-control"
                                                id="updated_at"
                                                name="updated_at"
                                                placeholder="Update Date"
                                                disabled={true}
                                                value={new Date(mediaCenterCarouselImagesDetails?.updated_at).toLocaleString(
                                                    {
                                                        month: "long",
                                                        day: "numeric",
                                                        hour: "2-digit",
                                                        hour12: true,
                                                        minute: "2-digit",
                                                    }
                                                )}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                )}
                        </Form>
                    )}
                </Formik>
            </div>
        </Loader>
    );
};

export default MediaCenterAddEditCarouselImage;
