export const incorrectPassword =
  "Password should consist of 8 characters with at least 1 number , 1 special character, 1 upper case letter, and 1 lower case letter";
export const incorrectEmail = "Email format is not correct";
export const resetLink = "We have sent you an email with password reset link";
export const passwordRecovered = "Updated successfully";
export const wrongCredentials = "Wrong Mobile Number or Password";
export const emailNotFound = "Email is not registered ";
export const invalidPhone = "Mobile format is not corred (Ex. 5xxxxxxxx)";
export const updated = "Updated successfully";
export const invalidOldPassword = "Old password is not correct";
export const resendIn = "Resend in";
export const resend = "Resend";
export const invalidCode = "Invalid code";
export const enterSentCode = "Please enter the code sent to you";
export const enterCode = "Enter code";
export const confirmCode = "Confirm";
export const confirmDelete = "Are you sure you want to delete?"
export const otpIsNotCorrect = "Verification code is not correct ";
export const otpIsTimeOut = "Verification code is timeout";
export const sentSuccessfully = "Sent Successfully"
export const invalidPasswordLink = "It looks like you clicked on an invalid password reset link. Please try again"
export const requiredElementType = "Element Type is required to export the request list"