import Restaurant from "assets/icons/restaurant.svg";
import Events from "assets/icons/events.svg";
import Sites from "assets/icons/sites.svg";
import Stores from "assets/icons/stores.svg";
import Services from "assets/icons/supportService.svg";
import Talent from "assets/icons/talent.svg";
import Work from "assets/icons/temporaryWork.svg";

export const paths = {
  "97ad819d-8361-445d-b535-298c4616a0d0": {
    icon: Events,
    type: "events"
  },
  "42fb5884-5a36-48e0-b7b8-5a96acc4f15a": {
    icon: Restaurant,
    type: "restaurants"
  },
  "a0558093-1d16-4bc6-a4ad-ad7aa9994198": {
    icon: Stores,
    type: "stores"
  },
  "11c772a5-b90b-4db2-a55c-8075c5ad232a": {
    icon: Sites,
    type: "venues"
  },
  "d77bfbcc-434f-4c57-ae10-76f362322429": {
    icon: Services,
    type: "logistics"
  },
  "0472cebe-9923-497b-9a64-09d3c2bbb943": {
    icon: Talent,
    type: "talents"
  },
  "66b1946e-e4c4-4254-8ce3-fdcb0b0726f3": {
    icon: Work,
    type: "temporary-works"
  }
};
