import React from "react";
import Layout from "layouts/Admin";
import { EventsList } from "components";

const AdminEventsList = () => {
  return (
    <Layout title="Events List">
      <EventsList />
    </Layout>
  );
};

export default AdminEventsList;
