import React, { useEffect, useState, useContext } from "react";
import ProfileLayout from "layouts/Profile";
import { PageTitle, Loader, ProfilePagination } from "components";
import { Link } from "react-router-dom";
import { getUserRequests } from "store/actions/requests.actions";
import { useDispatch, useSelector } from "react-redux";
import {requestHeadings} from './constants';
import { SAUDI_SEASON_EVENT_REVIEW } from "constants/paths";
import { statuses, REVIEW, SEASON } from "constants/seasonsEvents";
import { compile } from "path-to-regexp";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/helpers";
import styles from "./styles.module.scss";
import { Context } from "context";
import turquoiseStar from "../../assets/icons/turquoiseStar.svg";
import purpleStar from "../../assets/icons/purpleStar.svg";
import {
    useMediaQuery,
} from "utils/hooks";
import RequestAccordion from './RequestAccordion/RequestAccordion'

const ITEMS_PER_PAGE = 10;

const MyRequests = () => {
    const isMobile = useMediaQuery("(max-width: 500px)");
    const { t } = useTranslation();
    const { rtl } = useContext(Context);
    const dispatch = useDispatch();
    const {
        userRequests,
        userElementTypes,
        loaded,
        current_page,
        last_page,
        total,
    } = useSelector(({ requests }) => requests);
    const [currentPage, setCurrentPage] = useState(current_page);

    useEffect(() => {
        dispatch(
            getUserRequests.request({
                page: currentPage,
                perPage: ITEMS_PER_PAGE,
            })
        );
    }, [dispatch, currentPage]);

    const getElementTypeName = (id) => {
        return userElementTypes.find((element) => element.id === id)?.name;
    };

    const storeRequest = (reqId) => {
        localStorage.setItem(REVIEW, reqId);
    };

    const requestsListDesktop = () => {
        return(
            <div className="table-responsive">
                <div className={styles.requestsTable}>
                    <table className="table table-centered table-borderless mb-0">
                        <thead>
                        <tr>
                            {requestHeadings.map(({ value }, index) =>
                                <th key={index}>{t(`interests.${value}`)}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                            {userRequests?.map((request, i) => (
                            <tr key={i}>
                            {requestHeadings.map(({ key }) => {
                            switch (key) {
                                case "request_number":
                                    return (
                                        <td
                                        className={styles.interestTableUnderlined}
                                        key={key}
                                        >
                                            <Link to={compile(SAUDI_SEASON_EVENT_REVIEW)({
                                                    type: SEASON,
                                                    id: request.id,
                                                    action: REVIEW,
                                                })}>
                                                #{request[key]}
                                            </Link>
                                        </td>
                                    );
                                case "season":
                                    return (
                                        <td
                                        key={key}
                                        >
                                            {request.event ? request.event?.name : request.season?.name}
                                        </td>
                                    );
                                case "status":
                                    return (
                                    <td
                                        className={styles[statuses[request.status]]}
                                        key={key}
                                    >
                                    {t(`requests.statuses.${request.status}`)}
                                    {request.other_status && request.status === 4
                                        ? ` - ${request.other_status}`
                                        : ""}
                                    </td>
                                    );
                                case "element":
                                    return (
                                    <td
                                        className={styles[statuses[request.element]]}
                                        key={key}
                                    >
                                        {getElementTypeName(request.element_type_id)}
                                    </td>
                                    );
                                case "created_at":
                                    return (
                                        <td key={key}>{dateFormatter(rtl, request[key])}</td>
                                    );
                                case "request_details":
                                    return (
                                        <td key={key}>
                                        <Link  to={compile(SAUDI_SEASON_EVENT_REVIEW)({
                                                    type: SEASON,
                                                    id: request.id,
                                                    action: REVIEW,
                                                })}>
                                        <span className={styles.requestDetails} onClick={() => storeRequest(request.id)}>
                                            <i className="mdi mdi-eye"></i>
                                        </span>
                                        </Link>
                                        </td>
                                    );
                                default:
                                    return null;
                                }
                                })} 
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    };

    const requestsListMobile = () =>{
        return(
        <div className={styles.mobileRequestTableWrapper}>
        {userRequests?.map((request, i) => {
            return (<RequestAccordion request={request} key={i} userElementTypes={userElementTypes} rtl={rtl}/>)
        })}
        </div>
        )
}

    const emptyRequestsList = () => (
        <div className={styles.emptyList}>
            <img src={turquoiseStar} alt="" className={styles.turquoiseStar} />
            <img src={purpleStar} alt="" className={styles.purpleStar} />
        </div>
    );

    return (
        <ProfileLayout
            backgroundVariant={userRequests.length ? null : "StarBackground"}
        >
            <Loader loaded={loaded}>
                <div className={styles.myRequestsTitle}>
                    <PageTitle title={t("requests.title")} />
                    {!userRequests.length && (
                        <p className={styles.subtitle}>{t("requests.empty")}</p>
                    )}
                </div>
                {loaded && (
                    <section className={styles.myRequestsList}>
                        {userRequests.length
                            ? isMobile ? requestsListMobile() : requestsListDesktop()
                            : emptyRequestsList()}
                    </section>
                )}
                {total > ITEMS_PER_PAGE ? (
                    <ProfilePagination
                        currentPage={currentPage}
                        total={last_page}
                        handleClick={setCurrentPage}
                    />
                ) : null}
            </Loader>
        </ProfileLayout>
    );
};

export default MyRequests;
