import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Loader,
  Select,
  AlertBox
} from "components";
import { getNotificationData, createNotification } from "store/actions/adminElements.actions";
import * as Yup from "yup";
import { Dropdown, DropdownButton} from "react-bootstrap";
import { Button, ModalFooter } from "reactstrap";
import { useError, useFieldTouched } from "utils/forms";
import { sentSuccessfully } from "constants/messages";

const typeOptions = [
  { name: "All Users", value: "all" },
  { name: "Events", value: "event" },
  { name: "Seasons", value: "season" },
 ]

const sendOptions = [
  {name: "Profile", id: 'profile'},
  {name: "Email", id: 'email'},
  {name: "SMS", id:'sms'}
]

const CreateNotification = () => {
  const { seasonsArrangement, eventsArrangement, success, adminMainElements, loaded, error } = useSelector(({ adminElements }) => adminElements )
  const isAlertActive =  useSelector(({ alert }) => alert);
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      type: "",
      element_type: [],
      message_en: "",
      message_ar: "",
      season: "",
      event: "",
      transport: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required("Required"),
      message_en: Yup.string().required("Required"),
      message_ar: Yup.string(),
      transport: Yup.array().required("Required"),
      season: Yup.string().when("type", {
        is: () => formik.values.type?.value === "season",
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),
      event: Yup.string().when("type", {
        is: () => formik.values.type?.value === "event",
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      })
    }),
    onSubmit: (data) => {
      const notificationData = {
        recipient: data?.type?.value,
        message_en: data?.message_en,
        message_ar: data?.message_ar,
        transport: data?.transport,
        element_type_ids: data?.element_type,
        season_id: data?.season?.id,
        event_id: data?.event?.id,
      }

      const filteredNotificationData = Object.keys(notificationData).reduce((acc, key) => {
        if(notificationData[key]?.length){
          return {...acc, [key]: notificationData[key]}
        }
        return acc
      }, {})

      dispatch(createNotification.request(filteredNotificationData))
    }
  });

  useEffect(() => {
    dispatch(getNotificationData.request())
  }, [dispatch])
  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  const adminMainElementsMultiple = adminMainElements?.map(el => ({ name: el.name, id: el.id}))

  const profile = formik.values.transport.includes('profile')

  return (
    <Loader loaded={loaded}>
      {
        isAlertActive &&
        <AlertBox
            error={error}
            additionalStyles='alert-danger'
        />
      }
      {
        success &&
        <AlertBox
          message={sentSuccessfully}
          additionalStyles='alert-success'
        />
      }
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">

                <div className="form-group">
                  <label htmlFor="type" className="control-label">
                    Type
                  </label>
                  <DropdownButton
                    variant="light"
                    id="dropdown-basic-button"
                    title={formik.values?.type?.name || "Select Type"}
                  >
                    {typeOptions.map(element => (
                      <Dropdown.Item
                        key={element.value}
                        onClick={() => {
                          formik.setFieldValue("type", element)
                          formik.setFieldValue("season", '')
                          formik.setFieldValue("event", '')
                        }}
                      >
                        {element.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {getError("type")}
                </div>

                <div className="form-group">
                  <label htmlFor="transport" className="control-label">
                    Way To Send
                  </label>
                  <Select
                    formik={formik}
                    name="transport"
                    data={sendOptions}
                    multiple={true}
                    placeholder="Select..."
                    value={formik.values.transport || ""}
                  />
                  {getError("transport")}
                </div>

                <div className="form-group">
                  <label htmlFor="message_en" className="control-label">
                    {profile ? 'Message | En' : "Message"}
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="message_en"
                    name="message_en"
                    placeholder={profile ? 'Message | En' : "Message"}
                    onChange={formik.handleChange}
                    value={formik.values.message_en || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("message_en")}
                </div>

                {profile &&
                <div className="form-group">
                  <label htmlFor="message_ar" className="control-label">
                    Message | Ar
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="message_ar"
                    name="message_ar"
                    placeholder={'Message | Ar'}
                    onChange={formik.handleChange}
                    value={formik.values.message_ar || ""}
                    onFocus={setFieldTouched}
                  />
                  {getError("message_ar")}
              </div>
                }

                {formik.values.type?.value !== "event" &&
                  <div className="form-group">
                    <label htmlFor="season" className="control-label">
                      Seasons
                    </label>
                    <DropdownButton
                      variant="light"
                      id="dropdown-basic-button"
                      title={formik.values?.season?.name || "Select Season"}
                    >
                      {seasonsArrangement.map(element => (
                        <Dropdown.Item
                          key={element.id}
                          onClick={() => formik.setFieldValue("season", element)}
                        >
                          {element.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    {getError("season")}
                  </div>
                }

                {formik.values.type?.value !== "season" &&
                  <div className="form-group">
                    <label htmlFor="event_id" className="control-label">
                      Event
                    </label>
                    <DropdownButton
                      variant="light"
                      id="dropdown-basic-button"
                      title={formik.values?.event?.name || "Select Event"}
                    >
                      {eventsArrangement.map(element => (
                        <Dropdown.Item
                          key={element.id}
                          onClick={() => formik.setFieldValue("event", element)}
                        >
                          {element.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    {getError("event")}
                  </div>
                }

                <div className="form-group">
                  <label htmlFor="transport" className="control-label">
                   Element Type
                  </label>
                  <Select
                    formik={formik}
                    name="element_type"
                    data={adminMainElementsMultiple}
                    multiple={true}
                    value={formik?.values?.element_type || ""}
                    placeholder="Select..."
                  />
                  {getError("element_type")}
                </div>

              </div>
            </div>
            <ModalFooter>
              <Button type="submit" className="btn-paua">
                Send
              </Button>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Loader>
  )
}

export default CreateNotification