import React from "react";

export const useError = formik => name =>
  formik.errors[name] !== "." && formik.errors[name] && formik.touched[name] ? (
    <p className="text-danger form-field-error">{formik.errors[name]?.name || formik.errors[name]}</p>
  ) : null;

export const useFieldTouched = formik => ({ target: { name } }) =>
  formik.setFieldTouched(name, true);

