import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { ElementsApi } from "services/elements.service";
import { AdminElementsApi } from "services/adminElements.service";
import {
  GET_INTERESTS,
  CREATE_INTEREST,
  EDIT_INTEREST,
  GET_INTEREST,
  GET_REGIONS,
  GET_CITIES,
  DELETE_INTEREST,
  GET_ADMIN_MAIN_ELEMENTS,
  GET_ADMIN_SUPPORTING_ELEMENTS,
  GET_DASHBOARD_ELEMENTS_REQUEST,
  getInterests,
  createInterest,
  editInterest,
  getInterest,
  getRegions,
  getCities,
  deleteInterest,
  getAdminMainElements,
  getAdminSupportingElements,
  getDashboardElementsRequest
} from "store/actions/elements.actions";
import history from "../../history";
import * as Paths from "constants/paths";
import { compile } from "path-to-regexp";
import { TYPES } from "components/Accordion/contants";
import { vsCategory, vsTopography, vsLandTypes } from "utils/helpers";

function* getInterestsWorker({ payload }) {
  try {
    const [interests, elements, serviceCategories, venueSiteCategories] = yield all([
      call(ElementsApi.getInterests, payload),
      call(ElementsApi.getElements),
      call(ElementsApi.getServiceCategories),
      call(ElementsApi.getVenueSiteCategories),
    ]);
    const {
      data: {
        data: interest_data,
        current_page,
        last_page,
        total
      }
    } = interests;
    yield put(
      getInterests.success({
        interests: interest_data,
        userElementTypes: elements.data.userElementTypes,
        serviceCategories: serviceCategories.data.serviceCategories,
        venueSiteCategories: venueSiteCategories.data[vsCategory],
        current_page,
        last_page,
        total
      })
    );
  } catch (e) {
    yield put(getInterests.failure(e.data));
  }
}

function* createInterestsWorker({ payload }) {
  try {
    const { interests } = yield select((state) => state.elements);
    const { data } = yield call(ElementsApi.createInterest, payload);
    history.push(compile(Paths.EDIT_INTEREST)({ id: data.id, type: payload }));
    yield put(
      createInterest.success({
        newInterestId: data.id,
        interests: [...interests, data],
      })
    );
  } catch (e) {
    yield put(createInterest.failure(e.data));
  }
}

function* editInterestWorker({ payload }) {
  try {
    const obj = payload.data;

    if (payload.type === TYPES.talents) {
      const { interest } = yield select((state) => state.elements);
      const personal_info = interest?.talent_detail?.personal_info;
      const new_personal_info = payload?.data?.talent_detail?.personal_info;

      if (personal_info?.length > new_personal_info?.length) {
        const arr = personal_info.slice(new_personal_info.length);
        yield all(
          arr.map((member) => call(ElementsApi.deleteMember, member.id))
        );
      } else if (personal_info?.length < new_personal_info?.length) {
        const count = new_personal_info?.length - personal_info?.length;
        for (let i = 0; i < count; i++) {
          const { data } = yield call(ElementsApi.createMember, payload.id);
          personal_info.push(data);
        }
        obj.talent_detail.personal_info = personal_info.map((info, index) => ({
          ...info,
          ...new_personal_info[index],
          id: info.id
        }));
      }
    }

    const { data } = yield call(ElementsApi.editInterest, {
      ...payload,
      data: obj,
    });
    yield put(editInterest.success(data.status));
    yield history.push(Paths.INTERESTS);
  } catch (e) {
    yield put(editInterest.failure(e.data));
  }
}

function* getInterestWorker({ payload }) {

  try {
    const [
      disabilityTypes,
      interest,
      countries,
      categories,
      regions,
      languages,
      eventCategories,
      serviceCategories,
      storeFormats,
      productCategories,
      foodCategories,
      restaurantFormats,
      venueSiteCategories,
      venueSiteTopographies,
      venueSiteLandTypes,
      temporaryWorksCategories
    ] = yield all([
      call(ElementsApi.getDisabiltyTypes),
      call(ElementsApi.getInterest, payload),
      call(ElementsApi.getCountries),
      call(ElementsApi.getTalentCategories),
      call(ElementsApi.getRegions),
      call(ElementsApi.getLanguages),
      call(ElementsApi.getEventCategories),
      call(ElementsApi.getServiceCategories),
      call(ElementsApi.getStoreFormats),
      call(ElementsApi.getProductCategories),
      call(ElementsApi.getFoodCategories),
      call(ElementsApi.getRestaurantFormats),
      call(ElementsApi.getVenueSiteCategories),
      call(ElementsApi.getVenueSiteTopographies),
      call(ElementsApi.getVenueSiteLandTypes),
      call(ElementsApi.getTemporaryWorkCaterogries)
    ]);
    yield put(
      getInterest.success({
        disabilityTypes: disabilityTypes.data.disabilityTypes,
        interest: interest.data,
        countries: countries.data.countriesOfResidence,
        talentCategories: categories.data.talentCategories,
        regions: regions.data.saudiRegions,
        languages: languages.data.languages,
        eventCategories: eventCategories.data.eventCategories,
        serviceCategories: serviceCategories.data.serviceCategories,
        storeFormats: storeFormats.data.storeFormats,
        productCategories: productCategories.data.storeProductCategories,
        foodCategories: foodCategories.data.foodCategories,
        restaurantFormats: restaurantFormats.data.restaurantFormats,
        venueSiteCategories: venueSiteCategories.data[vsCategory],
        venueSiteTopographies: venueSiteTopographies.data[vsTopography],
        venueSiteLandTypes: venueSiteLandTypes.data[vsLandTypes],
        temporaryWorksCategories: temporaryWorksCategories.data.temporaryWorksCategories
      })
    );
  } catch (e) {
    yield put(getInterest.failure(e.data));
  }
}

function* getRegionsWorker() {
  try {
    const { data } = yield call(ElementsApi.getRegions);
    yield put(getRegions.success(data.saudiRegions));
  } catch (e) {
    yield put(getRegions.failure(e.data));
  }
}

function* getCitiesWorker({ payload }) {
  try {
    const { data } = yield call(ElementsApi.getCities, payload);
    yield put(getCities.success(data.cities));
  } catch (e) {
    yield put(getCities.failure(e.data));
  }
}

function* deleteInterestWorker({ payload }) {
  try {
    yield call(ElementsApi.deleteInterest, payload);
    const { interests } = yield select((state) => state.elements);
    const filteredInterest = interests.filter(({ id }) => id !== payload.id);
    history.replace(Paths.INTERESTS);
    yield put(deleteInterest.success(filteredInterest));
  } catch (e) {
    yield put(deleteInterest.failure(e.data));
  }
}

function* getAdminMainElementsWorker() {
  try {
    const [
      countries,
      categories,
      temporaryWorksCategories,
      regions,
      productCategories,
      foodCategories,
      languages,
    ] = yield all([
      call(ElementsApi.getCountries),
      call(ElementsApi.getTalentCategories),
      call(ElementsApi.getTemporaryWorkCategories),
      call(ElementsApi.getRegions),
      call(ElementsApi.getProductCategories),
      call(ElementsApi.getFoodCategories),
      call(ElementsApi.getLanguages),
    ]);

    yield put(
      getAdminMainElements.success({
        countries: countries.data.countriesOfResidence,
        talentCategories: categories.data.talentCategories,
        temporaryWorksCategories: temporaryWorksCategories.data.temporaryWorksCategories,
        regions: regions.data.saudiRegions,
        productCategories: productCategories.data.storeProductCategories,
        foodCategories: foodCategories.data.foodCategories,
        languages: languages.data.languages,
      })
    );
  } catch (e) {
    yield put(getAdminMainElements.failure(e.data));
  }
}

function* getAdminSupportingElementsWorker() {
  try {
    const [
      regions,
      venueCategories,
      venueTopographies,
      venueLandTypes,
      cities,
      eventCategories,
      countries,
      serviceCategories
    ] = yield all([
      call(ElementsApi.getRegions),
      call(AdminElementsApi.getVenueCategories),
      call(AdminElementsApi.getVenueTopographies),
      call(AdminElementsApi.getVenueLandTypes),
      call(ElementsApi.getCities),
      call(ElementsApi.getEventCategories),
      call(ElementsApi.getCountries),
      call(ElementsApi.getServiceCategories),
    ]);

    yield put(
      getAdminSupportingElements.success({
        regions: regions.data.saudiRegions,
        venueCategories: venueCategories.data.venueCategories,
        venueTopographies: venueTopographies.data.venueTopographies,
        venueLandTypes: venueLandTypes.data.venueLandTypes,
        cities: cities.data.cities,
        eventCategories: eventCategories.data.eventCategories,
        countries: countries.data.countriesOfResidence,
        serviceCategories: serviceCategories.data.serviceCategories
      })
    );
  } catch (e) {
    yield put(getAdminSupportingElements.failure(e.data));
  }
}

function* getDashboardElementsRequestWorker(){
  try{
    const { data } = yield call(ElementsApi.getDashboardElementsRequest);
    yield put(getDashboardElementsRequest.success(data))

  } catch(e){
    yield put(getDashboardElementsRequest.failure(e.data));
  }
}

function* elementsSaga() {
  yield takeLatest(GET_INTERESTS.REQUEST, getInterestsWorker);
  yield takeLatest(CREATE_INTEREST.REQUEST, createInterestsWorker);
  yield takeLatest(EDIT_INTEREST.REQUEST, editInterestWorker);
  yield takeLatest(GET_REGIONS.REQUEST, getRegionsWorker);
  yield takeLatest(GET_INTEREST.REQUEST, getInterestWorker);
  yield takeLatest(GET_CITIES.REQUEST, getCitiesWorker);
  yield takeLatest(DELETE_INTEREST.REQUEST, deleteInterestWorker);
  yield takeLatest(GET_ADMIN_MAIN_ELEMENTS.REQUEST, getAdminMainElementsWorker);
  yield takeLatest(
    GET_ADMIN_SUPPORTING_ELEMENTS.REQUEST,
    getAdminSupportingElementsWorker
  );
  yield takeLatest(GET_DASHBOARD_ELEMENTS_REQUEST.REQUEST, getDashboardElementsRequestWorker);
}

export default elementsSaga;