import React,{useState} from 'react'
import ReactPlayer from 'react-player';
import {ReactComponent as Calendar} from 'assets/icons/calendar.svg'
import VideoButton from "assets/images/loginImg/video-play-button.png";
import styles from './styles.module.scss'

const PhotoVideoCard = ({item,cardImage,video,openDetails}) => {
    const [isPause, setIsPause] = useState(true);
    const [isStarted, setIsStarted] = useState(false);
    const handlePlay = () => {
        setIsPause(false);
        setIsStarted(true);
    };

        return (
            <div className={styles.photoVideoCard} onClick={()=>openDetails(item.id)}>
                {!video ? <img src={cardImage} className={styles.image} alt='test'/>  : 
                    <div className={styles.video}>
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            url={item.video}
                            volume={0.1}
                            controls={isStarted}
                            onPlay={() => setIsPause(false)}
                            onPause={() => setIsPause(true)}
                            playing={!isPause}
                            />
                            {isPause ? (
                                    <button
                                        className={styles.videoSectionButton}
                                        onClick={handlePlay}
                                    >
                                        <img
                                            src={VideoButton}
                                            alt="play-button"
                                        />
                                    </button>
                                ) : null}
                    </div>
                }
                {!video ?  
                <div className={styles.cardSection}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.data}><Calendar/>{new Date(item.created_at).toLocaleString(
                                        `${localStorage.getItem("i18nextLng") === "en"? "en-GB" : "ar-SA"}`,
                                        {month: "long", day: "numeric"}
                                    )}</div>
                </div>
                    :null
                }
            </div>
        )
}

export default PhotoVideoCard;