import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toggleMenu } from "store/actions/auth.actions";
import { resetState } from "store/actions/ideas.actions";
import classnames from "classnames";
import styles from "./styles.module.scss";

const MenuItem = ({ to, title, className, open, action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const isMenuItemActive = to
    ? window.location.pathname.split("/")[1] === to.split("/")[1]
    : "";
  const toggleHover = () => setHover(!hover);
  const handleClick = () => {
    dispatch(resetState());
    dispatch(toggleMenu());
  };

  if (open) {
    return (
      <span
        className={styles.link}
        onClick={() => {
          open();
          handleClick();
        }}
      >
        <p
          className={classnames(styles.menuItem, className, {
            [styles.menuItemActive]: isMenuItemActive,
          })}
        >
          {t(title)}
        </p>
      </span>
    );
  }

  return (
    <Link
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      to={to || ""}
      onClick={() => {
        handleClick();
        !to && action && action();
      }}
    >
      <p
        className={classnames(styles.menuItem, className, {
          [styles.menuItemActive]: isMenuItemActive,
        })}
      >
        {t(title)}
      </p>
    </Link>
  );
};

MenuItem.propType = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default MenuItem;
