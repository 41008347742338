import React, { useEffect } from "react";
import Logo from "assets/images/new-logo.svg";
import {Link} from 'react-router-dom';
import { Loader, AlertBox } from "components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError, useFieldTouched } from "utils/forms";
import { passwordExp } from "utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { incorrectPassword, passwordRecovered } from "constants/messages";
import { resetPassword, resetAuthState, invalidTokenRedirect } from "store/actions/auth.actions";
import queryString from "query-string";
import {ADMIN_LOGIN} from "constants/paths";
import styles from "./styles.module.scss";

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const { token, email } = queryString.parse(window.location.search);

  const { loaded, loginError: error, success } = useSelector(
    state => state.auth
  );
  const isAlertActive =  useSelector(({ alert }) => alert);

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .matches(passwordExp, incorrectPassword)
        .required("Required"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required")
    }),
    onSubmit: values =>
      dispatch(resetPassword.request({ ...values, token, email }))
  });

  const getError = useError(formik);
  const setFieldTouched = useFieldTouched(formik);

  useEffect(() => {
    return () => dispatch(resetAuthState());
  }, [dispatch]);

  useEffect(() => {
    if(window.location.href.includes('tokeninvalid')){
      dispatch(invalidTokenRedirect.request())
    }
  },[dispatch])

  return (
    <div className={styles.resetPasswordForm}>
      <Loader loaded={loaded}>
        <Link to={ADMIN_LOGIN} className={styles.resetPasswordFormHeader}>
          <img src={Logo} alt="" />
          <p>Reset Password</p>
        </Link>
        {
          isAlertActive &&
          <AlertBox
            error={error}
            additionalStyles='alert-danger mt-4'
          />
        }

        {
          success &&
          <AlertBox
            message={passwordRecovered}
            additionalStyles='alert-success text-left'
            link={ADMIN_LOGIN}
          />
        }

        <div className={styles.resetPasswordFormMain}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formGroup}>
              <label forHtml="password">New Password</label>
              <input
                id="password"
                type="password"
                className={`form-control text-left ${styles.defaultInput}`}
                name="password"
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("password")}
            </div>

            <div className={styles.formGroup}>
              <label forHtml="password_confirmation">Repeat Password</label>
              <input
                id="password_confirmation"
                type="password"
                className={`form-control text-left ${styles.defaultInput}`}
                name="password_confirmation"
                onChange={formik.handleChange}
                onFocus={setFieldTouched}
              />
              {getError("password_confirmation")}
            </div>

            <button type="submit" className={styles.submitBtn}>
              Reset
            </button>
          </form>
        </div>
      </Loader>
    </div>
  );
};

export default ResetPasswordForm;
