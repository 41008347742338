import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  resendIn,
  resend,
  enterCode,
  invalidCode,
  enterSentCode,
  confirmCode,
} from "constants/messages";
import { ADMIN } from "components/protectedRoute/config";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  confirmSignup,
  login,
  getMobileOTP,
  toggleOtpModal,
  toggleSignUpModal,
  signUp,
  loginAdmin,
} from "store/actions/auth.actions";
import { OTPinput, AlertBox } from "components";

const CODE_LENGTH = 4;
const MIN_SECONDS_COUNT = 10;
const INITIAL_MINUTES = 1;
const INITIAL_SECONDS = 59;
const CONFIRM = "confirm";
const EXPIRATION_TIME = 1000 * 60 * 2;

function OTPmodal({ data = {}, forLogin = false, forAdmin = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    otpError: error,
    openOtpModal,
    tmpUserData,
    loaded,
  } = useSelector((state) => state.auth);
  const isAlertActive = useSelector(({ alert }) => alert);

  const action = localStorage.getItem("action");
  const target = JSON.parse(localStorage.getItem("target") || "{}");
  const otp_expiration = localStorage.getItem("otp_expiration");

  const [mins, setMins] = useState(INITIAL_MINUTES);
  const [seconds, setSeconds] = useState(INITIAL_SECONDS);
  const [timer, setTimer] = useState(true);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string()
        .test(
          "len",
          forAdmin ? enterSentCode : t("messages.enterCode"),
          (val) => val?.length === CODE_LENGTH
        )
        .typeError(forAdmin ? invalidCode : t("messages.invalidCode"))
        .required(forAdmin ? "Required" : t("messages.required")),
    }),
    onSubmit: ({ code }) => {
      if (forAdmin) {
        dispatch(loginAdmin.request({ ...target, otp_password: code }));
      } else if (action === CONFIRM) {
        dispatch(confirmSignup.request({ ...tmpUserData, otp: code }));
      } else {
        dispatch(login.request({ ...target, password: code }));
      }
    },
  });

  const tick = () => {
    const currentTime = new Date().getTime();
    if (otp_expiration > currentTime) {
      const date = new Date(otp_expiration - currentTime);
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      setSeconds(seconds);
      setMins(minutes);
    } else {
      clearInterval(timer);
      setTimer(null);
    }
  };

  const startTimer = () => {
    if (openOtpModal) {
      const interval = setInterval(tick, 1000);
      setTimer(interval);
      return () => clearInterval(interval);
    }
  };

  const toggle = () => {
    dispatch(toggleOtpModal());
    if (Object.values(tmpUserData).length > 0) {
      dispatch(toggleSignUpModal());
    }
  };

  const resendCode = () => {
    localStorage.setItem(
      "otp_expiration",
      new Date().getTime() + EXPIRATION_TIME
    );
    if (forAdmin) {
      dispatch(getMobileOTP.request({ values: target, type: ADMIN }));
    } else if (forLogin) {
      dispatch(getMobileOTP.request({ values: target }));
    } else {
      //since we dont have another api for getting otp code for new number we need to use the same used for signup
      dispatch(signUp.request(tmpUserData));
    }
  };

  const displayTimer = () => (
    <div className={styles.timerBox}>
      <button onClick={resendCode} disabled={timer}>
        {forAdmin
          ? timer
            ? resendIn
            : resend
          : timer
          ? t("otp.resendIn")
          : t("otp.resend")}{" "}
        {timer ? (
          <p className="mx-1">
            <span>{mins}</span>
            <span>{" : "}</span>
            <span>{seconds < MIN_SECONDS_COUNT && 0}</span>
            <span>{seconds}</span>
          </p>
        ) : null}
      </button>
    </div>
  );

  useEffect(() => {
    if (!openOtpModal) {
      setMins(INITIAL_MINUTES);
      setSeconds(INITIAL_SECONDS);
    }
  }, [openOtpModal]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    if (otp_expiration > currentTime) {
      const date = new Date(otp_expiration - currentTime);
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      setSeconds(seconds);
      setMins(minutes);
    } else {
      localStorage.removeItem("otp_expiration");
    }
  }, [otp_expiration, openOtpModal]);

  useEffect(startTimer, [seconds, openOtpModal]);

  return (
    <Modal
      animation={false}
      onHide={toggle}
      show={openOtpModal && loaded}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.otpModal}
    >
      <div className={styles.modalHeader}>
        <button onClick={toggle}>&times;</button>
      </div>
      {isAlertActive && (
        <AlertBox error={error} additionalStyles="alert-danger text-center" />
      )}
      <div className={styles.otpForm}>
        <p className={styles.title}>
          <span>{forAdmin ? enterCode : t("otp.enterCode")}</span>
          <span>{Object.values(target)[0]}</span>
        </p>
        <form onSubmit={formik.handleSubmit}>
          <OTPinput formik={formik} />
          <button type="submit" className={forAdmin ? styles.submitBtn : ""}>
            {forAdmin ? confirmCode : t("otp.confirm")}
          </button>
        </form>
        {displayTimer()}
      </div>
    </Modal>
  );
}

export default OTPmodal;
