import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";
import { arabicToEnglishNums } from "utils/validation";
import styles from "./styles.module.scss";

const ContactList = ({ formik, styles: incomingStyles, name, amount }) => {
  const MAX_LENGTH = amount || 3;
  const { t } = useTranslation();

  const values = formik.values[name] || [{}];

  const addContact = () => formik.setFieldValue(name, [...values, {}]);

  const deleteContact = (index) => () =>
    formik.setFieldValue(
      name,
      values.filter((_, idx) => idx !== index)
    );

  useEffect(() => {
    if (!formik.values[name]) formik.setFieldValue(name, [{}]);
  }, [formik, name]);

  const getError = (field, index) =>
    formik.errors[name]?.[index]?.[field] && (
      <p className={styles.errorMessage}>
        {t(`messages.${formik.errors[name]?.[index]?.[field]}`)}
      </p>
    );

  const handleChange = (e) => {
    e.target.value = arabicToEnglishNums(e.target.value);
    formik.handleChange(e);
  };

  return (
    <>
      {values.map((_, index) => (
        <div className={styles.contactGroup} key={index}>
          <div>
            <input
              className={classnames("form-control", incomingStyles.optionInpt)}
              name={`${name}[${index}].company_name`}
              onChange={formik.handleChange}
              value={values[index]?.company_name || ""}
              placeholder={t("contact-list.company_name")}
            />
            {getError("company_name", index)}
          </div>

          <div>
            <input
              className={classnames("form-control", incomingStyles.optionInpt)}
              name={`${name}[${index}].contact_number`}
              onChange={handleChange}
              value={values[index]?.contact_number || ""}
              placeholder={t("contact-list.contact_number")}
              type="tel"
              inputMode="decimal"
            />
            {getError("contact_number", index)}
          </div>
          {values.length > 1 && (
            <button
              className={classnames("btn-list", styles.contactGroupDelete)}
              onClick={deleteContact(index)}
              type="button"
            >
              x
            </button>
          )}
          {values.length < MAX_LENGTH && index === values.length - 1 && (
            <button
              className={classnames("btn-list", styles.contactGroupAdd)}
              onClick={addContact}
              type="button"
            >
              +
            </button>
          )}
        </div>
      ))}
    </>
  );
};

ContactList.propTypes = {
  formik: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(ContactList);
