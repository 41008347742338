import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BASE_URL } from "constants/api";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const statuses = ["Coming Soon", "Open", "Closed", "Cancelled"];

const DraggableList = ({ headerList, bodyList, reorderHandler }) => {
  const dispatch = useDispatch();
  const [posChanged, setPosChanged] = useState(false);
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if(bodyList.length){
      setListItems(bodyList.map(({ id, logo, status, name }) => ({
          id: id,
          image: logo,
          status: statuses[status],
          name
        }))
      );
    }
  }, [bodyList, setListItems]);

  //@todo: implement when backend will be updated
  // useEffect(() => {
  //   if (listItems) {
  //     dispatch(reorderHandler.request({ data: listItems?.map((item, i) => ({ id: item.id, order: (i + 1).toString() }))}));
  //   }
  // }, [listItems.length, dispatch, reorderHandler]);

  useEffect(() => {
    if (posChanged) {
      dispatch(reorderHandler.request({ data: listItems?.map((item, i) => ({ id: item.id, order: (i + 1).toString() })) }));
    }
  }, [posChanged, dispatch, reorderHandler, listItems]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(
      listItems,
      result.source.index,
      result.destination.index
    );
    setListItems(items);
    setPosChanged(result.source.index !== result.destination.index);
    //@todo: implement when backend will be updated
    //if the position of the the item has changed reorder the list
    // if(result.source.index !== result.destination.index){
    //   dispatch(reorderHandler.request({ data: items?.map((item, i) => ({ id: item.id, order: (i + 1).toString() })) }));
    // }
  }; 

  
  return (
    <Fragment>
      <div
        className={classNames("d-flex", "w-100", "align-items-center", "bg-light", "py-2", "mb-2")}
      >
        {headerList.map((item, i) => (
          <p
            key={i}
            className={classNames(`wper-${item.w} text-center m-0`, styles.headerList)}
          >
            {item.name}
          </p>
        ))}
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={() => setPosChanged(false)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {listItems?.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={classNames({[styles.draggableRow_active]: snapshot.isDragging}, "mb-2")}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={classNames("d-flex", "w-100")}>
                        <div  className={`wper-${headerList[0].w} text-center`}>
                          <img src={`${BASE_URL}/${item.image}`} className="w-50" alt={item.name}/>
                        </div>
                        <p className={`wper-${headerList[1].w} text-center d-flex justify-content-center align-items-center`}>
                          <span className="badge badge-success ">{item.status}</span>
                        </p>
                        <p className={`wper-${headerList[2].w} text-center d-flex justify-content-center align-items-center`}>{item.name}</p>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};

DraggableList.propTypes = {
  headerList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      w: PropTypes.number.isRequired,
    })
  ),
  bodyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  reorderHandler: PropTypes.object.isRequired
};

export default DraggableList;
