import React from "react";
import Layout from "layouts/Admin";
import { AdminViewUsersDetails } from "components";

const AdminUsersDetails = () => (
  <Layout title="Matloob User Details">
      <AdminViewUsersDetails/>
      {/* <AdminUserElements/> */}
  </Layout>
);

export default AdminUsersDetails;