import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TERMS, POLICY } from "constants/paths";
import { Link } from "react-router-dom";
import { RadioButton, Checkbox, PhoneInput, AlertBox } from "components";
import classnames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useError, useFieldTouched } from "utils/forms";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "store/actions/auth.actions";
import {
    phoneNumberWithoutCountryCode,
    arabicToEnglishNums,
    emailChars,
    digits,
    englishOrArabic,
} from "utils/validation";
import { INDIVIDUAL, COMPANY } from "constants/forms";
import { ID_LENGTH } from "components/Accordion/contants";
import styles from "./styles.module.scss";

const MIN_LENGTH = 2;
const IQAMA = 2;
const NATIONAL_ID = 1;
const VALID_PHONE_LENGTH = 12;
const EXPIRATION_TIME = 1000 * 60 * 2;

const SignUpForm = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        signupError: error,
        tmpUserData,
        loaded,
    } = useSelector((state) => state.auth);
    const isAlertActive = useSelector(({ alert }) => alert);
    const defaultValidation = {
        email: Yup.string()
            .email(t("messages.invalidEmail"))
            .required(t("messages.required"))
            .matches(emailChars, t("messages.name_en")),
        mobile_number: Yup.string()
            .matches(phoneNumberWithoutCountryCode, t("messages.invalidPhone"))
            .max(VALID_PHONE_LENGTH, t("messages.invalidPhone"))
            .min(VALID_PHONE_LENGTH, t("messages.invalidPhone"))
            .required(t("messages.required")),
        accept: Yup.boolean().test(
            "isTrue",
            t("messages.required"),
            (value) => value === true
        ),
    };
    let identity_type = null;
    const individualSchema = Yup.object().shape({
        ...defaultValidation,
        full_name: Yup.string()
            .matches(englishOrArabic, t("messages.arabicOrEnglish"))
            .test("len", t("messages.enterFullName"), (val) =>
                val
                    ? val?.split(" ").filter((i) => !!i).length >= MIN_LENGTH
                    : true
            )
            .required("Required"),
        id_number: Yup.string()
            .test(
                "len",
                t("messages.numbers10"),
                (val) => String(val)?.split("").length === ID_LENGTH
            )
            .test("isNationalId", t("messages.invalidIdNumber1"), (value) => {
                const firstLetter = value ? String(value)[0] : null;
                return +identity_type === 1
                    ? firstLetter === "1"
                    : true;
            })
            .test("isIqama", t("messages.invalidIdNumber2"), (value) => {
                const firstLetter = value ? String(value)[0] : null;
                return +identity_type === 2
                    ? firstLetter === "2" || firstLetter === "3"
                    : true;
            })
            .typeError(t("messages.invalidCode"))
            .required("."),
            identity_type: Yup.number().test("", "", (val) => {
                identity_type = val;
                return true;
            }),
    });

    const companySchema = Yup.object().shape({
        ...defaultValidation,
        company_name: Yup.string()
            .matches(englishOrArabic, t("messages.arabicOrEnglish"))
            .required("."),
        commercial_record: Yup.number()
            .test(
                "len",
                t("messages.numbers10"),
                (val) => String(val)?.split("").length === ID_LENGTH
            )
            .typeError(t("messages.invalidCode"))
            .required("."),
    });

    const [userType, setUserType] = useState(INDIVIDUAL);
    const [schema, setSchema] = useState(individualSchema);

    useEffect(() => {
        setUserType(tmpUserData?.user_type);
    }, [tmpUserData]);

    useEffect(() => {
        if (+userType === INDIVIDUAL) {
            setSchema(individualSchema);
        }
        if (+userType === COMPANY) {
            setSchema(companySchema);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType]);

    const formik = useFormik({
        initialValues: {
            user_type: tmpUserData?.user_type || INDIVIDUAL,
            mobile_number: tmpUserData?.mobile_number || "",
            email: tmpUserData?.email || "",
            identity_type: tmpUserData?.identity_type || "1",
            full_name: tmpUserData?.full_name || "",
            id_number: tmpUserData?.id_number || "",
            company_name: tmpUserData?.company_name || "",
            commercial_record: tmpUserData?.commercial_record || "",
            accept: false,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            const data = {
                user_type: +values.user_type,
                mobile_number: values.mobile_number,
                email: values.email,
            };
            if (values.user_type === INDIVIDUAL) {
                data.full_name = values.full_name;
                data.identity_type = +values.identity_type;
                data.id_number = values.id_number;
            } else {
                data.company_name = values.company_name;
                data.commercial_record = values.commercial_record;
            }
            localStorage.setItem(
                "otp_expiration",
                new Date().getTime() + EXPIRATION_TIME
            );
            localStorage.setItem("userType", userType);
            dispatch(signUp.request(data));
        },
    });

    const getError = useError(formik);
    const setFieldTouched = useFieldTouched(formik);

    const handleNumberInputChange = (e) => {
        e.target.value = arabicToEnglishNums(e.target.value);
        if (digits.test(e.target.value) || !e.target.value) {
            formik.handleChange(e);
        }
    };

    const checkError = (name) => formik.errors[name] && formik.submitCount;

    const defaultFields = () => (
        <>
            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.registerAs")}
                </label>
                <div className={styles.radioGroup}>
                    <div className={styles.radioItem}>
                        <label htmlFor="individual">
                            <RadioButton
                                value={INDIVIDUAL}
                                name="user_type"
                                onChange={formik.handleChange}
                                onClick={setFieldTouched}
                                formik={formik}
                            />
                            {t("signup.individual")}
                        </label>
                    </div>
                    <div className={styles.radioItem}>
                        <label htmlFor="company">
                            <RadioButton
                                value={COMPANY}
                                name="user_type"
                                onChange={formik.handleChange}
                                onClick={setFieldTouched}
                                formik={formik}
                            />
                            {t("signup.company")}
                        </label>
                    </div>
                </div>
            </div>
            <div
                className={classnames(
                    styles.signupFormGroup,
                    styles.phoneInput
                )}
            >
                <label htmlFor="" className="requiredField">
                    {t("signup.phone")}
                </label>
                <div>
                    <PhoneInput
                        name="mobile_number"
                        formik={formik}
                        className={classnames(
                            "form-control",
                            styles.optionInpt,
                            {
                                [styles.invalidField]:
                                    checkError("mobile_number"),
                            }
                        )}
                        onFocus={setFieldTouched}
                    />
                </div>
                {getError("mobile_number")}
            </div>
            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.email")}
                </label>
                <input
                    value={formik.values.email}
                    name="email"
                    type="email"
                    className={classnames("form-control", {
                        [styles.invalidField]: checkError("email"),
                    })}
                    placeholder={t("signup.email")}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                />
                {getError("email")}
            </div>
        </>
    );

    const individualFields = () => (
        <>
            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.fullName")}
                </label>
                <input
                    value={formik.values.full_name}
                    name="full_name"
                    type="text"
                    className={classnames("form-control", {
                        [styles.invalidField]: checkError("full_name"),
                    })}
                    placeholder={t("signup.firstAndLast")}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                />
                {getError("full_name")}
            </div>

            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.idType")}
                </label>
                <div className={styles.radioGroup}>
                    <div className={styles.radioItem}>
                        <label htmlFor="NID">
                            <RadioButton
                                formik={formik}
                                name="identity_type"
                                value={NATIONAL_ID}
                                onChange={formik.handleChange}
                                onClick={setFieldTouched}
                                className={classnames({
                                    [styles.invalidRadio]:
                                        checkError("identity_type"),
                                })}
                            />
                            {t("signup.national")}
                        </label>
                    </div>
                    <div className={styles.radioItem}>
                        <label htmlFor="Iqama">
                            <RadioButton
                                formik={formik}
                                name="identity_type"
                                value={IQAMA}
                                onChange={formik.handleChange}
                                onClick={setFieldTouched}
                                className={classnames({
                                    [styles.invalidRadio]:
                                        checkError("identity_type"),
                                })}
                            />
                            {t("signup.iqama")}
                        </label>
                    </div>
                </div>
                {getError("identity_type")}
            </div>

            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.idNumber")}
                </label>
                <input
                    type="tel"
                    inputMode="decimal"
                    name="id_number"
                    className={classnames("form-control", {
                        [styles.invalidField]: checkError("id_number"),
                    })}
                    placeholder={t("signup.idNumber")}
                    onChange={handleNumberInputChange}
                    onFocus={setFieldTouched}
                    value={formik.values.id_number}
                    maxLength="10"
                />
                {getError("id_number")}
            </div>
        </>
    );

    const companyFields = () => (
        <>
            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.companyName")}
                </label>
                <input
                    type="text"
                    name="company_name"
                    className={classnames("form-control", {
                        [styles.invalidField]: checkError("company_name"),
                    })}
                    placeholder={t("signup.companyName")}
                    onChange={formik.handleChange}
                    onFocus={setFieldTouched}
                />
                {getError("company_name")}
            </div>

            <div className={styles.signupFormGroup}>
                <label htmlFor="" className="requiredField">
                    {t("signup.commercialRecord")}
                </label>
                <input
                    type="tel"
                    inputMode="decimal"
                    name="commercial_record"
                    className={classnames("form-control", {
                        [styles.invalidField]: checkError("commercial_record"),
                    })}
                    placeholder="1234567890"
                    onChange={handleNumberInputChange}
                    onFocus={setFieldTouched}
                    value={formik.values.commercial_record}
                    maxLength="10"
                />
                {getError("commercial_record")}
            </div>
        </>
    );

    useEffect(() => {
        setUserType(formik.values.user_type);
    }, [formik.values.user_type]);

    return (
        <div>
            <div className={styles.signupWrapper}>
                <div className={styles.signupForm}>
                    <form action="" onSubmit={formik.handleSubmit}>
                        {defaultFields()}
                        {+formik.values.user_type === INDIVIDUAL &&
                            individualFields()}
                        {+formik.values.user_type === COMPANY &&
                            companyFields()}

                        <div
                            className={classnames(
                                styles.signupFormGroup,
                                styles.acceptMessage
                            )}
                        >
                            <label htmlFor="accept">
                                <Checkbox
                                    name="accept"
                                    value={true}
                                    formik={formik}
                                    className={classnames({
                                        [styles.invalidRadio]:
                                            checkError("accept"),
                                    })}
                                    onChange={formik.handleChange}
                                />
                                <p>
                                    {t("signup.agree")}
                                    <Link to={POLICY} target="_blank">
                                        {t("signup.policy")}
                                    </Link>
                                    {t("signup.and")}
                                    <Link to={TERMS} target="_blank">
                                        {t("signup.terms")}
                                    </Link>
                                </p>
                            </label>
                            {getError("accept")}
                        </div>

                        {isAlertActive && (
                            <AlertBox
                                error={error}
                                additionalStyles="alert-danger text-center"
                            />
                        )}
                        <button
                            type="submit"
                            disabled={!loaded}
                            onClick={formik.handleSubmit}
                        >
                            {t("signup.title")}
                        </button>
                    </form>
                    <p className={styles.alreadyAccount}>
                        <span>{t("signup.haveAccount")}</span>
                        <span onClick={closeModal}>{t("login.title")}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm;
