import React,{useState} from 'react'
import ReactPlayer from 'react-player';
import VideoButton from "assets/images/loginImg/video-play-button.png";
import classnames from 'classnames';

import styles from './styles.module.scss'


const MediaCenterPlayer = ({video,carousel}) => {
    const [isPause, setIsPause] = useState(true);
    const [isStarted, setIsStarted] = useState(false);
    const handlePlay = () => {
        setIsPause(false);
        setIsStarted(true);
    };
    return (
        <>
            <div className={classnames(styles.videoWrapper, {
                [styles.videoWrapperCarousel]: carousel,
                })}>
                <ReactPlayer
                width="100%"
                height="100%"
                url={video.video}
                volume={0.1}
                controls={isStarted}
                onPlay={() => setIsPause(false)}
                onPause={() => setIsPause(true)}
                playing={!isPause}
                />
                {isPause ? (
                        <button
                            className={styles.videoSectionButton}
                            onClick={handlePlay}
                        >
                            <img
                                src={VideoButton}
                                alt="play-button"
                            />
                        </button>
                    ) : null}
                </div>   
        </>
    )
}

export default MediaCenterPlayer;