import axiosInstance from "./interceptor";

export const AdminElementsApi = {
  addApplicationRequirement: (data) =>
    axiosInstance.post("/admin/application-requirements", data),
  adminCategories: (data) => axiosInstance.get(`${data}`),
  changeCategoryService: (form_id, level) =>
    axiosInstance.post("admin/favorites/add", { form_id, level }),
  addNewCPUser: (data) => axiosInstance.post("/admin/admin-users", data),
  createElementComment: (data) => axiosInstance.post("/admin/comments", data),
  changeBlockedStatus: ({ id, isBlocked }) =>
    axiosInstance.post(`/admin/users/${id}/change-permit`, {
      blocked: isBlocked,
    }),
  deleteElementComment: (comment_id) =>
    axiosInstance.delete(`/admin/comments/${comment_id}`),
  deleteCPUser: (admin_id) =>
    axiosInstance.delete(`admin/admin-users/${admin_id}`),
  deleteMatloobUser: (user_id) =>
    axiosInstance.delete(`admin/users/${user_id}`),
  editElementComment: ({ comment_id, comment }) =>
    axiosInstance.put(`/admin/comments/${comment_id}`, { comment }),
  editApplicationRequirement: ({ id, requirement }) =>
    axiosInstance.put(`/admin/application-requirements/${id}`, requirement),
  editCPUserDetails: ({ id, data }) =>
    axiosInstance.put(`/admin/admin-users/${id}`, data),
  getElementsUsersTarget: (type, parameters) =>
    axiosInstance.get(`/admin/export/${type}`, { params: { ...parameters } }),
  getElementsUsers: (type, parameters, page = 1, perPage = 100) =>
    axiosInstance.get(`/admin/forms/${type}`, {
      params: { page, perPage, ...parameters },
    }),
  getElementUserDetails: ({ type, id }) =>
    axiosInstance.get(`/admin/forms/${type}/${id}`),
  postAssignDirectorToElements:(data) =>
    axiosInstance.post(`/admin/forms/assign-director-to-elements`,data),
  getAssignDirectorToElements:(id) =>
    axiosInstance.get(`/admin/forms/assign-director-to-elements/${id}`),
  updateAssignDirectorToElements:(id,data) =>
    axiosInstance.post(`/admin/forms/assign-director-to-elements/${id}`,data),
  getElementComments: (element_id) =>
    axiosInstance.get("/admin/comments", { params: { element_id } }),
  getElementComment: (comment_id) =>
    axiosInstance.get(`/admin/comments/${comment_id}`),
  getApplicationRequirements: ({ page = 1, perPage = 100 }) =>
    axiosInstance.get("/admin/application-requirements", {
      params: { page, perPage },
    }),
  getApplicationRequirement: (id) =>
    axiosInstance.get(`/admin/application-requirements/${id}`),
  getAdminRegions: () => axiosInstance.get("/saudi-regions"),
  getMatloobUsers: ({ page = 1, type, parameters, perPage = 100 }) =>
    axiosInstance.get("/admin/users/list", {
      params: { page, perPage, user_type: type, ...parameters },
    }),
  getMatloobUserElements: (id, page = 1, perPage = 10) =>
    axiosInstance.get(`/admin/users/${id}/user-elements`, {
      params: { page, perPage },
    }),
  getMatloobUserDetails: (id) => axiosInstance.get(`/admin/users/${id}`),
  getEvents: (type, page = 1, perPage = 6) =>
    axiosInstance.get("admin/events", {
      params: { page, perPage, archive: type },
    }),
  getSeasons: (type, page = 1, perPage = 6) =>
    axiosInstance.get("admin/seasons", {
      params: { page, perPage, archive: type },
    }),
  getCPUsers: ({ type = null, parameters, page = 1, perPage = 100 }) =>
    axiosInstance.get("/admin/admin-users", {
      params: { page, perPage, user_type: type, ...parameters },
    }),
  getDashboardStatistics: () =>
    axiosInstance.get("/admin/dashboard-statistics"),
  getSubmittedFormsStatistics: (dates) =>
    axiosInstance.get("/admin/submitted-forms-statistics", {
      params: dates && { ...dates },
    }),
  getElementStatistics: () => axiosInstance.get("/admin/element-statistics"),
  getUserActions: () => axiosInstance.get("/admin/user-actions"),
  getCPUserDetails: (id) => axiosInstance.get(`/admin/admin-users/${id}`),
  removeFavoriteUser: (form_id) =>
    axiosInstance.post("admin/favorites/remove", { form_id }),
  reorderSeasons: (data) =>
    axiosInstance.post("/admin/seasons/reorder", { data }),
  reorderEvents: (data) =>
    axiosInstance.post("/admin/events/reorder", { data }),
  downloadFile: (data) =>
    axiosInstance.get(`/admin/download/${data}`, { responseType: "blob" }),
  exportAdminStatistics: () =>
    axiosInstance.get("/admin/export/stats/csv", { responseType: "blob" }),
  getAdminMainElementsTypes: () =>
    axiosInstance.get("/admin/user-element-types"),
  getAdminMainElementFormats: (format) => axiosInstance.get(`/${format}`),
  createAdminEventSeason: ({ data, type }) =>
    axiosInstance.post(`/admin/${type}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getNotificationsHistory: ({ page = 1, perPage = 10 }) =>
    axiosInstance.get("/admin/messaging/history", {
      params: { page, perPage },
    }),
  getAllEvents: () => axiosInstance.get("admin/events"),
  getAllSeasons: () => axiosInstance.get("admin/seasons"),
  createNotification: (data) =>
    axiosInstance.post("/admin/messaging/notification", data),
  getSeason: (id) => axiosInstance.get(`/admin/seasons/${id}`),
  getEvent: (id) => axiosInstance.get(`/admin/events/${id}`),
  editEventSeason: (type, id, data) =>
    axiosInstance.post(`/admin/${type}/${id}`, data),
  getEventsSeasonsRequests: (type, page = 1, perPage = 6) =>
    axiosInstance.get(`admin/${type}-no`, { params: { page, perPage }}),
  getEventsSeasonsStatusRequests: (type,seasonType, page = 1, perPage = 6, ) =>
    axiosInstance.get(`admin/${type}-no`, { params: {archive:seasonType, page, perPage }}),
  updateBulkEventSeasonStatusRequest:(type,data,params)=> {
    return  axiosInstance.put(`/admin/user-requests/${type}s/bulk_update`, data)
  },
  getEventSeasonRequest: (typeId, params, page = 1, perPage = 100) =>
    axiosInstance.get(`admin/user-requests/main-elements/${typeId}`, {
      params: { page, perPage, ...params },
    }),
  changeRequestStatus: (data, id, params) =>
    axiosInstance.put(`admin/user-requests/${id ? id : ""}`, data),
  getSupportingElementsRequests: ({ id, elementId, page = 1, perPage = 100 }) =>
    axiosInstance.get(
      `admin/user-requests/support-elements/${id}${
        elementId ? `/${elementId}` : ""
      }`,
      { params: { page, perPage } }
    ),
  getEventsArrangement: () => axiosInstance.get("admin/saudi-events-lookup"),
  getSeasonsArrangement: () => axiosInstance.get("admin/saudi-seasons-lookup"),
  getEventSeasonRequestsInfo: (typeId, id) =>
    axiosInstance.get(`admin/user-requests/main-elements/${typeId}/${id}`),
  getUpdatedHistory: (id) =>
    axiosInstance.get(`admin/user-requests/status-log/${id}`),
  getUsersRequestTarget: (parameters) =>
    axiosInstance.get("admin/export/user-requests", {
      params: { ...parameters },
    }),
  getApplicationRequirementRequestsTarget: (id) =>
    axiosInstance.get(`admin/download/${id}`, { responseType: "blob" }),
  getEventSeasonApplicationRequirements: () =>
    axiosInstance.get("admin/application-requirements/autocomplete"),
  getVenueCategories: () => axiosInstance.get(`/venue-categories`),
  getVenueTopographies: () => axiosInstance.get(`/venue-topographies`),
  getVenueLandTypes: () => axiosInstance.get(`/venue-land-types`),
  getUsersRequests: (typeId, perPage = 100) =>
    axiosInstance.get(`admin/user-requests`, {
      params: { user_id: typeId, perPage },
    }),
  getReports: ({ page = 1, perPage = 100 }) =>
    axiosInstance.get("admin/export-jobs", {
      params: { page, perPage },
  }),
  deleteReports: (id) =>
    axiosInstance.delete(`admin/export-jobs/${id}`),
  getReportDownload: (downloadLink) =>
    axiosInstance.get(downloadLink, { responseType: 'blob' }),
  getMediaCenterNewsHistory: ({ page = 1, perPage = 100 }) =>
    axiosInstance.get("admin/news", {
      params: { page, perPage },
  }),
  addMediaCenterNewNews: (data) => 
    axiosInstance.post("admin/news", data),
  getAdminMediaCenterNewsDetails: ({ id }) =>
    axiosInstance.get(`admin/news/${id}`),
  deleteMediaCenterNews: (id) => 
    axiosInstance.delete(`admin/news/${id}`),
  editMediaCenterNews: (id, data) =>
    axiosInstance.post(`admin/news/${id}`, data),
  getMediaCenterImagesVideosHistory: ({ page = 1, perPage = 100 }) =>
    axiosInstance.get("admin/media-center", {
      params: { page, perPage },
    }),
    addMediaCenterNewItem: (data) => 
      axiosInstance.post("admin/media-center", data),
    editMediaCenterItem: (id, data) =>
      axiosInstance.post(`admin/media-center/${id}`, data),
    deleteMediaCenterItem: (id) => 
      axiosInstance.delete(`admin/media-center/${id}`),
    getAdminMediaCenterImagesVideosDetails: ({ id }) =>
      axiosInstance.get(`admin/media-center/${id}`),
    getMediaCenterCarouselImagesHistory: ({page = 1, perPage = 100 }) =>
      axiosInstance.get("admin/media-center-carousel-images", {
      params: { page, perPage },
    }),
    addMediaCenterNewCarouselImage: (data) => 
      axiosInstance.post("admin/media-center-carousel-images/store", data),
    deleteMediaCenterCarouselImage: (id) => 
      axiosInstance.delete(`admin/media-center-carousel-images/${id}`),
    getAdminMediaCenterCarouselImagesDetails: ({ id }) =>
      axiosInstance.get(`admin/media-center-carousel-images/${id}`),
    editMediaCenterCarouselImage: (id, data) =>
      axiosInstance.post(`admin/media-center-carousel-images/${id}`, data),
    getAdminElementsDropDown: () =>
      axiosInstance.get(`/admin/forms/elements-drop-down-menu`)
};