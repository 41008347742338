import React from "react"
import Layout from "layouts/Admin";
import { MediaCenterAddEditCarouselImage } from "components";

import { useParams } from 'react-router-dom';

const AdminMediaCenterAddEditCarouselImage = () => {
  const { id } = useParams();
  return (
    <Layout  title={`${id ? "Edit" : "Create New"}  Carousel Image`}>
      <MediaCenterAddEditCarouselImage id={id}/>
    </Layout>
  )
}

export default AdminMediaCenterAddEditCarouselImage;