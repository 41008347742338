import React from "react";
import styles from "./styles.module.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SignUpForm } from "components";
import { useDispatch, useSelector } from "react-redux";
import { toggleSignUpModal, removeUser } from "store/actions/auth.actions";

function OTPmodal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openSignupModal, loaded } = useSelector(state => state.auth);

  const toggle = () => {
    dispatch(removeUser())
    dispatch(toggleSignUpModal());
  }

  return (
    <Modal
      onHide={toggle}
      show={openSignupModal && loaded}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={styles.modalOtpContent}
      className={styles.modal}
    >
      <div className={styles.modalHeader}>
        <p>{t("signup.title")}</p>
        <button onClick={toggle}>&times;</button>
      </div>
      <SignUpForm closeModal={toggle}/>
    </Modal>
  );
}

export default OTPmodal;
