import { ALERT_SET, ALERT_REMOVE } from 'store/actions/alert.actions';

const initialState = false;

export default function (state = initialState, action) {
  switch(action.type){
    case ALERT_SET:
      return true;
    case ALERT_REMOVE:
      return false;
    default:
      return state;
  }
}