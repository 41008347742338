import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Context } from "context";
import {
  Loader,
  AdminCardItem,
  AdminTopPanel,
  Pagination,
  AlertBox
} from "components";
import { useSelector, useDispatch } from "react-redux";
import { getSeasons, getAdminRegions } from "store/actions/adminElements.actions";
import { NEW_SEASON, EDIT_SEASON } from "constants/paths";
import { compile } from "path-to-regexp";
import { EDITION } from "constants/forms";

const statuses = [
  { name: "Active", type: 0 },
  { name: "Archived", type: 1 }
];
const AdminSeasonsList = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState({ name: "Active", type: 0 });
  const { user } = useContext(Context);

  const { adminRegions, seasons, loaded, error } = useSelector(({ adminElements }) => adminElements);
  const isAlertActive =  useSelector(({ alert }) => alert);

  const [seasonsData, setSeasonsData] = useState(seasons);

  const history = useHistory();

  const regionsData = Object.fromEntries(
    adminRegions.map(item => [item.id, item.name])
  );

  const handleChange = ({ target: { value } }) => {
    setSeasonsData(
      seasons?.data.filter(item =>
        item?.name_en?.toLowerCase()?.match(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    dispatch(getSeasons.request({ type: status?.type }));
  }, [dispatch, status]);

  useEffect(() => {
    dispatch(getAdminRegions.request());
  }, [dispatch]);

  useEffect(() => {
    setSeasonsData(seasons?.data);
  }, [seasons]);

  const searchTools = {
    placeholder: "Search season",
    onChange: handleChange,
    order: 1,
  };

  const redirectTo = (action, id = null) => {
    if(action === EDITION){
      history.push(compile(EDIT_SEASON)({ id }));
      return;
    }
    history.push(compile(NEW_SEASON)());
  }

  const dropdownTools = {
    label: "Status",
    dropdownElements: statuses,
    optionSelected: status,
    setOptionSelected: setStatus,
    order: 0,
  };

  const buttonTools = {
    label: 'New Season',
    onClick: () => redirectTo(),
    order: 2
  }

  return (
    <Loader loaded={loaded}>
      <AdminTopPanel
        withSearch
        searchTools={searchTools}
        withDropdown
        dropdownTools={dropdownTools}
        withButton={user?.user_type !== 3}
        buttonTools={buttonTools}
      />

      {
        isAlertActive &&
        <AlertBox
          error={error}
          additionalStyles='alert-danger'
        />
      }

      <Row>
        {seasonsData?.length ? (
          seasonsData?.map(item => (
            <Col key={item.id} sm={12} md={6} lg={4} xl={4} className="my-2">
              <AdminCardItem
                img={item.logo} 
                startDate={item.start_date}
                endDate={item.end_date}
                name={item.name_en}
                region={regionsData[item.region_id]}
                status={item.status}
                redirectTo={() => redirectTo(EDITION, item.id)}
              />
            </Col>
          ))
        ) : (
          <Col>
            <div className="text-center">Not Found</div>
          </Col>
        )}
      </Row>
      <Pagination
        action={getSeasons}
        currentPage={seasons?.current_page}
        lastPage={seasons?.last_page}
        type={status.type}
        goToPage
      />
    </Loader>
  );
};

export default AdminSeasonsList;
