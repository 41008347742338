import { createRequestTypes, createActionTypes } from "utils/redux";

export const GET_INTERESTS = createRequestTypes("GET_INTERESTS");
export const CREATE_INTEREST = createRequestTypes("CREATE_INTEREST");
export const EDIT_INTEREST = createRequestTypes("EDIT_INTEREST");
export const GET_INTEREST = createRequestTypes("GET_INTEREST");
export const GET_REGIONS = createRequestTypes("GET_REGIONS");
export const GET_CITIES = createRequestTypes("GET_CITIES");
export const DELETE_INTEREST = createRequestTypes("DELETE_INTEREST");
export const GET_ADMIN_MAIN_ELEMENTS = createRequestTypes("GET_ADMIN_MAIN_ELEMENTS");
export const GET_ADMIN_SUPPORTING_ELEMENTS = createRequestTypes("GET_ADMIN_SUPPORTING_ELEMENTS");
export const GET_DASHBOARD_ELEMENTS_REQUEST = createRequestTypes("GET_DASHBOARD_ELEMENTS_REQUEST");
export const CLEAR_SUCCESSFULLY_FIELD = "CLEAR_SUCCESSFULLY_FIELD";
export const GET_DISABILITY_TYPES = createRequestTypes("GET_DISABILITY_TYPES");


export const getDisabilityTypes = createActionTypes(GET_DISABILITY_TYPES);
export const getInterests = createActionTypes(GET_INTERESTS);
export const createInterest = createActionTypes(CREATE_INTEREST);
export const editInterest = createActionTypes(EDIT_INTEREST);
export const getInterest = createActionTypes(GET_INTEREST);
export const getRegions = createActionTypes(GET_REGIONS);
export const getCities = createActionTypes(GET_CITIES);
export const deleteInterest = createActionTypes(DELETE_INTEREST);
export const getAdminMainElements = createActionTypes(GET_ADMIN_MAIN_ELEMENTS);
export const getAdminSupportingElements = createActionTypes(GET_ADMIN_SUPPORTING_ELEMENTS);
export const getDashboardElementsRequest = createActionTypes(GET_DASHBOARD_ELEMENTS_REQUEST);
export const clearSuccessfullyField = () => ({type: CLEAR_SUCCESSFULLY_FIELD})
