import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RADIO_LIST, SOCIAL_MEDIA, OTHER_CATEGORY } from "./../contants";
import ChevronDown from "assets/images/chevron-down.svg";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { removeFieldIfNotEmpty } from "../../../utils/helpers";

const socialMedias = [
  { label: "twitter" },
  { label: "instagram" },
  { label: "google_maps" },
  { label: "website" },
];

const AccordionHeader = ({
  formik,
  index,
  handleToggle,
  detail,
  isAdmin,
  dependencyValue,
  filteredOptions,
  userType,
}) => {
  const { t } = useTranslation();
  const [accordionStatus, setAccordionStatus] = useState(0);
  const { detailsSelected } = useSelector(({ accordion }) => accordion);
  const { ...state } = useSelector((state) => state.elements);
  const tabFields = [];
  const classStatuses = {
    0: styles.hexagonProgressEmpty,
    1: styles.hexagonProgressHalfFilled,
    2: styles.hexagonProgressFilled,
    3: styles.hexagonProgressUndefined,
  };

  const tabProgressHandler = () => {
    filteredOptions.forEach((option) => {
      if (
        option.type === SOCIAL_MEDIA ||
        option.optional ||
        option?.required === false
      )
        return null;
      if (option.type === RADIO_LIST) concatListWithSubCategory(option);
      if (option.mightbeReplaced) {
        const field =
          formik.values[option.label] || formik.values[option.mightbeReplaced];
        if (!field) tabFields.push(option.label);
      } else {
        tabFields.push(option.label);
      }

      defineProgressStatus(option.type);
      return null;
    });
    return null;
  };
  // Check is subcategory in tab
  const concatListWithSubCategory = (option) => {
    if (option.subCategory) {
      const currnetOption = state?.[option.data]?.find(
        (category) => category.id === formik.values[option.label]
      );
      if (currnetOption?.children.length) tabFields.push(option.subCategory);
    }
    return null;
  };
  // Set progress status
  const defineProgressStatus = (type) => {
    if (
      tabFields.every((el) =>
        formik.values[el] === 0 ? true : formik.values[el]
      )
    ) {
      //remove item if it not empty

      if (formik.values.talent_category_id !== OTHER_CATEGORY) {
        for (let i in formik.values?.personal_info) {
          delete formik.values?.personal_info[i].disability_other_category;
        }
      }
      formik.values.personal_info &&
        formik.values.personal_info.forEach((val) => {
          if (val.additional_support === 0) {
            delete val.support_description;
          }
          if (val.special_needs === 0) {
            delete val.disability_period;
            delete val.disability_type;
            delete val.disability_other_category;
            delete val.additional_support;
            delete val.support_description;
          }
        });

      removeFieldIfNotEmpty(formik.values, tabFields);
      setAccordionStatus(2);
      tabFields.forEach((fieldName) => {
        if (type !== "file" && Array.isArray(formik.values[fieldName])) {
          const result =
            formik.values[fieldName].length &&
            formik.values[fieldName].every((fields) => {
              if (
                typeof fields === "object" &&
                Object.values(fields).length > 0
              ) {
                return Object.values(fields)
                  .filter((el) => el !== "id")
                  .every((el) => el);
              }
              return fields;
            });
          !result && setAccordionStatus(1);
        }
      });
      if (detail.name === "contact_social_media" && !detail.optional) {
        socialMedias.some((el) => formik.values[el.label]) &&
          setAccordionStatus(2);
        socialMedias.every((el) => !formik.values[el.label]) &&
          setAccordionStatus(1);
      }
    } else if (
      tabFields.some((el) =>
        formik.values[el] === 0 ? true : formik.values[el]
      )
    ) {
      setAccordionStatus(1);
    } else if (tabFields.every((el) => !!formik.values[el] === false)) {
      setAccordionStatus(0);
      if (tabFields.every((el) => formik.values[el] === undefined))
        setAccordionStatus(3);
    }
  };
  useEffect(() => {
    if (!isAdmin) {
      tabProgressHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, detail]);
  if ((userType === 4 || userType === 3) && detail.title === "Update History") {
    return null;
  }

  return (
    <div
      className={classnames(styles.detailHeader, {
        [styles.selected]: detailsSelected[index],
      })}
      onClick={() => handleToggle(index)}
    >
      <div
        className={classnames(
          !isAdmin && styles.hexagonProgress,
          !isAdmin && classStatuses[accordionStatus]
        )}
      >
        <div
          className={classnames(
            styles.hexagonContainer,
            isAdmin && styles.hexagonContainerAdmin
          )}
        >
          <p className={styles.hexagonNumber}>{index + 1}</p>
        </div>
      </div>
      <div className={styles.detailTitleWrapper}>
        {isAdmin ? (
          <p
            className={classnames(styles.detailTitle, styles.detailTitleAdmin)}
          >
            {detail.title}
          </p>
        ) : (
          <p className={styles.detailTitle}>
            {t(`${detail?.title}.title`)}
            {formik?.values[detail.dependency] &&
              ` (${
                dependencyValue?.find(
                  (item) => item.id === formik.values[detail.dependency]
                )?.name
              })`}
          </p>
        )}
        <img className={styles.arrow} src={ChevronDown} alt="Arrow" />
      </div>
    </div>
  );
};

export default AccordionHeader;
